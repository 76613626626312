import { openNotification } from '@/Components/Feedback/Toast';
import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
} from '@/ControllerApiHook/Controller';
import {
  CREATE_CONFERENCE_DRAFT,
  FETCH_GET_INVENTORY,
  FETCH_GET_INVENTORY_DETAILS,
  FETCH_INVENTORY_RELEASE,
  FETCH_LIST_INVENTORY,
  FINISH_CONFERENCE,
  SAVE_RELAUNCH_INVENTORY,
} from '@/ControllerApiHook/UniqIds/Inventory/Inventory';
import { InventoryAPI } from '@/Data/API/Supply/InventoryApit';
import {
  IGetInventory,
  IGetInventoryDetails,
  IGetInventoryRelease,
} from '@/Data/Interfaces/response/Inventory/InventoryResponse';
import { messageErrorToast } from '@/Utils/MessageErrorToast';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { history } from '@/Store';

export const useConferenceCallAPI = () => {
  const { externalId: inventoryId } = useParams<{ externalId: string }>();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { data: inventory } = useControllerQueryApiHook<IGetInventory>({
    uniqId: FETCH_GET_INVENTORY,
    entityApi: InventoryAPI.getInventory,
    externalIds: [inventoryId],
  });

  const { data: inventoryDetails } =
    useControllerQueryApiHook<IGetInventoryDetails>({
      uniqId: FETCH_GET_INVENTORY_DETAILS,
      entityApi: InventoryAPI.getInventoryDetails,
      externalIds: [inventoryId],
    });

  const { data: inventoryRelease } =
    useControllerQueryApiHook<IGetInventoryRelease>({
      uniqId: FETCH_INVENTORY_RELEASE,
      entityApi: InventoryAPI.getInventoryRelease,
      externalIds: [inventoryId],
    });

  const saveConferenceDraft = useControllerMutateApiHook({
    uniqId: CREATE_CONFERENCE_DRAFT,
    entityApi: InventoryAPI.saveConferenceDraft,
    options: {
      onSuccess: () => {
        openNotification(
          'sucess',
          t('supply.inventory.conference.saveConferenceDraftSuccessToastTitle'),
          t(
            'supply.inventory.conference.saveConferenceDraftSuccessToastDescription'
          ),
          false
        );
      },
    },
  });

  const finishConference = useControllerMutateApiHook({
    uniqId: FINISH_CONFERENCE,
    entityApi: InventoryAPI.finishConference,
  });

  const relaunchInventory: any = useControllerMutateApiHook({
    uniqId: SAVE_RELAUNCH_INVENTORY,
    entityApi: InventoryAPI.saveRelaunchInventory,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_GET_INVENTORY]);
        queryClient.invalidateQueries([FETCH_GET_INVENTORY_DETAILS]);
        queryClient.invalidateQueries([FETCH_INVENTORY_RELEASE]);
        queryClient.invalidateQueries([FETCH_LIST_INVENTORY]);
        history.push(`/inventory/stock-taking/${inventoryId}/release`);
      },
    },
  });

  return {
    inventory,
    inventoryDetails,
    inventoryRelease,
    saveConferenceDraft,
    finishConference,
    relaunchInventory,
  };
};
