import { useState } from 'react';

export const useManipulatedRecipeStates = () => {
  const [stepCurrent, setStepCurrent] = useState(0);

  const [componentsEditing, setComponentsEditing] = useState<number[]>([]);
  const [validatingComponents, setValidatingComponents] =
    useState<boolean>(false);
  const [manipulatedRecipeExternalId, setManipulatedRecipeExternalId] =
    useState<string | undefined>(undefined);

  return {
    stepCurrent,
    setStepCurrent,

    componentsEditing,
    setComponentsEditing,

    validatingComponents,
    setValidatingComponents,

    manipulatedRecipeExternalId,
    setManipulatedRecipeExternalId,
  };
};
