import { Button, Tooltip } from '@/Components';
import { ButtonDropdown } from '@/Components/Button/ButtonDropdown';
import { PermissionWrapper } from '@/Components/PermissionWrapper';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useTicketsDetailsShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/Sales/Tickets/details';

import styles from './BudgetButtons.module.scss';

interface IBudgetButtons {
  disabledButton?: boolean;

  onOkClick?: () => void;
  onCancelClick?: () => void;
  onClickDropdownItem?: () => void;
}

export const BudgetButtons: FC<IBudgetButtons> = ({
  disabledButton,
  onCancelClick,
  onClickDropdownItem,
  onOkClick,
}) => {
  const { t } = useTranslation();

  const { getShortcut } = useTicketsDetailsShortcuts(t);
  const approveButton = getShortcut('approveSaleOrder');
  const reproveButton = getShortcut('reproveSaleOrder');
  
  return (
    <Divflex className={styles['action-buttons']}>
      <PermissionWrapper
        permission={PermissionEnum.Vendas_PedidosVenda_Aprovar}
        tooltip={t('noPermission.sale.order.toApprove')}
      >
        <Tooltip
          title={approveButton.tooltip}
          showMe
          children={
            <Button
              dataTestId="approve-sale-order-button-data-test-id"
              leftIcon="like"
              type="primary"
              status="success"
              disabled={disabledButton}
              children={t('common.approve')}
              onClick={onOkClick}
            />
          }
        />
      </PermissionWrapper>
      <PermissionWrapper
        permission={PermissionEnum.Vendas_PedidosVenda_Reprovar}
        tooltip={t('noPermission.sale.order.disapprove')}
      >
        <Tooltip
          title={reproveButton.tooltip}
          showMe
          children={
            <Button
              dataTestId="reprove-sale-order-button-data-test-id"
              leftIcon="disapproved"
              type="secondary"
              children={t('common.reprove')}
              onClick={onCancelClick}
            />
          }
        />
      </PermissionWrapper>
      <ButtonDropdown
        type={'secondary'}
        leftIcon="menu-ellipsis-horizontal"
        dropdownItens={[
          {
            icon: 'close-x',
            label: t('common.cancel'),
            onClick: onClickDropdownItem,
            permission: {
              permission: PermissionEnum.Vendas_PedidosVenda_Cancelar,
              tooltip: t('noPermission.sale.order.cancel'),
            },
          },
        ]}
      />
    </Divflex>
  );
};
