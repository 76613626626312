import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_GET_INVENTORY_DETAILS } from '@/ControllerApiHook/UniqIds/Inventory/Inventory';
import { InventoryAPI } from '@/Data/API/Supply/InventoryApit';

export const useGetInventoryDetails = (inventoryId: string) => {
  const { data: inventoryDetails } = useControllerQueryApiHook<any>({
    uniqId: FETCH_GET_INVENTORY_DETAILS,
    entityApi: InventoryAPI.getInventoryDetails,
    externalIds: [inventoryId],
    refetchOnWindowFocus: false,
  });

  return { inventoryDetails };
};
