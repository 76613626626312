import React from 'react';
import { Table } from '../../../../../Components';
import { useTranslation } from 'react-i18next';
import { balanceAdjustmentsColumns } from './balanceAdjustments.columns';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { ContainerPage } from '../../../../../Layouts/ContainerPage';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListMemorizations } from './Hooks/useListMemorizations';
import { useBalanceAdjustmentListShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/inventory/balanceAdjustments/list';

export const BalanceAdjustmentsList: React.FC = () => {
  const { t } = useTranslation();

  const { balanceAdjustmentsList } = useListCallAPI();

  const { getShortcut } = useBalanceAdjustmentListShortcuts(t, {});

  const { headerOptions, rowsOptions } = useListMemorizations({ getShortcut });

  return (
    <PageLoading loading={balanceAdjustmentsList.isLoading}>
      <ContainerPage>
        <Table
          columns={balanceAdjustmentsColumns(t)}
          rowsOptions={rowsOptions}
          headerOptions={headerOptions}
          loading={balanceAdjustmentsList.isLoading}
          onRefetch={balanceAdjustmentsList.refetch}
          pagination={balanceAdjustmentsList.data}
        />
      </ContainerPage>
    </PageLoading>
  );
};

export default BalanceAdjustmentsList;
