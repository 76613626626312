import { useCallback } from 'react';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { App } from 'antd';
import { useTranslation } from 'react-i18next';
import { IUseFunctions } from './interfaces';
import { ILabelTemplatesListData } from '@/Data/Interfaces/response/LabelTemplates/ILabelTemplatesResponses';
import { IEditLabelTemplateStatus } from '@/Data/Interfaces/request/LabelTemplates/ILabelTemplatesRequest';
import { cannotDeleteLabelTemplateWithMovementsInTheSystemProps } from '@/Utils/ErrorCodeUtils/ErrorModalUtils/ErrorModalLabelTemplates';

export const useListFunctions = ({
  setSelectedRowsOnTable,
  deleteLabelTemplates,
  editLabelTemplateStatus,
}: IUseFunctions) => {
  const { t } = useTranslation();
  const app = App.useApp();

  const onSelectedRowsOnTable = useCallback(
    (x: ILabelTemplatesListData[]) => setSelectedRowsOnTable(x),
    []
  );

  const changeLabelTemplateStatus = useCallback(
    async (values: IEditLabelTemplateStatus) => {
      var body: IEditLabelTemplateStatus = { ...values };
      await editLabelTemplateStatus.mutateAsync({
        ...body,
      });
    },
    [editLabelTemplateStatus]
  );

  const onEdit = useCallback(
    (
      labelTemplate: ILabelTemplatesListData[],
      values: any
    ) =>
      changeLabelTemplateStatus({
        externalId: labelTemplate.map(
          (x: ILabelTemplatesListData) => x.externalId
        ),
        ativo: values.status === 'success',
      }),
    [changeLabelTemplateStatus]
  );


  const onDelete = useCallback(
    async (labelTemplates: ILabelTemplatesListData[]) => {
      await deleteLabelTemplates.mutateAsync(
        {
          externalId: labelTemplates.map((x) => x?.externalId),
        },
        {
          onError: handleModalError(
            app,
            cannotDeleteLabelTemplateWithMovementsInTheSystemProps(t) 
          ),
        }
      );
    },
    [deleteLabelTemplates, app, t]
  );

  return {
    onSelectedRowsOnTable,
    onEdit,
    onDelete,
  };
};
