import {
    BootysShortcuts,
    defaultShortcuts,
    DefaultShortcuts,
  } from '@/Components/Shortcuts/keymaps/default';
  import { TFunction } from 'react-i18next';
  import { useHotkeys } from 'reakeys';
  import { isVisible } from '@/Utils/DOMUtils';
  import { getShortcut, getShortcuts } from '@/Components/Shortcuts';

  export const pageKey = 'posology-details';
  
  export interface PosologyDetailsTabCallbacks extends DefaultShortcuts {}
  
  const shortcuts = (
    t: TFunction<'translation', undefined>,
    callbacks: PosologyDetailsTabCallbacks
  ): BootysShortcuts[] => [
    ...defaultShortcuts(t, callbacks),
    {
      id: 'editStatus',
      name: t('admin.editUserPage.shortcuts.editStatus'),
      keys: 's',
      action: 'keydown',
      withText: true,
      callback: (event: any) => {
        event.preventDefault();
        const element = document.querySelector(
          '[data-testid="status-dropdown-button"]'
        );
        if (isVisible(element)) {
          //@ts-ignore
          element?.click();
        }
      },
    },
    {
      id: 'editStatusActive',
      name: t('admin.editUserPage.shortcuts.editStatus'),
      keys: '1',
      action: 'keydown',
      withText: true,
      callback: (event: any) => {
        event.preventDefault();
        const element = document.querySelector(
          '[data-testid="dropdown-option-active-status"]'
        );
        if (isVisible(element)) {
          //@ts-ignore
          element?.click();
        }
      },
    },
    {
      id: 'editStatusInactive',
      name: t('admin.editCompany.shortcuts.editAddress'),
      keys: '2',
      action: 'keydown',
      withText: true,
      callback: (event: any) => {
        event.preventDefault();
        const element = document.querySelector(
          '[data-testid="dropdown-option-inactive-status"]'
        );
        if (isVisible(element)) {
          //@ts-ignore
          element?.click();
        }
      },
    },
    {
      id: 'editMoreActions',
      name: t('admin.editUserPage.shortcuts.moreActions'),
      keys: '.',
      action: 'keydown',
      withText: true,
      callback: (event: any) => {
        event.preventDefault();
        const element = document.querySelector(
          '[data-testid="tab-dropdown-options"]'
        );
        if (isVisible(element)) {
          //@ts-ignore
          element?.click();
        }
      },
    },
    {
      id: 'deletePosology',
      name: t('admin.editUserPage.shortcuts.moreActions'),
      keys: 'backspace',
      action: 'keydown',
      callback: (event: any) => {
        event.preventDefault();
        const element = document.querySelector(
          '[data-testid="delete-posology-option"]'
        );
        if (isVisible(element)) {
          //@ts-ignore
          element?.click();
        }
      },
    },
    {
      id: 'editPosology',
      name: t('production.posology.details.shortcuts.editPosology'),
      keys: 'e',
      action: 'keydown',
      callback: (event: any) => {
        event.preventDefault();
        const element = document.querySelector(
          '[data-testid="edit-posology-button"]'
        );
        if (isVisible(element)) {
          //@ts-ignore
          element?.click();
        }
      },
    },
  ];
  export const usePosologyDetailsShortcuts = (
    t: TFunction<'translation', undefined>,
    callbacks: PosologyDetailsTabCallbacks
  ) => {
    const pageShortcuts = shortcuts(t, callbacks);
    const hotkeys = useHotkeys(pageShortcuts);
    return {
      ...hotkeys,
      getShortcut: getShortcut(pageShortcuts),
      getShortcuts: getShortcuts(pageShortcuts),
    };
  };