import { QueryFunctionContext } from '@tanstack/react-query';
import { Http, IQueryListParams } from '../../../Utils/Http';
import {
  ILabelTemplatesList,
  IGetLabelTemplate,
} from './../../Interfaces/response/LabelTemplates/ILabelTemplatesResponses';
import {
  ICreateLabelTemplates,
  IEditLabelTemplate,
  IEditLabelTemplateStatus,
  IDeleteLabelTemplateRequest,
} from '../../Interfaces/request/LabelTemplates/ILabelTemplatesRequest';

export class LabelTemplatesAPI {
  public static async createLabelTemplate(body: ICreateLabelTemplates) {
    const res = await Http.fetcher('/pharma/producao/v1/ModeloRotulo', {
      method: 'POST',
      body: JSON.stringify(body),
    });
    if (!res) return [];
    return res;
  }

  public static async getLabelTemplatesList(
    context: QueryFunctionContext
  ): Promise<ILabelTemplatesList | null> {
    if (!context.queryKey[1]) return null;

    return await Http.fetcher<ILabelTemplatesList>(
      Http.AddQueryParams(
        `/pharma/producao/v1/ModeloRotulo/Listar`,
        context.queryKey[1] as IQueryListParams
      )
    );
  }

  public static async getCurrentLabelTemplate(
    context: QueryFunctionContext
  ): Promise<IGetLabelTemplate> {
    return await Http.fetcher<IGetLabelTemplate>(
      `/pharma/producao/v1/ModeloRotulo/${context.queryKey[1]}`
    );
  }

  public static async editLabelTemplateStatus(
    body: IEditLabelTemplateStatus
  ) {
    const res = await Http.fetcher(
      `/pharma/producao/v1/ModeloRotulo/AtualizarStatus`,
      {
        method: 'PUT',
        body: JSON.stringify(body),
      }
    );
    if (!res) return [];
    return res;
  }

  public static async editLabelTemplate(body: IEditLabelTemplate) {
    const res = await Http.fetcher('/pharma/producao/v1/ModeloRotulo', {
      method: 'PUT',
      body: JSON.stringify(body),
    });
    if (!res) return [];
    return res;
  }

  public static async deleteLabelTemplate(body: IDeleteLabelTemplateRequest) {
    const res = await Http.fetcher(`/pharma/producao/v1/ModeloRotulo`, {
      method: 'DELETE',
      body: JSON.stringify(body),
    });
    if (!res) return [];
    return res;
  }

  public static async isLabelTemplateNameAlreadyRegistred(
    descricao?: string,
    tipoRotulo?: number
  ): Promise<boolean> {
    if (!descricao) return false;
    try {
      const result = await Http.fetcher(
        '/pharma/producao/v1/ModeloRotulo/ValidarDescricao',
        {
          method: 'POST',
          body: JSON.stringify({ descricao, tipoRotulo }),
        }
      );

      return result.success;
    } catch (e) {
      return false;
    }
  }
}
