import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import {
  FETCH_GET_INVENTORY,
  FETCH_GET_INVENTORY_DETAILS,
  FETCH_INVENTORY_RELEASE,
  FETCH_LIST_INVENTORY,
  SAVE_PUT_DRAFT_RELEASE,
} from '@/ControllerApiHook/UniqIds/Inventory/Inventory';
import { InventoryAPI } from '@/Data/API/Supply/InventoryApit';
import { messageErrorToast } from '@/Utils/MessageErrorToast';
import { useQueryClient } from '@tanstack/react-query';

export const useSaveDraftRelease = () => {
  const queryClient = useQueryClient();

  const saveDraftRelease: any = useControllerMutateApiHook({
    uniqId: SAVE_PUT_DRAFT_RELEASE,
    entityApi: InventoryAPI.saveDraftRelease,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_GET_INVENTORY]);
        queryClient.invalidateQueries([FETCH_GET_INVENTORY_DETAILS]);
        queryClient.invalidateQueries([FETCH_INVENTORY_RELEASE]);
        queryClient.invalidateQueries([FETCH_LIST_INVENTORY]);
      },
    },
  });

  return { saveDraftRelease };
};
