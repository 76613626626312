import { FC } from 'react';
import { IPrintCardModal } from './interfaces';
import { Icon, Text } from '@/Components';

import styles from './styles.module.scss';

export const PrintCard: FC<IPrintCardModal> = ({
  icon,
  label,
  subLabel,
  isActive,
  onSelect,
}) => {
  return (
    <div 
      className={
        `
          ${styles['print-card-container']}
          ${isActive ? styles['active-card'] : ''}
        `
      }
      onClick={onSelect}
    >
      <Icon
        icon={icon}
        size='XXL'
        color='text-300'
      />
      <div>
        <Text color='text-50' type='ui-tiny-content'>
          {label}
        </Text>
        <Text color='text-200' type='ui-tiny-content'>
          {subLabel}
        </Text>
      </div>
    </div>
  );
};
