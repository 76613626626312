import { useEffect, useState } from 'react';
import { Card, Icon } from '@/Components';
import { useTranslation } from 'react-i18next';
import { ConferenceTable } from './Components/ConferenceTable';
import {
  IGetInventory,
  IGetInventoryDetails,
  ISpecificationInventoryReleaseItem,
  ISpecificationInventoryReleaseItems,
} from '@/Data/Interfaces/response/Inventory/InventoryResponse';
import { Switch } from '@/Components/Inputs/Switch';
import { PrintModal } from '../PrintModal';
import { IItemChecked } from '../../Hooks/interfaces';
import { useConferenceUtils } from '../../Hooks/useConferenceUtils';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';

import styles from './TableCard.module.scss';

interface ITableCardProps {
  index: number;
  item: ISpecificationInventoryReleaseItem;
  inventory: IGetInventory;
  inventoryDetails: IGetInventoryDetails;
  hideQuantityColumns: boolean;
  itemsChecked: IItemChecked[];
  onCheckItem: (isCorrect: boolean | null, index: number) => void;
  isConferenceStatus: boolean;
  printAll: boolean;
  printDivergent: boolean;
  setPrintAll: React.Dispatch<React.SetStateAction<boolean>>;
  setPrintDivergent: React.Dispatch<React.SetStateAction<boolean>>;
  setPrintViewerVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setSupplyPlaceSelected: React.Dispatch<React.SetStateAction<string>>;
  tolerance: number;
}

export const TableCard: React.FC<ITableCardProps> = ({
  index,
  inventory,
  inventoryDetails,
  item,
  hideQuantityColumns,
  itemsChecked,
  onCheckItem,
  isConferenceStatus,
  printAll,
  setPrintAll,
  printDivergent,
  setPrintDivergent,
  setPrintViewerVisible,
  setSupplyPlaceSelected,
  tolerance,
}) => {
  const { t } = useTranslation();

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: item.localEstoqueExternalId,
  });

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
  };

  const [showDivergentItems, setShowDivergentItems] = useState<boolean>(false);
  const [printModalVisible, setPrintModalVisible] = useState<boolean>(false);
  const [items, setItems] = useState<ISpecificationInventoryReleaseItems[]>(
    item.itens
  );

  const { calculateQuantityDivergent } = useConferenceUtils();

  useEffect(() => {
    if (tolerance && tolerance > 0 && showDivergentItems) {
      const divergentItemsFilter = item.itens.filter((item) => {
        return calculateQuantityDivergent(
          item.quantidade,
          item.quantidadeInventariada,
          tolerance
        );
      });

      setItems(divergentItemsFilter);
    } else {
      setItems(item.itens);
    }
  }, [showDivergentItems, item, tolerance]);

  return (
    <>    
      <div 
        ref={setNodeRef} 
        style={style}
      >
        <Card
          key={index}
          title={item.localEstoqueDescricao}
          dragAttributes={attributes}
          dragListeners={listeners}
          draggable
          showLeftActionElement
          leftActionElement={
            <Switch
              name="divergent-balance-switch"
              value={false}
              label={t('supply.inventory.conference.divergentBalanceSwitch')}
              className={styles['divergent-balance-switch']}
              onChange={(value) => setShowDivergentItems(value)}
            />
          }
          showButtonTitle
          buttonTittleText={
            <div style={{ display: 'flex', gap: '2px' }}>
              <Icon color="text-50" icon="printer" /> {t('common.print')}
            </div>
          }
          onButtonTitleClick={() => {
            setPrintModalVisible(true);
            setSupplyPlaceSelected(item?.localEstoqueExternalId);
          }}
          icon="drag"
        >
          <ConferenceTable
            tableItems={items}
            showQuantity={!inventory.ocultarQuantidadeLancamento}
            initialIndexCount={
              index === 0
                ? 0
                : inventoryDetails.especificacao
                    .slice(0, index)
                    .reduce(
                      (acc: number, curr: any) => acc + curr.itens.length,
                      0
                    )
            }
            hideQuantityColumns={hideQuantityColumns}
            itemsChecked={itemsChecked}
            onCheckItem={onCheckItem}
            isConferenceStatus={isConferenceStatus}
          />
        </Card>
      </div>
      <PrintModal
        visible={printModalVisible}
        onCancel={() => {
          setPrintModalVisible(false);
          setPrintAll(false);
          setPrintDivergent(false);
        }}
        onOkClick={() => {
          setPrintModalVisible(false);
          setPrintViewerVisible(true);
        }}
        printAll={printAll}
        printDivergent={printDivergent}
        setPrintAll={setPrintAll}
        setPrintDivergent={setPrintDivergent}
        localEstoqueDescricao={item?.localEstoqueDescricao}
      />
    </>
  );
};
