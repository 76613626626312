import { useTranslation } from 'react-i18next';
import { history } from '@/Store';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { IPurchaseOrderListData } from '@/Data/Interfaces/response/Purchase/IPurchaseOrderResponse';
import { TypePurchaseOrderEnum } from '@/Data/Interfaces/model/typePurchaseOrderEnum';
import { BootysShortcut } from '@/Components/Shortcuts';
import { Placements } from '@/Components/Tooltip';

interface IUseListMemorizations {
  getShortcut: (id: string) => BootysShortcut;
}

export const useListMemorizations = ({
  getShortcut,
}: IUseListMemorizations) => {
  const { t } = useTranslation();

  const addPurchaseOrderHotkey = getShortcut('addPurchaseOrder');

  const headerOptions = {
    hasSearch: true,
    searchPlaceholder: t('purchasing.purchaseOrder.list.searchPlaceholder'),
    newButton: {
      onClick: () => history.push('/order/purchase/create'),
      label: 'purchasing.purchaseOrder.newButton',
      permission: {
        permission: PermissionEnum.Compras_PedidoCompra_Cadastrar,
        tooltip: 'noPermission.purchasing.purchaseOrder.create',
      },
      tooltip: {
        title: addPurchaseOrderHotkey.tooltip,
        placement: 'topRight' as Placements,
      },
    },
    editButton: {
      permission: {
        permission: PermissionEnum.Compras_PedidoCompra_EditarDetalhes,
        tooltip: 'noPermission.purchasing.purchaseOrder.edit',
      },
    },
    deleteButton: {
      permission: {
        permission: PermissionEnum.Compras_PedidoCompra_Excluir,
        tooltip: 'noPermission.purchasing.purchaseOrder.delete',
      },
    },
    nameKey: 'sequenciaGroupTenant',
  };

  const rowsOptions = {
    onRowDoubleClick: (x: IPurchaseOrderListData) =>
      x?.status === TypePurchaseOrderEnum.draft
        ? history.push(`/order/purchase/create/${x.externalId}/draft`)
        : history.push(`/order/purchase/${x.externalId}/details`),
    permission: {
      permission: PermissionEnum.Compras_PedidoCompra_VerDetalhes,
      tooltip: 'noPermission.purchasing.purchaseOrder.detail',
    },
  };

  return {
    headerOptions,
    rowsOptions,
  };
};
