export const useConferenceUtils = () => {
  const calculateQuantityDivergent = (quantity: number, inventoriedQuantity: number, tolerance: number) => {
    if(quantity === 0 && (inventoriedQuantity > 0 || inventoriedQuantity < 0) && tolerance === 0) return true;

    if(quantity === 0 && inventoriedQuantity === 0 && tolerance === 0) return false;

    let tolerancePercentage = tolerance === 0 ? 0 : tolerance/100;
    let differencePercentage = Math.abs(((inventoriedQuantity - quantity)*100)/quantity)/100;

    return differencePercentage > tolerancePercentage
  };

  return {
    calculateQuantityDivergent,
  };
};
