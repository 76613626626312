import { useParams } from "react-router";
import { IUseFunctions } from "./interfaces";
import { 
  IFinishConferenceRequest, 
  ISaveConferenceDraftRequest 
} from "@/Data/Interfaces/request/Inventory/IInventoryRequest";
import { useQueryClient } from "@tanstack/react-query";
import { 
  FETCH_GET_INVENTORY, 
  FETCH_GET_INVENTORY_DETAILS, 
  FETCH_INVENTORY_RELEASE, 
  FETCH_LIST_INVENTORY 
} from "@/ControllerApiHook/UniqIds/Inventory/Inventory";
import { ISpecificationInventoryReleaseItem } from "@/Data/Interfaces/response/Inventory/InventoryResponse";
import { arrayMove } from "@dnd-kit/sortable";

export const useConferenceFunctions = ({
  itemsChecked,
  setItemsChecked,
  saveConferenceDraft,
  finishConference,
  setFinishModalVisible,
  setReleaseSpecifications,
}: IUseFunctions) => {
  const { externalId: inventoryId } = useParams<{ externalId: string }>();
  const queryClient = useQueryClient();

  const onCheckItem = (isCorrect: boolean | null, index: number) => {
    setItemsChecked((prevState) => prevState.map((item, i) => 
      i === index ?
      {
        ...item,
        aprovado: isCorrect
      } : item
    ))
  };

  const onSaveConferenceDraft = async () => {
    const body: ISaveConferenceDraftRequest = {
      inventarioExternalId: inventoryId,
      conferencia: itemsChecked
    };

    await saveConferenceDraft.mutateAsync(body);
    queryClient.invalidateQueries([FETCH_GET_INVENTORY]);
    queryClient.invalidateQueries([FETCH_GET_INVENTORY_DETAILS]);
    queryClient.invalidateQueries([FETCH_INVENTORY_RELEASE]);
    queryClient.invalidateQueries([FETCH_LIST_INVENTORY]);
  };

  const onFinishConference = async () => {
    const body: IFinishConferenceRequest = {
      inventarioExternalId: inventoryId,
      conferencia: itemsChecked.find((item) => item.aprovado === null) ? [] : itemsChecked
    };

    await finishConference.mutateAsync(body);
    queryClient.invalidateQueries([FETCH_GET_INVENTORY]);
    queryClient.invalidateQueries([FETCH_GET_INVENTORY_DETAILS]);
    queryClient.invalidateQueries([FETCH_INVENTORY_RELEASE]);
    queryClient.invalidateQueries([FETCH_LIST_INVENTORY]);
    setFinishModalVisible(false);
  };
  
  const onCreateItemsChecked = (items: ISpecificationInventoryReleaseItem[]) => {
    let formattedItemsChecked: { inventarioItemId: number; aprovado: boolean | null }[] = [];

    items.forEach((item) => {
      item.itens.forEach((item) => {
        formattedItemsChecked.push({
          inventarioItemId: item.inventarioItemId,
          aprovado: item.aprovado,
        });
      });
    });

    return formattedItemsChecked;
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;
  
    setReleaseSpecifications((items) => {
      const oldIndex = items.findIndex((i) => i.localEstoqueExternalId === active.id);
      const newIndex = items.findIndex((i) => i.localEstoqueExternalId === over.id);
      
      return arrayMove(items, oldIndex, newIndex);
    });
  };

  return {
    onCheckItem,
    onSaveConferenceDraft,
    onFinishConference,
    onCreateItemsChecked,
    handleDragEnd,
  };
};
