import { Col, Row } from 'antd';
import { PlusInput } from './components/PlusInput';
import { useFormikContext } from 'formik';
import { useGetOpenPurchaseOrderList } from '@/Hooks/HooksServices/Purchase/PurchaseOrder/useGetOpenPurchaseOrderList';
import { useEffect, useState } from 'react';
import AddPurchaseOrderModal from './components/PurchaseOrderFoundModal';
import { PurchaseOrdersModal } from './components/PurchaseOrdersModal';
import { Tooltip } from '@/Components';
import { useTranslation } from 'react-i18next';

interface IRowSix {
  keepSupplier: boolean;
  setKeepSupplier: any;
}

export const RowSix: React.FC<IRowSix> = ({
  keepSupplier,
  setKeepSupplier,
}) => {
  const [visibleFoundPurchaseModal, setVisibleFoundPurchaseModal] =
    useState(false);
  const [visiblePurchaseOrderTable, setVisiblePurchaseOrderTable] =
    useState(false);

  const form: any = useFormikContext();
  const supplierId = form.values.fornecedorExternalId;
  const { t } = useTranslation();

  const {
    data: allDataPurchaseOrder,
    refetch: refetchOpenPurchaseOrderList,
    isLoading,
  } = useGetOpenPurchaseOrderList({
    fieldName: 'pedidosCompra',
    enabled: !!supplierId,
  });

  useEffect(() => {
    if (supplierId) {
      refetchOpenPurchaseOrderList({
        filter: [{ filterName: 'fornecedorExternalId', value: supplierId }],
      });
    }
  }, [supplierId, keepSupplier]);

  useEffect(() => {
    if (
      allDataPurchaseOrder &&
      allDataPurchaseOrder.data.length &&
      !keepSupplier
    ) {
      setVisibleFoundPurchaseModal(true);
    }
  }, [allDataPurchaseOrder, keepSupplier]);

  return (
    <Row gutter={[16, 0]}>
      <Col span={6}>
        <Tooltip
          showMe={
            JSON.stringify(form.values.pedidosExternalId) !==
            JSON.stringify(form.initialValues.pedidosExternalId)
          }
          title={`${t(
            'purchasing.invoice.edit.originalPurchaseOrder'
          )}${form.initialValues.pedidosExternalId
            .map((e: any) => `#${e.sequenciaGroupTenant}`)
            .join(', ')}`}
        >
          <PlusInput
            showEdit={
              JSON.stringify(form.values.pedidosExternalId) !==
              JSON.stringify(form.initialValues.pedidosExternalId)
            }
            setVisiblePurchaseOrderTable={() => {
              setKeepSupplier(true);
              setVisiblePurchaseOrderTable(true);
            }}
            allDataPurchaseOrder={
              allDataPurchaseOrder ? allDataPurchaseOrder.data : []
            }
          />
        </Tooltip>

        <AddPurchaseOrderModal
          visible={visibleFoundPurchaseModal}
          changeVisibleModal={(value) => setVisibleFoundPurchaseModal(value)}
          purchaseOrderList={
            allDataPurchaseOrder ? allDataPurchaseOrder.data : []
          }
          totalPurchaseOrder={
            allDataPurchaseOrder ? allDataPurchaseOrder.count : 0
          }
          setVisiblePurchaseOrderTable={setVisiblePurchaseOrderTable}
        />

        <PurchaseOrdersModal
          changeVisibleModal={(value) => setVisiblePurchaseOrderTable(value)}
          visible={visiblePurchaseOrderTable}
          purchaseOrderList={
            allDataPurchaseOrder ? allDataPurchaseOrder : undefined
          }
          refetchPurchaseOrderList={refetchOpenPurchaseOrderList}
          setPurchaseOrderList={(value: any) => {
            form.setFieldValue('pedidosCompra', value);
          }}
          isLoading={isLoading}
        />
      </Col>
    </Row>
  );
};
