import { Modal, Text } from '@/Components';
import { IPostManipulatedRecipeRequest } from '@/Data/Interfaces/request/Sales/Service/IServiceRequest';
import { FormikContextType } from 'formik';
import { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

interface ISynonymExistModal {
  visible: boolean;

  synonymDescription?: string;

  setEditingSynonymComponent: Dispatch<SetStateAction<boolean>>;
  setSynonymExistModalVisible: Dispatch<SetStateAction<boolean>>;

  form: FormikContextType<IPostManipulatedRecipeRequest>;
  index: number;

  productDescription?: string;
}

export const SynonymExistModal: FC<ISynonymExistModal> = ({
  visible,

  synonymDescription,

  setEditingSynonymComponent,
  setSynonymExistModalVisible,

  form,
  index,
  productDescription,
}) => {
  const { t } = useTranslation();

  const existSynonymModali18n = useMemo(
    () =>
      'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.components.existSynonymModal',
    []
  );

  return (
    <Modal
      title={t(existSynonymModali18n + '.title')}
      body={
        <div style={{ padding: '20px' }}>
          <Text type="ui-tiny-content" withoutMarginBottom>
            <ReactMarkdown>
              {t(existSynonymModali18n + '.description', {
                descriptionComponent: `**${productDescription}**`,
                synonymDescription: `**${synonymDescription}**`,
                code: `**(#${
                  form.values.itens ? form.values.itens[index].code : ''
                })**`,
              })}
            </ReactMarkdown>
          </Text>
        </div>
      }
      visible={visible}
      onClose={() => {
        setEditingSynonymComponent(false);
        setSynonymExistModalVisible(false);
        form.setFieldValue(`itens[${index}].descricao`, synonymDescription);
      }}
      onCancelClick={() => {
        setEditingSynonymComponent(false);
        setSynonymExistModalVisible(false);
        form.setFieldValue(`itens[${index}].descricao`, synonymDescription);
      }}
      cancelButtonName="Fechar"
      withoutOkButton
    />
  );
};
