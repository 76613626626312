import {
  Avatar,
  Button,
  Icon,
  InputWithSearch,
  PopOver,
  TextInput,
} from '@/Components';
import { WeightInput } from '@/Components/Inputs/WeightInput';
import { Col, Row } from 'antd';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectPersonModal } from './SelectPersonModal';
import { CircularStatus } from '@/Components/CircularStatus';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_LIST_FOR_DROPDOWN_TYPE } from '@/ControllerApiHook/UniqIds/Supply/ProductKeys';
import { ProductAPI } from '@/Data/API/Supply/Product';
import { useListUnitByClass } from '@/Hooks/HooksServices/Supply/UnitMeasurement/useListUnitByClass';
import { FETCH_LIST_LOTS_DROPDOWN } from '@/ControllerApiHook/UniqIds/Supply/LotKeys';
import { LotsAPI } from '@/Data/API/Supply/LotApi';

interface IModalRow {
  isLastIndex: boolean;
  addRow: () => void;
  index: number;
  removeRow?: (value: string) => void;
  identifier?: string;
}

export const ModalRow: React.FC<IModalRow> = ({
  isLastIndex,
  addRow,
  index,
  removeRow,
  identifier,
}) => {
  const form: any = useFormikContext();
  const [showPersonModal, setShowPersonModal] = useState(false);
  const [classIds, setClassIds] = useState<number[]>([]);
  const { t } = useTranslation();

  const unitMeasurement = useListUnitByClass({
    classIds: classIds,
    fieldName: `unitId`,
    isDropdownRight: true,
  });

  const {
    data: productBalanceList,
    isLoading: isLoadingProduct,
    fetchNewPage: fetchNewProductPage,
    refetch: refetchSearchProductPage,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_FOR_DROPDOWN_TYPE,
    entityApi: ProductAPI.getListProductDropDown,
    autoIncrement: true,
    pagination: {
      pageIndex: 1,
      pageSize: 10,
    },
  });

  const {
    data: lotList,
    isLoading: isLotLoading,
    fetchNewPage: fetchNewLotPage,
    refetch: refetchLotList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_LOTS_DROPDOWN,
    entityApi: LotsAPI.getLotListDropDown,
    pagination: {
      filter: [
        {
          filterName: 'produtoExternalId',
          value: form?.values?.produtoExternalId,
        },
      ],
    },
  });

  const listLots = lotList.data
    ? lotList.data.map((e: any) => {
        return {
          id: e.externalId,
          label: e.loteNumero,
          allItem: e,
          subLabel: `${t('supply.losses.create.invoiceSubLabel')} ${
            e?.numeroNf
          }-${e?.serieNf}`,
        };
      })
    : [];
  return (
    <Row gutter={[16, 0]}>
      <Col span={7}>
        <InputWithSearch
          name={`product.${index}.externalId`}
          items={productBalanceList?.data?.map((value: any) => ({
            id: value?.externalId,
            label: value?.descricao,
            subLabel: `${t('supply.losses.create.codeSubLabel')} ${
              value?.sequenciaGroupTenant
            }`,
            allItem: value,
          }))}
          onSearch={(search) =>
            refetchSearchProductPage({
              search: search,
            })
          }
          onChange={(value) => {
            const product = productBalanceList?.data?.find(
              (item: any) => item.externalId === value
            );
            setClassIds([product?.classeProdutoId]);
            refetchLotList({
              filter: [
                {
                  filterName: 'produtoExternalId',
                  value: value,
                },
              ],
            });
            if (isLastIndex) addRow();
          }}
          onScrollEnd={fetchNewProductPage}
          isLoading={isLoadingProduct}
          placeHolder="Pesquise por um produto"
        />
      </Col>
      <Col span={4}>
        <WeightInput
          name={`product.${index}.quantity`}
          placeHolder="0,0000"
          dropdownRight={{
            ...unitMeasurement,
            name: `product.${index}.unitId`,
          }}
        />
      </Col>
      <Col span={4}>
        <InputWithSearch
          name={`product.${index}.loteExternalId`}
          placeHolder="Pesquise por um lote"
          items={listLots}
          isLoading={isLotLoading}
          onScrollEnd={fetchNewLotPage}
          onChange={(value) => {
            const lot = lotList.data.find(
              (item: any) => item.externalId === value
            );
            const invoice = lot
              ? `${String(lot.numeroNf).padStart(5, '0')} / ${String(
                  lot.serieNf
                ).padStart(3, '0')}`
              : '';
            form.setFieldValue(`product.${index}.invoice`, invoice);
          }}
          onSearch={(search) => {
            refetchLotList({
              search: search,
            });
          }}
        />
      </Col>
      <Col span={5}>
        <TextInput
          name={`product.${index}.invoice`}
          disabled
          placeHolder={t('supply.inventory.release.invoice')}
        />
      </Col>
      <Col span={2}>
        <PopOver
          placement="bottomRight"
          visible={showPersonModal}
          onOpenChange={() => setShowPersonModal((prev) => !prev)}
          content={<SelectPersonModal index={index} />}
          trigger="click"
          title={
            <div style={{ padding: '16px' }}>
              {t('supply.inventory.release.responsibleOperator')}
            </div>
          }
        >
          <div style={{ cursor: 'pointer' }}>
            {form.values.product &&
            form.values.product[index] &&
            form.values.product[index].username ? (
              <Avatar
                fisrtName={form.values.product[index].username}
                lastName={form.values.product[index].userLastName}
                size="M"
                type="paragraph"
                color="white"
              />
            ) : (
              <CircularStatus icon="plus" type={'default'} size="SM" />
            )}
          </div>
        </PopOver>
      </Col>
      <Col span={2}>
        <Button
          type="secondary"
          onClick={() => {
            form.setFieldValue(
              `product`,
              form.values.product.filter((item: any, i: number) => i !== index)
            );
            removeRow && removeRow(identifier || '');
          }}
        >
          <Icon icon="trash" size="M" />
        </Button>
      </Col>
    </Row>
  );
};
