import { Text } from '@/Components';
import FormBreakLine from '@/Components/FormBreakLine';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useFormRows } from '@/Hooks/UtilHooks/useFormRows';
import { RowElements } from './RowElements';

interface ISupplyPlaceRowProps {
  edit: boolean;
}

export const SupplyPlaceRow: React.FC<ISupplyPlaceRowProps> = ({ edit }) => {
  const [isInitial, setIsInitial] = useState(true);

  const form: any = useFormikContext();
  const { addRow, arrayControl, removeRow, pushRow, removeArrayControlLast } =
    useFormRows({
      key: 'inventarioEspecificacao',
    });

  const { t } = useTranslation();

  useEffect(() => {
    if (form.values.inventarioEspecificacao.length > arrayControl.length) {
      const diff =
        form.values.inventarioEspecificacao.length - arrayControl.length;
      Array.from({ length: diff }).forEach(() => {
        pushRow();
      });
    }
  }, [form.values.inventarioEspecificacao]);

  useEffect(() => {
    if (isInitial && edit) {
      pushRow();
      setIsInitial(false);
    }

    if (!edit && !isInitial) {
      removeArrayControlLast();
      setIsInitial(true);
    }
  }, [isInitial, edit]);

  return (
    <div>
      {arrayControl.map((e, index) => {
        const supplyPlace = form.values.inventarioEspecificacao[index]
          ? form.values.inventarioEspecificacao[index].localEstoqueExternalId
          : null;
        const group = form.values.inventarioEspecificacao[index]
          ? form.values.inventarioEspecificacao[index].grupoExternalId
          : null;

        return (
          <div key={e}>
            <Row
              gutter={16}
              style={index !== 0 ? { display: 'none' } : undefined}
            >
              <Col span={14}>
                <Text type="paragraph-bold">
                  {t('supply.inventory.create.supplyPlaces')}
                </Text>
              </Col>
              <Col span={5}>
                <Text type="paragraph-bold">
                  {t('supply.inventory.create.groups')}
                </Text>
              </Col>
              <Col span={5}>
                <Text type="paragraph-bold">
                  {t('supply.inventory.create.subgroups')}
                </Text>
              </Col>
            </Row>
            <FormBreakLine
              marginTop="10px"
              style={index !== 0 ? { display: 'none' } : undefined}
            />
            <Row gutter={16}>
              <RowElements
                index={index}
                addRow={addRow}
                edit={edit}
                supplyPlace={supplyPlace}
                group={group}
                removeRow={removeRow}
              />
            </Row>
          </div>
        );
      })}
    </div>
  );
};
