import { useMemo } from "react";
import { StandardFormulaDetail } from '../Components/Detail';
import { useTranslation } from "react-i18next";
import { IUseMemorizations } from "./interfaces";
import { PermissionEnum } from "@/Data/Interfaces/model/permissionEnum";
import { IButtonDropDownItems } from "@/Components/Button/ButtonDropdown";
import { useStandardFormulaDetailsShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/Production/StandardFormula/details';

export const useEditMemorizations = ({
  disabled,
  setDisabled,
  setVisibleModal,
  tab,
  standardFormula
}: IUseMemorizations) => {

  const { t } = useTranslation();
  const { getShortcut } = useStandardFormulaDetailsShortcuts(t, {});
  const editStandardFormulaHotkey = getShortcut('editStandardFormula');

  const items = useMemo(() => (
    [
      {
        label: t('common.details'),
        key: 'details',
        children: (
          <StandardFormulaDetail
            disabled={disabled}
            onChangeDisabled={(x: boolean) => setDisabled(x)}
          />
        ),
      },
    ]
  ), [t, disabled, setDisabled]);

  const dropdownItems: IButtonDropDownItems | undefined = [
    {
      label: t('common.delete'),
      icon: 'trash',
      onClick: () => {
        setVisibleModal(true);
      },
      dataTestId: 'dropdown-option-delete',
      key: 'backspace',
      permission: {
        permission: PermissionEnum.Producao_FormulaPadrao_Excluir,
        tooltip: 'noPermission.production.standardFormula.delete',
      },
    },
  ];

  const rightButton = {
    ...(disabled &&
      tab === 'details' &&
      standardFormula && {
        onClick: () => setDisabled(false),
        isVisible: disabled,
        codePermissionEdit:
          PermissionEnum.Producao_FormulaPadrao_EditarDetalhes,
        messageTooltipPermission:
          'noPermission.production.standardFormula.edit',
      }),
      dataTestId: 'edit-standard-formula-button',
      hotkeyTooltip: {
        title: editStandardFormulaHotkey.tooltip,
      },
  };

  return {
    items,
    dropdownItems,
    rightButton
  };
};