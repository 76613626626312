import { useState } from 'react';

export const useTopButtonsStates = () => {
  const [productModalOpen, setProductModalOpen] = useState(false);
  const [manipulatedRecipeModalOpen, setManipulatedRecipModalOpen] =
    useState(false);
  const [historicModalOpen, setHistoricModalOpen] = useState(false);

  const [stepCurrent, setStepCurrent] = useState(0);

  return {
    productModalOpen,
    setProductModalOpen,

    manipulatedRecipeModalOpen,
    setManipulatedRecipModalOpen,

    historicModalOpen,
    setHistoricModalOpen,

    stepCurrent,
    setStepCurrent,
  };
};
