import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { Modal } from '@/Components/Modal';
import { Steps } from '@/Components/Steps';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { useTranslation } from 'react-i18next';
import { FormikHelpers, FormikValues } from 'formik';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { ManipulatedRecipeSchema } from './ManipulatedRecipe.schema';
import { useManipulatedRecipeMemorizations } from './Hooks/useManipulatedRecipeMemorizations';
import { useManipulatedRecipeCallAPI } from './Hooks/useManipulatedRecipeCallAPI';
import { useManipulatedRecipeStates } from './Hooks/useManipulatedRecipeStates';
import { IPostManipulatedRecipeRequest } from '@/Data/Interfaces/request/Sales/Service/IServiceRequest';
import _ from 'lodash';

import styles from './ManipulatedRecipe.module.scss';

interface IAddManipulatedRecipeModal {
  visibleModal: boolean;
  changeVisibleModal: (value: boolean) => void;

  countRecipe: number;

  client?: {
    externalId: string;
    name: string;
  };
  saleOrderExternalId?: string;
}

export const AddManipulatedRecipeModal: FC<IAddManipulatedRecipeModal> = ({
  changeVisibleModal,
  visibleModal,
  countRecipe,
  client,
  saleOrderExternalId,
}: IAddManipulatedRecipeModal) => {
  const {
    componentsEditing,
    setComponentsEditing,

    stepCurrent,
    setStepCurrent,

    validatingComponents,
    setValidatingComponents,

    manipulatedRecipeExternalId,
    setManipulatedRecipeExternalId,
  } = useManipulatedRecipeStates();

  const {
    createManipulatedRecipe,
    createManipulatedRecipeIsLoading,
    addManipulatedRecipeOnSaleOrder,
  } = useManipulatedRecipeCallAPI({
    setStepCurrent,
    setManipulatedRecipeExternalId,
    changeVisibleModal,
  });

  const { steps, initialValues } = useManipulatedRecipeMemorizations({
    componentsEditing,
    setComponentsEditing,
    validatingComponents,
    client,
    manipulatedRecipeExternalId,
  });

  useEffect(() => {
    componentsEditing.length === 0 && setValidatingComponents(false);
  }, [componentsEditing.length]);

  const { t } = useTranslation();

  return (
    <Modal
      title={`${t('common.add')} ${countRecipe + 1}ª ${t(
        'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.recipe'
      )}`}
      schema={ManipulatedRecipeSchema()}
      body={
        <div className={styles['container']}>
          <Divflex className={styles['content-step']}>
            <Steps
              type="step-by-step"
              current={stepCurrent}
              items={[t('common.components'), t('common.details')]}
              className={styles['steps']}
            />
          </Divflex>
          {steps[stepCurrent]}
        </div>
      }
      visible={visibleModal}
      okButtonName={t('common.next')}
      onCancelClick={() => changeVisibleModal(false)}
      onClose={() => changeVisibleModal(false)}
      widthModal={700}
      className={styles['modal-content']}
      withForm
      htmlType="submit"
      initialValues={initialValues}
      loading={createManipulatedRecipeIsLoading}
      onOkClickForm={(value: any, formik) =>
        stepCurrent === 0
          ? ComponentsFormErrors(
              value,
              componentsEditing,
              formik,
              setValidatingComponents,
              createManipulatedRecipe
            )
          : addManipulatedRecipeOnSaleOrder({
              pedidoVendaExternalId: saleOrderExternalId,
              valorUnitario: value.valorUnitario,
              desconto: value.desconto,
              tipoDesconto: value.tipoDesconto,
              receitaManipuladaExternalId: manipulatedRecipeExternalId,
              valorTotalItem: value.valorTotalItem,
            })
      }
      disabledButton={validatingComponents && componentsEditing.length > 0}
      onReturn={() => setStepCurrent((x) => x - 1)}
      showReturnButton={stepCurrent > 0}
    />
  );
};

const ComponentsFormErrors = (
  value: IPostManipulatedRecipeRequest,
  componentsEditing: number[],
  form: FormikHelpers<FormikValues>,
  setValidatingComponents: Dispatch<SetStateAction<boolean>>,
  createManipulatedRecipe: UseMutateAsyncFunction<
    unknown,
    unknown,
    unknown,
    unknown
  >
) => {
  if (componentsEditing.length > 0) {
    form.setSubmitting(false);
    setValidatingComponents(true);
  } else
    createManipulatedRecipe(
      {
        pacienteExternalId: value.pacienteExternalId,
        formaFarmaceuticaExternalId: value.formaFarmaceuticaExternalId,
        prescritorExternalId: value.prescritorExternalId,
        dataPrescricao: value.dataPrescricao,
        quantidadeReceita: value.quantidadeReceita,
        quantidadeRepetir: value.quantidadeRepetir,
        quantidadeDose: value.quantidadeDose,
        itens: value.itens
          .map((item) =>
            !_.isNil(item.produtoExternalId)
              ? {
                  produtoExternalId: item.produtoExternalId,
                  produtoSinonimoExternalId: item.produtoSinonimoExternalId,
                  descricaoRotulo: item.descricaoRotulo,
                  quantidade: item.quantidade,
                  unidadeMedidaId: item.unidadeMedidaId,
                  tipo: item.tipo,
                  ordem: item.ordem,
                  ocultaRotulo: item.ocultaRotulo,
                }
              : {}
          )
          .filter((item) => Object.keys(item).length > 0),
      },
      { onSuccess: () => form.resetForm() }
    );
};
