import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import {
  FETCH_GET_INVENTORY,
  FETCH_GET_INVENTORY_DETAILS,
  FETCH_INVENTORY_RELEASE,
  FETCH_LIST_INVENTORY,
  SAVE_PRODUCT_RELEASE,
} from '@/ControllerApiHook/UniqIds/Inventory/Inventory';
import { InventoryAPI } from '@/Data/API/Supply/InventoryApit';
import { messageErrorToast } from '@/Utils/MessageErrorToast';
import { useQueryClient } from '@tanstack/react-query';

export const useSaveProduct = () => {
  const queryClient = useQueryClient();

  const saveProductRelease: any = useControllerMutateApiHook({
    uniqId: SAVE_PRODUCT_RELEASE,
    entityApi: InventoryAPI.saveProductRelease,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        queryClient.invalidateQueries([FETCH_GET_INVENTORY]);
        queryClient.invalidateQueries([FETCH_GET_INVENTORY_DETAILS]);
        queryClient.invalidateQueries([FETCH_INVENTORY_RELEASE]);
        queryClient.invalidateQueries([FETCH_LIST_INVENTORY]);
      },
    },
  });

  return { saveProductRelease };
};
