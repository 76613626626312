import { ModalFuncProps } from 'antd';
import {
  ICommonError,
  IGlobalModal,
} from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { Content } from './Components/Content';
import { Title } from './Components/Title';
import { Footer } from './Components/Footer';
import { Icon } from '@/Components';

export const CannotDeleteModal: (
  error: ICommonError,
  instance: IGlobalModal
) => ModalFuncProps = (error, instance) => ({
  closable: true,
  closeIcon: <Icon icon="close-x" color="text-400" size="XXL" />,
  icon: <></>,
  title: <Title {...error} />,
  content: <Content {...error} />,
  footer: <Footer instance={instance} content={error.content} />,
});
