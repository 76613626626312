import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { Table } from '../../../../../Components';
import { orderCollums } from './order.columns';
import { CreateServiceModal } from '../../Tickets/List/Modal/CreateOrder';
import { useOrderListCallAPI } from './Hooks/useOrderListCallAPI';
import { useOrderListFunctions } from './Hooks/useOrderListFunctions';
import { useOrderListMemorizations } from './Hooks/useOrderListMemorizations';
import { useOrderListStates } from './Hooks/useOrderListState';
import { useSaleOrderListShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/Sales/SaleOrder/list';

export const OrderList: FC = () => {
  const { visibleCreateModal, setVisibleCreateModal } = useOrderListStates();

  const { t } = useTranslation();

  const {
    createSaleOrder,
    serviceList,
    serviceListLoading,
    refetchServiceList,
  } = useOrderListCallAPI({
    setVisibleCreateModal,
  });

  const { getShortcut } = useSaleOrderListShortcuts(t);

  const { onSubmit } = useOrderListFunctions({
    createSaleOrder,
  });

  const { headerOptions, rowsOptions } = useOrderListMemorizations({
    setVisibleCreateModal,
    t,
    getShortcut,
  });

  return (
    <PageLoading loading={serviceListLoading}>
      <Table
        columns={orderCollums(t)}
        headerOptions={headerOptions}
        rowsOptions={rowsOptions}
        loading={serviceListLoading}
        pagination={serviceList}
        onRefetch={refetchServiceList}
      />

      <CreateServiceModal
        changeVisible={(x) => setVisibleCreateModal(x)}
        visible={visibleCreateModal}
        onSubmit={onSubmit}
        isLoading={createSaleOrder.isLoading}
        t={t}
      />
    </PageLoading>
  );
};
