import { useCallback, useState } from 'react';
import { PermissionWrapper } from '../../../../../../PermissionWrapper';
import { Status } from '../../../Status';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../../../Modal';
import { Divflex } from '../../../../../../../Layouts/DivWhithFlex';
import { Text } from '../../../../../../Text';
import { PermissionEnum } from '../../../../../../../Data/Interfaces/model/permissionEnum';
import {
  EDIT_STATUS_POSOLOGY,
  FETCH_GET_POSOLOGY,
  FETCH_LIST_POSOLOGY,
} from '../../../../../../../ControllerApiHook/UniqIds/Production/PosologyKeys';
import { useQueryClient } from '@tanstack/react-query';
import { history } from '../../../../../../../Store';
import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
} from '@/ControllerApiHook/Controller';
import { PosologyAPI } from '@/Data/API/Production/Posology';
import { IGetPosology } from '@/Data/Interfaces/response/Posology/IPosologyResponse';
import ReactMarkdown from 'react-markdown';
import { Tooltip } from '@/Components/Tooltip';
import { usePosologyDetailsShortcuts } from './../../../../../../Shortcuts/keymaps/pages/sectors/Production/Posology/details';

export const DynamicEditPosologyBreadcrumb = ({ match }: any) => {
  const { data: posology } = useControllerQueryApiHook<IGetPosology>({
    uniqId: FETCH_GET_POSOLOGY,
    entityApi: PosologyAPI.getPosology,
    externalIds: [match.params.externalId],
  });

  return <div style={{ display: 'flex' }}>{posology?.posologiaDescricao}</div>;
};

export const DynamicEditPosologyStatusBreadcrumb = ({ match }: any) => {
  const { data: posology } = useControllerQueryApiHook<IGetPosology>({
    uniqId: FETCH_GET_POSOLOGY,
    entityApi: PosologyAPI.getPosology,
    externalIds: [match.params.externalId],
  });

  const { t } = useTranslation();
  
  const { getShortcut } = usePosologyDetailsShortcuts(t, {});
  const editStatusHotkey = getShortcut('editStatus');

  const [modalVisible, setModalVisible] = useState(false);

  const [status, setStatus] = useState(undefined);

  const editStatus: any = useControllerMutateApiHook({
    uniqId: EDIT_STATUS_POSOLOGY,
    entityApi: PosologyAPI.putPosologyStatus,
  });

  const queryClient = useQueryClient();

  const changeStatus = useCallback(
    async (value: boolean) => {
      const res = await editStatus.mutateAsync({
        externalId: [match.params.externalId],
        ativo: value,
      });

      if (res.success) {
        queryClient.invalidateQueries([FETCH_LIST_POSOLOGY]);
        history.push('/production/posology');
      }
    },
    [editStatus, match.params.externalId, queryClient]
  );

  const onEditStatus = useCallback(
    async (status: any) => {
      const res = await editStatus.mutateAsync({
        externalId: [match.params.externalId],
        ativo: status,
      });
      if (res.success) {
        queryClient.invalidateQueries([FETCH_LIST_POSOLOGY]);
        history.push('/production/posology');
      }
    },
    [queryClient, match.params.externalId, editStatus]
  );

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {`${t('breadCrumb.production.posology.posologyDetailTitle')} | ${
        posology?.posologiaDescricao
      }`}
      <PermissionWrapper
        permission={PermissionEnum.Producao_Posologia_AlterarStatus}
        tooltip="noPermission.production.posology.statusEdit"
      >
        <Tooltip showMe title={editStatusHotkey.tooltip}>
          <Status
            type={posology?.ativo ? 'success' : 'disabled'}
            ative={posology?.ativo || false}
            onClick={(value) => {
              value ? changeStatus(value) : setModalVisible(true);
              setStatus(value);
            }}
            permission={{
              permission: PermissionEnum.Producao_Posologia_AlterarStatus,
              tooltip: 'noPermission.production.posology.statusEdit',
            }}
            dataTestId="status-dropdown-button"
          />
        </Tooltip>
      </PermissionWrapper>
      <Modal
        title={`${t('production.posology.details.statusTypes.inactive')} ${t(
          'common.posology'
        )} "${posology?.posologiaDescricao}"?`}
        body={
          <Divflex style={{ padding: '20px' }}>
            <Text type="ui-tiny-content" withoutMarginBottom color="text-50" />
            <ReactMarkdown>
              {t(
                'production.posology.details.changeStatusDescriptionPosology',
                {
                  description: `**${posology?.posologiaDescricao}** ?`,
                }
              )}
            </ReactMarkdown>
          </Divflex>
        }
        onCancelClick={() => setModalVisible(false)}
        visible={modalVisible}
        onOkClick={() => status !== undefined && onEditStatus(status)}
        onClose={() => setModalVisible(false)}
        okButtonName={t('production.posology.details.statusTypes.inactive')}
      />
    </div>
  );
};
