import { useCallback } from 'react';
import { history } from '@/Store';
import { IUseFunctions } from './interfaces';

export const useFooterFunctions = ({ instance, content }: IUseFunctions) => {
  const onOkClick = useCallback(() => {
    history.push(`/inventory/groups/${content[0]?.grupoExternalId}/products`);
    instance.destroy();
  }, [content, instance]);

  return { onOkClick };
};
