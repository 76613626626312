import React, { FC, useState } from 'react';

import { IButton } from '../';
import { Text, Icon, Tooltip } from '../..';
import { Button as AntdButton } from 'antd';

import styles from './CompanyButton.module.scss';
import './CompanyButton.override.scss';
import { useTranslation } from 'react-i18next';

interface ICompapyButton extends IButton {
  compapyButton?: 'matriz' | 'filial';
  company?: 'matriz' | 'filial';
}
export const CompanyButton: FC<ICompapyButton> = ({
  type,
  compapyButton,
  htmlType,
  disabled,
  children,
  onClick,
  onMouseEnter,
  onMouseLeave,
  className,
  tooltip,
}) => {
  const [onMouseDown, setOnMouseDown] = useState(false);
  const { t } = useTranslation();
  const tabIndexCompanyButton = 52;
  return (
    <Tooltip
      showMe={!!tooltip?.title}
      title={tooltip?.title}
      targetOffset={tooltip?.targetOffset}
    >
      <AntdButton
        tabIndex={tabIndexCompanyButton}
        className={`button-${type} ${
          onMouseDown ? `${type}-button-mouseDown` : ''
        } ${styles['variable']} ${className ? className : ''}`}
        htmlType={htmlType}
        onClick={onClick}
        disabled={disabled}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseDown={() => setOnMouseDown(true)}
        onMouseUp={() => setOnMouseDown(false)}
        data-testid="user-company-button"
      >
        <>
          <div
            className={
              type === 'primary-company'
                ? styles['div-company-primary']
                : styles['div-company-secondary']
            }
          >
            <Text
              type="small-text"
              children={
                compapyButton === 'matriz'
                  ? t(`header.matriz`)
                  : t(`header.filial`)
              }
            />
          </div>
          <Text
            className={styles['children']}
            type="ui-tiny-bold"
            children={children}
          />
          {type === 'primary-company' && (
            <div className={styles['div-rightIcon']}>
              <Icon className="button-rightIcon" size="M" icon="chevron-down" />
            </div>
          )}
        </>
      </AntdButton>
    </Tooltip>
  );
};
