import { Text } from '@/Components';
import FormBreakLine from '@/Components/FormBreakLine';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { ModalRow } from './ModalRow';
import { useState } from 'react';
import { v4 as uuidV4 } from 'uuid';

export const ModalBody: React.FC = ({}) => {
  const [modalRows, setModalRows] = useState<string[]>([uuidV4()]);
  const { t } = useTranslation();

  return (
    <div style={{ padding: 20 }}>
      <Row gutter={[16, 0]}>
        <Col span={7}>
          <Text type="paragraph-bold">
            {t('supply.inventory.release.product')}
          </Text>
        </Col>
        <Col span={4}>
          <Text type="paragraph-bold">
            {t('supply.inventory.release.quanty')}
          </Text>
        </Col>
        <Col span={4}>
          <Text type="paragraph-bold">{t('supply.inventory.release.lot')}</Text>
        </Col>
        <Col span={5}>
          <Text type="paragraph-bold">
            {t('supply.inventory.release.invoice')}
          </Text>
        </Col>
        <Col span={4}>
          <Text type="paragraph-bold">
            {t('supply.inventory.release.responsibleTitle')}
          </Text>
        </Col>
      </Row>
      <FormBreakLine marginTop="5px" />
      {modalRows.map((row, index) => (
        <ModalRow
          key={row}
          isLastIndex={index === modalRows.length - 1}
          addRow={() => {
            setModalRows([...modalRows, uuidV4()]);
          }}
          removeRow={(identifier: string) => {
            setModalRows(modalRows.filter((row) => row !== identifier));
          }}
          index={index}
          identifier={row}
        />
      ))}
    </div>
  );
};
