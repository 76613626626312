import {
  BootysShortcuts,
  defaultShortcuts,
  DefaultShortcuts,
} from '@/Components/Shortcuts/keymaps/default';
import { TFunction } from 'react-i18next';
import { useHotkeys } from 'reakeys';
import { isVisible } from '@/Utils/DOMUtils';
import { getShortcut, getShortcuts } from '@/Components/Shortcuts';

export const pageKey = 'lot-details';

export interface LotDetailsTabCallbacks extends DefaultShortcuts {}

const shortcuts = (
  t: TFunction<'translation', undefined>,
  callbacks: LotDetailsTabCallbacks
): BootysShortcuts[] => [
  ...defaultShortcuts(t, callbacks),
  {
    id: 'editStatus',
    name: t('admin.editUserPage.shortcuts.editStatus'),
    keys: 's',
    action: 'keydown',
    withText: true,
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="status-dropdown-button"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'editStatusReleased',
    name: t('admin.editUserPage.shortcuts.editStatus'),
    keys: '1',
    action: 'keydown',
    withText: true,
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="dropdown-option-0-status"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'editStatusQualityControl',
    name: t('admin.editCompany.shortcuts.editAddress'),
    keys: '2',
    action: 'keydown',
    withText: true,
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="dropdown-option-1-status"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'editStatusBlocked',
    name: t('admin.editCompany.shortcuts.editAddress'),
    keys: '3',
    action: 'keydown',
    withText: true,
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="dropdown-option-2-status"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'editMoreActions',
    name: t('admin.editUserPage.shortcuts.moreActions'),
    keys: '.',
    action: 'keydown',
    withText: true,
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="more-actions-button"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'deleteLot',
    name: t('admin.editUserPage.shortcuts.moreActions'),
    keys: 'backspace',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="dropdown-option-delete"]'
      );
      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'editLot',
    name: t('supply.lot.shortcuts.editLot'),
    keys: 'e',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector('[data-testid="edit-lot-button"]');
      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'alternateTabDetails',
    name: t('supply.lot.shortcuts.goToLotDetails'),
    keys: 'shift+!',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector('[data-testid="tab-lot-details"]');
      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'alternateTabDetailsNumberVariation',
    name: t('supply.lot.shortcuts.goToLotDetails'),
    keys: 'shift+1',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector('[data-testid="tab-lot-details"]');
      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'alternateTabTraceability',
    name: t('supply.lot.shortcuts.goToLotTraceability'),
    keys: 'shift+@',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="tab-lot-traceability"]'
      );
      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'alternateTabTraceabilityNumberVariation',
    name: t('supply.lot.shortcuts.goToLotTraceability'),
    keys: 'shift+2',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="tab-lot-traceability"]'
      );
      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'alternateTabSupplyPlaces',
    name: t('supply.lot.shortcuts.goToLotSupplyPlaces'),
    keys: 'shift+#',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="tab-lot-supply-places"]'
      );
      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'alternateTabSupplyPlacesNumberVariation',
    name: t('supply.lot.shortcuts.goToLotSupplyPlaces'),
    keys: 'shift+3',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="tab-lot-supply-places"]'
      );
      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
];

export const useLotDetailsShortcuts = (
  t: TFunction<'translation', undefined>,
  callbacks: LotDetailsTabCallbacks
) => {
  const pageShortcuts = shortcuts(t, callbacks);

  const hotkeys = useHotkeys(pageShortcuts);

  return {
    ...hotkeys,
    getShortcut: getShortcut(pageShortcuts),
    getShortcuts: getShortcuts(pageShortcuts),
  };
};
