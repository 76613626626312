import { useTranslation } from 'react-i18next';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { Table } from '../../../../../Components/Table';
import { productMensageColumns } from './productList.columns';
import { useListStates } from './Hooks/useListStates';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListFunctions } from './Hooks/useListFunctions';
import { useListMemorizations } from './Hooks/useListMemorizations';
import { useProductMessageListShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/inventory/productMessage/list';

export const ProductMensageList: React.FC = () => {
  const { t } = useTranslation();

  const { selectedRowsOnTable, setSelectedRowsOnTable, selectedItems } =
    useListStates();

  const {
    productMessageList,
    isLoadingProductMessageList,
    deleteProductMessage,
    refetchProductMessageList,
  } = useListCallAPI();

  const { getShortcut } = useProductMessageListShortcuts(t, {});

  const { onDelete } = useListFunctions({ deleteProductMessage });

  const { headerOptions, rowOptions, deleteModal, tableHeaderHotKeyTooltip } =
    useListMemorizations({
      selectedRowsOnTable,
      getShortcut,
    });

  return (
    <PageLoading>
      <Table
        columns={productMensageColumns(t)}
        loading={isLoadingProductMessageList}
        headerOptions={headerOptions}
        hasSelection
        pagination={productMessageList}
        rowsOptions={rowOptions}
        onRefetch={refetchProductMessageList}
        onDelete={onDelete}
        deleteModal={deleteModal}
        selectedRowsOnTable={selectedRowsOnTable}
        setSelectedRowsOnTable={setSelectedRowsOnTable}
        tableHeaderTooltip={tableHeaderHotKeyTooltip}
        keyToSelect="externalId"
        initialRowsSelected={selectedItems}
      />
    </PageLoading>
  );
};
export default ProductMensageList;
