export const FETCH_GET_LABEL_TEMPLATES =
  '[LABEL_TEMPLATES] FETCH_GET_LABEL_TEMPLATES';
export const FETCH_LIST_LABEL_TEMPLATES =
  '[LABEL_TEMPLATES] FETCH_LIST_LABEL_TEMPLATES';
export const EDIT_STATUS_LABEL_TEMPLATES =
  '[LABEL_TEMPLATES] EDIT_STATUS_LABEL_TEMPLATES';

export const CREATE_LABEL_TEMPLATES =
  '[LABEL_TEMPLATES] CREATE_LABEL_TEMPLATES';
export const EDIT_LABEL_TEMPLATES = 
  '[LABEL_TEMPLATES] EDIT_LABEL_TEMPLATES';
export const DELETE_LABEL_TEMPLATES =
  '[LABEL_TEMPLATES] DELETE_LABEL_TEMPLATES';

export const THERE_IS_LABEL_TEMPLATE_TYPE =
  '[LABEL_TEMPLATES] THERE_IS_LABEL_TEMPLATE_TYPE';

