import {
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { IUseCallAPI } from './interfaces';
import { LaboratoryAPI } from '@/Data/API/Production/Laboratory';
import {
  LIST_COMPANY_FOR_DROPDOWN_TYPE_TO_LABORATORY,
  LIST_SUPPLYPLACE_FOR_DROPDOWN_TYPE_TO_LABORATORY,
} from '@/ControllerApiHook/UniqIds/Production/LaboratoryKeys';
import { useEffect } from 'react';

export const useEditCallAPI = ({ form, t }: IUseCallAPI) => {
  const {
    data: companyList,
    isLoading: isLoadingCompany,
    refetch: refetchSearchCompanyPage,
    fetchNewPage: fetchInputCompany,
  } = useControllerQueryListApiHook({
    uniqId: LIST_COMPANY_FOR_DROPDOWN_TYPE_TO_LABORATORY,
    entityApi: LaboratoryAPI.listCompanyForDropdownToLaboratory,
    pagination: {
      sorter: { column: 'nomeFantasia', direction: 'ASC' },
    },
    autoIncrement: true
  });

  const {
    data: supplyPlaceList,
    isLoading: isLoadingSupplyPlace,
    refetch: refetchSupplyPlace,
    fetchNewPage: fetchNewPageSupplyPlace,
  } = useControllerQueryListApiHook({
    uniqId: LIST_SUPPLYPLACE_FOR_DROPDOWN_TYPE_TO_LABORATORY,
    entityApi: LaboratoryAPI.listSupplyPlaceForDropdownToLaboratory,
    pagination: {
      sorter: { column: 'descricao', direction: 'ASC' },
      filter: [
        {
          filterName: 'empresaExternalId',
          value: form?.values?.empresaExternalId,
        },
      ],
    },
    autoIncrement: true,
  });

  useEffect(() => {
    refetchSupplyPlace({
      filter: [
        {
          filterName: 'empresaExternalId',
          value: form.values.empresaExternalId,
        },
      ],
    });
  }, [form?.values?.empresaExternalId]);

  return {
    companyList,
    isLoadingCompany,
    refetchSearchCompanyPage,
    fetchInputCompany,
    supplyPlaceList,
    isLoadingSupplyPlace,
    refetchSupplyPlace,
    fetchNewPageSupplyPlace,
  };
};
