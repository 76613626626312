import { Page, Document, StyleSheet, Font } from '@react-pdf/renderer';
import { PdfHeader } from '../PdfComponent/components/PdfHeader';
import { PageCount } from '../PdfComponent/components/PageCount';
import { SupplyPlaceTable } from '../PdfComponent/components/SupplyPlaceTable';

interface IAllItemsPdf {
  inventoryCode: string;
  supplyPlaces: any[];
  onRender?: () => void;
  hideQuantityPrint?: boolean;
}

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
    paddingBottom: '100px',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

export const AllItemsPdf: React.FC<IAllItemsPdf> = ({
  inventoryCode,
  supplyPlaces,
  onRender,
  hideQuantityPrint,
}) => {
  return (
    <Document onRender={onRender}>
      <Page size="A4" style={styles.page}>
        <PdfHeader inventoryCode={inventoryCode} />
        {supplyPlaces.map((e) => {
          return (
            <SupplyPlaceTable
              supplyPlace={e.localEstoqueDescricao}
              tableItems={e.itens}
              hideQuantityPrint={hideQuantityPrint}
            />
          );
        })}
        <PageCount />
      </Page>
    </Document>
  );
};
