import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IUseMemorizations } from './interfaces';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { IButtonDropDownItems } from '@/Components/Button/ButtonDropdown';
import { DetailsTab } from '../Tabs/Details';
import { ClienteExternalId } from '../Tabs/Tickets';
import { OrderList } from '../Tabs/Order';

export const useEditClientMemorizations = ({
  client,
  setVisibleDeleteModal,
  getShortcut,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const alternateTabDetailsHotkey = getShortcut('alternateTabDetails');
  const alternateTabServiceHotkey = getShortcut('alternateTabService');
  const alternateTabSalesOrderHotkey = getShortcut('alternateTabSalesOrder');

  const items = useMemo(
    () => [
      {
        label: t('common.details'),
        key: 'details',
        children: client && (
          <DetailsTab client={client} getShortcut={getShortcut} />
        ),
        dataTestId: 'tab-customer-details',
        shortCutTooltip: {
          title: alternateTabDetailsHotkey.tooltip,
          targetOffset: [0, 10],
        },
      },
      {
        label: t('callCenter.service'),
        key: 'tickets',
        children: client && (
          <ClienteExternalId
            externalId={client.externalId}
            clientDescription={client.nome}
          />
        ),
        dataTestId: 'tab-customer-service',
        shortCutTooltip: {
          title: alternateTabServiceHotkey.tooltip,
          targetOffset: [0, 10],
        },
      },
      {
        label: t('saleOrder.saleOrder'),
        key: 'sale-order',
        children: client && (
          <OrderList externalId={client.externalId} client={client} />
        ),
        dataTestId: 'tab-customer-sale-order',
        shortCutTooltip: {
          title: alternateTabSalesOrderHotkey.tooltip,
          targetOffset: [0, 10],
        },
      },
    ],
    [t, client]
  );

  const dropdownItems = useMemo(
    (): IButtonDropDownItems | undefined => [
      {
        label: t('common.delete'),
        icon: 'trash',
        onClick: () => setVisibleDeleteModal(true),
        permission: {
          permission: PermissionEnum.Vendas_Clientes_Excluir,
          tooltip: 'noPermission.sale.client.delete',
        },
        key: 'backspace',
        dataTestId: 'dropdown-option-delete',
      },
    ],
    [t, setVisibleDeleteModal]
  );

  return {
    items,
    dropdownItems,
  };
};
