import { Text } from '../../../../../Components/Text';
import { hasPermission } from '../../../../../Components/PermissionWrapper';
import { Link, Status, i18n } from '../../../../../Components';
import { PermissionEnum } from '../../../../../Data/Interfaces/model/permissionEnum';

export const labelTemplatesColumns = (t: any) => [
  {
    title: t('production.labelTemplates.list.LabelTemplateName'),
    dataIndex: 'descricao',
    key: 'descricao',
    isHighlightable: true,
    sortColumn: 'descricao',
    sorter: true,
    defaultSortOrder: 'ascend',
    render: (x: string, row: any) => {
      return !hasPermission(PermissionEnum.Producao_ModeloRotulo_VerDetalhes) ? (
        <Text type="ui-tiny-bold" style={{ width: 'fit-content' }}>
          {x}
        </Text>
      ) : (
        <Link
          type="ui-tiny-bold"
          to={`/production/label-templates/${row.externalId}/details`}
          style={{ width: 'fit-content' }}
        >
          <Text type="ui-tiny-bold" color="text-50">
            {x}
          </Text>
        </Link>
      );
    },
  },
  {
    title: t('production.labelTemplates.list.labelType'),
    dataIndex: 'tipoRotulo',
    key: 'tipoRotulo',
    width: '30%',
    render: (prop: any) => `${LabelType(prop)}`,
  },

    {
    title: t('production.labelTemplates.list.status'),
    dataIndex: 'ativo',
    key: 'ativo',
    width: '14%',
    render: (prop: any) => (
      <Status type={prop ? 'success' : 'disabled'}>
        {t(
          `production.labelTemplates.list.statusTypes.${
            prop ? 'active' : 'inactive'
          }`
        )}
      </Status>
    ),
  },
];

const LabelType = (value: any) => {
  switch (value) {
    case 1:
      return `${i18n.t('production.labelTemplates.list.labelTypes.product')}`;
    case 2:
      return `${i18n.t('production.labelTemplates.list.labelTypes.prescription')}`;
    case 3:
      return `${i18n.t('production.labelTemplates.list.labelTypes.inventory')}`;
    default:
      return `${i18n.t('production.labelTemplates.list.labelTypes.product')}`;
  }
};
