import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { PermissionWrapper } from '../../../../../../../PermissionWrapper';
import { Skeleton, Text, Tooltip } from '../../../../../../../';
import { Status } from '../../../../Status';
import { Modal } from '../../../../../../../Modal';
import { Divflex } from '../../../../../../../../Layouts/DivWhithFlex';
import ReactMarkdown from 'react-markdown';
import { PermissionEnum } from '../../../../../../../../Data/Interfaces/model/permissionEnum';
import {
  CHANGE_STATUS_SPECIALTIES,
  GET_SPECIALTIES,
} from '../../../../../../../../ControllerApiHook/UniqIds/People/SpecialtiesKeys';
import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
} from '@/ControllerApiHook/Controller';
import { SpecialtiesAPI } from '@/Data/API/People/SpecialtiesApi';
import { IGetSpecialtiesResponse } from '@/Data/Interfaces/response/Specialties/ISpecialtiesResponse';
import { useExpertiseDetailsShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/Sales/Expertise/details';

export const DynamicEditSpecialtiesBreadcrumb = ({ match }: any) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [status, setStatus] = useState(undefined);
  const queryClient = useQueryClient();

  const { data: specialtie } =
    useControllerQueryApiHook<IGetSpecialtiesResponse>({
      uniqId: GET_SPECIALTIES,
      entityApi: SpecialtiesAPI.getSpecialties,
      externalIds: [match.params.externalId],
    });

  const { t } = useTranslation();

  const { getShortcut } = useExpertiseDetailsShortcuts(t);

  const editStatusHotkey = getShortcut('editStatus');

  const {
    mutateAsync: changeSpecialtiesStatus,
    isLoading: changeSpecialtiesStatusLoading,
  }: any = useControllerMutateApiHook({
    uniqId: CHANGE_STATUS_SPECIALTIES,
    entityApi: SpecialtiesAPI.changeSpecialtiesStatus,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_SPECIALTIES]);
        setModalVisible(false);
      },
    },
  });

  const onEditStatus = useCallback(
    async (value: any) => {
      await changeSpecialtiesStatus({
        especialidadePrescritorExternalId: [match.params.externalId],
        ativo: value,
      });
    },
    [match.params.externalId, changeSpecialtiesStatus]
  );

  if (!specialtie) {
    return (
      <div style={{ width: 400 }}>
        <Skeleton />
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Text
        type="heading3-bold"
        children={`${t('specialties.specialtie')} | ${specialtie.descricao}`}
      />
      <PermissionWrapper
        permission={PermissionEnum.Vendas_EspecialidadePrescritor_AlterarStatus}
        tooltip="noPermission.sale.specialties.editStatus"
      >
        <Tooltip showMe title={editStatusHotkey.tooltip}>
          <Status
            type={specialtie?.ativo ? 'success' : 'disabled'}
            ative={specialtie?.ativo || false}
            onClick={(value) => {
              value ? onEditStatus(value) : setModalVisible(true);
              setStatus(value);
            }}
            loading={changeSpecialtiesStatusLoading}
            dataTestId="status-dropdown-button"
          />
        </Tooltip>
      </PermissionWrapper>
      <Modal
        body={
          <Divflex style={{ padding: '20px', maxWidth: 400 }}>
            <Text type="ui-tiny-content" withoutMarginBottom>
              <ReactMarkdown>
                {t('specialties.edit.statusModal.confirmation', {
                  specialties: `**${specialtie.descricao}**`,
                })}
              </ReactMarkdown>
            </Text>
          </Divflex>
        }
        title={`${t('common.inactivate')} "${specialtie.descricao}"?`}
        onCancelClick={() => setModalVisible(false)}
        visible={modalVisible}
        onOkClick={() => status !== undefined && onEditStatus(status)}
        onClose={() => setModalVisible(false)}
        okButtonName={t('common.inactivate')}
        loading={changeSpecialtiesStatusLoading}
      />
    </div>
  );
};

export const EditSpecialtiesTitle = ({ match }: any) => {
  const { t } = useTranslation();
  const { data: specialtie } =
    useControllerQueryApiHook<IGetSpecialtiesResponse>({
      uniqId: GET_SPECIALTIES,
      entityApi: SpecialtiesAPI.getSpecialties,
      externalIds: [match.params.externalId],
    });

  if (!specialtie) {
    return (
      <div style={{ width: 400 }}>
        <Skeleton />
      </div>
    );
  }
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Text
        type="heading3-bold"
        children={`${t('specialties.specialtie')} | ${specialtie.descricao}`}
      />
    </div>
  );
};

export const EditSpecialtiesBreadcrumb = ({ match }: any) => {
  const { data: specialtie } =
    useControllerQueryApiHook<IGetSpecialtiesResponse>({
      uniqId: GET_SPECIALTIES,
      entityApi: SpecialtiesAPI.getSpecialties,
      externalIds: [match.params.externalId],
    });

  return <Text type="ui-tiny-bold" children={specialtie?.descricao} />;
};
