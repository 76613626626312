import React from 'react';
import { Table } from '../../../../../Components';
import { supplyPlacesColumns } from './StorageLocation.columns';
import { useTranslation } from 'react-i18next';
import { editTableStatus } from '../../../../../Utils/EditModalTableUtils';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { useListStates } from './Hooks/useListStates';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListMemorizations } from './Hooks/useListMemorizations';
import { useListFunctions } from './Hooks/useListFunctions';
import { useSupplyPlaceListShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/inventory/supplyPlaces/list';

const List: React.FC = () => {
  const { t } = useTranslation();

  const { selectedRowsOnTable, setSelectedRowsOnTable, selectedItems } =
    useListStates();

  const {
    deleteSupplyPlace,
    editSupplyPlaceStatus,
    supplyPlaceList,
    isLoadingSupplyPlaceList,
    refetchSupplyPlaceList,
  } = useListCallAPI();

  const { getShortcut } = useSupplyPlaceListShortcuts(t, {});

  const {
    headerOptions,
    rowOptions,
    deleteModal,
    editModal,
    tableHeaderHotKeyTooltip,
  } = useListMemorizations({
    selectedRowsOnTable,
    getShortcut,
  });

  const { onEdit, onDelete } = useListFunctions({
    setSelectedRowsOnTable,
    editSupplyPlaceStatus,
    deleteSupplyPlace,
  });

  return (
    <PageLoading loading={isLoadingSupplyPlaceList}>
      <Table
        columns={supplyPlacesColumns(t)}
        loading={isLoadingSupplyPlaceList}
        headerOptions={headerOptions}
        fieldStatus="ativo"
        hasSelection
        rowsOptions={rowOptions}
        pagination={supplyPlaceList}
        editStatusModalLoading={editSupplyPlaceStatus.isLoading}
        editOptions={editTableStatus()}
        onEdit={onEdit}
        onRefetch={refetchSupplyPlaceList}
        onDelete={onDelete}
        editModal={editModal}
        deleteModal={deleteModal}
        selectedRowsOnTable={selectedRowsOnTable}
        setSelectedRowsOnTable={setSelectedRowsOnTable}
        tableHeaderTooltip={tableHeaderHotKeyTooltip}
        keyToSelect="externalId"
        initialRowsSelected={selectedItems}
      />
    </PageLoading>
  );
};

export default List;
