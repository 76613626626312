import { Divflex } from '@/Layouts/DivWhithFlex';
import { FC } from 'react';

import styles from './Hotkey.module.scss';
import {
  IconArrowDown,
  IconArrowLeft,
  IconArrowRight,
  IconArrowUp,
  IconBackspace,
  IconArrowBigUp,
} from '@tabler/icons-react';
import { Text } from '@components/Text';

export type PossibleKey =
  | 'ctrl'
  | 'shift'
  | 'alt'
  | 'option'
  | 'tab'
  | 'enter'
  | 'backspace'
  | 'up'
  | 'down'
  | 'left'
  | 'right'
  | 'a'
  | 'b'
  | 'c'
  | 'd'
  | 'e'
  | 'f'
  | 'g'
  | 'h'
  | 'i'
  | 'j'
  | 'k'
  | 'l'
  | 'm'
  | 'n'
  | 'o'
  | 'p'
  | 'q'
  | 'r'
  | 's'
  | 't'
  | 'u'
  | 'v'
  | 'w'
  | 'y'
  | 'x'
  | 'z'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '0'
  | '!'
  | '@'
  | '#'
  | '$'
  | '%'
  | '^'
  | '&'
  | '*'
  | '('
  | ')'
  | '?';

export interface HotkeyProps {
  hotKey?: PossibleKey;
  className?: string;
  width?: number;
  height?: number;
  fontSize?: number;
  hotkeys?: PossibleKey[];
  withAfterText?: boolean;
}

export const Hotkey: FC<HotkeyProps> = ({
  hotKey,
  className,
  width,
  height,
  fontSize,
  hotkeys,
  withAfterText,
}) => {
  let key = hotKey?.toUpperCase() as any;

  let keys = hotkeys?.map((x) => x.toUpperCase());

  if (key === 'CTRL') {
    key = 'Ctrl';
  }

  if (key === 'ALT') {
    key = 'Alt';
  }

  if (key === 'TAB') {
    key = 'Tab';
  }

  if (key === 'ENTER') {
    key = 'Enter';
  }

  if (key === 'OPTION') {
    key = '⌥';
  }

  if (key === 'SHIFT') {
    key = <IconArrowBigUp color="#717171" width="15" height="15" />;
  }

  if (keys?.includes('SHIFT')) {
    key = (
      <IconArrowBigUp
        color="#717171"
        width="11"
        height="11"
        style={{ marginRight: 2, marginBottom: 2 }}
      />
    );
  }

  if (key === 'BACKSPACE') {
    key = <IconBackspace color="#717171" width="20" height="20" />;
  }

  if (key === 'UP') {
    key = <IconArrowUp color="#717171" width="20" height="20" />;
  }

  if (key === 'DOWN') {
    key = <IconArrowDown color="#717171" width="20" height="20" />;
  }

  if (key === 'LEFT') {
    key = <IconArrowLeft color="#717171" width="20" height="20" />;
  }

  if (key === 'RIGHT') {
    key = <IconArrowRight color="#717171" width="20" height="20" />;
  }

  return withAfterText ? (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Divflex
        className={`${hotkeys && styles['container-multiple-keys']} ${
          styles['container']
        } ${className}`}
        style={{ width: width, height: height, fontSize: fontSize }}
      >
        {key}
        {keys && keys[0]}
      </Divflex>

      {keys && keys.length === 2 && (
        <Text
          type="small-text"
          color="text-200"
          style={{ margin: '0px 6px 0 4px' }}
        >
          depois
        </Text>
      )}

      {keys && keys.length === 2 && (
        <Divflex
          className={`${hotkeys && styles['container-multiple-keys']} ${
            styles['container']
          } ${className}`}
          style={{ width: width, height: height, fontSize: fontSize }}
        >
          {keys[1]}
        </Divflex>
      )}
    </div>
  ) : (
    <Divflex
      className={`${hotkeys && styles['container-multiple-keys']} ${
        styles['container']
      } ${className}`}
      style={{ width: width, height: height, fontSize: fontSize }}
    >
      {key}
      {keys && `${keys[1]}`}
    </Divflex>
  );
};
