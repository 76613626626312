import * as yup from 'yup';
import { i18n } from '../../../../../Components';

export const CreateSchema = (
  validateName: (
    descricao: string | null | undefined,
    tipoRotulo: string | null | undefined,
    callback: (success: boolean) => void
  ) => void,
  t: (key: string) => string
) => yup.object().shape({
    descricao: yup
      .string()
      .required(
        i18n.t('production.labelTemplates.create.errors.requiredLabelTemplate')
      )
      .test(
        'checkDuplications',
        t('production.labelTemplates.create.errors.errorLabelTemplateNameExist'),
        function (value) {
          const { tipoRotulo } = this.parent;
          return new Promise((resolve) => {
            validateName(
              value, 
              tipoRotulo,
              (success) => {
                resolve(success);
              }
            );
          });
        }
      ),
    tipoRotulo: yup
      .string()
      .required(
        i18n.t(
          'production.labelTemplates.create.errors.requiredLabelType'
        )
      ),
  });
