import { useFormikContext } from 'formik';

export const useAllowNegativeValue = () => {
  const form: any = useFormikContext();

  const onChangeValue = (x: any, name: string) => {
    if (x.includes('-')) {
      const numberValue = Number(
        x.replace('-', '').replace(',', '.').replace('R$', '').replace('$', '')
      );
      form.setFieldValue(name, numberValue);
      return numberValue;
    }
    return x;
  };

  return { onChangeValue };
};
