import { Col, Row } from 'antd';
import { FC, useState } from 'react';
import { Card, Label, Text } from '../../../../../../../../../Components';
import { Avatar } from '../../../../../../../../../Components/Avatar';
import { ButtonCard } from '../../../../../../../../../Components/EntityInformation/ButtonCard';
import { InfoModal } from '../../../../Modal/InfoModal/infoModal';
import DateUtils from '../../../../../../../../../Utils/DateUtils';
import { useTranslation } from 'react-i18next';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { MaskForCNPJ, MaskForCPF } from '@/Utils/MasksUtils';
import StorageUtils from '@/Utils/StorageUtils';
import _ from 'lodash';
import { IGetClientResponse } from '@/Data/Interfaces/response/Person/Client';
import { Placements } from '@/Components/Tooltip';

export enum PersonType {
  legalEntity = 0,
  juridicalPerson = 1,
}

interface IInfoCard {
  client: IGetClientResponse;
  tooltip?: {
    title: React.ReactNode | string;
    placement?: Placements;
    targetOffset?: number[];
  };
  dataTestIdEditButton?: string;
}

export const InfoCard: FC<IInfoCard> = ({
  client,
  tooltip,
  dataTestIdEditButton,
}) => {
  const [editCardDisabled, setEditCardDisabled] = useState(true);

  const preferences = StorageUtils.getPreference();
  const { t } = useTranslation();

  return (
    <Card
      title={
        <Avatar
          fisrtName={client.nome}
          size="M"
          type="ui-tiny-bold"
          description={{
            description: client.nome,
            type: 'heading4',
            color: 'text-50',
          }}
          color="white"
        />
      }
      withoutCollapse
    >
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Label
            type="ui-tiny-content"
            color="text-200"
            children={t('client.editPage.personType')}
            withBottomMargin
          />
          <Text
            type="ui-tiny-content"
            color="text-50"
            children={
              client.tipoPessoa === PersonType.legalEntity
                ? t('client.editPage.legalEntity')
                : t('client.editPage.juridicalPerson')
            }
          />
        </Col>
        <Col span={24}>
          <Label
            type="ui-tiny-content"
            color="text-200"
            children={
              client.tipoPessoa === PersonType.legalEntity ? 'CPF' : 'CNPJ'
            }
            withBottomMargin
          />
          <Text
            type="ui-tiny-content"
            color={
              client.tipoPessoa === PersonType.juridicalPerson
                ? client.cnpj
                  ? 'text-50'
                  : 'text-300'
                : 'text-50'
            }
            children={
              client.tipoPessoa === PersonType.legalEntity
                ? MaskForCPF(client.cpf)
                : client.cnpj
                ? MaskForCNPJ(client.cnpj)
                : t('client.editPage.withoutCNPJ')
            }
          />
        </Col>
        {client.tipoPessoa === PersonType.legalEntity ? (
          <Col span={24}>
            <Label
              type="ui-tiny-content"
              color="text-200"
              children={t('client.editPage.birthDate')}
              withBottomMargin
            />
            <Text
              type="ui-tiny-content"
              color="text-50"
              children={
                !_.isNil(client.dataNascimento)
                  ? DateUtils.convertDate(client.dataNascimento)
                  : t('client.editPage.withoutBirthDate')
              }
            />
          </Col>
        ) : (
          <Col span={24}>
            <Label
              type="ui-tiny-content"
              color="text-200"
              children={t('client.editPage.corporateReason')}
              withBottomMargin
            />
            <Text
              type="ui-tiny-content"
              color="text-50"
              children={client.razaoSocial}
            />
          </Col>
        )}

        <Col span={24}>
          <Label
            type="ui-tiny-content"
            color="text-200"
            children={t('client.editPage.observation')}
            withBottomMargin
          />
          <Text
            type="ui-tiny-content"
            color="text-50"
            children={client.observacao}
          />
        </Col>
        <Col span={12}>
          <Label
            type="ui-tiny-content"
            color="text-200"
            children={t('client.editPage.descProduct')}
            withBottomMargin
          />
          <Text
            type="ui-tiny-content"
            color={client.descontoProdutosAcabados > 0 ? 'text-50' : 'text-300'}
            children={
              client.descontoProdutosAcabados > 0
                ? `${client.descontoProdutosAcabados?.toLocaleString(
                    preferences?.idioma,
                    {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                    }
                  )}%`
                : t('client.editPage.withoutDiscount')
            }
          />
        </Col>
        <Col span={12}>
          <Label
            type="ui-tiny-content"
            color={'text-200'}
            children={t('client.editPage.descFormula')}
            withBottomMargin
          />
          <Text
            type="ui-tiny-content"
            color={client.descontoFormulas > 0 ? 'text-50' : 'text-300'}
            children={
              client.descontoFormulas > 0
                ? `${client.descontoFormulas?.toLocaleString(
                    preferences?.idioma,
                    {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                    }
                  )}%`
                : t('client.editPage.withoutDiscount')
            }
          />
        </Col>
        <ButtonCard
          disabled={editCardDisabled}
          onDisabledChange={setEditCardDisabled}
          permission={{
            permission: PermissionEnum.Vendas_Clientes_EditarDetalhes,
            tooltip: 'noPermission.sale.client.edit',
          }}
          dataTestId={dataTestIdEditButton}
          tooltip={tooltip}
        />
      </Row>
      <InfoModal
        visible={!editCardDisabled}
        client={client}
        changeVisibleModal={(x) => setEditCardDisabled(x)}
      />
    </Card>
  );
};
