import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Form } from '../../../../../Components';
import { CreateSchema } from './CreateForm.schema';
import { useCreateCallAPI } from './Hooks/useCreateCallAPI';
import { useCreateFunctions } from './Hooks/useCreateFunction';
import { FooterButtons } from './Components/FooterButtons';
import { RowDescription } from './Components/Rows/DescriptionRow/index';
import { ConnectedFocusError } from '@/Components/ConnectedFocusError';
import { useCheckNameDuplicationCallAPI } from './Hooks/useCheckNameDuplicationCallAPI';

export const CreateLabelTemplate: React.FC = () => {
  const { t } = useTranslation();

  const { checkNameDuplicationsDebounced } = useCheckNameDuplicationCallAPI();

  const { createLabelTemplate, createLabelTemplateIsLoading } =
    useCreateCallAPI();

  const { onSubmit } = useCreateFunctions({
    createLabelTemplate: createLabelTemplate,
  });

  return (
    <Form onSubmit={onSubmit} schema={CreateSchema(checkNameDuplicationsDebounced, t)} validateOnChange>
      <Card
        title={t('production.labelTemplates.create.card')}
        withoutCollapse={true}
      >
        <RowDescription />
      </Card>

      <FooterButtons
        isLoadingCreateLabelTemplate={createLabelTemplateIsLoading}
      />
      <ConnectedFocusError />
    </Form>
  );
};
