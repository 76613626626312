import { FC } from 'react';
import { Table } from '@/Components';
import { useTranslation } from 'react-i18next';
import { editTableStatus } from '@/Utils/EditModalTableUtils';
import { PageLoading } from '@/Layouts/PageLoading';
import { expertiseCollums } from './expertise.columns';
import { useListStates } from './Hooks/useListStates';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListFunctions } from './Hooks/useListFunctions';
import { useListMemorizations } from './Hooks/useListMemorizations';
import { CreateExpertiseModal } from './Modal/CreateExpertiseModal';
import { useExpertiseListShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/Sales/Expertise/list';

export const List: FC = () => {
  const { t } = useTranslation();

  const {
    selectedRowsOnTable,
    setSelectedRowsOnTable,
    createModalOpen,
    setCreateModalOpen,
    selectedItems,
  } = useListStates();

  const {
    changeSpecialtiesStatus,
    changeSpecialtiesStatusLoading,
    deleteSpecialties,
    especialtiesList,
  } = useListCallAPI();

  const { getShortcut } = useExpertiseListShortcuts(t);

  const { onEdit, onDelete } = useListFunctions({
    changeSpecialtiesStatus,
    deleteSpecialties,
    setSelectedRowsOnTable,
    setCreateModalOpen,
  });

  const { headerOptions, rowOptions, deleteModal, tableHeaderHotKeyTooltip } =
    useListMemorizations({
      setCreateModalOpen,
      selectedRowsOnTable,
      getShortcut,
    });

  return (
    <>
      <PageLoading loading={especialtiesList.isLoading}>
        <Table
          columns={expertiseCollums(t)}
          headerOptions={headerOptions}
          rowsOptions={rowOptions}
          onEdit={onEdit}
          onDelete={onDelete}
          deleteModal={deleteModal}
          editStatusModalLoading={changeSpecialtiesStatusLoading}
          editOptions={editTableStatus()}
          loading={especialtiesList.isLoading}
          hasSelection
          fieldStatus="ativo"
          pagination={especialtiesList.data}
          onRefetch={especialtiesList.refetch}
          selectedRowsOnTable={selectedRowsOnTable}
          setSelectedRowsOnTable={setSelectedRowsOnTable}
          tableHeaderTooltip={tableHeaderHotKeyTooltip}
          keyToSelect="externalId"
          initialRowsSelected={selectedItems}
        />
      </PageLoading>
      <CreateExpertiseModal
        createModalOpen={createModalOpen}
        setCreateModalOpen={setCreateModalOpen}
      />
    </>
  );
};
