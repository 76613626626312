import { Modal, Text } from '@/Components';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useFinishRelease } from '../../hooks/useFinishRelease';
import { useParams } from 'react-router';

interface IFinishReleaseModalProps {
  visible: boolean;
  changeVisibility: () => void;
}

export const FinishReleaseModal: React.FC<IFinishReleaseModalProps> = ({
  changeVisibility,
  visible,
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();
  const { finishRelease } = useFinishRelease();
  const { externalId } = useParams<{ externalId: string }>();

  const onFinishRelease = async () => {
    await finishRelease.mutate({
      ...form.values,
      inventarioExternalId: externalId,
    });
    changeVisibility();
  };

  return (
    <Modal
      title={t('supply.inventory.release.modalFinishRelease.title')}
      visible={visible}
      onCancelClick={changeVisibility}
      onClose={changeVisibility}
      onOkClick={onFinishRelease}
      body={
        <div style={{ padding: 20 }}>
          <Text type="paragraph">
            {t('supply.inventory.release.modalFinishRelease.body')}
          </Text>
        </div>
      }
      widthModal={400}
      withForm
      okButtonColor="status-success-base"
      okButtonName={t('supply.inventory.release.modalFinishRelease.title')}
      disabledButton={finishRelease.isLoading}
      loading={finishRelease.isLoading}
    />
  );
};
