import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@/Components/Table';
import { ContainerPage } from '@/Layouts/ContainerPage';
import { stockLocationColumns } from './stockLocation.columns';
import { PageLoading } from '@/Layouts/PageLoading';
import { useStockLocationCallAPI } from './Hooks/useStockLocationCallAPI';
import { useStockLocationMemorizations } from './Hooks/useStockLocationMemorizations';
import { useLotSupplyPlacesTabShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/inventory/lots/supplyPlaces';
import { useSupplyPlaceDetailsShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/inventory/supplyPlaces/details';

export const StockLocationsList: FC = () => {
  const { t } = useTranslation();

  const { stockLocationList, isLoadingList, refetchStockLocationList } =
    useStockLocationCallAPI();

  useSupplyPlaceDetailsShortcuts(t, {});

  const { headerOptions } = useStockLocationMemorizations();

  useLotSupplyPlacesTabShortcuts(t, {});

  return (
    <PageLoading loading={isLoadingList}>
      <ContainerPage>
        <Table
          columns={stockLocationColumns(t)}
          hasSelection={false}
          headerOptions={headerOptions}
          pagination={stockLocationList}
          onRefetch={refetchStockLocationList}
          loading={isLoadingList}
        />
      </ContainerPage>
    </PageLoading>
  );
};

export default StockLocationsList;
