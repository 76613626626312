import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StockLocationsList } from '../StockLocation';
import { DetailsForm } from '../DetailsCards';
import { useParams } from 'react-router-dom';
import { history } from '@/Store';
import { IUseMemorizations } from './interfaces';
import { IPermissionEditButon } from '@/Components/HorizontalTabs';
import { IButtonDropDownItems } from '@/Components/Button/ButtonDropdown';

export const useEditMemorizations = ({
  disabled,
  setDisabled,
  setVisibleModal,
  lot,
  getShortcut,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const alternateTabDetailsHotkey = getShortcut('alternateTabDetails');
  const alternateTabTraceabilityHotkey = getShortcut(
    'alternateTabTraceability'
  );
  const alternateTabSupplyPlacesHotkey = getShortcut(
    'alternateTabSupplyPlaces'
  );
  const editLotHotkey = getShortcut('editLot');

  const params: Readonly<
    Partial<{
      externalId: string;
      tab: string;
    }>
  > = useParams();

  const items = useMemo(
    () => [
      {
        label: t('Detalhes'),
        key: 'details',
        children: (
          <DetailsForm
            disabled={disabled}
            toogleDisabled={(x: boolean) => setDisabled(x)}
          />
        ),
        dataTestId: 'tab-lot-details',
        shortCutTooltip: {
          title: alternateTabDetailsHotkey.tooltip,
          targetOffset: [0, 10],
        },
      },
      {
        label: t('Rastreabilidade'),
        key: 'traceability',
        disabled: true,
        dataTestId: 'tab-lot-traceability',
        shortCutTooltip: {
          title: alternateTabTraceabilityHotkey.tooltip,
          targetOffset: [0, 10],
        },
      },
      {
        label: t('Locais de Estoque'),
        key: 'stockLocations',
        children: <StockLocationsList />,
        dataTestId: 'tab-lot-supply-places',
        shortCutTooltip: {
          title: alternateTabSupplyPlacesHotkey.tooltip,
          targetOffset: [0, 10],
        },
      },
    ],
    [t, disabled]
  );

  const rightButton = useMemo(
    () => ({
      ...(disabled &&
        params.tab === 'details' && {
          onClick: () => setDisabled(false),
          isVisible: disabled,
          codePermissionEdit: 2004,
          messageTooltipPermission: 'noPermission.supply.lot.edit',

          dataTestId: 'edit-lot-button',
          hotkeyTooltip: {
            title: editLotHotkey.tooltip,
          },
        }),
    }),
    [disabled]
  );

  const leftButton = useMemo(
    (): IPermissionEditButon | undefined => ({
      isVisible: true,
      onClick: () =>
        history.push(`/inventory/products/${lot?.produtoExternalId}/details`),
      leftIcon: 'Box',
      children: t('supply.lot.details.goToProducts'),
      codePermissionEdit: 2007,
      messageTooltipPermission: 'noPermission.supply.lot.detailProducts',
    }),
    [t, lot]
  );

  const dropdownItems = useMemo(
    (): IButtonDropDownItems | undefined => [
      {
        label: 'Excluir',
        icon: 'trash',
        onClick: () => setVisibleModal(true),
        permission: {
          permission: 2006,
          tooltip: 'noPermission.supply.lot.delete',
        },
        key: 'backspace',
        dataTestId: 'dropdown-option-delete',
      },
    ],
    []
  );

  return {
    items,
    rightButton,
    leftButton,
    dropdownItems,
  };
};
