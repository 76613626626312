import { Button, Dropdown, Icon } from '@/Components';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { useListSupplyPlace } from '../hooks/useListSupplyPlace';
import { useListGroups } from '../hooks/useListGroups';
import { useListSubgroups } from '../hooks/useListSubgroups';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

interface IRowElementsProps {
  index: number;
  addRow: (index: number) => void;
  edit: boolean;
  supplyPlace: string;
  group: string;
  removeRow: (index: number) => void;
}

export const RowElements: React.FC<IRowElementsProps> = ({
  index,
  addRow,
  edit,
  group,
  supplyPlace,
  removeRow,
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();
  const [isInitial, setIsInitial] = useState(true);

  const { fetchNewSupplyPlacePage, supplyPlaceData } = useListSupplyPlace();
  const { refetchPage, groupsData, fetchNewGroupsPage } = useListGroups();
  const { fetchNewSubgroupsPage, refetchPageSubGroup, subgroupsData } =
    useListSubgroups();

  useEffect(() => {
    if (
      form.values.inventarioEspecificacao.length &&
      isInitial &&
      form.values.inventarioEspecificacao[index] &&
      form.values.inventarioEspecificacao[index].grupoExternalId &&
      form.values.inventarioEspecificacao[index].localEstoqueExternalId
    ) {
      refetchPage(
        form.values.inventarioEspecificacao[index].localEstoqueExternalId
      );
      refetchPageSubGroup(
        form.values.inventarioEspecificacao[index].localEstoqueExternalId,
        form.values.inventarioEspecificacao[index].grupoExternalId
      );

      setIsInitial(false);
    }
  }, [form.values.inventarioEspecificacao]);

  return (
    <>
      <Col span={14}>
        <Dropdown
          name={`inventarioEspecificacao.${index}.localEstoqueExternalId`}
          items={supplyPlaceData.supplyPlacesList}
          placeHolder={t('supply.inventory.create.supplyPlacesPlaceholder')}
          onScrollEnd={fetchNewSupplyPlacePage}
          onChange={(value: string) => {
            form.setFieldValue(
              `inventarioEspecificacao.${index}.grupoExternalId`,
              undefined
            );
            form.setFieldValue(
              `inventarioEspecificacao.${index}.subGrupoExternalId`,
              undefined
            );
            addRow(index);
            refetchPage(value);
          }}
          disabled={!edit}
          selectedItemFromForm={
            form.values.inventarioEspecificacao[index]
              ? `${form.values.inventarioEspecificacao[index].inventarioEspecificacaoId}-local-estoque`
              : ''
          }
        />
      </Col>
      <Col span={4}>
        <Dropdown
          name={`inventarioEspecificacao.${index}.grupoExternalId`}
          disabled={!supplyPlace || !edit}
          placeHolder={t('supply.inventory.create.groupsPlaceholder')}
          items={groupsData}
          onChange={(value: string) => {
            form.setFieldValue(
              `inventarioEspecificacao.${index}.subGrupoExternalId`,
              undefined
            );
            addRow(index);
            refetchPageSubGroup(supplyPlace, value);
          }}
          onScrollEnd={fetchNewGroupsPage}
          selectedItemFromForm={
            form.values.inventarioEspecificacao[index]
              ? `${form.values.inventarioEspecificacao[index].inventarioEspecificacaoId}-grupo`
              : ''
          }
        />
      </Col>
      <Col span={4}>
        <Dropdown
          name={`inventarioEspecificacao.${index}.subGrupoExternalId`}
          disabled={!group || !edit}
          items={subgroupsData}
          placeHolder={t('supply.inventory.create.subgroupsPlaceholder')}
          onChange={() => {
            addRow(index);
          }}
          onScrollEnd={fetchNewSubgroupsPage}
          selectedItemFromForm={
            form.values.inventarioEspecificacao[index]
              ? `${form.values.inventarioEspecificacao[index].inventarioEspecificacaoId}-subgrupo`
              : ''
          }
        />
      </Col>
      {edit && (
        <Col span={2}>
          <Button
            disabled={index === form.values?.inventarioEspecificacao?.length}
            type="secondary"
            onClick={() => {
              removeRow(index);
            }}
          >
            <Icon
              style={{
                cursor:
                  index === form.values?.inventarioEspecificacao?.length
                    ? 'not-allowed'
                    : 'default',
              }}
              icon="trash"
              size="M"
            />
          </Button>
        </Col>
      )}
    </>
  );
};
