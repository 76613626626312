export const productionEN = {
  packingClassification: {
    list: {
      searchPlaceholder: 'Search by a packaging classification',
      description: 'Packing Classification Name',
      linkedProduct: 'Linked Products',
      status: 'Status',
      statusTypes: {
        active: 'Active',
        inactive: 'Inactive',
      },

      shortcuts: {
        addPackingClassification: 'Add new packing classification',
        selectAll: 'Select all',
        editSelected: 'Edit selected',
        deleteSelected: 'Delete selected',
      },

      modalDelete: {
        descriptionPackingClassification: 'packing classification',
      },
      newButton: 'Register Packing Classification',
      editStatus: 'Status',
    },
    create: {
      card: 'Details',

      name: 'Packing Classification Name',
      namePlaceholder: 'Enter the name of the Pack Classification',

      footerButton: {
        cancel: 'Cancel',
        okButton: 'Register',
      },

      erros: {
        requiredName: 'Packing Classification Name is required',
        errorPackingClassificationNameExist:
          'This packaging classification already exists in the system',
      },
    },

    details: {
      shortcuts: {
        goToDetailsTab: 'Go to packing classification details',
        goToLinkedProductsTab: 'Go to linked products of the packing classification',
        editPackingClassification: 'Edit packing classification',
      },
      name: 'Packing Classification Name',
      namePlaceholder: 'Enter the name of the Pack Classification',
      changeStatusDescriptionTitle: ' the packing classification',
      changeStatusDescription:
        'Are you sure you want to inactivate the packing classification {{description}}',
      statusTypes: {
        active: 'Activate',
        inactive: 'Inactivate',
      },
      editPackingClassification: {
        tab: {
          details: 'Details',
        },
        buttonActions: {
          delete: 'Delete',
        },
      },
      modal: {
        messageQuestionDelete:
          'Are you sure you want to delete the package classification',
      },
      tooTips: {
        description: 'Original Packaging Classification name: ',
      },
    },

    modalCannotDelete: {
      messageTitle: 'the packing classification ',
      messageSubtitle: 'the packing classification ',
      manyMessageSubtitle: 'packaging classifications',
      manyMessageReasonCannotDelete:
        'These packaging classifications have already been moved within the system and therefore it is not possible to delete them.',
      messageReasonCannotDelete:
        'This packaging classification has already been moved within the system and therefore it is not possible to exclude it',
      messageWarning:
        'If you no longer want to use this package classification, change its status to "Inactive".',
      manyMessageWarning:
        'If you no longer want to use these packaging classifications, change their status to “Inactive”.',
    },

    linkedList: {
      shortcuts: {
        selectAll: 'Select all',
        unlinkSelected: 'Unlink product from the packing classification',
      },
      code: 'Code',
      productName: 'Product Name',
      statusName: 'Status',
      buttonUnlink: 'Remover Packaging Classification',
      modalRemoveLinked: {
        messageTitle: 'of this packaging classification',
        messageStartBody:
          'Are you sure you want to remove the product {{action}}',
        messageFinishBody: 'packaging classification ',
      },
    },
  },

  capsuleColors: {
    list: {
      searchPlaceholder: 'Search for a capsule color',
      capsuleColors: 'Capsule Color',
      capsuleTransparent: 'Transparent Capsule',
      yesValue: 'Yes',
      noValue: 'No',

      newButton: 'Register Capsule Color',

      shortcuts: {
        addCapsuleColor: 'Add new capsule color',
        selectAll: 'Select all',
        deleteSelected: 'Delete selected',
      },

      modalDelete: {
        descriptionCapsuleColors: 'capsule color',

        deletePackingClassificationDetail: 'Delete Packing Classification',

        deleteCapsuleColorsList:
          'Are you sure you want to delete the capsule color',
        theManyCapsuleColors: ' capsule color',
        theCapsuleColors: 'the capsule colors',
        deleteCapsuleColorsDetail: 'Delete capsule color',
        deleteManyCapsuleColors: 'Capsule Colors',
      },
    },

    create: {
      capsuleColor: 'Capsule Color',
      namePlaceholder: 'IEnter capsule color',

      transparentCapsule: 'Transparent Capsule',

      erros: {
        requiredName: 'Capsule Color Name is required',
      },
    },

    details: {
      shortcuts: {
        editCapsuleColor: 'Edit capsule color',
      },
      name: 'Capsule Color',
      namePlaceholder: 'Enter Capsule Color name',

      transparentCapsule: 'Transparent Capsule',

      editCapsuleColor: {
        tab: {
          details: 'Details',
        },
        buttonActions: {
          delete: 'Delete',
        },
      },
      modal: {
        messageQuestionDelete:
          'Are you sure you want to delete the capsule color',
        deleteCapsuleColors: 'Delete Capsule Color',
      },
      tooTips: {
        description: 'Original capsule color: ',
      },
    },

    errors: {
      requiredCapsuleColor: 'Capsule color name is required.',
      errorCapsuleColorNameExist:
        'This capsule color is already registered in the system',
    },

    modalCannotDelete: {
      messageTitle: 'the capsule color ',
      manyMessageTitle: 'Capsule Colors',
      messageSubtitle: 'the capsule color ',
      manyMessageSubtitle: 'the capsule colors:',
      messageReasonCannotDelete:
        'This capsule color has already been moved within the system and therefore it is not possible to exclude it',
      manyMessageReasonCannotDelete:
        'These capsule colors have already been moved within the system and therefore it is not possible to delete them.',
    },
  },

  posology: {
    list: {
      searchPlaceholder: 'Search for a dosage',
      posologyDescription: 'Posology',
      pharmaceuticalFormDescription: 'Pharmaceutical Form',
      amountDosePeriod: 'Quantity of dose per Period',
      period: 'Period',
      shortcuts: {
        addPosology: 'Add new posology',
        selectAll: 'Select all',
        editSelected: 'Edit selected',
        deleteSelected: 'Delete selected',
      },
      day: 'Day',
      week: 'Week',
      month: 'Month',

      yesValue: 'Yes',
      noValue: 'No',

      newButton: 'Posology Register',

      status: 'Status',
      statusTypes: {
        active: 'Activate',
        inactive: 'Inactivate',
      },

      modalDelete: {
        descriptionPosology: 'the dosage',
      },
    },

    create: {
      posology: 'Posology',
      namePlaceholder: 'Posology description',

      pharmaceuticalForm: 'Pharmaceutical Form',
      pharmaceuticalFormPlaceholder: 'Select a pharmaceutical form',

      amountDosePeriod: 'Quantity of Dose per Period',
      period: 'Period',
      periodPlaceholder: 'Select a period',

      periodType: {
        day: 'Day',
        week: 'Week',
        month: 'Month',
      },

      errors: {
        requiredName: 'Posology name is required',
        requiredPharmaceuticalForm: 'Pharmaceutical Form is required',
        requiredDosePeriodForm: 'Dose is required',
        requiredPeriod: 'Period is required',
        errorPosologyNameExist:
          'This dosage already exists for this pharmaceutical form',
      },
    },

    details: {
      shortcuts: {
        editPosology: 'Edit posology',
      },
      changeStatusDescriptionPosology:
        'Are you sure you want to inactivate the dosage {{description}}',

      statusTypes: {
        active: 'Activate',
        inactive: 'Inactivate',
      },

      toolTips: {
        descriptionPosology: 'Original Dosage Name: ',
        amountPeriod: 'Dose Quantity per Original Period: ',
        period: 'Original period: ',
        pharmaceuticalForm: 'Original Pharmaceutical Form: ',
      },

      modal: {
        messageQuestionDelete:
          'Are you sure you want to delete the dosage {{posology}}?',
      },
    },
  },

  pharmaceuticalForm: {
    list: {
      searchPlaceholder: 'Search for a pharmaceutical form',
      orderNumber: 'Order',
      description: 'Description',
      presentation: 'Presentation',
      usePharmaceuticalForm: 'Use',
      laboratoryDescription: 'Laboratory',
      status: 'Status',
      statusTypes: {
        active: 'Activate',
        inactive: 'Inactivate',
      },
      shortcuts: {
        addPharmaceuticalForm: 'Add new pharmaceutical form',
        selectAll: 'Select all',
        editSelected: 'Edit selected',
        deleteSelected: 'Delete selected',
      },

      internal: 'Internal',
      external: 'External',
      topical: 'Topical',

      newButton: 'Register Pharmaceutical Form',

      editStatus: 'Status',
    },

    create: {
      order: 'Order',
      orderPlaceholder: 'Ex: 001',

      description: 'Description',
      descriptionPlaceholder: 'Pharmaceutical Form Description',

      calculationType: 'Calculation Type',
      calculationTypePlaceholder: 'Select a calculation type',

      excipientMinimum: 'Minimum Excipient (%)',
      excipientMinimumPlaceholder: 'Ex. 0.5%',

      laboratory: 'Laboratory',
      laboratoryPlaceholder: 'Search for a laboratory',

      use: 'Use',
      usePlaceholder: 'Select the usage type',
      useType: {
        internalType: 'Internal',
        externalType: 'External',
        topicalType: 'Topical',
      },

      presentation: 'Presentation',
      presentationPlaceholder: 'Ex: CAP',

      unitMeasurement: 'Unit of Measurement',
      unitMeasurementPlaceholder: 'Select the unit of measurement',

      validityDurationInDays: 'Expiration',
      validityDurationInDaysPlaceholder: 'EX: 10',
      validityDurationSuffix: 'days',
      validityDurationInDaysTooltips: 'Expiration in Days',

      operationalCost: 'Operating Cost',
      operationalCostPlaceholder: 'EX: R$10,00',

      productCode: 'Cód. Prod.',
      productCodePlaceholder: '1234',

      packingClassification: 'Packaging Classification',
      packingClassificationPlaceholder: 'Search for a packaging classification',
      linkedPackingClassification: 'Link packaging classification',
      linkedPackingClassificationButton: 'Link',

      buttonCancel: 'Cancel',

      calculationTypeDropdown: {
        baseRecipe: 'According to the prescription',
        baseQSP: 'According to the QSP',
      },

      errors: {
        requiredName: 'Pharmaceutical Form Name is required',
        errorPharmaceuticalFormDescriptionExist:
          'This pharmaceutical form is already registered in the system',
        errorPharmaceuticalFormApresentationExist:
          'This pharmaceutical form already exists with this presentation',
        requiredOrder: 'Order is required',
        requiredDescription: 'Description is required',
        requiredPresentation: 'Presentation is required',
        requiredUse: 'Use is required',
        requiredCalculationType: 'Calculation Type is required',
        requiredUnitOfMensurament: 'Unit of Measurement is required',
        requiredMinimunExcipient: 'Minimum Excipient is required',
        requiredExpirationDays: 'Expiration Days is required',
        requiredLaboratory: 'Laboratory is required',
        requiredOperationalCost: 'Cost Price is Required',
        requiredPackingClassification:
          'Packing Classification is required',
      },
    },

    details: {
      shortcuts: {
        goToDetailsTab: 'Go to pharmaceutical form details',
        goToPackingClassificationTab: 'Go to packing classifications of the pharmaceutical form',
        editPharmaceuticalForm: 'Edit pharmaceutical form',
      },

      name: 'Pharmaceutical Form Name',
      namePlaceholder: 'Enter the name of the Pharmaceutical Form',

      money: 'US$ ',

      oneDeleteTitle: 'the pharmaceutical form',
      manyDeleteTitle: ' pharmaceutical forms',
      deleteInfo:
        'Are you sure you want to delete the pharmaceutical form {{description}}?',

      tooltips: {
        previousOrder: 'Previous PharmaceuticalForm Name: ',
        previousDescription: 'Previous Description: ',
        previousCalculationType: 'Previous Calculation Type: ',
        previousExcipientMinimum: 'Previous Excipient Minimum: ',
        previousLaboratory: 'Previous Laboratory: ',
        previousUse: 'Previous Use Type: ',
        previousPresentation:
          'Previous Presentation of the original Pharmaceutical Form: ',
        previousUnitMeasurement: 'Previous Unit of Measurement: ',
        previousValidityDurationInDays: 'Previous Expiration in days: ',
        previousDays: 'Days',
        previousOperationalCost: 'Previous operation cost: ',
      },

      changeStatusDescription:
        'Are you sure you want to inactivate the Pharmaceutical Form {{pharmaceuticalForm}}',
      statusTypes: {
        active: 'Activate',
        inactive: 'Inactivate',
      },

      packingClassification: {
        shortcuts: {
          addPackingClassification: 'Add new packaging classification',
          selectAll: 'Select all',
          unlinkSelected: 'Unlink packaging classification from pharmaceutical form',
        },
        description: 'Product Name',
        status: 'Status',
        newButton: 'Link Packaging Classification',
        searchPlaceHolder: 'Search for a product',
        unlink: 'Unlink Packaging Classification',
        newLink: 'Packaging Classification Successfully Linked',
        messageTitleOne: 'Unlink',
        messageMultiple:
          'Are you sure you want to unlink from the pharmaceutical form',
        messageTitleMultiple: 'Packaging Classifications',
        messageFirst:
          'Are you sure you want to unlink the packaging classification {{action}}',
        messageFinishBody: 'from the pharmaceutical form',
        errorPharmaceuticalFormBindPackingClassificationExist:
          'This Packaging Classification has already been linked',
      },

      titleInativateStatusModal: 'Inactivate the pharmaceutical form',
    },

    modalCannotDelete: {
      messageTitle: 'the pharmaceutical form ',
      messageSubtitle: 'the pharmaceutical form ',
      manyMessageSubtitle: 'pharmaceutical forms',
      messageReasonCannotDelete:
        'This pharmaceutical form has already had activity within the system and, therefore, deletion is not possible.',
      manyMessageReasonCannotDelete:
        'These pharmaceutical forms have already been moved within the system and therefore their exclusion is not possible.',
      messageWarningCannotDelete:
        'If you no longer want to use this pharmaceutical form, change its status to "Inactive".',
      manyMessageWarningCannotDelete:
        'If you no longer wish to use these pharmaceutical forms, change their status to “Inactive”.',
    },
  },

  standardFormula: {
    list: {
      searchPlaceholder: 'Search for a standard formula',
      newButton: 'Register Standard Formula',
      productDescription: 'Product',
      pharmaceuticalFormDescription: 'Pharmaceutical Form',
      amountDefaultDescription: 'Standard Quantity',
      unitMeasurementDescription: 'Unit. Measurement',
      dismembermentDescription: 'Type of Dismemberment',
      shortcuts: {
        addStandardFormula: 'Add new standard formula',
        selectAll: 'Select all',
        editSelected: 'Edit selected',
        deleteSelected: 'Delete selected',
        editStandardFormula: "Edit standard formula"
      },
      typeToken: {
        notDismember: 'Not Dismember',
        dismemberSameToken: 'Dismember - Same Token',
        dismemberTokenSeparate: 'Dismember - Token Separate',
      },

      modalDelete: {
        descriptionStandardFormua: 'the standard formula',
      },
    },

    create: {
      product: 'Prodct',
      productPlaceholder: 'Select a Product',

      pharmaceuticalForm: 'Pharmaceutical Form',
      pharmaceuticalFormPlaceholder: 'Search for a dosage form',

      amoundDefault: 'Standard Quantity',
      amoundDefaultPlaceholder: '0,0000',

      dismembermentType: 'Type of Dismemberment',
      dismembermentTypePlaceholder: 'Select the type of dismemberment',

      validity: 'Validity',
      validityPlaceholder: 'Ex: 10',

      dilution: 'Dilution',
      dilutionPlaceholder: '0%',

      reason: 'Reason',
      reasonPlaceholder: '0:0',

      density: 'Density',
      densityPlaceholder: '0,000',

      procedures: 'Procedures',
      proceduresPlaceholder: 'Details',

      baseboard: 'Baseboard',
      baseboardPlaceholder: 'Details',

      toolTips: {
        validyMessage: 'Standard formula validity in days',
        dilutionMessage: 'Internal Dilution Percentage',
      },

      productLine: {
        productCode: 'Cod. Prod.',
        productCodePlaceholder: 'Cdd.',

        productName: 'Product',
        productNamePlaceholder: 'Seach for a product',
        productNameSubCode: 'Code',

        quantity: 'Amount',
        quantityPlaceholder: '0,0000',

        phase: 'Phase',
        phasePlaceholder: '00',

        type: 'Type',
        typePlaceholder: 'Select type',

        formulaProductTypes: {
          normal: 'Normal',
          qsp: 'QSP',
          qs: 'QS',
        },

        errors: {
          errorStandardFormulaNameExist:
            'There is already a standard formula for this product',

          requiredProduct: 'Product is required',
          requiredPharmaceuticaForm:
            'Pharmaceutical Form is required',
          requiredAmount: 'Standard Quantity is required',
          requiredDismembermentType: 'Type of Dismemberment is required',
          requiredProductType: 'Type is required',
          requiredAmountProduct: 'Amount is required',
        },
      },

      modalCannotDelete: {
        messageTitle: 'the standard formula ',
        messageSubtitle: 'the standard formula ',
        messageReasonCannotDelete:
          'This standard formula has already been moved within the system and therefore it is not possible to delete it.',
        messageWarningCannotDelete:
          'If you do not want to use this default formula, change its status to "inactive".',
      },

      successMessageToasts: {
        create: 'Standard formula created successfully!',
      },
    },

    details: {
      product: 'Prodct',
      productPlaceholder: 'Select a Product',

      pharmaceuticalForm: 'Pharmaceutical Form',
      pharmaceuticalFormPlaceholder: 'Search for a dosage form',

      amoundDefault: 'Standard Quantity',
      amoundDefaultPlaceholder: '0,0000',

      dismembermentType: 'Type of Dismemberment',
      dismembermentTypePlaceholder: 'Select the type of dismemberment',

      validity: 'Validity',
      validityPlaceholder: 'Ex: 10',

      dilution: 'Dilution',
      dilutionPlaceholder: '0%',

      reason: 'Reason',
      reasonPlaceholder: '0:0',

      density: 'Density',
      densityPlaceholder: '0,000',

      procedures: 'Procedures',
      proceduresPlaceholder: 'Details',

      baseboard: 'Baseboard',
      baseboardPlaceholder: 'Details',

      productLine: {
        productCode: 'Cod. Prod.',
        productCodePlaceholder: 'Cdd.',

        productName: 'Product',
        productNamePlaceholder: 'Seach for a product',
        productNameSubCode: 'Code',

        quantity: 'Amount',
        quantityPlaceholder: '0,0000',

        phase: 'Phase',
        phasePlaceholder: '00',

        type: 'Type',
        typePlaceholder: 'Select type',

        formulaProductTypes: {
          normal: 'Normal',
          qsp: 'QSP',
          qs: 'QS',
        },

        errors: {
          errorStandardFormulaNameExist:
            'There is already a standard formula for this product',

          requiredProduct: 'Product Name is required',
          requiredPharmaceuticaForm:
            'Pharmaceutical Form is required',
          requiredAmount: 'Standard Quantity is required',
          requiredDismembermentType: 'Type of Dismemberment is required',
          requiredProductType: 'Type is required',
          requiredAmountProduct: 'Amount is required',
        },
      },

      tooltips: {
        previousProduct: 'Original product: ',
        previousPharmaceuticalForm: 'Original Pharmaceutical Form: ',
        previousAmountDefault: 'Original Standard Quantity: ',
        previousDismemberment: 'Original Dismemberment Type: ',
        previousValidity: 'Original Validity: ',
        previousDilution: 'Original Internal Dilution Percentage: ',
        previousReason: 'Original Reason: ',
        previousDensity: 'Original Density: ',
        previousProcedure: 'Original Procedure Details: ',
        previousBaseboard: 'Original Footer Details: ',
      },

      errors: {
        errorStandardFormulaNameExist:
          'There is already a standard formula for this product',

        requiredProduct: 'Product is Required',
        requiredPharmaceuticaForm: 'Pharmaceutical Form is Required',
        requiredAmount: 'Standard Quantity is Required',
        requiredDismembermentType: 'Type of Dismemberment is Required',
        requiredAmountProduct: 'Quantity is Required',
        requiredTypeProduct: 'Type is Required',
      },

      successMessageToasts: {
        edit: 'Standard formula successfully changed!',
      },

      modal: {
        messageQuestionDelete:
          'Are you sure you want to delete the default formula',
      },
    },
  },

  laboratory: {
    list: {
      searchPlaceholder: 'Search for a laboratory, company, or supply places',
      newButton: 'Register Laboratory',
      laboratoryName: 'Laboratory Name',
      company: 'Company',
      supplyPlace: 'Supply places',
      status: 'Status',
      shortcuts: {
        addLaboratory: 'Add new laboratory',
        selectAll: 'Select all',
        editSelected: 'Edit selected',
        deleteSelected: 'Delete selected',
        editLaboratory: 'Edit laboratory',

      },
      statusTypes: {
        active: 'Activate',
        inactive: 'Inactivate',
      },
      deleteModal: {
        description:
          'Are you sure you want to delete the laboratory {{laboratorio}} of company {{empresa}}?',
      },
      canNotDeleteModal: {
        body: 'It is not possible to delete the laboratory {{laboratorio}} of company {{empresa}}.',
        bodyComent:
          'This laboratory has already had movement within the system and therefore it is not possible to delete it.',
        bodyExclamation:
          'If you no longer want to use this laboratory, change its status to "inactive".',
        title: 'Unable to delete laboratory "{{laboratorio}}"',
      },
      customEndTitleEditStatusModal: 'Laboratories',
    },

    create: {
      name: 'Laboratory Name',
      namePlaceholder: 'Laboratory Name',
      company: 'Company',
      companyPlaceholder: 'Search for a Company',
      supplyPlaces: 'Supply Places',
      supplyPlacesPlaceholder: 'Select a Supply Place',
      validation:
        'There is already a laboratory with this name in the selected company',
      errors: {
        requiredLaboratoryName: 'Laboratory name is mandatory',
        requiredNomeFantasia: 'Company is mandatory',
        requiredDescricao: 'Supply place is mandatory',
      },
    },

    deleteTitle: 'Delete',
    toast: 'Laboratory successfully deleted!',

    details: {
      disable: 'Disable',

      tooltips: {
        previousLaboratory: 'Original Laboratory Name',
        previousCompany: 'Original Company Name',
        previousSupplyPlace: 'Original Supply Place Name',
      },
    },

    modalCannotDelete: {
      messageTitle: 'the laboratory',
      messageSubtitle: 'the laboratory',
      messageReasonCannotDelete:
        'This laboratory has already been moved within the system and therefore it is not possible to exclude it',
      messageWarningCannotDelete:
        'If you do not wish to use this pharmaceutical form, change its status to "inactive".',
      messageManyTitles: 'Laboratories',
      messageManySubtitleFirst: 'It is not pussible to delete',
      messageManySubtitleSecond: 'these laboratories:',
      messageManyReasonCannotDelete:
        'These laboratories have already undergone movement within the system and therefore it is not possible to exclude them.',
      messageManyWarningCannotDelete:
        'If you do not want to use these labs, change their status to "inactive".',
    },
  },
  labelTemplates: {
    list: {
      searchPlaceholder: 'Search for a label template',
      newButton: 'New Label Template',
      LabelTemplateName: 'Label Template Name',
      labelType: 'Label Type',
      status: 'Status',
      shortcuts: {
        addLabelTemplate: 'Add new label template',
        selectAll: 'Select all',
        editSelected: 'Edit selected',
        deleteSelected: 'Delete selected',
        editLabelTemplate: 'Edit label template',
      },
      labelTypes: {
        product: 'Product',
        prescription: 'Prescription',
        inventory: 'Inventory',
      },
      statusTypes: {
        active: 'Activate',
        inactive: 'Inactivate',
      },
      modalCannotDelete: {
        messageTitle: 'the label template',
        messageSubtitle: 'the label template',
        messageReasonCannotDelete:
          'This label template has already been moved within the system and therefore it is not possible to exclude it',
        messageWarningCannotDelete:
          'If you do not wish to use this label template, change its status to "inactive".',
        messageManyTitles: 'label templates',
        messageManySubtitle: 'these label templates:',
        messageManyReasonCannotDelete:
        'These label templates have already undergone movement within the system and therefore it is not possible to exclude them.',
        messageManyWarningCannotDelete:
        'If you do not want to use these label templates, change their status to "inactive".',
      },
      deleteModal: {
        description:
          'Are you sure you want to delete the label template {{labelTemplate}}?',
      },
      customEndTitleEditStatusModal: 'label templates',
    },
    create: {
      card: 'Details',
      labelTemplateName: 'Label Template name',
      labelType: 'Label Type',
      labelTypePlaceholder: 'Select a label type',
      labelTypes: {
        products: 'Products',
        prescriptions: 'Prescriptions',
        inventory: 'Inventory',
      },
      footerButton: {
        cancel: 'Cancel',
        okButton: 'Register',
      },
      errors: {
        requiredLabelTemplate: 'Label Template name is mandatory',
        requiredLabelType: 'Label Type is mandatory',
        errorLabelTemplateNameExist:
        'There is already a label template for this type',
      },
    }
  }
};

export const productionPT = {
  packingClassification: {
    list: {
      searchPlaceholder: 'Pesquise por uma classificação de embalagem',
      description: 'Nome da Classificação de Embalagem',
      linkedProduct: 'Produtos Vinculados',
      status: 'Status',
      statusTypes: {
        active: 'Ativo',
        inactive: 'Inativo',
      },

      shortcuts: {
        addPackingClassification: 'Adicionar nova classificação de embalagem',
        selectAll: 'Selecionar todos',
        editSelected: 'Editar selecionados',
        deleteSelected: 'Excluir selecionados',
      },

      modalDelete: {
        descriptionPackingClassification: 'a classificação de embalagem',
      },
      newButton: 'Cadastrar Classificação de Embalagem',
      editStatus: 'Status',
    },

    create: {
      card: 'Detalhes',

      name: 'Nome da Classificação de Embalagem',
      namePlaceholder: 'Insira o nome da Classificação de Embalagem',

      footerButton: {
        cancel: 'Cancelar',
        okButton: 'Cadastrar',
      },

      erros: {
        requiredName: 'Nome da Classificação de Embalagem é obrigatório',
        errorPackingClassificationNameExist:
          'Já existe essa classificação de embalagem cadastrada no sistema',
      },
    },

    details: {
      shortcuts: {
        goToDetailsTab: 'Ir para detalhes da classificação de embalagem',
        goToLinkedProductsTab: 'Ir para produtos vinculados da classificação de embalagem',
        editPackingClassification: 'Editar classificação de embalagem',
      },
      name: 'Nome da Classificação de Embalagem',
      namePlaceholder: 'Insira o nome da Classificação de Embalagem',
      changeStatusDescriptionTitle: ' a classificação de embalagem',
      changeStatusDescription:
        'Você tem certeza que deseja inativar a classificação de embalagem {{description}}',
      statusTypes: {
        active: 'Ativar',
        inactive: 'Inativar',
      },
      editPackingClassification: {
        tab: {
          details: 'Detalhes',
        },
        buttonActions: {
          delete: 'Excluir',
        },
      },
      modal: {
        messageQuestionDelete:
          'Você tem certeza que deseja excluir a classificação de embalagem',
      },
      tooTips: {
        description: 'Nome da Classificação de Embalagem original: ',
      },
    },

    modalCannotDelete: {
      messageTitle: 'a classificação de embalagem ',
      messageSubtitle: 'a classificação de embalagem ',
      manyMessageSubtitle: 'as classificações de embalagem',
      messageReasonCannotDelete:
        'Essa classificação de embalagem já teve movimentação dentro do sistema e por isso não é possível a sua exclusão',
      manyMessageReasonCannotDelete:
        'Essas classificações de embalagem já tiveram movimentação dentro do sistema e por isso não é possível a sua exclusão.',
      messageWarning:
        'Caso não queria mais utilizar essa classificação de embalagem, mude o status dela para "Inativo".',
      manyMessageWarning:
        'Caso não queira mais utilizar essas classificações de embalagem, mude o status delas para “Inativa”.',
    },

    linkedList: {
      shortcuts: {
        selectAll: 'Selecionar todos',
        unlinkSelected: 'Desvincular produto da classificação de embalagem',
      },
      code: 'Código',
      productName: 'Nome do Produto',
      statusName: 'Status',
      buttonUnlink: 'Remover Classificação de Embalagem',
      modalRemoveLinked: {
        messageTitle: 'dessa classificação de embalagem',
        messageStartBody:
          'Você tem certeza que deseja remover o produto {{action}}',
        messageFinishBody: 'da classificação de embalagem ',
      },
    },
  },

  capsuleColors: {
    list: {
      searchPlaceholder: 'Pesquise por uma cor de cápsula',
      capsuleColors: 'Cor da Cápsula',
      capsuleTransparent: 'Cápsula Transparente',
      yesValue: 'Sim',
      noValue: 'Não',

      newButton: 'Cadastrar Cor de Cápsula',

      shortcuts: {
        addCapsuleColor: 'Adicionar nova cor de cápsula',
        selectAll: 'Selecionar todos',
        deleteSelected: 'Excluir selecionados',
      },

      modalDelete: {
        descriptionCapsuleColors: 'a cor de cápsula',

        deleteCapsuleColorsList:
          'Você tem certeza que deseja excluir a cor de cápsula',
        theManyCapsuleColors: ' cor de cápsula',
        theCapsuleColors: 'as cores de cápsulas',
        deleteCapsuleColorsDetail: 'Excluir a Cor de Cápsula',
        deleteCapsuleColors: 'Delete Capsule Color',
        deleteManyCapsuleColors: 'Cores de Cápsulas',
      },
    },

    create: {
      capsuleColor: 'Cor da Cápsula',
      namePlaceholder: 'Insira a cor da cápsula',

      transparentCapsule: 'Cápsula Transparente',

      erros: {
        requiredName: 'Nome da Cor de Cápsula é obrigatório',
      },
    },

    details: {
      shortcuts: {
        editCapsuleColor: 'Editar cor de cápsulas',
      },
      name: 'Cor da Cápsula',
      namePlaceholder: 'Insira o nome da Cor da Cápsula',

      transparentCapsule: 'Cápsula Transparente',

      editCapsuleColor: {
        tab: {
          details: 'Detalhes',
        },
        buttonActions: {
          delete: 'Excluir',
        },
      },
      modal: {
        messageQuestionDelete:
          'Você tem certeza que deseja excluir a cor da cápsula',
        deleteCapsuleColors: 'Excluir a Cor de Cápsula',
      },
      tooTips: {
        description: 'Cor da Cápsula original: ',
      },
    },

    errors: {
      requiredCapsuleColor: 'Nome da cor da cápsula é obrigatório.',
      errorCapsuleColorNameExist:
        'Já existe essa cor de cápsula cadastrada no sistema',
    },

    modalCannotDelete: {
      messageTitle: 'a cor de cápsula',
      manyMessageTitle: 'Cores de Cápsulas',
      messageSubtitle: 'a cor de cápsula',
      manyMessageSubtitle: 'as cores de cápsulas:',
      messageReasonCannotDelete:
        'Essa cor de cápsula já teve movimentação dentro do sistema e por isso não é possível a sua exclusão.',
      manyMessageReasonCannotDelete:
        'Essas cores de cápsula já tiveram movimentação dentro do sistema e por isso não é possível a sua exclusão.',
    },
  },

  posology: {
    list: {
      searchPlaceholder: 'Pesquise por uma posologia',
      posologyDescription: 'Posologia',
      pharmaceuticalFormDescription: 'Forma Farmacêutica',
      amountDosePeriod: 'Qtd. da Dose por Período',
      period: 'Período',
      shortcuts: {
        addPosology: 'Adicionar nova posologia',
        selectAll: 'Selecionar todos',
        editSelected: 'Editar selecionados',
        deleteSelected: 'Excluir selecionados',
      },
      day: 'Dia',
      week: 'Semana',
      month: 'Mês',

      yesValue: 'Sim',
      noValue: 'Não',

      newButton: 'Cadastrar Posologia',

      status: 'Status',
      statusTypes: {
        active: 'Ativo',
        inactive: 'Inativo',
      },

      modalDelete: {
        descriptionPosology: 'a posologia',
      },
    },

    create: {
      posology: 'Posologia',
      namePlaceholder: 'Descrição da posologia',

      pharmaceuticalForm: 'Forma Farmacêutica',
      pharmaceuticalFormPlaceholder: 'Selecione uma forma farmacêutica',

      amountDosePeriod: 'Qtd. da Dose por Período',
      period: 'Período',
      periodPlaceholder: 'Selecione um período',

      periodType: {
        day: 'Dia',
        week: 'Semana',
        month: 'Mês',
      },

      errors: {
        requiredName: 'Nome da Posologia é obrigatório',
        requiredPharmaceuticalForm: 'Forma Farmacêutica é obrigatória',
        requiredDosePeriodForm: 'Qtd. da dose por Período é obrigatória',
        requiredPeriod: 'Período é obrigatório',
        errorPosologyNameExist:
          'Já existe uma posologia com esse nome para essa forma farmacêutica',
      },
    },

    details: {
      shortcuts: {
        editPosology: 'Editar posologia',
      },
      changeStatusDescriptionPosology:
        'Você tem certeza que deseja inativar a posologia {{description}}',

      statusTypes: {
        active: 'Ativar',
        inactive: 'Inativar',
      },

      toolTips: {
        descriptionPosology: 'Nome da Posologia original: ',
        amountPeriod: 'Qtd. da Dose por Período original: ',
        period: 'Período original: ',
        pharmaceuticalForm: 'Forma Farmacêutica original: ',
      },

      modal: {
        messageQuestionDelete:
          'Você tem certeza que deseja excluir a posologia {{posology}}?',
      },
    },
  },

  pharmaceuticalForm: {
    list: {
      searchPlaceholder: 'Pesquise por uma forma farmacêutica',
      orderNumber: 'Ordem',
      description: 'Descrição',
      presentation: 'Apresentação',
      usePharmaceuticalForm: 'Uso',
      laboratoryDescription: 'Laboratório',
      status: 'Status',
      statusTypes: {
        active: 'Ativo',
        inactive: 'Inativo',
      },
      shortcuts: {
        addPharmaceuticalForm: 'Adicionar nova forma farmacêutica',
        selectAll: 'Selecionar todos',
        editSelected: 'Editar selecionados',
        deleteSelected: 'Excluir selecionados',
      },

      internal: 'Interno',
      external: 'Externo',
      topical: 'Tópico',

      newButton: 'Cadastrar Forma Farmacêutica',

      editStatus: 'Status',

      deleteModal: 'Forma Farmacêutica excluida com sucesso',
    },

    create: {
      order: 'Ordem',
      orderPlaceholder: 'Ex: 001',

      description: 'Descrição',
      descriptionPlaceholder: 'Descrição de Forma Farmacêutica',

      calculationType: 'Tipo de Cálculo',
      calculationTypePlaceholder: 'Selecione um tipo de cálculo',

      excipientMinimum: 'Mínimo de Excipiente (%)',
      excipientMinimumPlaceholder: 'Ex. 0.5%',

      laboratory: 'Laboratório',
      laboratoryPlaceholder: 'Procure por um laboratório',

      use: 'Uso',
      usePlaceholder: 'Selecione o tipo de uso',
      useType: {
        internalType: 'Interno',
        externalType: 'Externo',
        topicalType: 'Tópico',
      },

      presentation: 'Apresentação',
      presentationPlaceholder: 'Ex: CAP',

      unitMeasurement: 'Unidade de Medida',
      unitMeasurementPlaceholder: 'Selecione a unidade de medida',

      validityDurationInDays: 'Validade',
      validityDurationInDaysPlaceholder: 'EX: 10',
      validityDurationSuffix: 'dias',
      validityDurationInDaysTooltips: 'Validade em dias',

      operationalCost: 'Custo Operacional',
      operationalCostPlaceholder: 'EX: R$10,00',

      productCode: 'Cód. Prod.',
      productCodePlaceholder: '1234',

      linkedPackingClassification: 'Vincular Classificação de Embalagem',
      packingClassification: 'Classificação de Embalagem',
      packingClassificationPlaceholder:
        'Pesquise por uma classificação de embalagem',
      linkedPackingClassificationButton: 'Vincular',

      buttonAddProduct: 'Adicionar Produto',
      buttonRemove: 'Remover Produto',

      buttonCancel: 'Cancelar',
      buttonAdd: 'Cadastrar',

      calculationTypeDropdown: {
        baseRecipe: 'Com base na receita',
        baseQSP: 'Com base no QSP',
      },

      errors: {
        requiredName: 'Forma Farmacêutica é obrigatória',
        errorPharmaceuticalFormDescriptionExist:
          'Já existe essa forma farmacêutica com essa descrição',
        errorPharmaceuticalFormApresentationExist:
          'Já existe uma forma farmacêutica com essa apresentação',
        requiredOrder: 'Ordem é obrigatória',
        requiredDescription: 'Descrição é obrigatória',
        requiredPresentation: 'Apresentação é obrigatória',
        requiredUse: 'Uso é obrigatório',
        requiredCalculationType: 'Tipo de Cálculo é obrigatório',
        requiredUnitOfMensurament: 'Unidade de Medida é obrigatória',
        requiredMinimunExcipient: 'Mínimo Excipiente é obrigatório',
        requiredExpirationDays: 'Validade em Dias é obrigatória',
        requiredLaboratory: 'Laboratório é obrigatório',
        requiredOperationalCost: 'Valor de Custo é obrigatório',
        requiredPackingClassification:
          'Classificação de Embalagem é obrigatória',
      },
    },

    modalCannotDelete: {
      messageTitle: 'a forma farmacêutica',
      messageSubtitle: 'a forma farmacêutica',
      manyMessageSubtitle: 'as formas farmacêuticas',
      messageReasonCannotDelete:
        'Essa forma farmacêutica já teve movimentação dentro do sistema e por isso não é possível a sua exclusão.',
      manyMessageReasonCannotDelete:
        'Essas formas farmacêuticas já tiveram movimentação dentro do sistema e por isso não é possível a sua exclusão.',
      messageWarningCannotDelete:
        'Caso não queira mais utilizar essa forma farmacêutica, mude o status dela para "inativa".',
      manyMessageWarningCannotDelete:
        'Caso não queira mais utilizar essas formas farmacêuticas, mude o status delas para “Inativa”.',
    },

    details: {
      shortcuts: {
        goToDetailsTab: 'Ir para detalhes da forma farmacêutica',
        goToPackingClassificationTab: 'Ir para classificações de embalagem da forma farmacêutica',
        editPharmaceuticalForm: 'Editar forma farmacêutica',
      },

      name: 'Nome da Forma Farmacêutica',
      namePlaceholder: 'Insira o nome da Forma Farmacêutica',

      money: 'R$ ',

      tooltips: {
        previousOrder: 'Número da ordem original: ',
        previousDescription: 'Nome da Forma Farmacêutica original: ',
        previousCalculationType: 'Tipo de cálculo original: ',
        previousExcipientMinimum: 'Mínimo de Excipiente original: ',
        previousLaboratory: 'Laboratório original: ',
        previousUse: 'Tipo de uso original: ',
        previousPresentation: 'Apresentação da Forma Farmacêutica original: ',
        previousUnitMeasurement: 'Unidade de Medida original: ',
        previousValidityDurationInDays: 'Validade em Dias original: ',
        previousDays: 'Dias',
        previousOperationalCost: 'Custo operacional original: ',
      },

      oneDeleteTitle: ' a forma farmacêutica',
      manyDeleteTitle: ' formas farmacêuticas',
      deleteInfo:
        'Você tem certeza que deseja excluir a forma farmacêutica {{description}}?',

      changeStatusDescription:
        'Você tem certeza que deseja inativar a Forma Farmacêutica {{pharmaceuticalForm}}',
      statusTypes: {
        active: 'Ativar',
        inactive: 'Inativar',
      },

      packingClassification: {
        shortcuts: {
          addPackingClassification: 'Adicionar nova classificação de embalagem',
          selectAll: 'Selecionar todos',
          unlinkSelected: 'Desvincular classificação de embalagem da forma farmacêutica',
        },
        description: 'Classificação de Embalagem',
        status: 'Status',
        newButton: 'Vincular Classificação de Embalagem',
        searchPlaceHolder: 'Procure por uma classificação de embalagem',
        unlink: 'Desvincular Classificação de Embalagem',
        newLink: 'Classificação de Embalagem Vinculada com Sucesso',
        messageTitleOne: 'Desvincular',
        messageMultiple:
          'Você tem certeza que deseja desvincular da forma farmacêutica ',
        messageTitleMultiple: 'Classificações de Embalagem',
        messageFirst:
          'Você tem certeza que deseja desvincular a classificação de embalagem {{action}}',
        messageFinishBody: 'da forma farmacêutica',
        errorPharmaceuticalFormBindPackingClassificationExist:
          'Essa Classificação de Embalagem já foi vinculada',
      },

      titleInativateStatusModal: 'Inativar a forma farmacêutica',
    },
  },

  standardFormula: {
    list: {
      searchPlaceholder: 'Pesquise por uma fórmula padrão',
      newButton: 'Cadastrar Fórmula Padrão',
      productDescription: 'Produto',
      pharmaceuticalFormDescription: 'Forma Farmacêutica',
      amountDefaultDescription: 'Quantidade Padrão',
      unitMeasurementDescription: 'Unid. de Medida',
      dismembermentDescription: 'Tipo de Desmembramento',
      shortcuts: {
        addStandardFormula: 'Adicionar nova fórmula padrão',
        selectAll: 'Selecionar todos',
        editSelected: 'Editar selecionados',
        deleteSelected: 'Excluir selecionados',
        editStandardFormula: "Editar fórmula padrão"
      },
      typeToken: {
        notDismember: 'Não Desmembra',
        dismemberSameToken: 'Desmembra - Mesma Ficha',
        dismemberTokenSeparate: 'Desmembra - Ficha Separada',
      },

      modalDelete: {
        descriptionStandardFormua: 'a fórmula padrão',
      },
    },

    create: {
      product: 'Produto',
      productPlaceholder: 'Selecione um Produto',

      pharmaceuticalForm: 'Forma Farmacêutica',
      pharmaceuticalFormPlaceholder: 'Procure por uma forma farmacêutica',

      amoundDefault: 'Quantidade Padrão',
      amoundDefaultPlaceholder: '0,0000',

      dismembermentType: 'Tipo de Desmembramento',
      dismembermentTypePlaceholder: 'Selecione o tipo de desmembramento',

      validity: 'Validade',
      validityPlaceholder: 'Ex: 10',

      dilution: 'Diluição',
      dilutionPlaceholder: '0%',

      reason: 'Razão',
      reasonPlaceholder: '0:0',

      density: 'Densidade',
      densityPlaceholder: '0,000',

      procedures: 'Procedimentos',
      proceduresPlaceholder: 'Detalhes',

      baseboard: 'Rodapé',
      baseboardPlaceholder: 'Detalhes',

      toolTips: {
        validyMessage: 'Validade da fórmula padrão em dias',
        dilutionMessage: 'Percentual de Diluição Interna',
      },

      productLine: {
        productCode: 'Cód. Prod.',
        productCodePlaceholder: 'Cód.',

        productName: 'Produto',
        productNamePlaceholder: 'Pesquise por um produto',
        productNameSubCode: 'Código',

        quantity: 'Quantidade',
        quantityPlaceholder: '0,0000',

        phase: 'Fase',
        phasePlaceholder: '00',

        type: 'Tipo',
        typePlaceholder: 'Selecione o tipo',

        formulaProductTypes: {
          normal: 'Normal',
          qsp: 'QSP',
          qs: 'QS',
        },
      },
      successMessageToasts: {
        create: 'Fórmula padrão criada com sucesso!',
      },

      errors: {
        errorStandardFormulaNameExist:
          'Já existe uma fórmula padrão para esse produto',

        requiredProduct: 'Produto é obrigatório',
        requiredPharmaceuticaForm: 'Forma Farmacêutica é obrigatória',
        requiredAmount: 'Quantidade Padrão é obrigatória',
        requiredDismembermentType: 'Tipo de Desmembramento é obrigatório',
        requiredAmountProduct: 'Quantidade é Obrigatória',
        requiredTypeProduct: 'Tipo é Obrigatório',
      },
    },

    details: {
      product: 'Produto',
      productPlaceholder: 'Selecione um Produto',

      pharmaceuticalForm: 'Forma Farmacêutica',
      pharmaceuticalFormPlaceholder: 'Procure por uma forma farmacêutica',

      amoundDefault: 'Quantidade Padrão',
      amoundDefaultPlaceholder: '0,0000',

      dismembermentType: 'Tipo de Desmembramento',
      dismembermentTypePlaceholder: 'Selecione o tipo de desmembramento',

      validity: 'Validade',
      validityPlaceholder: 'Ex: 10',

      dilution: 'Diluição',
      dilutionPlaceholder: '0%',

      reason: 'Razão',
      reasonPlaceholder: '0:0',

      density: 'Densidade',
      densityPlaceholder: '0,000',

      procedures: 'Procedimentos',
      proceduresPlaceholder: 'Detalhes',

      baseboard: 'Rodapé',
      baseboardPlaceholder: 'Detalhes',

      toolTips: {
        validyMessage: 'Validade da fórmula padrão em dias',
        dilutionMessage: 'Percentual de Diluição Interna',
      },

      productLine: {
        productCode: 'Cód. Prod.',
        productCodePlaceholder: 'Cód.',

        productName: 'Produto',
        productNamePlaceholder: 'Pesquise por um produto',
        productNameSubCode: 'Código',

        quantity: 'Quantidade',
        quantityPlaceholder: '0,0000',

        phase: 'Fase',
        phasePlaceholder: '00',

        type: 'Tipo',
        typePlaceholder: 'Selecione o tipo',

        formulaProductTypes: {
          normal: 'Normal',
          qsp: 'QSP',
          qs: 'QS',
        },
      },

      tooltips: {
        previousProduct: 'Produto original: ',
        previousPharmaceuticalForm: 'Forma Farmacêutica original: ',
        previousAmountDefault: 'Quantidade Padrão original: ',
        previousDismemberment: 'Tipo de Desmembramento original: ',
        previousValidity: 'Validade original: ',
        previousDilution: 'Percentual de Diluição Interna original: ',
        previousReason: 'Razão original: ',
        previousDensity: 'Densidade original: ',
        previousProcedure: 'Detalhes do Procedimento original: ',
        previousBaseboard: 'Detalhes do Rodapé original: ',
      },

      errors: {
        errorStandardFormulaNameExist:
          'Já existe uma fórmula padrão para esse produto',

        requiredProduct: 'Produto é obrigatório',
        requiredPharmaceuticaForm: 'Forma Farmacêutica é obrigatória',
        requiredAmount: 'Quantidade Padrão é obrigatória',
        requiredDismembermentType: 'Tipo de Desmembramento é obrigatório',
        requiredProductType: 'Tipo é obrigatório',
        requiredAmountProduct: 'Quantidade é obrigatória',
        requiredTypeProduct: 'Tipo é obrigatório',
      },

      successMessageToasts: {
        edit: 'Fórmula padrão alterada com sucesso!',
      },

      modal: {
        messageQuestionDelete:
          'Você tem certeza que deseja excluir a fórmula padrão',
      },
    },

    modalCannotDelete: {
      messageTitle: 'a fórmula padrão ',
      messageSubtitle: 'a fórmula padrão ',
      messageReasonCannotDelete:
        'Essa fórmula padrão já teve movimentação dentro do sistema e por isso não é possível a sua exclusão.',
      messageWarningCannotDelete:
        'Caso não queira utilizar essa fórmula padrão, mude o status dela para "inativa".',
    },
  },
  laboratory: {
    list: {
      searchPlaceholder:
        'Pesquise por um laboratório, empresa ou local de estoque',
      newButton: 'Cadastrar Laboratório',
      laboratoryName: 'Nome do Laboratório',
      company: 'Empresa',
      supplyPlace: 'Local de Estoque',
      status: 'Status',
      shortcuts: {
        addLaboratory: 'Adicionar novo laboratório',
        selectAll: 'Selecionar todos',
        editSelected: 'Editar selecionados',
        deleteSelected: 'Excluir selecionados',
        editLaboratory: 'Editar laboratório',
      },
      statusTypes: {
        active: 'Ativo',
        inactive: 'Inativo',
      },
      deleteModal: {
        description:
          'Você tem certeza que deseja excluir o laboratório {{laboratorio}} da empresa {{empresa}}?',
      },
      canNotDeleteModal: {
        body: 'Não é possivel excluir o laboratório {{laboratorio}} da empresa {{empresa}}.',
        bodyComent:
          'Esse laboratório já teve movimentação dentro do sistema e por isso não é possível a sua exclusão.',
        bodyExclamation:
          'Caso não queira mais utilizar esse laboratório, mude o status dele para "inativo"',
        title: 'Não foi possível excluir o laboratório "{{laboratorio}}"',
      },
      customEndTitleEditStatusModal: 'Laboratórios',
    },

    create: {
      name: 'Nome do Laboratório',
      namePlaceholder: 'Nome do Laboratório',
      company: 'Empresa',
      companyPlaceholder: 'Procure por uma Empresa',
      supplyPlaces: 'Local de Estoque',
      supplyPlacesPlaceholder: 'Selecione um local de estoque',
      validation:
        'Já existe um laboratório com esse nome na empresa selecionada',
      errors: {
        requiredLaboratoryName: 'Nome do Laboratório é obrigatório',
        requiredNomeFantasia: 'Empresa é obrigatória',
        requiredDescricao: 'Local de Estoque é obrigatório',
      },
    },

    deleteTitle: 'Excluir',
    toast: 'Laboratório excluido com sucesso!',

    details: {
      disable: 'Desativar',

      tooltips: {
        previousLaboratory: 'Nome do Laboratório original',
        previousCompany: 'Nome da Empresa original',
        previousSupplyPlace: 'Nome do Local de Estoque original',
      },
    },

    modalCannotDelete: {
      messageTitle: 'o laboratório',
      messageSubtitle: 'o laboratório',
      messageReasonCannotDelete:
        'Esse laboratório já teve movimentação dentro do sistema ou está associado a algum lote ou alguma forma farmacêutica, e por isso não é possível a sua exclusão',
      messageWarningCannotDelete:
        'Caso não queira utilizar esse laboratório, mude o status dele para "inativo".',
      messageManyTitles: 'Laboratórios',
      messageManySubtitleFirst: 'Não é possível excluir',
      messageManySubtitleSecond: 'os laboratórios:',
      messageManyReasonCannotDelete:
        'Esses laboratórios já tiveram uma movimentação dentro do sistema e por isso não é possível a sua exclusão',
      messageManyWarningCannotDelete:
        'Caso não queira utilizar esses laboratórios, mude o status deles para "inativo".',
    },
  },
  labelTemplates: {
    list: {
      searchPlaceholder: 'Pesquise por um modelo de rótulo',
      newButton: 'Novo Modelo de Rótulo',
      LabelTemplateName: 'Nome do Modelo de Rótulo',
      labelType: 'Tipo de Rótulo',
      status: 'Status',
      shortcuts: {
        addLabelTemplate: 'Adicionar novo modelo de rótulo',
        selectAll: 'Selecionar todos',
        editSelected: 'Editar selecionados',
        deleteSelected: 'Excluir selecionados',
        editLabelTemplate: 'Editar modelo de rótulo',
      },
      labelTypes: {
        product: 'Produtos',
        prescription: 'Receita',
        inventory: 'Estoque',
      },
      statusTypes: {
        active: 'Ativo',
        inactive: 'Inativo',
      },
      modalCannotDelete: {
        messageTitle: 'o modelo de rótulo',
        messageSubtitle: 'o modelo de rótulo',
        messageReasonCannotDelete:
        'Esse modelo de rótulo já teve movimentação dentro do sistema ou está associado a algum lote ou alguma forma farmacêutica, e por isso não é possível a sua exclusão',
        messageWarningCannotDelete:
        'Caso não queira utilizar esse modelo de rótulo, mude o status dele para "inativo".',
        messageManyTitles: 'modelos de rótulo',
        messageManySubtitle: 'os modelos de rótulo:',
        messageManyReasonCannotDelete:
        'Esses modelos de rótulo já tiveram uma movimentação dentro do sistema e por isso não é possível a sua exclusão',
        messageManyWarningCannotDelete:
        'Caso não queira utilizar esses modelos de rótulo, mude o status deles para "inativo".',
      },
      deleteModal: {
        description:
          'Você tem certeza que deseja excluir o modelo de rótulo {{labelTemplate}}?',
      },
      customEndTitleEditStatusModal: 'modelos de rótulo',
    },
    create: {
      card: 'Detalhes',
      labelTemplateName: 'Nome do Modelo de Rótulo',
      labelType: 'Tipo de Rótulo',
      labelTypePlaceholder: 'Selecione um tipo de rótulo',
      labelTypes: {
        products: 'Produtos',
        prescriptions: 'Receitas',
        inventory: 'Estoque',
      },
      footerButton: {
        cancel: 'Cancelar',
        okButton: 'Cadastrar',
      },
      errors: {
        requiredLabelTemplate: 'Nome do Modelo de Rótulo é obrigatório',
        requiredLabelType: 'Tipo de Rótulo é obrigatório',
        errorLabelTemplateNameExist:
        'Já existe um modelo de rótulo para esse tipo',
      },
    }
  }
};
