import { Dropdown, NumberInput, Text } from '@/Components';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { Col } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './CapsuleInfo.module.scss';

export const CapsuleInfo: FC = () => {
  const { t } = useTranslation();
  return (
    <Divflex>
      <Col span={17} className={styles['container-capsule']}>
        <Divflex>
          <Col span={20}>
            <Dropdown
              name="informacoesAdicionais.produtoCapsulaExternalId"
              label={t('common.capsule')}
              withoutMarginBottom
            />
          </Col>
          <Col span={4}>
            <NumberInput
              name="informacoesAdicionais.quantidadeCapsula"
              label={'Quant.'}
              placeHolder="0"
              withoutMarginBottom
            />
          </Col>
        </Divflex>
        <Divflex>
          <Col span={20}>
            <Dropdown
              name="informacoesAdicionais.produtoEmbalagemExternalId"
              label={t('common.packing')}
              withoutMarginBottom
            />
          </Col>
          <Col span={4}>
            <NumberInput
              name="informacoesAdicionais.quantidadeEmbalagem"
              label={'Quant.'}
              placeHolder="0"
              withoutMarginBottom
            />
          </Col>
        </Divflex>
      </Col>
      <Col span={7} className={styles['content-capsule']}>
        <Text
          type="small-text-bold"
          color="text-400"
          children={t('common.noImageAvailable')}
        />
      </Col>
    </Divflex>
  );
};
