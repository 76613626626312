import { Page, Document, StyleSheet, Font } from '@react-pdf/renderer';
import { PdfHeader } from './components/PdfHeader';
import { PageCount } from './components/PageCount';
import { SupplyPlaceTable } from './components/SupplyPlaceTable';
import React from 'react';

interface IOneItemPdf {
  supplyPlace: string;
  tableItems: any[];
  inventoryCode: string;
  onRenderPdf?: () => void;
  hideQuantityPrint?: boolean;
}

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
    paddingBottom: '100px',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

export const OneItemPdf: React.FC<IOneItemPdf> = ({
  supplyPlace,
  tableItems,
  inventoryCode,
  onRenderPdf,
  hideQuantityPrint,
}) => (
  <Document onRender={onRenderPdf}>
    <Page size="A4" style={styles.page}>
      <PdfHeader inventoryCode={inventoryCode} />
      <SupplyPlaceTable
        hideQuantityPrint={hideQuantityPrint}
        supplyPlace={supplyPlace}
        tableItems={tableItems}
      />
      <PageCount />
    </Page>
  </Document>
);

Font.register({
  family: 'OpenSans',
  fonts: [
    { src: '/fonts/open-sans.ttf', fontWeight: 'normal' },
    { src: '/fonts/open-sans-bold.ttf', fontWeight: 'bold' },
  ],
});
