import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { HorizontalTabs } from '../../../../../Components';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { PackingClassificationDetail } from '../Details';
import { DeleteProductModal } from '../Details/DetailsForm.modal';
import { PermissionEnum } from '../../../../../Data/Interfaces/model/permissionEnum';
import { LinkedProduct } from '../Details/Components/LinkedProduct';
import { useEditStates } from './Hooks/useEditStates';
import { useEditCallAPI } from './Hooks/useEditCallAPI';
import { usePackingClassificationDetailsShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/Production/PackingClassification/details';

const FormPackingClassification: React.FC = () => {
  const { externalId } = useParams<{ externalId: string }>();

  const { disabled, setDisabled, setVisibleModal, visibleModal } =
    useEditStates();

  const { packingClassification } = useEditCallAPI({ externalId });

  const { t } = useTranslation();

  const { getShortcut } = usePackingClassificationDetailsShortcuts(t, {});

  const alternateTabDetailsHotkey = getShortcut('alternateTabDetails');
  const alternateTabLinkedProductsHotkey = getShortcut('alternateTabLinkedProducts');
  const editMoreActionsHotkey = getShortcut('editMoreActions');
  const editPackingClassificationHotkey = getShortcut('editPackingClassification');

  return (
    <PageLoading>
      <HorizontalTabs
        type="secondary"
        items={[
          {
            label: t(
              'production.packingClassification.details.editPackingClassification.tab.details'
            ),
            key: 'details',
            children: (
              <PackingClassificationDetail
                disabled={disabled}
                onChangeDisabled={(x: boolean) => setDisabled(x)}
              />
            ),
            dataTestId: 'tab-packing-classification-details',
            shortCutTooltip: {
              title: alternateTabDetailsHotkey.tooltip,
              targetOffset: [0, 10],
            },
          },
          {
            label: t('common.linkedProduct'),
            key: 'linkedProducts',
            children: (
              <LinkedProduct
                packingClassification={packingClassification?.descricao}
              />
            ),
            dataTestId: 'tab-packing-classification-linked-products',
            shortCutTooltip: {
              title: alternateTabLinkedProductsHotkey.tooltip,
              targetOffset: [0, 10],
            },
          },
        ]}
        dropdownItens={[
          {
            label: t(
              'production.packingClassification.details.editPackingClassification.buttonActions.delete'
            ),
            icon: 'trash',
            permission: {
              permission:
                PermissionEnum.Producao_ClassificacaoEmbalagem_Excluir,
              tooltip: 'noPermission.production.packingClassification.delete',
            },
            onClick: () => setVisibleModal(true),
            dataTestId: 'delete-packaging-classification-option',
            key: 'backspace',
          },
        ]}
        dropdownDirection="bottomRight"
        dropdownTitle={t('admin.editUserPage.tab.buttonActions.title')}
        moreActionsButton={{ title: editMoreActionsHotkey.tooltip }}
        leftIcon="menu-ellipsis-horizontal"
        rightButton={{
          ...(disabled && {
            onClick: () => setDisabled(false),
            isVisible: disabled,
            codePermissionEdit:
              PermissionEnum.Producao_ClassificacaoEmbalagem_EditarDetalhes,
            messageTooltipPermission:
              'noPermission.production.packingClassification.edit',
            dataTestId: 'production-packaging-classification-edit-button',
          }),
          dataTestId: 'edit-packing-classification-form-button',
          hotkeyTooltip: {
            title: editPackingClassificationHotkey.tooltip,
          },
        }}
        dataTestIdDropdownButton="tab-dropdown-options"
      />
      {packingClassification && (
        <DeleteProductModal
          packingClassification={packingClassification}
          visibleModal={visibleModal}
          changeVisibleModal={(value) => setVisibleModal(value)}
        />
      )}
    </PageLoading>
  );
};

export default FormPackingClassification;
