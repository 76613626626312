import { useTranslation } from 'react-i18next';
import { PdfLogo } from './Components/PdfLogo';
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import StorageUtils from '@/Utils/StorageUtils';
import DateUtils from '@/Utils/DateUtils';
import React from 'react';

interface IPdfHeaderProps {
  inventoryCode?: string;
  responsible?: string;
}

const styles = StyleSheet.create({
  logo: {
    padding: '10px 20px',
    flexGrow: 1,
    borderBottom: '1px solid #EAEAEA',
    height: '50px',
    width: '100%',
  },
  header: {
    width: '100%',
    padding: '0px 20px 10px 20px',
    fontSize: '13px',
    fontFamily: 'OpenSans',
    fontWeight: 'bold',
    marginTop: '10px',
    borderBottom: '1px solid #EAEAEA',
  },
});

export const PdfHeader: React.FC<IPdfHeaderProps> = ({ 
  inventoryCode,
  responsible, 
}) => {
  const { t } = useTranslation();
  const currentDate = new Date();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const formattedDate = new Date(
    currentDate.toLocaleString('en-US', { timeZone })
  ).toISOString();

  const preferences = StorageUtils.getPreference();

  return (
    <>
      <View style={{ width: '100%' }} fixed>
        <View style={styles.logo}>
          <PdfLogo />
        </View>
        <Text style={styles.header}>
          {t('supply.inventory.pdf.inventory')} | #{inventoryCode} |{' '}
          {t('supply.inventory.pdf.operator')}:{' '}{responsible} |{' '}
          {t('supply.inventory.pdf.printDate')}:{' '}
          {`${DateUtils.convertDate(
            formattedDate,
            preferences?.padraoData
          )} ${t('common.dateBreakApart')} ${DateUtils.convertDate(
            formattedDate,
            preferences?.padraoHora
          )}`}
        </Text>
      </View>
    </>
  );
};
