import {
    BootysShortcuts,
    defaultShortcuts,
    DefaultShortcuts,
  } from '@/Components/Shortcuts/keymaps/default';
  import { TFunction } from 'react-i18next';
  import { useHotkeys } from 'reakeys';
  import { isVisible } from '@/Utils/DOMUtils';
  import { getShortcut, getShortcuts } from '@/Components/Shortcuts';
  export const pageKey = 'pharmaceutical-form-details';
  
  export interface PackingClassificationDetailsTabCallbacks extends DefaultShortcuts {}

  const shortcuts = (
    t: TFunction<'translation', undefined>,
    callbacks: PackingClassificationDetailsTabCallbacks
  ): BootysShortcuts[] => [
    ...defaultShortcuts(t, callbacks),
    {
      id: 'editStatus',
      name: t('admin.editUserPage.shortcuts.editStatus'),
      keys: 's',
      action: 'keydown',
      withText: true,
      callback: (event: any) => {
        event.preventDefault();
        const element = document.querySelector(
          '[data-testid="status-dropdown-button"]'
        );
        if (isVisible(element)) {
          //@ts-ignore
          element?.click();
        }
      },
    },
    {
      id: 'editStatusActive',
      name: t('admin.editUserPage.shortcuts.editStatus'),
      keys: '1',
      action: 'keydown',
      withText: true,
      callback: (event: any) => {
        event.preventDefault();
        const element = document.querySelector(
          '[data-testid="dropdown-option-active-status"]'
        );
        if (isVisible(element)) {
          //@ts-ignore
          element?.click();
        }
      },
    },
    {
      id: 'editStatusInactive',
      name: t('admin.editCompany.shortcuts.editAddress'),
      keys: '2',
      action: 'keydown',
      withText: true,
      callback: (event: any) => {
        event.preventDefault();
        const element = document.querySelector(
          '[data-testid="dropdown-option-inactive-status"]'
        );
        if (isVisible(element)) {
          //@ts-ignore
          element?.click();
        }
      },
    },
    {
      id: 'editMoreActions',
      name: t('admin.editUserPage.shortcuts.moreActions'),
      keys: '.',
      action: 'keydown',
      withText: true,
      callback: (event: any) => {
        event.preventDefault();
        const element = document.querySelector(
          '[data-testid="tab-dropdown-options"]'
        );
        if (isVisible(element)) {
          //@ts-ignore
          element?.click();
        }
      },
    },
    {
      id: 'deletePackingClassification',
      name: t('admin.editUserPage.shortcuts.moreActions'),
      keys: 'backspace',
      action: 'keydown',
      callback: (event: any) => {
        event.preventDefault();
        const element = document.querySelector(
          '[data-testid="delete-packaging-classification-option"]'
        );
        if (isVisible(element)) {
          //@ts-ignore
          element?.click();
        }
      },
    },
    {
      id: 'editPackingClassification',
      name: t('production.packingClassification.details.shortcuts.editPackingClassification'),
      keys: 'e',
      action: 'keydown',
      callback: (event: any) => {
        event.preventDefault();
        const element = document.querySelector(
          '[data-testid="edit-packing-classification-form-button"]'
        );
        if (isVisible(element)) {
          //@ts-ignore
          element?.click();
        }
      },
    },
    {
      id: 'alternateTabDetails',
      name: t('production.packingClassification.details.shortcuts.goToDetailsTab'),
      keys: 'shift+!',
      action: 'keydown',
      callback: (event: any) => {
        event.preventDefault();
        const element = document.querySelector(
          '[data-testid="tab-packing-classification-details"]'
        );
        if (isVisible(element)) {
          //@ts-ignore
          element?.click();
        }
      },
    },
    {
      id: 'alternateTabDetailsNumberVariation',
      name: t('production.packingClassification.details.shortcuts.goToDetailsTab'),
      keys: 'shift+1',
      action: 'keydown',
      callback: (event: any) => {
        event.preventDefault();
        const element = document.querySelector(
          '[data-testid="tab-packing-classification-details"]'
        );
        if (isVisible(element)) {
          //@ts-ignore
          element?.click();
        }
      },
    },
    {
      id: 'alternateTabLinkedProducts',
      name: t('production.packingClassification.details.shortcuts.goToLinkedProductsTab'),
      keys: 'shift+@',
      action: 'keydown',
      callback: (event: any) => {
        event.preventDefault();
        const element = document.querySelector(
          '[data-testid="tab-packing-classification-linked-products"]'
        );
        if (isVisible(element)) {
          //@ts-ignore
          element?.click();
        }
      },
    },
    {
      id: 'alternateTabLinkedProductsNumberVariation',
      name: t('production.packingClassification.details.shortcuts.goToLinkedProductsTab'),
      keys: 'shift+2',
      action: 'keydown',
      callback: (event: any) => {
        event.preventDefault();
        const element = document.querySelector(
          '[data-testid="tab-packing-classification-linked-products"]'
        );
        if (isVisible(element)) {
          //@ts-ignore
          element?.click();
        }
      },
    },

  ];
  export const usePackingClassificationDetailsShortcuts = (
    t: TFunction<'translation', undefined>,
    callbacks: PackingClassificationDetailsTabCallbacks
  ) => {
    const pageShortcuts = shortcuts(t, callbacks);
    const hotkeys = useHotkeys(pageShortcuts);
    return {
      ...hotkeys,
      getShortcut: getShortcut(pageShortcuts),
      getShortcuts: getShortcuts(pageShortcuts),
    };
  };