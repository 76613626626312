import { FC } from 'react';
import { IPrintModal } from './interfaces';
import { Modal, Text } from '@/Components';
import { PrintCard } from './Components/Card';

import styles from './styles.module.scss';
import { Trans, useTranslation } from 'react-i18next';

export const PrintModal: FC<IPrintModal> = ({ 
  localEstoqueDescricao,
  visible,
  onOkClick,
  loading,
  onCancel, 
  printAll,
  printDivergent,
  setPrintAll,
  setPrintDivergent,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={
        t(
          'supply.inventory.conference.printModal.title',
          { localEstoque: localEstoqueDescricao }
        )
      }
      body={
        <div className={styles['print-modal-container']}>
          <Text color='text-50' type='ui-tiny-content'>
            <Trans
              i18nKey={t('supply.inventory.conference.printModal.intro')}
              components={[
                <strong />
              ]}
            />
          </Text>
          <div className={styles['cards-container']}>
            <PrintCard
              icon='alt-cubes'
              label={t('supply.inventory.conference.printModal.labelPrintAllCard')}
              subLabel={
                t(
                  'supply.inventory.conference.printModal.subLabelPrintAllCard',
                  { localEstoque: localEstoqueDescricao }
                )
              }
              isActive={printAll}
              onSelect={() => {
                setPrintAll(!printAll);
                setPrintDivergent(false);
              }}
            />
            <PrintCard
              icon='Box'
              label={t('supply.inventory.conference.printModal.labelPrintDivergentCard')}
              subLabel={
                t(
                  'supply.inventory.conference.printModal.subLabelPrintDivergentCard',
                  { localEstoque: localEstoqueDescricao }
                )
              }
              isActive={printDivergent}
              onSelect={() => {
                setPrintDivergent(!printDivergent);
                setPrintAll(false);
              }}
            />
          </div>
        </div>
      }
      visible={visible}
      okButtonColor={printAll || printDivergent ? 'primary-base' : 'primary-t30'}
      okButtonName={t('supply.inventory.conference.printModal.okButtonName')}
      okButtonLeftIcon='printer'
      leftIconColor='text-800'
      disabledButton={printAll || printDivergent ? false : true}
      onCancelClick={onCancel}
      onClose={onCancel}
      onOkClick={onOkClick}
      loading={loading} 
      widthModal={528}
    />
  );
};
