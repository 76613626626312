import { useEffect } from 'react';
import { Col, Row } from 'antd';
import { Text } from '@/Components';
import { useFormikContext } from 'formik';
import { useCalculateDeference } from './Hooks/useCalculateDiferrence';

import styles from '../../styles.module.scss';
import { ConferenceButtons } from './Components/ConferenceButtons';
import { IItemChecked } from '../../../../../../Hooks/interfaces';

interface ITableRowProps {
  index: number;
  item: any;
  hideQuantityColumns: boolean;
  itemsChecked: IItemChecked[];
  onCheckItem: (isCorrect: boolean | null, index: number) => void;
  isConferenceStatus: boolean;
}

export const TableRow: React.FC<ITableRowProps> = ({
  index,
  item,
  hideQuantityColumns,
  itemsChecked,
  onCheckItem,
  isConferenceStatus,
}) => {
  const form: any = useFormikContext();
  const { calculateDeference, defineColor } = useCalculateDeference(
    index,
    item.quantidade
  );

  useEffect(() => {
    form.setFieldValue(`itens.${index}.unidadeMedidaId`, item.unidadeMedidaId);
    form.setFieldValue(`itens.${index}.usuarioExternalId`, null);
    form.setFieldValue(
      `itens.${index}.inventarioItemId`,
      item.inventarioItemId
    );
    form.setFieldValue(
      `itens.${index}.quantidadeInventariada`,
      item.quantidadeInventariada
    );
  }, []);

  return (
    <Row
      className={`${styles['row']} ${index % 2 !== 0 ? styles['even'] : ''}`}
      gutter={[16, 0]}
      style={{ paddingLeft: '10px' }}
    >
      <Col span={2}>
        <Text type="paragraph">{item.produtoCodigo}</Text>
      </Col>
      <Col span={isConferenceStatus ? 9 : 11}>
        <Text type="paragraph-bold">{item.produtoDescricao}</Text>
      </Col>
      <Col span={4}>
        <Text type="paragraph">{item.loteNumero}</Text>
      </Col>
      {hideQuantityColumns == false && (
        <Col span={3}>
          <Text type="paragraph">
            {item.quantidade}
            {item.unidadeMedidaAbreviacao}
          </Text>
        </Col>
      )}
      <>
        <Col span={2}>
          <Text type="paragraph" style={{ marginLeft: '4px' }}>
            {item.quantidadeInventariada}
            {item.unidadeMedidaAbreviacao}
          </Text>
        </Col>
        {hideQuantityColumns == false && (
          <Col span={2}>
            <Text
              type="paragraph"
              style={{ marginLeft: '4px' }}
              color={defineColor()}
            >
              {calculateDeference(item.unidadeMedidaAbreviacao)}
            </Text>
          </Col>
        )}
      </>
      {isConferenceStatus && (
        <Col span={2}>
          <ConferenceButtons
            index={index}
            itemsChecked={itemsChecked}
            onCheckItem={onCheckItem}
            inventoryItemId={item.inventarioItemId}
            initialApproved={item.aprovado}
          />
        </Col>
      )}
    </Row>
  );
};
