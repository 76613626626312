import { useMemo } from 'react';
import { IUseMemorizations } from './interfaces';
import { useTranslation } from 'react-i18next';
import { history } from '@/Store';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { DeleteModalBody } from '../Modal/DeleteModal';
import { IDeleteModalTable } from '@/Components/Table';
import { Placements } from '@/Components/Tooltip';

export const useListMemorizations = ({
  selectedRowsOnTable,
  getShortcut,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const addPrescriberHotkey = getShortcut('addPrescriber');
  const selectAllTableHotKey = getShortcut('selectAll');
  const deletePrescriberHotkey = getShortcut('deleteSelected');
  const editPrescriberHotkey = getShortcut('editSelected');

  const headerOptions = useMemo(
    () => ({
      nameKey: 'nomeCompleto',
      hasSearch: true,
      searchPlaceholder:
        'Pesquise por um prescritor, registro, especialidade, etc.',
      newButton: {
        label: t('prescribers.listPage.newButton'),
        onClick: () => history.push('/sales/prescribers/create'),
        permission: {
          permission: PermissionEnum.Vendas_Prescritores_Cadastrar,
          tooltip: 'noPermission.sale.prescriber.create',
        },
        tooltip: {
          title: addPrescriberHotkey.tooltip,
          placement: 'topRight' as Placements,
        },
      },
      deleteButton: {
        permission: {
          permission: PermissionEnum.Vendas_Prescritores_Excluir,
          tooltip: 'noPermission.sale.prescriber.delete',
        },
        tooltip: {
          title: deletePrescriberHotkey.tooltip,
          placement: 'topRight' as Placements,
        },
      },
      editButton: {
        tooltip: {
          title: editPrescriberHotkey.tooltip,
          placement: 'topRight' as Placements,
        },
        options: [
          {
            label: t('common.status'),
            isStatusOnly: true,
            permission: {
              permission: PermissionEnum.Vendas_Prescritores_AlterarStatus,
              tooltip: 'noPermission.sale.prescriber.editStatus',
            },
            key: 's',
          },
        ],
      },
    }),
    [t]
  );

  const rowOptions = useMemo(
    () => ({
      onRowDoubleClick: (x: any) =>
        history.push(`/sales/prescribers/${x.externalId}/details`),
      permission: {
        permission: PermissionEnum.Vendas_Prescritores_VerDetalhes,
        tooltip: 'noPermission.sale.prescriber.detail',
      },
    }),
    []
  );

  const deleteModal = useMemo(
    (): IDeleteModalTable | undefined =>
      selectedRowsOnTable && {
        title:
          selectedRowsOnTable.length === 1
            ? `${t('common.delete')} "${selectedRowsOnTable[0].nomeCompleto}"?`
            : `${t('common.delete')} ${selectedRowsOnTable.length} ${t(
                'prescribers.prescribers'
              )}?`,
        body: <DeleteModalBody prescriber={selectedRowsOnTable} />,
        buttons: {
          okButtonColor: 'status-danger-base',
        },
      },
    [t, selectedRowsOnTable]
  );

  const tableHeaderHotKeyTooltip = {
    title: selectAllTableHotKey.tooltip,
    placement: 'topLeft' as Placements,
    targetOffset: [11, 0],
  };

  return {
    headerOptions,
    rowOptions,
    deleteModal,
    tableHeaderHotKeyTooltip,
  };
};
