import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HorizontalTabs } from '../../../../../Components';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import SupplierDetailsTabForm from '../Details';
import { DeleteSupplierModal } from '../Details/Components/Modal/deleteModal';
import { useParams } from 'react-router';
import { PermissionEnum } from '../../../../../Data/Interfaces/model/permissionEnum';
import { useEditCallAPI } from '../Details/Hooks/useEditCallAPI';
import { useSupplierDetailsShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/Order/Supplier/details';

export const EditSupplier: FC = () => {
  const { externalId } = useParams<{ externalId: string }>();
  const [visibleModal, setVisibleModal] = useState(false);
  const { t } = useTranslation();

  const { supplier, isLoadingSupplier } = useEditCallAPI({ externalId });

  const { getShortcut } = useSupplierDetailsShortcuts(t);

  const editMoreActionsHotkey = getShortcut('editMoreActions');

  return (
    <PageLoading loading={isLoadingSupplier}>
      <HorizontalTabs
        type="secondary"
        items={[
          {
            label: t('purchasing.provider.details.tabs.details'),
            key: 'details',
            children: <SupplierDetailsTabForm />,
          },
          {
            label: t('purchasing.provider.details.tabs.purchaseOrder'),
            key: 'purchaseOrder',
            disabled: true,
            children: '',
          },
          {
            label: t('purchasing.provider.details.tabs.productBatches'),
            key: 'productBatches',
            disabled: true,
            children: '',
          },
        ]}
        dropdownDirection="bottomRight"
        dropdownTitle={t('admin.editUserPage.tab.buttonActions.title')}
        leftIcon="menu-ellipsis-horizontal"
        dataTestIdDropdownButton="more-actions-button"
        moreActionsButton={{ title: editMoreActionsHotkey.tooltip }}
        dropdownItens={[
          {
            label: t('common.delete'),
            icon: 'trash',
            onClick: () => setVisibleModal(true),
            permission: {
              permission: PermissionEnum.Compras_Fornecedores_Excluir,
              tooltip: 'noPermission.purchasing.supplier.delete',
            },
            dataTestId: 'dropdown-option-delete',
            key: 'backspace',
          },
        ]}
      />
      <DeleteSupplierModal
        supplier={supplier}
        visibleModal={visibleModal}
        changeVisibleModal={(value) => setVisibleModal(value)}
      />
    </PageLoading>
  );
};
