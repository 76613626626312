import { ButtonDropdown } from '@/Components/Button/ButtonDropdown';
import { useTranslation } from 'react-i18next';
import { useReleaseMemorizations } from '../../hooks/useReleaseMemorizations';
import React from 'react';

interface IDropdownButtonProps {
  setIsVisibleCancelModal: any;
  releaseHistory: any;
}

export const DropdownButton: React.FC<IDropdownButtonProps> = ({
  releaseHistory,
  setIsVisibleCancelModal,
}) => {
  const { t } = useTranslation();
  const { dropdownReleaseOptionsItems } = useReleaseMemorizations({
    releaseHistory,
    setIsVisibleCancelModal,
  });

  return (
    <ButtonDropdown
      dropdownDirection="bottomRight"
      leftIcon="menu-ellipsis-horizontal"
      dropdownItens={dropdownReleaseOptionsItems}
      dropdownTitle={t('supply.inventory.release.dropdownOptions.title')}
      type="secondary"
      dataTestId="release-options"
    />
  );
};
