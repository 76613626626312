import i18n from 'i18next';
import { IInvoiceGetResponse } from '../../../../../../Data/Interfaces/response/Purchase/IInvoiceResponse';
import { IPermissionEditButon } from '../../../../../../Components/HorizontalTabs';
import { IButtonDropDownItems } from '../../../../../../Components/Button/ButtonDropdown';
import { Sections } from '../Tabs/Details/Sections';
import { PermissionEnum } from '../../../../../../Data/Interfaces/model/permissionEnum';
import { history } from '../../../../../../Store';
import { LotsPage } from '../Tabs/LotsPage';
import { BootysShortcut } from '@/Components/Shortcuts';

enum Invoice_Status {
  draft = 1,
  pending = 2,
  thrown = 3,
  ended = 4,
}

export const itemsOnTab = (
  alternateTabDetailsHotkey: BootysShortcut,
  alternateTabLotsHotkey: BootysShortcut,
  invoice?: IInvoiceGetResponse
) => [
  {
    label: i18n.t('purchasing.invoice.details.details'),
    key: 'details',
    children: <Sections invoice={invoice} />,
    dataTestId: 'tab-invoice-details',
    shortCutTooltip: {
      title: alternateTabDetailsHotkey.tooltip,
      targetOffset: [0, 10],
    },
  },
  {
    label: i18n.t('purchasing.invoice.details.lots'),
    key: 'lots',
    disabled: invoice ? invoice?.status < Invoice_Status.thrown : true,
    children: <LotsPage />,
    dataTestId: 'tab-invoice-lots',
    shortCutTooltip: {
      title: alternateTabLotsHotkey.tooltip,
      targetOffset: [0, 10],
    },
  },
];

export const dropdownTitle = i18n.t('purchasing.invoice.details.moreActions');

export const leftButtonConfig = (
  openThrowLotsModal: () => void,
  invoice?: IInvoiceGetResponse
): IPermissionEditButon => {
  return {
    isVisible: invoice?.status !== Invoice_Status.thrown,
    leftIcon: 'cube',
    children: i18n.t('purchasing.invoice.details.throwLots'),
    onClick: () => openThrowLotsModal(),
    codePermissionEdit: PermissionEnum.Compras_NotaFiscalEntrada_LancarLotes,
    messageTooltipPermission: 'noPermission.purchasing.invoice.throwLots',
  };
};

export const rightButtonConfig = (
  editInvoiceHotkey: BootysShortcut,
  invoice?: IInvoiceGetResponse
): IPermissionEditButon => {
  return {
    disabled: invoice?.status === Invoice_Status.thrown,
    isVisible: true,
    messageTooltipPermission: 'noPermission.purchasing.invoice.edit',
    messageTooltipDisabled: i18n.t(
      'purchasing.invoice.details.cantEditInvoiceWithThrownLots'
    ),
    tooltipPosition: 'bottomRight',
    targetOffsetTooltip: [35, -15],
    codePermissionEdit: PermissionEnum.Compras_NotaFiscalEntrada_EditarDetalhes,
    onClick: () => history.push(`/order/invoice/${invoice?.externalId}/edit`),
    dataTestId: 'edit-invoice-button',
    hotkeyTooltip: { title: editInvoiceHotkey.tooltip },
  };
};

export const dropdownItems = (
  openDeleteModal: () => void
): IButtonDropDownItems => {
  return [
    {
      label: i18n.t('purchasing.invoice.details.exclude'),
      icon: 'trash',
      targetOffsetTooltip: [35, -15],
      onClick: openDeleteModal,
      permission: {
        permission: PermissionEnum.Compras_NotaFiscalEntrada_Excluir,
        tooltip: 'noPermission.purchasing.invoice.delete',
      },
      key: 'backspace',
      dataTestId: 'dropdown-option-delete',
    },
  ];
};

export const productKeys = [
  'ncmCodigo',
  'cstCsosnCodigo',
  'cfopCodigo',
  'valorUnitario',
  'quantidadeComprada',
  'total',
  'baseCalculoIcms',
  'valorIcms',
  'valorIpi',
];

export const productItemColumns = [
  'purchasing.invoice.create.NCM',
  'purchasing.invoice.create.CST',
  'purchasing.invoice.create.CFOP',
  'purchasing.invoice.create.unitValue',
  'purchasing.invoice.create.quantity',
  'purchasing.invoice.create.total',
  'purchasing.invoice.create.calcICMS',
  'purchasing.invoice.create.ICMSVal',
  'purchasing.invoice.create.IPIVal',
];
