import React, { FC } from 'react';

import { Icon } from '..';
import { history } from '../../Store';
import { IconName } from '../Icon/IconClasses';
import { UserInfo } from './Components/UserInfo';
import { ContextButton } from './Components/ContextButton';
import { IGetUserResponse } from '@/Data/Interfaces/response/User/IGetUserReponse';
import { getKey } from '@/Utils/OnKeyDownKeys';

import styles from './HeaderMenu.module.scss';
import './HeaderMenu.override.scss';
import { useHeaderMenuShortcuts } from '../Shortcuts/keymaps/Components/HeaderMenu/headerMenu';
import { useTranslation } from 'react-i18next';
export interface IHeaderItem {
  logo: IconName;
  logoCollapsed: IconName;
  isCollapsed: boolean;
  getMe?: IGetUserResponse;
}

export const HeaderMenu: FC<IHeaderItem> = ({
  logo,
  logoCollapsed,
  isCollapsed,
  getMe,
}) => {
  const tabIndexHeaderMenuIcon = 1;
  const { t } = useTranslation();

  const { getShortcut } = useHeaderMenuShortcuts(t);

  const openUserMenuHotkey = getShortcut('openUserMenu');
  const openCompanyMenuHotkey = getShortcut('openCompanyMenu');

  return (
    <div className={styles['HeaderMenu']}>
      <div
        className={isCollapsed ? styles['collapsed'] : styles['LeftMenu']}
        tabIndex={tabIndexHeaderMenuIcon}
        onKeyDown={(e) => {
          if (e.key === getKey.enterKey) {
            history.push('/');
          }
        }}
      >
        {isCollapsed ? (
          <Icon
            onClick={() => {
              history.push('/');
            }}
            icon={logoCollapsed}
            size="XXL"
            color="primary-base"
          />
        ) : (
          <div className={styles['LeftLogo']}>
            <Icon
              onClick={() => {
                history.push('/');
              }}
              icon={logo}
              size="XXL"
              color="primary-base"
            />
          </div>
        )}
      </div>
      <div className={styles['RightMenu']}>
        <div>
          <ContextButton
            hotkeyTooltip={{
              title: openCompanyMenuHotkey.tooltip,
              targetOffset: [0, -8],
            }}
          />
          <div className={styles['line']} />
          {getMe && (
            <UserInfo
              name={getMe.nome}
              lastName={getMe.sobrenome}
              email={getMe.email}
              hotkeyTooltip={{
                title: openUserMenuHotkey.tooltip,
                targetOffset: [-6, -8],
              }}
              getShortcut={getShortcut}
            />
          )}
        </div>
      </div>
    </div>
  );
};
