import { IGetInventory } from '@/Data/Interfaces/response/Inventory/InventoryResponse';

export const useFormatInitialValues = () => {
  const formatInventory = (inventory: IGetInventory | undefined) => {
    if (!inventory) return;
    const specificationsInitial: { [key: string]: any } = {};

    inventory.inventarioEspecificacao.forEach((specification) => {
      const supplyPlaceKey = `${specification.inventarioEspecificacaoId}-local-estoque`;
      const groupKey = `${specification.inventarioEspecificacaoId}-grupo`;
      const subgroupKey = `${specification.inventarioEspecificacaoId}-subgrupo`;
      specificationsInitial[supplyPlaceKey] = {
        id: specification.localEstoqueExternalId,
        label: specification.localEstoqueDescricao,
      };

      specificationsInitial[groupKey] = {
        id: specification.grupoExternalId,
        label: specification.grupoDescricao,
      };

      specificationsInitial[subgroupKey] = {
        id: specification.subGrupoExternalId,
        label: specification.subGrupoDescricao,
      };
    });

    return {
      ...inventory,
      ...specificationsInitial,
    };
  };

  return { formatInventory };
};
