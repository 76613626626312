import { IItemChecked } from '../../../Hooks/interfaces';

export const useProgressFunctions = () => {
  const calculateProgress = (itemsChecked: IItemChecked[]) => {
    let completedItems = 0;
    const totalItems = itemsChecked ? itemsChecked.length : 0;
    
    if (totalItems === 0) return 0;

    itemsChecked.forEach((item: IItemChecked) => {
      if (item.aprovado !== null) 
        completedItems += 1;
    });

    return (completedItems / totalItems) * 100;
  };

  return { calculateProgress };
};
