import { useMemo } from 'react';
import { history } from '@/Store';
import { useTranslation } from 'react-i18next';
import { BootysShortcut } from '@/Components/Shortcuts';
import { Placements } from '@/Components/Tooltip';

interface IUseListMemorizations {
  getShortcut: (id: string) => BootysShortcut;
}

export const useListMemorizations = ({
  getShortcut,
}: IUseListMemorizations) => {
  const { t } = useTranslation();

  const addNewLoss = getShortcut('addLosses');

  const headerOption = useMemo(
    () => ({
      hasSearch: true,
      searchPlaceholder: t('supply.losses.list.searchPlaceholder'),
      newButton: {
        onClick: () => history.push('/inventory/losses/create'),
        label: 'supply.losses.newButton',
        permission: {
          permission: 2018,
          tooltip: 'noPermission.supply.losses.create',
        },
        dataTestId: 'inventory-losses-new-button',
        tooltip: {
          title: addNewLoss.tooltip,
          placement: 'topRight' as Placements,
        },
      },
      editButton: {},
      deleteButton: {},
      nameKey: 'dataPerda',
    }),
    [t]
  );
  const rowsOptions = useMemo(
    (): { onRowDoubleClick?: (record: any) => void } => ({
      onRowDoubleClick: (x) =>
        history.push(`/inventory/losses/${x.externalId}/details`),
    }),
    []
  );

  return { headerOption, rowsOptions };
};
