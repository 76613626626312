import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HorizontalTabs } from '../../../../../Components';
import { DetailForm } from './Components/DetailsForm';
import { useLossesListShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/inventory/losses/list';

const LossesEditPage: FC = () => {
  const [disabledFormInput, setDisabledFormInput] = useState(true);
  const { t } = useTranslation();

  const { getShortcut } = useLossesListShortcuts(t, {});

  const editLoss = getShortcut('editLosses');

  return (
    <HorizontalTabs
      type="secondary"
      items={[
        {
          label: t('supply.balanceAdjustment.detail'),
          key: 'details',
          children: (
            <DetailForm
              disabledForm={disabledFormInput}
              onDisableEditButton={() => setDisabledFormInput(true)}
            />
          ),
        },
      ]}
      rightButton={{
        ...(disabledFormInput && {
          onClick: () => setDisabledFormInput(false),
          leftIcon: 'edit',
          children: t('common.edit'),
          isVisible: disabledFormInput,
          codePermissionEdit: 2019,
          messageTooltipPermission: 'noPermission.supply.losses.edit',
          dataTestId: 'edit-loss-button',
          hotkeyTooltip: {
            title: editLoss.tooltip,
            targetOffset: [0, 5],
          },
        }),
      }}
    />
  );
};

export default LossesEditPage;
