import { IUseMemorizations } from './interfaces';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '@/Store';
import { IDeleteModalTable } from '@/Components/Table';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { DeleteModalOnTable } from '@/Utils/DeleteModalUtils';
import { DeleteModal } from '../Modal/deleteModal';
import { Placements } from '@/Components/Tooltip';

export const useListMemorizations = ({
  selectedRowsOnTable,
  getShortcut,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const addCapsuleColorHotkey = getShortcut('addCapsuleColor');
  const selectAllTableHotKey = getShortcut('selectAll');
  const deleteCapsuleColortHotkey = getShortcut('deleteSelected');

  const headerOptions = {
    hasSearch: true,
    newButton: {
      label: t('production.capsuleColors.list.newButton'),
      onClick: () => history.push('/production/capsule-colors/create'),
      permission: {
        permission: PermissionEnum.Producao_CapsulaCor_Cadastrar,
        tooltip: 'noPermission.production.capsuleColors.create',
      },
      tooltip: {
        title: addCapsuleColorHotkey.tooltip,
        placement: 'topRight' as Placements,
      },
      dataTestId: 'production-capsule-colors-new-button',
    },
    deleteButton: {
      permission: {
        permission: PermissionEnum.Producao_CapsulaCor_Excluir,
        tooltip: 'noPermission.production.capsuleColors.delete',
      },
      tooltip: {
        title: deleteCapsuleColortHotkey.tooltip,
        placement: 'topRight' as Placements,
      },
      dataTestId: 'delete-capsule-colors-button',
    },
    searchPlaceholder: t('production.capsuleColors.list.searchPlaceholder'),
    nameKey: 'corCapsula',
  };

  const rowOptions = {
    onRowDoubleClick: (x: any) =>
      history.push(`/production/capsule-colors/${x.externalId}/details`),
  };

  const deleteModal = useMemo(
    (): IDeleteModalTable | undefined => ({
      title:
        selectedRowsOnTable &&
        `${t('common.delete')} ${
          selectedRowsOnTable.length > 1
            ? `${t(
                'production.capsuleColors.list.modalDelete.deleteManyCapsuleColors'
              )}?`
            : `${t(
                'production.capsuleColors.list.modalDelete.descriptionCapsuleColors'
              )} "${selectedRowsOnTable[0]?.corCapsula}"?`
        }`,
      body:
        selectedRowsOnTable && selectedRowsOnTable?.length > 1 ? (
          DeleteModalOnTable(
            selectedRowsOnTable,
            `${t('modal.deleteModal.many.title')} ${t(
              'production.capsuleColors.list.modalDelete.theCapsuleColors'
            )}:`,
            'corCapsula',
            t('common.stockLocations')
          )
        ) : (
          <DeleteModal selectedRowsOnTable={selectedRowsOnTable} />
        ),
      buttons: {
        okButtonColor: 'status-danger-base',
        okButtonDataTestId: 'delete-capsule-colors-submit-button',
      },
    }),
    [selectedRowsOnTable, t]
  );

  const tableHeaderHotKeyTooltip = {
    title: selectAllTableHotKey.tooltip,
    placement: 'topLeft' as Placements,
    targetOffset: [11, 0],
  };

  return {
    headerOptions,
    rowOptions,
    deleteModal,
    tableHeaderHotKeyTooltip
  };
};
