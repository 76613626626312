import { Page, Document, StyleSheet } from '@react-pdf/renderer';
import { PdfHeader } from './Components/PdfHeader';
import { SupplyPlaceTable } from './Components/SupplyPlaceTable';
import { PageCount } from './Components/PageCount';
import { IPrintItems } from './interfaces';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
    paddingBottom: '100px',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

export const PrintItems: React.FC<IPrintItems> = ({
  inventoryCode,
  supplyPlaces,
  onRender,
  responsible,
}) => {
  return (
    <Document onRender={onRender}>
      <Page size="A4" style={styles.page}>
        <PdfHeader 
          inventoryCode={inventoryCode}
          responsible={responsible}
        />
        {supplyPlaces?.map((e) => {
          return (
            <SupplyPlaceTable
              supplyPlace={e.localEstoqueDescricao}
              tableItems={e.itens}
            />
          );
        })}
        <PageCount />
      </Page>
    </Document>
  );
};
