import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReproveOrderModal } from './Modal/StatusModals/ReproveOrderModal';
import { ReleasedOrderModal } from './Modal/StatusModals/ReleasedOrderModal';
import { ConfirmWithSupplierOrderModal } from './Modal/StatusModals/ConfirmWithSupplierModal';
import { ApproveOrderModal } from './Modal/StatusModals/ApproveOrderModal';
import { StatusCard as StatusCardComponent } from '@/Components';
import { StatusPurchaseOrderEnum } from './enum';
import { ApproveButton } from './Components/ApproveButton';
import { DisapproveButton } from './Components/DisapproveButton';
import { useStatusCardStates } from './Hooks/useStatusCardStates';
import { useStatusCardCallAPI } from './Hooks/useStatusCardCallAPI';
import { useStatusCardFunctions } from './Hooks/useStatusCardFunctions';
import { useStatusCardMemorizations } from './Hooks/useStatusCardMemorizations';

import styles from './StatusCard.module.scss';
import { BootysShortcut } from '@/Components/Shortcuts';

interface IStatusCard {
  getShortcut: (id: string) => BootysShortcut;
}

export const StatusCard: FC<IStatusCard> = ({ getShortcut }) => {
  const { t } = useTranslation();

  const { statusType, statusPurchaseOrder } = useStatusCardMemorizations({});

  const {
    approveModal,
    setApproveModal,
    reproveModal,
    setReproveModal,
    reverseModal,
    setReverseModal,
    confirmWithSupplierModal,
    setConfirmWithSupplierModal,
  } = useStatusCardStates();

  const {
    reproveOrderPurchase,
    reverseConfirmWithSupplierOrderPurchase,
    reverseReleasedOrderPurchase,
    confirmWithSupplier,
    approveOrderPurchase,
  } = useStatusCardCallAPI();

  const {
    statusTranslationFunction,
    onCancelClickReproveOrder,
    onCloseReproveOrder,
    onOkClickReproveOrder,
    onCancelClickReleasedOrder,
    onCloseReleasedOrder,
    onOkClickReleasedOrder,
    onCancelClickConfirmWithSupplierOrder,
    onCloseConfirmWithSupplierOrder,
    onOkClickConfirmWithSupplierOrder,
    onCancelClickApproveOrder,
    onCloseApproveOrder,
    onOkClickApproveOrder,
  } = useStatusCardFunctions({
    statusType,
    setReproveModal,
    reproveOrderPurchase,
    statusPurchaseOrder,
    setReverseModal,
    reverseConfirmWithSupplierOrderPurchase,
    reverseReleasedOrderPurchase,
    confirmWithSupplier,
    setConfirmWithSupplierModal,
    approveOrderPurchase,
    setApproveModal,
  });

  const { statusIcon, statusColor, translatedStatus } =
    useStatusCardMemorizations({
      statusTranslationFunction,
    });

  const approvePurchaseOrderHotkey = getShortcut('approvePurchaseOrder');
  const reprovePurchaseOrderHotkey = getShortcut('reprovePurchaseOrder');
  const confirmPurchaseOrderHotkey = getShortcut('confirmPurchaseOrder');

  return (
    <>
      <StatusCardComponent
        type="ui-main-semibold"
        className={`${
          styles[`${statusColor[statusPurchaseOrder - 1]}-status`]
        } ${styles['status-card']}`}
        icon={{ icon: statusIcon[statusPurchaseOrder - 1] }}
        children={translatedStatus}
      />
      {statusPurchaseOrder !== StatusPurchaseOrderEnum.Reprovado &&
      statusPurchaseOrder !== StatusPurchaseOrderEnum.Cancelado &&
      statusPurchaseOrder !== StatusPurchaseOrderEnum.RecebidoTotal ? (
        <>
          <div
            style={{
              justifyContent: 'space-between',
              margin: '15px 0 15px 0',
              width: '100%',
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: 5,
            }}
          >
            <ApproveButton
              statusPurchaseOrder={statusPurchaseOrder}
              setApproveModal={setApproveModal}
              setConfirmWithSupplierModal={setConfirmWithSupplierModal}
              hotkeyTooltip={{
                title:
                  statusPurchaseOrder ===
                  StatusPurchaseOrderEnum.AguardandoAprovacao
                    ? approvePurchaseOrderHotkey.tooltip
                    : statusPurchaseOrder === StatusPurchaseOrderEnum.Liberado
                    ? confirmPurchaseOrderHotkey.tooltip
                    : t(
                        'purchasing.purchaseOrder.shortcuts.receivePurchaseOrder'
                      ),
              }}
              dataTestId={
                statusPurchaseOrder ===
                StatusPurchaseOrderEnum.AguardandoAprovacao
                  ? 'approve-button'
                  : statusPurchaseOrder === StatusPurchaseOrderEnum.Liberado
                  ? 'confirm-button'
                  : ''
              }
            />
            <DisapproveButton
              statusPurchaseOrder={statusPurchaseOrder}
              setReproveModal={setReproveModal}
              setReverseModal={setReverseModal}
              hotkeyTooltip={{
                title:
                  statusPurchaseOrder ===
                  StatusPurchaseOrderEnum.AguardandoAprovacao
                    ? reprovePurchaseOrderHotkey.tooltip
                    : statusPurchaseOrder === StatusPurchaseOrderEnum.Liberado
                    ? t(
                        'purchasing.purchaseOrder.shortcuts.reversePurchaseOrder'
                      )
                    : statusPurchaseOrder ===
                      StatusPurchaseOrderEnum.ConfirmadoComFornecedor
                    ? t(
                        'purchasing.purchaseOrder.shortcuts.reversePurchaseOrder'
                      )
                    : '',
              }}
              dataTestId={
                statusPurchaseOrder ===
                StatusPurchaseOrderEnum.AguardandoAprovacao
                  ? 'reprove-button'
                  : ''
              }
            />
          </div>
        </>
      ) : (
        <></>
      )}
      <div style={{ marginBottom: 15, marginTop: 15 }}></div>
      <ReproveOrderModal
        isVisible={reproveModal}
        onCancelClick={onCancelClickReproveOrder}
        onClose={onCloseReproveOrder}
        onOkClick={onOkClickReproveOrder}
        okButtonName={
          reproveOrderPurchase.isLoading
            ? t('common.reproveLoading')
            : t('common.reprove')
        }
        loading={reproveOrderPurchase.isLoading}
      />
      <ReleasedOrderModal
        isVisible={reverseModal}
        onCancelClick={onCancelClickReleasedOrder}
        onClose={onCloseReleasedOrder}
        onOkClick={onOkClickReleasedOrder}
        okButtonName={
          reverseConfirmWithSupplierOrderPurchase.isLoading ||
          reverseReleasedOrderPurchase.isLoading
            ? t('common.reverseLoading')
            : t('common.confirmReverse')
        }
        loading={
          reverseConfirmWithSupplierOrderPurchase.isLoading ||
          reverseReleasedOrderPurchase.isLoading
        }
        statusPurchaseOrder={statusPurchaseOrder}
      />
      <ConfirmWithSupplierOrderModal
        onCancelClick={onCancelClickConfirmWithSupplierOrder}
        isVisible={confirmWithSupplierModal}
        onClose={onCloseConfirmWithSupplierOrder}
        onOkClick={onOkClickConfirmWithSupplierOrder}
        okButtonName={
          confirmWithSupplier.isLoading
            ? t('common.confirmLoading')
            : t('common.confirmPurchase')
        }
        loading={confirmWithSupplier.isLoading}
      />
      <ApproveOrderModal
        onCancelClick={onCancelClickApproveOrder}
        isVisible={approveModal}
        onClose={onCloseApproveOrder}
        onOkClick={onOkClickApproveOrder}
        okButtonName={
          approveOrderPurchase.isLoading
            ? t('common.approveLoading')
            : t('common.approve')
        }
        loading={approveOrderPurchase.isLoading}
      />
    </>
  );
};
