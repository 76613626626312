import {
  IInvoiceGetLotResponse,
  IInvoiceGetResponse,
} from '@/Data/Interfaces/response/Purchase/IInvoiceResponse';
import {
  CREATE_INVOICE_LOTS,
  DELETE_INVOICE_LOT_DRAFT,
  FETCH_GET_INVOICE,
  FETCH_LIST_INVOICE,
  FETCH_LIST_LOTS,
} from '@/ControllerApiHook/UniqIds/Purchase/InvoiceKeys';
import { Form } from '@components/Form';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { formatInvoice } from '@/Pages/Sectors/Order/Invoice/Edit/utils/functions';
import { InfoSection } from './Secions/InfoSection';
import { LotsSection } from './Secions/LotsSection';
import { TechnicalInformationCard } from './components/TecnicalInformationCard';
import { v4 as uuidV4 } from 'uuid';
import { Button, i18n } from '@/Components';
import { history } from '@/Store';
import { useTranslation } from 'react-i18next';
import {
  defineButtonDraftStatusMessage,
  enableDraftButton,
  enableSubmitButton,
  formatLotsGet,
} from './utils/functions';
import DateUtils from '@/Utils/DateUtils';
import { useQueryClient } from '@tanstack/react-query';
import { ButtonDraft } from '@/Components/Button/ButtonDraft';
import { DeleteDraftModal } from './components/DeleteDraftModal';
import StorageUtils from '@/Utils/StorageUtils';
import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
} from '@/ControllerApiHook/Controller';

import styles from './registerLots.module.scss';
import { InvoiceAPI } from '@/Data/API/Purchase/InvoiceApi';
import {
  messageErrorToast,
  messageSucessToast,
} from '@/Utils/MessageErrorToast';

export const RegisterLots: React.FC = () => {
  const { t } = useTranslation();
  const { externalId } = useParams<{ externalId: string }>();
  const { data: lots } = useControllerQueryApiHook<IInvoiceGetLotResponse>({
    uniqId: FETCH_LIST_LOTS,
    entityApi: InvoiceAPI.getInvoiceLots,
    externalIds: [externalId],
  });
  const { data: invoice } = useControllerQueryApiHook<IInvoiceGetResponse>({
    uniqId: FETCH_GET_INVOICE,
    entityApi: InvoiceAPI.getInvoice,
    externalIds: [externalId],
  });
  const [isInitial, setIsInitial] = useState<boolean>(true);

  const createLots: any = useControllerMutateApiHook({
    uniqId: CREATE_INVOICE_LOTS,
    entityApi: InvoiceAPI.postInvoiceLots,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () =>
        messageSucessToast(
          i18n.t('purchasing.invoice.successMessageToasts.createLot')
        ),
    },
  });

  const createLotsDraft = useControllerMutateApiHook({
    uniqId: CREATE_INVOICE_LOTS,
    entityApi: InvoiceAPI.postInvoiceLotsDraft,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () =>
        messageSucessToast(
          i18n.t('purchasing.invoice.successMessageToasts.createLotDraft')
        ),
    },
  });

  const updateLotsDraft: any = useControllerMutateApiHook({
    uniqId: DELETE_INVOICE_LOT_DRAFT,
    entityApi: InvoiceAPI.savePutInvoiceLotDraft,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () =>
        messageSucessToast(
          i18n.t('purchasing.invoice.successMessageToasts.editLotDraft')
        ),
    },
  });

  const queryClient = useQueryClient();
  const [selectedProductIndex, setSelectedProductIndex] = useState<number>(0);

  const preferences = StorageUtils.getPreference();

  const [selectedLotIndex, setSelectedLotIndex] = useState<number>(0);
  const [formattedInvoice, setFormattedInvoice] =
    useState<IInvoiceGetResponse>();
  const [showTechnicalInformation, setShowTechnicalInformation] =
    useState<boolean>(false);
  const [defaultSupplyPlace, setDefaultSupplyPlace] = useState<string>('');
  const [lotsListController, setLotsListController] = useState<any[]>([]);
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [lotErrorNamesIndexes, setLotErrorNamesIndexes] = useState<number[]>(
    []
  );
  const [disabledSubmitButton, setDisabledSubmitButton] =
    useState<boolean>(false);
  const [isDraftSaved, setIsDraftSaved] = useState<boolean>(false);

  const [savedLots, setSavedLots] = useState<any[]>([]);

  const toggleShowTechnicalInformation = () => {
    if (showTechnicalInformation) setShowTechnicalInformation(false);
    if (!showTechnicalInformation) setShowTechnicalInformation(true);
  };

  const handleSubmit = async (values: any) => {
    if (!preferences) return;
    if (!invoice) return;
    try {
      let lotsForm = [...values.lotes];
      lotsForm = lotsForm.map((lot: any) => {
        return {
          ...lot,
          dataFabricacao: DateUtils.convertDateToApi(
            lot.dataFabricacao,
            preferences.padraoData
          ),
          dataValidade: DateUtils.convertDateToApi(
            lot.dataValidade,
            preferences.padraoData
          ),
          fornecedorExternalId: invoice?.fornecedorExternalId,
        };
      });
      let res;
      res = await createLots.mutateAsync({
        notaFiscalEntradaExternalId: invoice?.externalId,
        lotes: lotsForm,
      });
      if (res.success) {
        queryClient.invalidateQueries([FETCH_LIST_INVOICE]);
        history.push('/order/invoice/');
      }
    } catch (e) {}
  };

  const onSaveDraft = async (values: any) => {
    if (!preferences) return;
    if (!invoice) return;
    try {
      let formLots = [...values.lotes];
      formLots = formLots.map((lot: any) => {
        const dataFabricacao = lot.dataFabricacao
          ? DateUtils.convertDateToApi(
              lot.dataFabricacao,
              preferences.padraoData
            )
          : '';

        const dataValidade = lot.dataValidade
          ? DateUtils.convertDateToApi(lot.dataValidade, preferences.padraoData)
          : '';
        return {
          ...lot,
          dataFabricacao,
          dataValidade,
          fornecedorExternalId: invoice?.fornecedorExternalId,
          informacaoTecnica: lot.informacaoTecnica ?? null,
        };
      });
      let res;
      if (savedLots.length) {
        res = await updateLotsDraft.mutateAsync({
          notaFiscalEntradaExternalId: invoice?.externalId,
          lotes: formLots,
        });
      } else {
        res = await createLotsDraft.mutateAsync({
          notaFiscalEntradaExternalId: invoice?.externalId,
          lotes: formLots,
        });
      }

      if (res.success) {
        setIsDraftSaved(true);
        queryClient.invalidateQueries([FETCH_LIST_LOTS]);
        setLotsListController([]);
        setSavedLots(values.lotes);
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (invoice) {
      setFormattedInvoice(formatInvoice(invoice));
    }
  }, [invoice]);

  useEffect(() => {
    if (!lots) return;
    if (formattedInvoice && isInitial) {
      setIsInitial(false);
      const temporaryLots: any[] = [];
      lots.itens.forEach((item: any) => {
        item.lotes.forEach((lot: any) => {
          temporaryLots.push({
            ...lot,
            notaFiscalEntradaItemId: item.notaFiscalEntradaItemId,
            produto: item.produto,
            unidadeMedida: item.unidadeMedida,
            valorUnitario: item.valorUnitario,
            valorTotal: item.valorTotal,
          });
        });
      });

      const formattedLotsTemp = formatLotsGet(temporaryLots);

      if (formattedLotsTemp.length) {
        setLotsListController([]);
        setSavedLots(formattedLotsTemp);
        setFormattedInvoice((prev: any) => {
          return { ...prev, lotes: formattedLotsTemp };
        });
      }
    }
  }, [lots, formattedInvoice, isInitial]);

  return (
    <>
      <Form
        initialValues={formattedInvoice ? formattedInvoice : { lotes: [] }}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <>
            <InfoSection
              selectSupplyPlace={(supplyPlaceId: string) =>
                setDefaultSupplyPlace(supplyPlaceId)
              }
            />
            <div
              className={` ${
                showTechnicalInformation
                  ? styles['showTechnicalCard']
                  : styles['hideTechnicalCard']
              }`}
            >
              <LotsSection
                products={invoice ? invoice.itens : []}
                showTechnicalInformation={showTechnicalInformation}
                showTechInformation={() => setShowTechnicalInformation(true)}
                toggleShowTechnicalInformation={toggleShowTechnicalInformation}
                selectProductIndex={(index: number) =>
                  setSelectedProductIndex(index)
                }
                selectLotIndex={(index: number) => setSelectedLotIndex(index)}
                lotsListController={lotsListController}
                createNewLot={(itemId: number) =>
                  setLotsListController((prev) => [
                    ...prev,
                    { uuid: uuidV4(), itemId },
                  ])
                }
                removeLot={(uuid: string) => {
                  setLotsListController((prev) =>
                    prev.filter((e) => e.uuid !== uuid)
                  );
                }}
                lotErrorNamesIndexes={lotErrorNamesIndexes}
                setLotErrorNamesIndexes={(indexes: number[]) =>
                  setLotErrorNamesIndexes(indexes)
                }
                savedLots={savedLots}
                setSubmitButtonState={(value: boolean) =>
                  setDisabledSubmitButton(value)
                }
              />
              {invoice && (
                <TechnicalInformationCard
                  selectedProduct={invoice.itens[selectedProductIndex]}
                  selectedLotIndex={selectedLotIndex}
                  defaultSupplyPlace={defaultSupplyPlace}
                  lotErrorNamesIndexes={lotErrorNamesIndexes}
                  setLotErrorNamesIndexes={(indexes: number[]) =>
                    setLotErrorNamesIndexes(indexes)
                  }
                />
              )}
            </div>
            <div className={styles['footer-button']}>
              <div>
                <Button
                  type="secondary"
                  style={{ marginRight: 8 }}
                  children={t('common.cancel')}
                  onClick={() => history.push('/order/invoice')}
                />
                <Button
                  type="primary"
                  children={t('purchasing.invoice.registerLots.button.save')}
                  htmlType="submit"
                  disabled={
                    enableSubmitButton(props.values.lotes, lots?.itens ?? []) ||
                    disabledSubmitButton
                  }
                  loading={createLots.isLoading}
                />
              </div>
              <ButtonDraft
                type="secondary"
                htmlType="button"
                disabled={enableDraftButton(
                  savedLots,
                  savedLots,
                  props.values.lotes
                )}
                withStatusIcon={
                  !enableDraftButton(
                    savedLots,
                    savedLots,
                    props.values.lotes
                  ) && !!savedLots.length
                }
                withStatusMessage={t(
                  defineButtonDraftStatusMessage(
                    isDraftSaved,
                    savedLots,
                    props.values.lotes
                  )
                )}
                onClick={() => {
                  onSaveDraft(props.values);
                }}
                children={
                  createLotsDraft.isLoading || updateLotsDraft.isLoading
                    ? t('common.saving')
                    : t('common.saveDraft')
                }
                withDropdownEditButton={
                  savedLots.length
                    ? {
                        options: [
                          {
                            label: t('common.modalDraftDelete.title'),
                            icon: 'trash',
                            onClick: () => {
                              setVisibleModal(true);
                            },
                          },
                        ],
                      }
                    : undefined
                }
              />
            </div>
          </>
        )}
      </Form>
      {invoice && (
        <DeleteDraftModal
          invoiceId={externalId}
          visibleModal={visibleModal}
          changeVisibleModal={(visible) => setVisibleModal(visible)}
        />
      )}
    </>
  );
};
