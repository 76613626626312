import { IListSpecialtiesData } from '@/Data/Interfaces/response/Specialties/ISpecialtiesResponse';
import { useState } from 'react';

export const useListStates = () => {
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);

  const [selectedRowsOnTable, setSelectedRowsOnTable] = useState<
    IListSpecialtiesData[]
  >([]);

  const [selectedItems, setSelectedItems] = useState<string[] | undefined>(
    undefined
  );

  return {
    createModalOpen,
    setCreateModalOpen,
    selectedRowsOnTable,
    setSelectedRowsOnTable,
    selectedItems,
    setSelectedItems,
  };
};
