import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '../../../../../Components/Table';
import { editTableStatus } from '../../../../../Utils/EditModalTableUtils';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { labelTemplatesColumns } from './LabelTemplates.column';
import { useListStates } from './Hooks/useListStates';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListMemorizations } from './Hooks/useListMemorizations';
import { useListFunctions } from './Hooks/useListFunctions';
import { useLabelTemplatesListShortcuts } from './../../../../../Components/Shortcuts/keymaps/pages/sectors/Production/LabelTemplates/list';

export const LabelTemplatesList: React.FC = () => {
  const { t } = useTranslation();

  const { selectedRowsOnTable, setSelectedRowsOnTable, selectedItems } = useListStates();

  const {
    deleteLabelTemplates,
    labelTemplatesList,
    editLabelTemplateStatus,
    isLoadingLabelTemplatesList,
    refetchLabelTemplatesList,
  } = useListCallAPI();

  const { getShortcut } = useLabelTemplatesListShortcuts(t, {});

  const { headerOptions, rowOptions, tableHeaderHotKeyTooltip, translationsEditModal, deleteModal} = useListMemorizations({
    selectedRowsOnTable,
    getShortcut,
  });

  const { onDelete, onEdit } = useListFunctions({
    setSelectedRowsOnTable,
    deleteLabelTemplates,
    editLabelTemplateStatus,
  });

  return (
    <PageLoading loading={isLoadingLabelTemplatesList}>
      <Table
        columns={labelTemplatesColumns(t)}
        loading={isLoadingLabelTemplatesList}
        headerOptions={headerOptions}
        onEdit={onEdit}
        fieldStatus='ativo'
        editOptions={editTableStatus()}
        editStatusModalLoading={editLabelTemplateStatus.isLoading}
        hasSelection
        rowsOptions={rowOptions}
        pagination={labelTemplatesList}
        onRefetch={refetchLabelTemplatesList}
        onDelete={onDelete}
        selectedRowsOnTable={selectedRowsOnTable}
        setSelectedRowsOnTable={setSelectedRowsOnTable}
        tableHeaderTooltip={tableHeaderHotKeyTooltip}
        keyToSelect="externalId"
        initialRowsSelected={selectedItems}
        deleteModal={deleteModal}
        dataTestId="production-label-templates"
        translations={translationsEditModal}
      ></Table>
    </PageLoading>
  );
};

export default LabelTemplatesList;
