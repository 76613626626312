import {
    BootysShortcuts,
    defaultShortcuts,
    DefaultShortcuts,
  } from '@/Components/Shortcuts/keymaps/default';
  import { TFunction } from 'react-i18next';
  import { history } from '../../../../../../../Store';
  import { useHotkeys } from 'reakeys';
  import { isVisible } from '@/Utils/DOMUtils';
  import { getShortcut, getShortcuts } from '@/Components/Shortcuts';

  export const pageKey = 'standardFormula-details';

  export interface StandardFormulaDetailsTabCallbacks extends DefaultShortcuts {}
  
  const shortcuts = (
    t: TFunction<'translation', undefined>,
    callbacks: StandardFormulaDetailsTabCallbacks,
  ): BootysShortcuts[] => [
    ...defaultShortcuts(t, callbacks),
    {
      id: 'editMoreActions',
      name: t('admin.editUserPage.shortcuts.moreActions'),
      keys: '.',
      action: 'keydown',
      withText: true,
      callback: (event: any) => {
        event.preventDefault();
        const element = document.querySelector(
          '[data-testid="more-actions-button"]'
        );
        if (isVisible(element)) {
          //@ts-ignore
          element?.click();
        }
      },
    },
    {
      id: 'deleteStandardFormula',
      name: t('admin.editUserPage.shortcuts.moreActions'),
      keys: 'backspace',
      action: 'keydown',
      callback: (event: any) => {
        event.preventDefault();
        const element = document.querySelector(
          '[data-testid="dropdown-option-delete"]'
        );
        if (isVisible(element)) {
          //@ts-ignore
          element?.click();
        }
      },
    },
    {
      id: 'editStandardFormula',
      name: t('production.standardFormula.list.shortcuts.editStandardFormula'),
      keys: 'e',
      action: 'keydown',
      callback: (event: any) => {
        event.preventDefault();
        const element = document.querySelector(
          '[data-testid="edit-standard-formula-button"]'
        );
        if (isVisible(element)) {
          //@ts-ignore
          element?.click();
        }
      },
    },
  ];
  export const useStandardFormulaDetailsShortcuts = (
    t: TFunction<'translation', undefined>,
    callbacks: StandardFormulaDetailsTabCallbacks,
  ) => {
    const pageShortcuts = shortcuts(t, callbacks);
    const hotkeys = useHotkeys(pageShortcuts);
    return {
      ...hotkeys,
      getShortcut: getShortcut(pageShortcuts),
      getShortcuts: getShortcuts(pageShortcuts),
    };
  };