import { useTranslation } from 'react-i18next';
import { DetailsTab } from '../../DetailsTab';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { ReleaseTab } from '../../ReleaseTab';
import { ConferenceTab } from '../../ConferenceTab';

export const useTabs = (edit: boolean, setEdit: any, inventory: any) => {
  const { t } = useTranslation();

  const tabs = [
    {
      label: t('common.details'),
      key: 'details',
      children: <DetailsTab edit={edit} setEdit={setEdit} />,
    },
    {
      label: t('common.release'),
      key: 'release',
      children: <ReleaseTab />,
      tabPermission: PermissionEnum.Estoque_Inventario_VerDetalhesConferencia,
      permissionTooltip: 'noPermission.supply.inventory.release',
    },
    {
      label: t('common.conference'),
      key: 'conference',
      children: <ConferenceTab />,
      tabPermission: PermissionEnum.Estoque_Inventario_VerDetalhesConferencia,
      permissionTooltip: 'noPermission.supply.inventory.conference',
      disabled: 
        inventory?.statusInventarioId === 3 || inventory?.statusInventarioId === 4 ? false : true
    },
  ];

  return {
    tabs,
  };
};
