import React, { CSSProperties, FC, ReactNode, useRef } from 'react';
import { Avatar as AntAvatar } from 'antd';

import {
  AvatarColors,
  AvatarColor,
  Color,
  Colors,
} from '../../Utils/ColorUtils';
import { Text, TextType } from '../Text';
import { history } from '../../Store';
import { IconName } from '../Icon/IconClasses';
import { Icon } from '../Icon';
import { ShortNameUtils } from '../../Utils/ShortNameUtils';
import { getKey } from '@/Utils/OnKeyDownKeys';

import styles from './Avatar.module.scss';
import './Avatar.override.scss';
import { Tooltip } from '../Tooltip';

type ISizes = 'SS' | 'Ss' | 'S' | 'SM' | 'M' | 'LM' | 'L' | 'XL' | 'XXL';

export interface IAvatarItem {
  type: TextType;
  color: Color;
  backgroundColor?: Color;

  fisrtName?: string;
  lastName?: string;

  routeLink?: string;

  description?: {
    type: TextType;
    description?: string;
    color: Color;
  };
  email?: {
    type: TextType;
    email: ReactNode;
    color?: Color;
  };

  image?: string;

  size: ISizes;

  className?: string;

  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;

  style?: CSSProperties;

  tabIndex?: number;

  channelAvatar?: {
    backgroundColor: Color;
    icon: IconName;
  };

  dataTestId?: string;
  hotkeyTooltip?: {
    title: ReactNode | string;
    targetOffset?: number[];
  };
}

export const Avatar: FC<IAvatarItem> = ({
  fisrtName,
  lastName,
  routeLink,
  description,
  email,
  image,
  className,
  size,
  type,
  color,
  backgroundColor,
  onClick,
  onMouseEnter,
  onMouseLeave,
  style,
  channelAvatar,
  tabIndex,
  dataTestId,
  hotkeyTooltip,
}) => {
  const currentSize = styles[size || 'M'];
  const avatarRef = useRef<HTMLDivElement | null>(null);
  return (
    <div
      className={`${styles['content-avatar']} ${
        routeLink ? styles['avatar-with-link'] : ''
      }`}
      style={style}
      onClick={() => routeLink && history.push(routeLink)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-testid={dataTestId}
    >
      <div
        tabIndex={tabIndex}
        onKeyDown={(e) => {
          if (e.key === getKey.enterKey && avatarRef.current) {
            avatarRef.current.click();
          }
        }}
      >
        <Tooltip
          showMe={!!hotkeyTooltip?.title}
          title={hotkeyTooltip?.title}
          targetOffset={hotkeyTooltip?.targetOffset}
          placement="bottomRight"
        >
          <AntAvatar
            ref={avatarRef}
            className={`${styles['avatar']} ${className} ${currentSize} `}
            style={{
              background: backgroundColor
                ? Colors[backgroundColor]
                : fisrtName &&
                  AvatarColors[
                    AvatarColor(ShortNameUtils(fisrtName, lastName))
                  ],
            }}
            onClick={() => onClick && onClick()}
            data-testid="user-menu-avatar-button"
          >
            {image ? (
              image
            ) : (
              <Text
                type={type}
                color={color}
                children={fisrtName && ShortNameUtils(fisrtName, lastName)}
              />
            )}
          </AntAvatar>
        </Tooltip>
        {channelAvatar && (
          <div
            className={styles['channel']}
            style={{ background: Colors[channelAvatar.backgroundColor] }}
            children={<Icon icon={channelAvatar.icon} size="S" color="white" />}
          />
        )}
      </div>

      {(description || email) && (
        <div
          className={`${styles['content-description']} ${
            channelAvatar ? styles['description-with-channel'] : ''
          }`}
        >
          <div>
            {description && (
              <Text
                type={description.type}
                children={description.description}
                color={description.color}
              />
            )}
            {email && (
              <Text
                type={email.type}
                children={email.email}
                color={email.color}
                style={{ marginTop: 2 }}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
