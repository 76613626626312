import React from 'react';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { useTranslation } from 'react-i18next';
import { Table } from '../../../../../Components';
import { invoiceColumn } from './invoice.columns';
import { App } from 'antd';
import { useInvoiceListStates } from './Hooks/useInvoiceListStates';
import { useInvoiceListCallAPI } from './Hooks/useInvoiceListCallAPI';
import { useInvoiceListFunctions } from './Hooks/useInvoiceListFunctions';
import { useInvoiceListMemorizations } from './Hooks/useInvoiceListMemorizations';
import StorageUtils from '@/Utils/StorageUtils';
import { useInvoiceListShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/Order/Invoice/list';

const InvoiceList: React.FC = () => {
  const { t } = useTranslation();
  const { selectedRowsOnTable, setSelectedRowsOnTable, selectedItems } =
    useInvoiceListStates();

  const { deleteInvoice, invoiceList } = useInvoiceListCallAPI();

  const { onDelete } = useInvoiceListFunctions({
    setSelectedRowsOnTable,
    deleteInvoice,
  });

  const { getShortcut } = useInvoiceListShortcuts(t);

  const { headerOptions, rowOptions, deleteModal, tableHeaderHotKeyTooltip } =
    useInvoiceListMemorizations({
      selectedRowsOnTable,
      getShortcut,
    });

  const preferences = StorageUtils.getPreference();

  const app = App.useApp();

  return (
    <PageLoading loading={invoiceList.isLoading}>
      <Table
        headerOptions={headerOptions}
        columns={invoiceColumn(t, preferences)}
        pagination={invoiceList.data}
        onRefetch={invoiceList.refetch}
        hasSelection
        loading={invoiceList.isLoading}
        rowsOptions={rowOptions}
        deleteModal={deleteModal}
        onDelete={async (invoices) => await onDelete(invoices, app)}
        selectedRowsOnTable={selectedRowsOnTable}
        setSelectedRowsOnTable={setSelectedRowsOnTable}
        tableHeaderTooltip={tableHeaderHotKeyTooltip}
        keyToSelect="externalId"
        initialRowsSelected={selectedItems}
      />
    </PageLoading>
  );
};

export default InvoiceList;
