import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '@/Components';
import { Avatar } from '@/Components/Avatar';
import { Modal } from '@/Components/Modal';
import { EditModalInfoBody } from './EditModal/editModalBody';
import { useEditCallAPI } from '../../../Hooks/useEditCallAPI';
import { useEditStates } from '../../../Hooks/useEditStates';
import { useEditFunctions } from '../../../Hooks/useEditFunctions';
import { RowsSupplierInfoCard } from './Rows';
import { useParams } from 'react-router';
import { InfoCardSchema } from './InfoCard.schema';
import { IGetSupplierResponse } from '@/Data/Interfaces/response/Purchase/ISupplierResponse';
import { Placements } from '@/Components/Tooltip';

export interface IInfoSupplierInterface {
  supplier?: IGetSupplierResponse;
  tooltip?: {
    title: React.ReactNode | string;
    placement?: Placements;
    targetOffset?: number[];
  };
  dataTestIdEditButton?: string;
}

export const InfoSupplierCard: FC<IInfoSupplierInterface> = ({
  supplier,
  tooltip,
  dataTestIdEditButton,
}) => {
  const { externalId } = useParams<{ externalId: string }>();
  const { t } = useTranslation();

  const { editCard, setEditCard } = useEditStates();

  const { editSupplierInfo, isLoadingEditSupplierInfo } = useEditCallAPI({
    externalId,
  });

  const { onEditInfoSupplier } = useEditFunctions({
    editSupplierInfo,
    supplier,
  });

  return (
    <>
      <Card
        title={
          <Avatar
            fisrtName={supplier?.nome}
            size="M"
            type="ui-tiny-bold"
            description={{
              description: supplier?.nome,
              type: 'heading4',
              color: 'text-50',
            }}
            color="white"
          />
        }
        withoutCollapse
      >
        <RowsSupplierInfoCard
          supplier={supplier}
          setEditCard={setEditCard}
          tooltip={tooltip}
          dataTestIdEditButton={dataTestIdEditButton}
        />
      </Card>

      {supplier && (
        <Modal
          schema={InfoCardSchema(supplier?.tipoPessoa)}
          visible={editCard}
          onCancelClick={() => setEditCard(false)}
          onClose={() => setEditCard(false)}
          okButtonName={
            isLoadingEditSupplierInfo ? t('common.saving') : t('common.save')
          }
          loading={isLoadingEditSupplierInfo}
          title={t('purchasing.provider.details.cards.infoCard.editDetail')}
          body={<EditModalInfoBody supplier={supplier} />}
          withForm
          widthModal={800}
          htmlType="submit"
          initialValues={supplier}
          onOkClickForm={(values) => {
            onEditInfoSupplier(values);
            setEditCard(false);
          }}
        />
      )}
    </>
  );
};
