import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_INVENTORY_RELEASE } from '@/ControllerApiHook/UniqIds/Inventory/Inventory';
import { InventoryAPI } from '@/Data/API/Supply/InventoryApit';

export const useGetInventoryRelease = (inventoryId: string) => {
  const { data: inventoryRelease } = useControllerQueryApiHook<any>({
    uniqId: FETCH_INVENTORY_RELEASE,
    entityApi: InventoryAPI.getInventoryRelease,
    externalIds: [inventoryId],
    refetchOnWindowFocus: false,
  });

  return { inventoryRelease };
};
