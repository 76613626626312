import { FC } from 'react';
import { Button, Tooltip } from '@/Components';
import { IDisapproveButton } from './interfaces';
import { useDisapproveButtonMemorizations } from './Hooks/useDisapproveButtonMemorizations';
import { useDisapproveButtonFunctions } from './Hooks/useDisapproveButtonFunctions';

export const DisapproveButton: FC<IDisapproveButton> = ({
  statusPurchaseOrder,
  setReproveModal,
  setReverseModal,
  dataTestId,
  hotkeyTooltip,
}) => {
  const { leftIcon, permission, isDisabled, children } =
    useDisapproveButtonMemorizations({
      statusPurchaseOrder,
    });

  const { onClick } = useDisapproveButtonFunctions({
    statusPurchaseOrder,
    setReproveModal,
    setReverseModal,
  });

  return (
    <>
      <Tooltip
        showMe
        title={hotkeyTooltip?.title}
        targetOffset={hotkeyTooltip?.targetOffset}
      >
        <Button
          leftIcon={leftIcon}
          type="secondary"
          permission={permission}
          style={{ width: '100%' }}
          fullWidth
          children={children}
          disabled={isDisabled}
          onClick={onClick}
          dataTestId={dataTestId}
        />
      </Tooltip>
    </>
  );
};
