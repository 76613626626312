import React, { FC, ReactNode, useState } from 'react';
import { Link, Avatar, Text, Icon, PopOver } from '../../..';
import StorageUtils from '../../../../Utils/StorageUtils';
import { useTranslation } from 'react-i18next';
import { history } from '../../../../Store';
import { CURRENT_USER } from '@/Utils/ConstantsUtils';
import { Cookies } from 'react-cookie';
import { CURRENT_USER_ROLES } from '@/Utils/PermissionUtils';
import { getKey } from '@/Utils/OnKeyDownKeys';

import styles from './UserInfo.module.scss';
import { Hotkey } from '@/Components/Shortcuts/Hotkey';
import { BootysShortcut } from '@/Components/Shortcuts';
import { useHeaderMenuShortcuts } from '@/Components/Shortcuts/keymaps/Components/HeaderMenu/headerMenu';

export const HeaderMenutitle = (
  onClick: () => void,
  name: string,
  lastName?: string,
  email?: string
) => {
  return (
    <>
      <div className={styles['divTitle']}>
        <div className={styles['divAvatar']}>
          <Avatar
            fisrtName={name}
            lastName={lastName}
            size="L"
            type="heading4"
            color="white"
          />
        </div>

        <div className={styles['divAvatarItem']}>
          <div className={styles['name']}>
            <Text type="paragraph2-bold" color="text-50" children={name} />
            <Text
              type="paragraph2-bold"
              color="text-50"
              children={` ${lastName}`}
            />
          </div>
          <Text type="paragraph2" color="text-300" children={email} />
        </div>
      </div>
    </>
  );
};

export const HeaderMenucontent = (
  onClick: () => void,
  getShortcut: (id: string) => BootysShortcut
) => {
  const { t } = useTranslation();

  useHeaderMenuShortcuts(t);

  const cookies = new Cookies();

  const manageAccountTabIndex = 54;
  const userPreferenceTabIndex = 55;
  const aboutTabIndex = 56;
  const helpTabIndex = 57;
  const logoutTabIndex = 58;

  return (
    <>
      <div>
        <div className={styles['divContentItem']}>
          <div
            className={styles['divItem']}
            onClick={() => {
              onClick();
              history.push('/account-manager/account-details');
            }}
            tabIndex={manageAccountTabIndex}
            onKeyDown={(e) => {
              if (e.key === getKey.enterKey) {
                history.push('/account-manager/account-details');
              }
            }}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginRight: 10,
            }}
            data-testid="manage-account-option"
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Icon
                className={styles['icon']}
                color="text-50"
                icon="user-mark"
                size="M"
              />
              <Link
                className={styles['itemLink']}
                type="paragraph2-bold"
                color="text-50"
                children={t('header.manageAccount')}
                to="/account-manager/account-details"
                onClick={onClick}
              />
            </div>
            <Hotkey
              hotkeys={['g', 'a']}
              height={17}
              width={17}
              fontSize={10}
              withAfterText
            />
          </div>
          <div
            className={styles['divItem']}
            onClick={() => {
              onClick();
              history.push('/user-preference/place-and-time');
            }}
            tabIndex={userPreferenceTabIndex}
            onKeyDown={(e) => {
              if (e.key === getKey.enterKey) {
                history.push('/user-preference/place-and-time');
              }
            }}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginRight: 10,
            }}
            data-testid="manage-preference-option"
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Icon
                className={styles['icon']}
                color="text-50"
                icon="multimidia-equalizer"
                size="M"
              />
              <Link
                className={styles['itemLink']}
                type="paragraph2-bold"
                color="text-50"
                children={t('header.preference')}
                to="/user-preference/place-and-time"
                onClick={onClick}
              />
            </div>
            <Hotkey
              hotkeys={['g', 'p']}
              height={17}
              width={17}
              fontSize={10}
              withAfterText
            />
          </div>
          <div className={styles['divItem']} tabIndex={aboutTabIndex}>
            <Icon
              className={styles['icon']}
              color="text-50"
              icon="exclamation-upside-down"
              size="M"
            />
            <Link
              className={styles['itemLink']}
              type="paragraph2-bold"
              color="text-50"
              children={t('header.about')}
              to=""
            />
          </div>
          <div
            className={`${styles['divItem']} ${styles['divItem-help']}`}
            tabIndex={helpTabIndex}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginRight: 10,
            }}
            data-testid="help-option"
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Icon
                className={styles['icon']}
                color="text-50"
                icon="question-mark"
                size="M"
              />
              <Link
                className={styles['itemLink']}
                type="paragraph2-bold"
                color="text-50"
                children={t('header.help')}
                to=""
              />
            </div>
            <Hotkey hotKey="?" height={17} width={17} fontSize={10} />
          </div>
        </div>
        <div
          className={`${styles['divContentItem']} ${styles['divContentItem-exit']}`}
          onClick={() => {
            cookies.remove(CURRENT_USER_ROLES, { path: '/' });
            cookies.remove(CURRENT_USER, { path: '/' });
            StorageUtils.SetRememberMe(false);
          }}
        >
          <div
            className={`${styles['divItem-exit']} ${styles['divItem']}`}
            tabIndex={logoutTabIndex}
            onKeyDown={(e) => {
              if (e.key === getKey.enterKey) {
                cookies.remove(CURRENT_USER_ROLES, { path: '/' });
                cookies.remove(CURRENT_USER, { path: '/' });
                StorageUtils.SetRememberMe(false);
              }
            }}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginRight: 10,
            }}
            data-testid="exit-option"
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Icon
                className={styles['icon']}
                color="text-50"
                icon="exit"
                size="M"
              />
              <Link
                className={styles['itemLink']}
                type="paragraph2-bold"
                color="text-50"
                children={t('header.logout')}
                to="/login"
              />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Hotkey hotKey="alt" height={17} fontSize={10} />
              <Hotkey hotKey="shift" height={17} width={23} />
              <Hotkey hotKey="q" height={17} width={17} fontSize={10} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export interface IUserInfo {
  name: string;
  lastName?: string;
  email?: string;
  hotkeyTooltip?: {
    title: ReactNode | string;
    targetOffset?: number[];
  };
  getShortcut?: (id: string) => BootysShortcut;
}

export const UserInfo: FC<IUserInfo> = ({
  name,
  lastName,
  email,
  hotkeyTooltip,
  getShortcut,
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const tabIndexHeaderAvatar = 53;
  return (
    <div className={styles['circleAvatar']}>
      <PopOver
        visible={visible}
        onOpenChange={() => setVisible(!visible)}
        placement="bottomRight"
        title={HeaderMenutitle(
          () => setVisible(!visible),
          name,
          lastName,
          email
        )}
        content={HeaderMenucontent(() => setVisible(!visible), getShortcut!)}
        trigger="click"
      >
        <Avatar
          tabIndex={tabIndexHeaderAvatar}
          size="SM"
          type="paragraph2-bold"
          color="white"
          fisrtName={name}
          lastName={lastName}
          hotkeyTooltip={hotkeyTooltip}
        />
      </PopOver>
    </div>
  );
};
