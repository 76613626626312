import {
  BootysShortcuts,
  defaultShortcuts,
} from '@/Components/Shortcuts/keymaps/default';
import { TFunction } from 'react-i18next';
import { useHotkeys } from 'reakeys';
import { isVisible } from '@/Utils/DOMUtils';
import { getShortcut, getShortcuts } from '@/Components/Shortcuts';

export const pageKey = 'customer-details';

const shortcuts = (
  t: TFunction<'translation', undefined>
): BootysShortcuts[] => [
  ...defaultShortcuts(t, {}),
  {
    id: 'editStatus',
    name: t('admin.editUserPage.shortcuts.editStatus'),
    keys: 's',
    action: 'keydown',
    withText: true,
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="status-dropdown-button"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'editStatusActive',
    name: t('admin.editUserPage.shortcuts.editStatus'),
    keys: '1',
    action: 'keydown',
    withText: true,
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="dropdown-option-active-status"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'editStatusInactive',
    name: t('admin.editCompany.shortcuts.editAddress'),
    keys: '2',
    action: 'keydown',
    withText: true,
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="dropdown-option-inactive-status"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'editMoreActions',
    name: t('admin.editUserPage.shortcuts.moreActions'),
    keys: '.',
    action: 'keydown',
    withText: true,
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="more-actions-button"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'deleteCustomer',
    name: t('admin.editUserPage.shortcuts.moreActions'),
    keys: 'backspace',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="dropdown-option-delete"]'
      );
      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'alternateTabDetails',
    name: t('client.shortcuts.goToCustomerDetails'),
    keys: 'shift+!',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="tab-customer-details"]'
      );
      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'alternateTabService',
    name: t('client.shortcuts.goToCustomerService'),
    keys: 'shift+@',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="tab-customer-service"]'
      );
      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'alternateTabSalesOrder',
    name: t('client.shortcuts.goToCustomerSalesOrder'),
    keys: 'shift+#',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="tab-customer-sale-order"]'
      );
      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'editCustomerDetails',
    name: t('purchasing.provider.shortcuts.editDetails'),
    keys: 'e+d',
    action: 'keydown',
    withText: true,
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="details-edit-button"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'editCustomerAddress',
    name: t('purchasing.provider.shortcuts.editAddress'),
    keys: 'e+a',
    action: 'keydown',
    withText: true,
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="address-edit-button"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'editCustomerContact',
    name: t('purchasing.provider.shortcuts.editContact'),
    keys: 'e+c',
    action: 'keydown',
    withText: true,
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="contact-edit-button"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'editCustomerDocument',
    name: t('purchasing.provider.shortcuts.editDocument'),
    keys: 'e+o',
    action: 'keydown',
    withText: true,
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="document-edit-button"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
];

export const useCustomerDetailsShortcuts = (
  t: TFunction<'translation', undefined>
) => {
  const pageShortcuts = shortcuts(t);

  const hotkeys = useHotkeys(pageShortcuts);

  return {
    ...hotkeys,
    getShortcut: getShortcut(pageShortcuts),
    getShortcuts: getShortcuts(pageShortcuts),
  };
};
