import { Button } from '@/Components';
import { useTranslation } from 'react-i18next';
import { useProgressFunctions } from '../Progress/Hooks/useProgressFunctions';
import { IItemChecked } from '../../Hooks/interfaces';

interface IFinishButtonProps {
  setIsVisibleModal: (value: boolean) => void;
  itemsChecked: IItemChecked[];
}

export const FinishButton: React.FC<IFinishButtonProps> = ({
  setIsVisibleModal,
  itemsChecked,
}) => {
  const { t } = useTranslation();
  const { calculateProgress } = useProgressFunctions();

  const disabled = calculateProgress(itemsChecked) < 100;

  return (
    <Button
      leftIcon={'check'}
      leftIconColor="white"
      type="primary"
      style={{ width: '100%' }}
      status="success"
      onClick={async () => {
        setIsVisibleModal(true);
      }}
      disabled={disabled}
    >
      {t('supply.inventory.release.finish')}
    </Button>
  );
};
