import { FC } from 'react';
import { Form } from '../../../../../Components';
import { useParams } from 'react-router';
import { ContactCard } from '../../../../../Components/EntityInformation/ContactCard';
import { AdressCard } from '../../../../../Components/EntityInformation/AddressCard';
import { DocumentCard } from '../../../../../Components/EntityInformation/DocumentCard';
import { PermissionEnum } from '../../../../../Data/Interfaces/model/permissionEnum';
import { InfoSupplierCard } from './Components/Cards/InfoCard';
import { useEditCallAPI } from './Hooks/useEditCallAPI';

import styles from './suppliersDetails.module.scss';
import { ContactCardSchema } from '../Create/Components/Cards/ContactCard/contactCard.schema';
import { DocumentCardSchema } from '../Create/Components/Cards/DocumentCard/documentCard.schema';
import { useSupplierDetailsShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/Order/Supplier/details';
import { useTranslation } from 'react-i18next';

export const SupplierDetailsTabForm: FC = () => {
  const { t } = useTranslation();
  const { externalId } = useParams<{ externalId: string }>();

  const {
    addAddress,
    addContact,
    addDocument,
    changeFavoriteAddress,
    changeFavoriteContact,
    deleteAddress,
    deleteContact,
    deleteDocument,
    editAddress,
    editContact,
    editDocument,
    supplier,
    documentList,
    fetchNewPageDocument,
  } = useEditCallAPI({ externalId });

  const { getShortcut } = useSupplierDetailsShortcuts(t);

  const editSupplierDetailsHotkey = getShortcut('editSupplierDetails');
  const editSupplierContactHotkey = getShortcut('editSupplierContact');
  const editSupplierAddressHotkey = getShortcut('editSupplierAddress');
  const editSupplierDocumentHotkey = getShortcut('editSupplierDocument');

  return (
    <Form>
      <>
        <div className={styles['content']}>
          <div className={styles['side-left']}>
            <InfoSupplierCard
              supplier={supplier}
              tooltip={{ title: editSupplierDetailsHotkey.tooltip }}
              dataTestIdEditButton="details-edit-button"
            />
            <ContactCard
              addEntity={addContact}
              editEntity={editContact}
              deleteEntity={deleteContact}
              entityContact={supplier?.fornecedorContatos}
              entityExternalId={supplier?.fornecedorExternalId}
              entityContactExternalIdName="fornecedorContatoExternalId"
              entityExternalIdName="fornecedorExternalId"
              changeFavorite={changeFavoriteContact}
              entityContactPermission={{
                permission: PermissionEnum.Compras_Fornecedores_EditarContatos,
                tooltip: 'noPermission.purchasing.supplier.editContact',
              }}
              schema={ContactCardSchema}
              dataTestIdEditButton="contact-edit-button"
              tooltipEditButton={{
                title: editSupplierContactHotkey.tooltip,
                targetOffset: [120, -15],
              }}
            />
          </div>
          <div className={styles['side-right']}>
            <AdressCard
              addEntity={addAddress}
              editEntity={editAddress}
              deleteEntity={deleteAddress}
              entityAdress={supplier?.fornecedorEnderecos}
              entityExternalId={supplier?.fornecedorExternalId}
              entityAddressExternalIdName="fornecedorEnderecoExternalId"
              entityExternalIdName="fornecedorExternalId"
              changeFavorite={changeFavoriteAddress}
              entityAddressPermission={{
                permission: PermissionEnum.Compras_Fornecedores_EditarEndereco,
                tooltip: 'noPermission.purchasing.supplier.editAddress',
              }}
              dataTestIdEditButton="address-edit-button"
              tooltipEditButton={{
                title: editSupplierAddressHotkey.tooltip,
                targetOffset: [270, -15],
              }}
            />
            <DocumentCard
              addEntity={addDocument}
              editEntity={editDocument}
              deleteEntity={deleteDocument}
              entityDocuments={supplier?.fornecedorDocumentos}
              entityExternalId={supplier?.fornecedorExternalId}
              entityDocumentExternalIdName="fornecedorDocumentoExternalId"
              entityExternalIdName="fornecedorExternalId"
              entityDocumentPermission={{
                permission: PermissionEnum.Compras_Fornecedores_EditarDocumento,
                tooltip: 'noPermission.purchasing.supplier.editDocument',
              }}
              documentList={documentList}
              fetchNewPageDocument={fetchNewPageDocument}
              schema={DocumentCardSchema}
              dataTestIdEditButton="document-edit-button"
              tooltipEditButton={{
                title: editSupplierDocumentHotkey.tooltip,
                targetOffset: [270, -15],
              }}
            />
          </div>
        </div>
      </>
    </Form>
  );
};

export default SupplierDetailsTabForm;
