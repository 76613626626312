import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '../../../../../Components/Table';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { capsuleColorsColumns } from './CapsuleColors.column';
import { useListStates } from './Hooks/useListStates';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListMemorizations } from './Hooks/useListMemorizations';
import { useListFunctions } from './Hooks/useListFunctions';
import { useCapsuleColorListShortcuts } from './../../../../../Components/Shortcuts/keymaps/pages/sectors/Production/CapsuleColors/list';

export const CapsuleColorsList: React.FC = () => {
  const { t } = useTranslation();

  const { selectedRowsOnTable, setSelectedRowsOnTable, selectedItems } = useListStates();

  const {
    deleteCapsuleColors,
    capsuleColorsList,
    isLoadingCapsuleColorsList,
    refetchCapsuleColorsList,
  } = useListCallAPI();

  const { getShortcut } = useCapsuleColorListShortcuts(t, {});

  const { headerOptions, rowOptions, deleteModal, tableHeaderHotKeyTooltip} = useListMemorizations({
    selectedRowsOnTable,
    getShortcut,
  });

  const { onDelete } = useListFunctions({
    setSelectedRowsOnTable,
    deleteCapsuleColors,
  });

  return (
    <PageLoading loading={isLoadingCapsuleColorsList}>
      <Table
        columns={capsuleColorsColumns(t)}
        loading={isLoadingCapsuleColorsList}
        headerOptions={headerOptions}
        hasSelection
        rowsOptions={rowOptions}
        pagination={capsuleColorsList}
        deleteModal={deleteModal}
        onRefetch={refetchCapsuleColorsList}
        onDelete={onDelete}
        selectedRowsOnTable={selectedRowsOnTable}
        setSelectedRowsOnTable={setSelectedRowsOnTable}
        tableHeaderTooltip={tableHeaderHotKeyTooltip}
        keyToSelect="externalId"
        initialRowsSelected={selectedItems}
        dataTestId="production-capsule-colors"
      ></Table>
    </PageLoading>
  );
};

export default CapsuleColorsList;
