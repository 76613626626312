import { useTranslation } from 'react-i18next';
import { UseMutationResult } from '@tanstack/react-query';
import { FC, useState } from 'react';
import { AdderList, Card, Form } from '../..';
import { ContactModal } from '../Modal';
import { ButtonCard } from '../ButtonCard';
import { IPermissionWrapper } from '../../PermissionWrapper';
import { formatPhoneNumber } from 'react-phone-number-input';
import { ObjectSchema } from 'yup';
import { Placements } from '@/Components/Tooltip';

interface IContactCard {
  addEntity: UseMutationResult<any, any, any, any>;
  editEntity: UseMutationResult<any, any, any, any>;
  deleteEntity: UseMutationResult<any, any, any, any>;
  changeFavorite: UseMutationResult<any, any, any, any>;
  entityContact?: any[];
  entityExternalId?: string;
  entityContactExternalIdName: string;
  entityExternalIdName: string;
  entityContactPermission?: IPermissionWrapper;
  schema?: ObjectSchema<any, object> | undefined;
  tooltipEditButton?: {
    title: React.ReactNode | string;
    placement?: Placements;
    targetOffset?: number[];
  };
  dataTestIdEditButton?: string;
}

export const ContactCard: FC<IContactCard> = ({
  addEntity,
  editEntity,
  deleteEntity,
  changeFavorite,
  entityContact,
  entityExternalId,
  entityContactExternalIdName,
  entityExternalIdName,
  entityContactPermission,
  schema,
  dataTestIdEditButton,
  tooltipEditButton,
}) => {
  const [editCardDisabled, setEditCardDisabled] = useState(true);
  const { t } = useTranslation();

  return (
    <Form>
      {(props) => (
        <>
          <Card title={t('contact.contacts')} withoutCollapse>
            <AdderList
              items={entityContact?.map((x) => ({
                description: [
                  x.tipoContatoId,
                  x.tipoContatoId === 2 ||
                  x.tipoContatoId === 3 ||
                  x.tipoContatoId === 4
                    ? formatPhoneNumber(x.contatoIdentificacao)
                    : x.contatoIdentificacao,
                  x.contatoObservacao === null
                    ? ''
                    : `${t('common.observation')} ${x.contatoObservacao}`,
                ],
                favorite: x.contatoPrincipal,
                values: {
                  [entityContactExternalIdName]: x.contatoExternalId,
                  tipoContatoId: x.tipoContatoId,
                  identificacao: x.contatoIdentificacao,
                  principal: x.contatoPrincipal,
                  observacao: x.contatoObservacao,
                },
              }))}
              type="contactType"
              favoriteFormName="principal"
              collum={[
                {
                  label: t('contact.contactType'),
                  width: 6,
                  key: 'tipoContatoId',
                },
                {
                  label: t('contact.contact'),
                  width: 8,
                  key: 'identificacao',
                },
                {
                  label: t('contact.observation'),
                  width: 8,
                  key: 'observacao',
                },
              ]}
              onChange={(values) => props.setFieldValue('contatos', values)}
              onDelete={async (x) =>
                x.values[entityContactExternalIdName] &&
                (await deleteEntity.mutateAsync(
                  x.values[entityContactExternalIdName]
                ))
              }
              modal={{
                editTitle: t('contact.editContact'),
                addTitle: t('contact.addContact'),
                modalFormName: ['tipoContatoId', 'identificacao', 'observacao'],
                modalFormRequiredName: ['tipoContatoId', 'identificacao'],
                body: <ContactModal />,
                okAddButtonLabel: t('common.add'),
                okEditButtonLabel: t('common.save'),
                widthModal: 600,
              }}
              buttonLabel={t('contact.addContact')}
              empytDataDescription={t('contact.contactEmpyt')}
              withoutAddButton={editCardDisabled}
              actions={!editCardDisabled}
              schema={schema}
              onAdd={async (x) =>
                entityExternalId &&
                (await addEntity.mutateAsync({
                  ...x,
                  [entityExternalIdName]: entityExternalId,
                }))
              }
              onEdit={async (x) =>
                entityExternalId &&
                (await editEntity.mutateAsync({
                  ...x,
                  [entityExternalIdName]: entityExternalId,
                }))
              }
              onChangeFavorite={async (contactExternalId, value) =>
                await changeFavorite.mutateAsync({
                  [entityContactExternalIdName]: contactExternalId,
                  principal: value,
                })
              }
              entityExternalIdName={entityContactExternalIdName}
            />
            {entityContact && (
              <ButtonCard
                type="contactType"
                disabled={editCardDisabled}
                onDisabledChange={setEditCardDisabled}
                permission={entityContactPermission}
                dataTestId={dataTestIdEditButton}
                tooltip={tooltipEditButton}
              />
            )}
          </Card>
        </>
      )}
    </Form>
  );
};
