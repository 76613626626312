import { FC } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { InputLabel } from '@/Components/Inputs/LabelTotal';
import { currencyFormater } from '@/Utils/Currency';
import { IProductsValues } from './interfaces';

export const ProductsValues: FC<IProductsValues> = ({
  productsTotal,
  productTotalWithDiscount,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Row justify="start" gutter={[16, 0]}>
        <Col span={24}>
          <InputLabel
            labelTitle={t('purchasing.purchaseOrder.create.productValues')}
            labelValue={currencyFormater(productsTotal.totalValue)}
          />
        </Col>
        <Col span={24}>
          <InputLabel
            labelTitle={t('purchasing.purchaseOrder.create.unitaryDiscount')}
            labelValue={currencyFormater(productsTotal.totalDiscount)}
          />
        </Col>
        <Col span={24}>
          <InputLabel
            labelTitle={t('purchasing.purchaseOrder.create.total')}
            labelValue={currencyFormater(productTotalWithDiscount)}
          />
        </Col>
      </Row>
    </>
  );
};
