import { useTranslation } from 'react-i18next';
import PurchaseOrderDetailsTabForm from '../Tabs';
import { TypePurchaseOrderEnum } from '@/Data/Interfaces/model/typePurchaseOrderEnum';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { history } from '@/Store';
import { IUseMemorizations } from './interfaces';
import { IButtonDropDownItems } from '@/Components/Button/ButtonDropdown';

export const useDetailsMemorizations = ({
  orderPurchase,
  setCancelModal,
  externalId,
  getShortcut,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const editPurchaseOrderHotkey = getShortcut('editPurchaseOrder');

  const items = [
    {
      key: 'details',
      children: <PurchaseOrderDetailsTabForm getShortcut={getShortcut} />,
    },
  ];

  const dropdownItems: IButtonDropDownItems | undefined = [
    {
      label: t('common.cancel'),
      icon: 'close-x',
      onClick: () => setCancelModal(true),
      targetOffsetTooltip: [35, -15],
      disabled:
        orderPurchase?.status === TypePurchaseOrderEnum.canceled ||
        orderPurchase?.status === TypePurchaseOrderEnum.disapproved ||
        orderPurchase?.status === TypePurchaseOrderEnum.confirmedWithSupplier ||
        orderPurchase?.status === TypePurchaseOrderEnum.released ||
        orderPurchase?.status === TypePurchaseOrderEnum.receivedTotal,
      messageTooltipDisabled:
        orderPurchase?.status === TypePurchaseOrderEnum.canceled
          ? t(
              'purchasing.purchaseOrder.disabledMessageTooltips.deleteCancelOrder'
            )
          : orderPurchase?.status === TypePurchaseOrderEnum.disapproved
          ? t(
              'purchasing.purchaseOrder.disabledMessageTooltips.cancelReproveOrder'
            )
          : orderPurchase?.status ===
            TypePurchaseOrderEnum.confirmedWithSupplier
          ? t(
              'purchasing.purchaseOrder.disabledMessageTooltips.cancelConfirmWithProviderOrder'
            )
          : orderPurchase?.status === TypePurchaseOrderEnum.released
          ? t('purchasing.purchaseOrder.disabledMessageTooltips.cancelReleased')
          : orderPurchase?.status === TypePurchaseOrderEnum.receivedTotal
          ? t(
              'purchasing.purchaseOrder.disabledMessageTooltips.cancelRecivedInFull'
            )
          : '',
      dataTestId: 'dropdown-option-delete',
      key: 'backspace',
    },
  ];

  const rightButton = {
    codePermissionEdit: PermissionEnum.Compras_PedidoCompra_EditarDetalhes,
    messageTooltipPermission: 'noPermission.purchasing.purchaseOrder.edit',
    targetOffsetTooltip: [-50, -10],
    isVisible: true,
    disabled:
      orderPurchase?.status === TypePurchaseOrderEnum.canceled ||
      orderPurchase?.status === TypePurchaseOrderEnum.disapproved ||
      orderPurchase?.status === TypePurchaseOrderEnum.confirmedWithSupplier ||
      orderPurchase?.status === TypePurchaseOrderEnum.released ||
      orderPurchase?.status === TypePurchaseOrderEnum.receivedTotal,
    messageTooltipDisabled:
      orderPurchase?.status === TypePurchaseOrderEnum.canceled
        ? t('purchasing.purchaseOrder.disabledMessageTooltips.editCancelOrder')
        : orderPurchase?.status === TypePurchaseOrderEnum.disapproved
        ? t('purchasing.purchaseOrder.disabledMessageTooltips.editReproveOrder')
        : orderPurchase?.status === TypePurchaseOrderEnum.confirmedWithSupplier
        ? t(
            'purchasing.purchaseOrder.disabledMessageTooltips.editConfirmWithProviderOrder'
          )
        : orderPurchase?.status === TypePurchaseOrderEnum.released
        ? t('purchasing.purchaseOrder.disabledMessageTooltips.editReleased')
        : orderPurchase?.status === TypePurchaseOrderEnum.receivedTotal
        ? t(
            'purchasing.purchaseOrder.disabledMessageTooltips.editRecivedInFull'
          )
        : '',
    onClick: () => history.push(`/order/purchase/${externalId}/edit`),
    dataTestId: 'edit-purchase-button',
    hotkeyTooltip: {
      title: editPurchaseOrderHotkey.tooltip,
    },
  };

  return {
    items,
    dropdownItems,
    rightButton,
  };
};
