import { UseMutationResult } from '@tanstack/react-query';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IAddressRequest } from '../../../Data/Interfaces/request/Address/IAddressRequenst';
import { ButtonCard } from '../ButtonCard';
import { Card, Form } from '../..';
import { AdderList } from '../../AdderList';
import { AddressModal } from '../Modal';
import { IPermissionWrapper } from '../../PermissionWrapper';
import { AdderListSchema } from '@/Components/AdderList/AdderList.schema';
import { Placements } from '@/Components/Tooltip';

interface IContactCard {
  addEntity: UseMutationResult<any, any, any, any>;
  editEntity: UseMutationResult<any, any, any, any>;
  deleteEntity: UseMutationResult<any, any, any, any>;
  changeFavorite: UseMutationResult<any, any, any, any>;
  entityExternalId?: string;
  entityAdress?: IAddressRequest[];
  entityAddressExternalIdName: string;
  entityExternalIdName: string;
  entityAddressPermission?: IPermissionWrapper;
  tooltipEditButton?: {
    title: React.ReactNode | string;
    placement?: Placements;
    targetOffset?: number[];
  };
  dataTestIdEditButton?: string;
}

export const AdressCard: FC<IContactCard> = ({
  addEntity,
  editEntity,
  deleteEntity,
  changeFavorite,
  entityExternalId,
  entityAdress,
  entityAddressExternalIdName,
  entityExternalIdName,
  entityAddressPermission,
  dataTestIdEditButton,
  tooltipEditButton,
}) => {
  const [editCardDisabled, setEditCardDisabled] = useState(true);
  const { t } = useTranslation();

  return (
    <Card title={t('address.adresses')} withoutCollapse>
      <Form>
        {(props) => (
          <>
            <AdderList
              schema={AdderListSchema}
              favoriteFormName="principal"
              items={entityAdress?.map((x) => ({
                description: [
                  x.enderecoDescricao,
                  x.logradouro,
                  x.numero,
                  x.bairro,
                  x.cidadeExternalId,
                  x.estadoExternalId,
                  x.paisExternalId,
                ],
                favorite: x.enderecoPrincipal,
                values: {
                  [entityAddressExternalIdName]: x.enderecoExternalId,
                  paisExternalId: x.paisExternalId,
                  estadoExternalId: x.estadoExternalId,
                  cidadeExternalId: x.cidadeExternalId,
                  bairro: x.bairro,
                  numero: x.numero,
                  complemento: x.complemento,
                  pontoReferencia: x.pontoReferencia,
                  principal: x.enderecoPrincipal,
                  cep: x.cep,
                  descricao: x.enderecoDescricao,
                  logradouro: x.logradouro,
                  cidadeDescricao: x.cidadeDescricao,
                  estadoDescricao: x.estadoDescricao,
                  paisDescricao: x.paisDescricao,
                  cityLabel: {
                    id: x.cidadeExternalId,
                    label: x.cidadeDescricao,
                  },
                  stateLabel: {
                    id: x.estadoExternalId,
                    label: x.estadoDescricao,
                  },
                },
              }))}
              onChange={(values) => props.setFieldValue('enderecos', values)}
              collum={[
                {
                  key: 'descricao',
                },
                {
                  key: 'logradouro',
                },
                {
                  key: 'numero',
                },
                {
                  key: 'bairro',
                },
                {
                  key: 'cidadeExternalId',
                },
                {
                  key: 'estadoExternalId',
                },
                {
                  key: 'paisExternalId',
                },
              ]}
              modal={{
                editTitle: t('address.editAddress'),
                addTitle: t('address.addAddress'),
                modalFormName: [
                  'descricao',
                  'cep',
                  'logradouro',
                  'numero',
                  'bairro',
                  'cidadeExternalId',
                  'estadoExternalId',
                  'paisExternalId',
                  'complemento',
                  'cityLabel',
                  'stateLabel',
                ],
                modalFormRequiredName: ['descricao'],
                body: <AddressModal />,
                okAddButtonLabel: t('common.add'),
                okEditButtonLabel: t('common.save'),
                widthModal: 800,
              }}
              buttonLabel={t('address.addAddress')}
              empytDataDescription={t('address.addressEmpyt')}
              type="adrressType"
              actions={!editCardDisabled}
              withoutAddButton={editCardDisabled}
              onAdd={async (x) =>
                entityExternalId &&
                (await addEntity.mutateAsync({
                  ...x,
                  [entityExternalIdName]: entityExternalId,
                }))
              }
              onEdit={async (x) =>
                entityExternalId &&
                (await editEntity.mutateAsync({
                  ...x,
                  [entityExternalIdName]: entityExternalId,
                }))
              }
              onDelete={async (x) =>
                x.values[entityAddressExternalIdName] &&
                (await deleteEntity.mutateAsync(
                  x.values[entityAddressExternalIdName]
                ))
              }
              onChangeFavorite={async (addressExternalId, value) =>
                await changeFavorite.mutateAsync({
                  [entityAddressExternalIdName]: addressExternalId,
                  principal: value,
                })
              }
              entityExternalIdName={entityAddressExternalIdName}
            />
            {entityAdress && (
              <ButtonCard
                type="adrressType"
                disabled={editCardDisabled}
                onDisabledChange={setEditCardDisabled}
                permission={entityAddressPermission}
                dataTestId={dataTestIdEditButton}
                tooltip={tooltipEditButton}
              />
            )}
          </>
        )}
      </Form>
    </Card>
  );
};
