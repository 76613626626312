import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_LIST_INVENTORY } from '@/ControllerApiHook/UniqIds/Inventory/Inventory';
import { InventoryAPI } from '@/Data/API/Supply/InventoryApit';

export const useInventoryListCallAPI = () => {
  const inventoryList = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_INVENTORY,
    entityApi: InventoryAPI.getInventoryList,
    pagination: {
      sorter: { column: 'codigo', direction: 'DESC' },
    },
  });

  return { inventoryList };
};
