import { Button, Text, Tooltip } from '@/Components';
import { PopOver } from '@/Components/PopOver';
import { Divflex } from '@/Layouts/DivWhithFlex';
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { OptionsButton } from './Components/OptionsButton';
import { FormikContextType, useFormikContext } from 'formik';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { IPostManipulatedRecipeRequest } from '@/Data/Interfaces/request/Sales/Service/IServiceRequest';
import { IconName } from '@/Components/Icon/IconClasses';
import { BaseItem, SortableList } from './Components/SortableList/SortableList';
import { SelectedComponent } from './Components/SelectedComponent';
import { SelectComponent } from './Components/SelectComponent';
import { AmountComponent } from './Components/AmountComponent';
import _ from 'lodash';

import styles from './ComponentLine.module.scss';

export enum ComponentType {
  formula = 0,
  rawMaterial = 1,
  capsuleType = 7,
}

export const ComponentTypeIcon: IconName[] = [
  'edit-file',
  'gral',
  'medicine-bottle',
  'simple-cube',
  'medical-clipboard',
  'capsules',
  'mask',
  'medicine',
];

interface IComponentsLine {
  setItems: Dispatch<SetStateAction<BaseItem[]>>;

  setComponentsEditing: Dispatch<SetStateAction<number[]>>;

  componentsEditing: number[];

  index: number;

  productPrescriptionList: any;

  validatingComponents?: boolean;
}

export const ComponentsLine: FC<IComponentsLine> = ({
  setItems,

  setComponentsEditing,

  componentsEditing,

  index,

  productPrescriptionList,

  validatingComponents,
}) => {
  const [visibleOptionButton, setVisibleOptionButton] = useState(false);
  const [visibleCreateSynonymModal, setVisibleCreateSynonymModal] =
    useState(false);

  const [editingComponent, setEditingComponent] = useState(false);
  const [editingSynonymComponent, setEditingSynonymComponent] = useState(false);

  const [showSubComponents, setShowSubComponents] = useState(false);

  const [productDescription, setProductDescription] = useState<
    string | undefined
  >(undefined);
  const [synonymousOf, setSynonymousOf] = useState<string | null>(null);

  const [componentType, setComponentType] = useState<number | undefined>(
    undefined
  );

  const amountRef = useRef<any>(null);

  const form: FormikContextType<IPostManipulatedRecipeRequest> =
    useFormikContext();
  const { t } = useTranslation();

  const lastLine: boolean = useMemo(
    () => form?.values?.itens?.length - 1 === index,
    [form.values.itens, index]
  );

  const componenti18n = useMemo(
    () => 'saleOrder.editOrder.SaleOrder.addManipulatedRecipe.components',
    []
  );

  useEffect(() => {
    !editingComponent && !lastLine && amountRef && amountRef.current.focus();
  }, [editingComponent, lastLine]);
  console.log(form);
  return (
    <>
      <Row gutter={[6, 12]} className={styles['component-input-container']}>
        {!lastLine && (
          <div
            className={styles['order-button']}
            onMouseDown={() => setShowSubComponents(false)}
          >
            <SortableList.DragHandle />
          </div>
        )}
        <Col flex={'78%'}>
          {(!lastLine && !editingComponent) || editingSynonymComponent ? (
            <SelectedComponent
              icon={
                !_.isNil(componentType)
                  ? ComponentTypeIcon[componentType]
                  : undefined
              }
              productExternalId={
                form?.values?.itens &&
                form?.values?.itens[index]?.produtoExternalId
              }
              editingSynonymComponent={editingSynonymComponent}
              setEditingSynonymComponent={setEditingSynonymComponent}
              setVisibleCreateSynonymModal={setVisibleCreateSynonymModal}
              visibleCreateSynonymModal={visibleCreateSynonymModal}
              index={index}
              form={form}
              setShowSubComponents={setShowSubComponents}
              showSubComponents={showSubComponents}
              componentType={componentType}
              productDescription={productDescription}
              setProductDescription={setProductDescription}
              synonymousOf={synonymousOf}
              setSynonymousOf={setSynonymousOf}
            />
          ) : (
            <SelectComponent
              index={index}
              setEditingComponent={setEditingComponent}
              componentsEditing={componentsEditing}
              form={form}
              productPrescriptionList={productPrescriptionList}
              focus={editingComponent}
              validatingComponents={validatingComponents}
              setSynonymousOf={setSynonymousOf}
              setComponentType={setComponentType}
              setProductDescription={setProductDescription}
            />
          )}
        </Col>
        <Col flex={'22%'}>
          <AmountComponent index={index} form={form} amountRef={amountRef} />
        </Col>
      </Row>
      {!lastLine && !editingComponent && (
        <Divflex className={styles['actions-button']}>
          <Tooltip title={t(componenti18n + '.changeProduct')} showMe>
            <Button
              type="secondary"
              rightIcon="edit"
              onClick={() => {
                setComponentsEditing((currentState) => [
                  ...currentState,
                  index,
                ]);
                setEditingComponent(true);
              }}
            />
          </Tooltip>
          <PopOver
            visible={visibleOptionButton}
            onOpenChange={() => setVisibleOptionButton(!visibleOptionButton)}
            placement="bottom"
            title={
              <Text
                type="small-text"
                color="text-300"
                style={{ padding: '16px 12px 8px 12px' }}
                children={t('saleOrder.editOrder.componentType')}
              />
            }
            content={
              <OptionsButton
                setItems={setItems}
                index={index}
                setEditingSynonymComponent={setEditingSynonymComponent}
                setVisibleOptionButton={setVisibleOptionButton}
                setVisibleCreateSynonymModal={setVisibleCreateSynonymModal}
                form={form}
                componentType={componentType}
              />
            }
            trigger="click"
          >
            <Button type="secondary" rightIcon="menu-ellipsis-horizontal" />
          </PopOver>
        </Divflex>
      )}
      {editingComponent && (
        <Tooltip title={t(componenti18n + '.cancelEdit')} showMe>
          <Button
            type="secondary"
            rightIcon="close-x"
            className={styles['cancel-edit-button']}
            onClick={() => {
              setComponentsEditing((currentState) =>
                currentState.filter((value) => value !== index)
              );
              setEditingComponent(false);
            }}
          />
        </Tooltip>
      )}
    </>
  );
};
