import { Button, Icon, Text } from '@/Components';
import { PDFViewer } from '@react-pdf/renderer';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AllItemsPdf } from '../AllItemsPdf';
import { Loading } from '@/Components/Loading';

interface IPrintAllButton {
  supplyPlaces: any[];
  inventoryConference: boolean;
  inventoryCode: string;
  startRelease: boolean;
  hideQuantityPrint?: boolean;
}

export const PrintAllButton: React.FC<IPrintAllButton> = ({
  supplyPlaces,
  inventoryConference,
  inventoryCode,
  startRelease,
  hideQuantityPrint,
}) => {
  const { t } = useTranslation();
  const [isPdfVisible, setIsPdfVisible] = useState(false);
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);

  return (
    <>
      {isPdfVisible && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            zIndex: 1033,
            background: 'white',
          }}
        >
          {isGeneratingPdf && (
            <div
              style={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '5px',
              }}
            >
              <Loading size="30px" />
              <Text type="heading1">{t('common.loading')}</Text>
            </div>
          )}
          <PDFViewer
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <AllItemsPdf
              inventoryCode={inventoryCode}
              supplyPlaces={supplyPlaces}
              onRender={() => {
                setIsGeneratingPdf(false);
              }}
              hideQuantityPrint={hideQuantityPrint}
            />
          </PDFViewer>
          <div
            style={{
              position: 'fixed',
              bottom: '10px',
              left: '0px',
              zIndex: 1034,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {!isGeneratingPdf && (
              <Button
                type="primary"
                onClick={() => {
                  setIsGeneratingPdf(true);
                  setIsPdfVisible(false);
                }}
              >
                {t('common.back')}
              </Button>
            )}
          </div>
        </div>
      )}
      <div style={{ display: startRelease ? 'none' : 'block' }}>
        <Button
          id="printAllButton"
          type="secondary"
          style={{ width: '100%' }}
          onClick={() => {
            setIsGeneratingPdf(true);
            setIsPdfVisible(true);
          }}
        >
          <>
            <Icon icon="printer" color="text-50" size="ML" />
            {inventoryConference && (
              <span style={{ marginLeft: '8px' }}>
                {t('supply.inventory.release.dropdownOptions.print')}
              </span>
            )}
          </>
        </Button>
      </div>
    </>
  );
};
