import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View, Font } from '@react-pdf/renderer';
import { useSupplyPlaceTableFunctions } from './Hooks/useSupplyPlaceTableFunctions';

interface ISupplyPlaceTable {
  supplyPlace: string;
  tableItems: any[];
}

const styles = StyleSheet.create({
  mainContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: 0,
  },
  supplyPlace: {
    width: '100%',
    padding: '-10px 20px 0px 20px',
    fontSize: '12px',
    fontFamily: 'OpenSans',
    fontWeight: 'bold',
    marginTop: '10px',
    borderBottom: '1px solid #EAEAEA',
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 20px 10px 20px',
    fontSize: '11px',
    marginBottom: '1px',
    borderBottom: '1px solid #EAEAEA',
  },
  columnNumberOne: {
    width: '40px',
    fontFamily: 'OpenSans',
    fontWeight: 'normal',
  },
  columnNumberTwo: {
    width: '260px',
    fontFamily: 'OpenSans',
    fontWeight: 'normal',
  },
  columnNumberThree: {
    width: '135px',
    fontFamily: 'OpenSans',
    fontWeight: 'normal',
  },
  columnNumberFour: {
    width: '120px',
    fontFamily: 'OpenSans',
    fontWeight: 'normal',
  },
  columnNumberFive: {
    width: '75px',
    fontFamily: 'OpenSans',
    fontWeight: 'normal',
  },
  columnNumberSix: {
    width: '96px',
    fontFamily: 'OpenSans',
    fontWeight: 'normal',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '5px 20px 5px 20px',
    fontSize: '11px',
  },
});

export const SupplyPlaceTable: React.FC<ISupplyPlaceTable> = ({
  supplyPlace,
  tableItems,
}) => {
  const { t } = useTranslation();
  const { formatString } = useSupplyPlaceTableFunctions();

  return (
    <View style={{ marginBottom: '20px' }}>
      <Text style={styles.supplyPlace}>
        {t('supply.inventory.pdf.supplyPlace')}: {supplyPlace}
      </Text>
      <View style={styles.tableHeader}>
        <Text style={styles.columnNumberOne}>
          {t('supply.inventory.release.code')}
        </Text>
        <Text style={styles.columnNumberTwo}>
          {t('supply.inventory.release.product')}
        </Text>
        <Text style={styles.columnNumberThree}>
          {t('supply.inventory.release.lot')}
        </Text>
        <Text style={styles.columnNumberFour}>
          {t('supply.inventory.release.invoice')}
        </Text>
        <Text style={styles.columnNumberFive}>
          {t('supply.inventory.release.quantity')}
        </Text>
        <Text style={styles.columnNumberSix}>
          {t('supply.inventory.release.count')}
        </Text>
      </View>
      {tableItems.map((item, index) => {
        return (
          <View
            style={{
              ...styles.tableRow,
              backgroundColor: index % 2 === 0 ? 'white' : '#F3F3F3',
            }}
            key={index}
          >
            <Text 
              style={{
                ...styles.columnNumberOne,
                flexWrap: 'wrap',
              }}
            >
              {String(`${item.produtoCodigo}`).padStart(3, '0')}
            </Text>
            <View
              style={{
                flexDirection: 'row',
                flexWrap: 'wrap',
                width: '260px',
              }}
            >
              {item.produtoDescricao
                ?.match(/\w+|\W+/g)
                ?.map((seg: string, i: number) => (
                  <Text
                    style={{
                      ...styles.columnNumberTwo,
                      width: 'auto',
                      fontWeight: 'bold',
                    }}
                    key={i}
                  >
                    {seg}
                  </Text>
                ))}
            </View>

            <View style={{ width: '135px' }}>
              <Text
                style={{
                  ...styles.columnNumberThree,

                  maxWidth: '80px',
                }}
              >
                {item.loteNumero}
              </Text>
            </View>

            <View style={{ width: '120px' }}>
              <Text
                style={{
                  ...styles.columnNumberFour,
                  width: '90px',
                  flexWrap: 'wrap',
                }}
              >
                {formatString(item.notaFiscalNumero)}
              </Text>
            </View>
            <View
              style={{
                width: '75px',
              }}
            >
              <Text
                style={{
                  ...styles.columnNumberFive,
                  flexWrap: 'wrap',
                  width: '75%',
                }}
              >
                {item.quantidade}
                {item.unidadeMedidaAbreviacao}
              </Text>
            </View>

            <Text
              style={{
                ...styles.columnNumberSix,
                flexWrap: 'wrap',
              }}
            >
              {item.quantidadeInventariada}
              {item.unidadeMedidaAbreviacao}
            </Text>
          </View>
        );
      })}
    </View>
  );
};

Font.registerHyphenationCallback((word) => {
  const newWord = word.split('');
  return newWord;
});
