import { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Label,
  Text,
  Tooltip,
} from '../../../../../../../../../Components';
import { Col, Row } from 'antd';
import {
  MaskForCNPJ,
  MaskForCPF,
} from '../../../../../../../../../Utils/MasksUtils';
import { TypePersonEnum } from '../../../../../../../../../Data/Interfaces/model/typePersonEnum';
import { PermissionEnum } from '../../../../../../../../../Data/Interfaces/model/permissionEnum';
import { Placements } from '@/Components/Tooltip';

export interface IRowsSupplierInfoCard {
  supplier?: any;
  setEditCard: Dispatch<SetStateAction<boolean>>;
  dataTestIdEditButton?: string;
  tooltip?: {
    title: React.ReactNode | string;
    placement?: Placements;
    targetOffset?: number[];
  };
}

export const RowsSupplierInfoCard: FC<IRowsSupplierInfoCard> = ({
  supplier,
  setEditCard,
  tooltip,
  dataTestIdEditButton,
}) => {
  const { t } = useTranslation();

  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <Label
          type="ui-tiny-content"
          color="text-200"
          children={t('purchasing.provider.details.cards.infoCard.personType')}
          withBottomMargin
        />
        <Text
          type="ui-tiny-content"
          color="text-50"
          children={`${t('common.people')} ${supplier?.tipoPessoaDescricao}`}
        />
      </Col>
      {supplier?.tipoPessoa === TypePersonEnum.juridical_person ? (
        <Col span={24}>
          <Label
            type="ui-tiny-content"
            color="text-200"
            children={t('purchasing.provider.details.cards.infoCard.cnpj')}
            withBottomMargin
          />
          <Text
            type="ui-tiny-content"
            color="text-50"
            children={MaskForCNPJ(supplier?.cnpj)}
          />
        </Col>
      ) : (
        <Col span={24}>
          <Label
            type="ui-tiny-content"
            color="text-200"
            children={t('purchasing.provider.details.cards.infoCard.cpf')}
            withBottomMargin
          />
          <Text
            type="ui-tiny-content"
            color="text-50"
            children={MaskForCPF(supplier?.cpf)}
          />
        </Col>
      )}
      {supplier?.tipoPessoa === TypePersonEnum.juridical_person ? (
        <Col span={24}>
          <Label
            type="ui-tiny-content"
            color="text-200"
            children={t(
              'purchasing.provider.details.cards.infoCard.corporateName'
            )}
            withBottomMargin
          />
          <Text
            type="ui-tiny-content"
            color="text-50"
            children={supplier?.razaoSocial}
          />
        </Col>
      ) : (
        <></>
      )}
      <Col span={24}>
        <Label
          type="ui-tiny-content"
          color="text-200"
          children={t(
            'purchasing.provider.details.cards.infoCard.supplierType'
          )}
          withBottomMargin
        />
        <Text
          type="ui-tiny-content"
          color="text-50"
          children={supplier?.tipoFornecedorDescricao}
        />
      </Col>
      <Col span={24}>
        <Label
          type="ui-tiny-content"
          color="text-200"
          children={t(
            'purchasing.provider.details.cards.infoCard.observations'
          )}
          withBottomMargin
        />
        <Text
          type="ui-tiny-content"
          color="text-50"
          children={supplier?.observacao}
        />
      </Col>
      <Tooltip
        showMe
        placement={tooltip?.placement}
        targetOffset={tooltip?.targetOffset}
        title={tooltip?.title}
      >
        <Button
          type="secondary"
          children={t('common.editDetails')}
          onClick={() => setEditCard(true)}
          permission={{
            permission: PermissionEnum.Compras_Fornecedores_EditarDetalhes,
            tooltip: 'noPermission.purchasing.supplier.edit',
          }}
          dataTestId={dataTestIdEditButton}
        />
      </Tooltip>
    </Row>
  );
};
