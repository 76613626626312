import {
  BootysShortcuts,
  defaultShortcuts,
  DefaultShortcuts,
} from '@/Components/Shortcuts/keymaps/default';
import { TFunction } from 'react-i18next';
import { history } from '../../../../../../../Store';
import { useHotkeys } from 'reakeys';
import { isVisible } from '@/Utils/DOMUtils';
import { getShortcut, getShortcuts } from '@/Components/Shortcuts';

export const pageKey = 'product-details';

const shortcuts = (
  t: TFunction<'translation', undefined>,
  externalId: string
): BootysShortcuts[] => [
  ...defaultShortcuts(t, externalId),
  {
    id: 'editStatus',
    name: t('admin.editUserPage.shortcuts.editStatus'),
    keys: 's',
    action: 'keydown',
    withText: true,
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="status-dropdown-button"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'editStatusActive',
    name: t('admin.editUserPage.shortcuts.editStatus'),
    keys: '1',
    action: 'keydown',
    withText: true,
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="dropdown-option-active-status"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'editStatusInactive',
    name: t('admin.editCompany.shortcuts.editAddress'),
    keys: '2',
    action: 'keydown',
    withText: true,
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="dropdown-option-inactive-status"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'editMoreActions',
    name: t('admin.editUserPage.shortcuts.moreActions'),
    keys: '.',
    action: 'keydown',
    withText: true,
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="more-actions-button"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'deleteProduct',
    name: t('admin.editUserPage.shortcuts.moreActions'),
    keys: 'backspace',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="dropdown-option-delete"]'
      );
      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'editProduct',
    name: t('products.productList.shortcuts.editProduct'),
    keys: 'e',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="edit-product-button"]'
      );
      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'editProductFinance',
    name: t('products.productList.shortcuts.editProductFinance'),
    keys: 'e',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="edit-product-button"]'
      );
      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'alternateTabDetails',
    name: t('products.productList.shortcuts.goToProductDetails'),
    keys: 'shift+!',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="tab-user-details"]'
      );
      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'alternateTabDetailsNumberVariation',
    name: t('products.productList.shortcuts.goToProductDetails'),
    keys: 'shift+1',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="tab-user-details"]'
      );
      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'alternateTabFinance',
    name: t('products.productList.shortcuts.goToProductFinance'),
    keys: 'shift+@',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="tab-user-finance"]'
      );
      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'alternateTabFinanceNumberVariation',
    name: t('products.productList.shortcuts.goToProductFinance'),
    keys: 'shift+2',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="tab-user-finance"]'
      );
      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'alternateTabStock',
    name: t('products.productList.shortcuts.goToProductStock'),
    keys: 'shift+#',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector('[data-testid="tab-user-stock"]');
      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'alternateTabStockNumberVariation',
    name: t('products.productList.shortcuts.goToProductStock'),
    keys: 'shift+3',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector('[data-testid="tab-user-stock"]');
      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'alternateTabProductMessage',
    name: t('products.productList.shortcuts.goToProductMessage'),
    keys: 'shift+$',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="tab-user-product-message"]'
      );
      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'alternateTabProductMessageNumberVariation',
    name: t('products.productList.shortcuts.goToProductMessage'),
    keys: 'shift+4',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="tab-user-product-message"]'
      );
      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'alternateTabAssociated',
    name: t('products.productList.shortcuts.goToAssociated'),
    keys: 'shift+%',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      history.push(`/inventory/products/${externalId}/associated`);
    },
  },
  {
    id: 'alternateTabDiluted',
    name: t('products.productList.shortcuts.goToDiluted'),
    keys: 'shift+ˆ',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      history.push(`/inventory/products/${externalId}/diluted`);
    },
  },
  {
    id: 'alternateTabIncompatible',
    name: t('products.productList.shortcuts.goToIncompatible'),
    keys: 'shift+&',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      history.push(`/inventory/products/${externalId}/incompatible`);
    },
  },
  {
    id: 'alternateTabSynonym',
    name: t('products.productList.shortcuts.goToSynonym'),
    keys: 'shift+*',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      history.push(`/inventory/products/${externalId}/synonym`);
    },
  },
];

export const useProductDetailsShortcuts = (
  t: TFunction<'translation', undefined>,
  externalId: string
) => {
  const pageShortcuts = shortcuts(t, externalId);

  const hotkeys = useHotkeys(pageShortcuts);

  return {
    ...hotkeys,
    getShortcut: getShortcut(pageShortcuts),
    getShortcuts: getShortcuts(pageShortcuts),
  };
};
