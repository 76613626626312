import {
  BootysShortcuts,
  defaultShortcuts,
} from '@/Components/Shortcuts/keymaps/default';
import { TFunction } from 'react-i18next';
import { useHotkeys } from 'reakeys';
import { isVisible } from '@/Utils/DOMUtils';
import { getShortcut, getShortcuts } from '@/Components/Shortcuts';

export const pageKey = 'admin-company-details';

const shortcuts = (
  t: TFunction<'translation', undefined>
): BootysShortcuts[] => [
  ...defaultShortcuts(t, {}),
  {
    id: 'editStatus',
    name: t('admin.editUserPage.shortcuts.editStatus'),
    keys: 's',
    action: 'keydown',
    withText: true,
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="status-dropdown-button"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'editStatusActive',
    name: t('admin.editUserPage.shortcuts.editStatus'),
    keys: '1',
    action: 'keydown',
    withText: true,
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="dropdown-option-active-status"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'editStatusInactive',
    name: t('admin.editCompany.shortcuts.editAddress'),
    keys: '2',
    action: 'keydown',
    withText: true,
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="dropdown-option-inactive-status"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'editMoreActions',
    name: t('admin.editUserPage.shortcuts.moreActions'),
    keys: '.',
    action: 'keydown',
    withText: true,
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="more-actions-button"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'deleteSupplier',
    name: t('admin.editUserPage.shortcuts.moreActions'),
    keys: 'backspace',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="dropdown-option-delete"]'
      );
      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'alternateTabDetails',
    name: t('adminBootis.shortcuts.goToCompanyDetails'),
    keys: 'shift+!',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="company-details-tab"]'
      );
      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'editInfo',
    name: t('adminBootis.shortcuts.editData'),
    keys: 'e+d',
    action: 'keydown',
    withText: true,
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="data-edit-button"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'editAddress',
    name: t('adminBootis.shortcuts.editAddress'),
    keys: 'e+a',
    action: 'keydown',
    withText: true,
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="address-edit-button"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'editUser',
    name: t('adminBootis.shortcuts.editUser'),
    keys: 'e+u',
    action: 'keydown',
    withText: true,
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="responsible-edit-button"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'editBillings',
    name: t('adminBootis.shortcuts.editBilling'),
    keys: 'e+f',
    action: 'keydown',
    withText: true,
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="billings-edit-button"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
];

export const useAdminCompanyDetailsShortcuts = (
  t: TFunction<'translation', undefined>
) => {
  const pageShortcuts = shortcuts(t);

  const hotkeys = useHotkeys(pageShortcuts);

  return {
    ...hotkeys,
    getShortcut: getShortcut(pageShortcuts),
    getShortcuts: getShortcuts(pageShortcuts),
  };
};
