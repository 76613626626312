import { useFormikContext } from 'formik';

export const useCalculateDeference = (index: number, initial: number) => {
  const form: any = useFormikContext();

  const calculateDeference = (unit: string) => {
    if (
      !form.values.itens ||
      !form.values.itens[index] ||
      !form.values.itens[index].quantidadeInventariada
    ) {
      return 0 + unit;
    }

    const quantity = form.values.itens[index].quantidadeInventariada;
    const deference = quantity - initial;

    if (deference === 0) {
      return 0 + unit;
    }

    const prefix = deference > 0 ? '+' : '';

    return prefix + deference + unit;
  };

  const defineColor = () => {
    if (
      !form.values.itens ||
      !form.values.itens[index] ||
      !form.values.itens[index].quantidadeInventariada
    ) {
      return 'text-300';
    }

    const quantity = form.values.itens[index].quantidadeInventariada;
    const deference = quantity - initial;

    if (deference === 0) {
      return 'text-300';
    }

    if (deference > 0) {
      return 'status-success-base';
    }

    return 'status-danger-base';
  };

  return { calculateDeference, defineColor };
};
