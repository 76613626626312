import { IUseFunctions } from './interfaces';
import { ICreateLabelTemplates } from '@/Data/Interfaces/request/LabelTemplates/ILabelTemplatesRequest';

export const useCreateFunctions = ({
  createLabelTemplate,
}: IUseFunctions) => {
  const onSubmit = async (values: ICreateLabelTemplates) => {
    const body: ICreateLabelTemplates = { ...values };
    return await createLabelTemplate({
      descricao: body?.descricao,
      tipoRotulo: body?.tipoRotulo,
      ativo: true,
    });
  };

  return { onSubmit };
};
