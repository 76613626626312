import { Dispatch, FC, SetStateAction } from 'react';
import { HeaderRecipeDescription } from './Components/HeaderRecipeDescription';
import { ComponentSelection } from './Components/ComponentSelection';

import styles from './AddManipulatedRecipe.module.scss';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { SalesOrderAPI } from '@/Data/API/Sales/SalesOrderApi';
import { GET_MANIPULATED_RECIPE } from '@/ControllerApiHook/UniqIds/Sales/SalesOrderKeys';
import { IGetManipulatedRecipeResponse } from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import { useFormikContext } from 'formik';

interface IAddManipulatedRecipeModalBody {
  setComponentsEditing: Dispatch<SetStateAction<number[]>>;
  componentsEditing: number[];

  validatingComponents: boolean;
  manipulatedRecipeExternalId?: string;
}

export const AddManipulatedRecipeModalBody: FC<
  IAddManipulatedRecipeModalBody
> = ({
  setComponentsEditing,
  componentsEditing,
  validatingComponents,
  manipulatedRecipeExternalId,
}) => {
  const form = useFormikContext();
  const { data: manipulatedRecipeData } =
    useControllerQueryApiHook<IGetManipulatedRecipeResponse>({
      entityApi: SalesOrderAPI.getManipulatedRecipe,
      uniqId: GET_MANIPULATED_RECIPE,
      externalIds: [manipulatedRecipeExternalId],
      onSuccess: (data) => form.setValues(data),
    });
  return (
    <div
      className={styles['container']}
      id="add-manipulated-recipe-dropdown-components-container"
    >
      <HeaderRecipeDescription />
      <ComponentSelection
        setComponentsEditing={setComponentsEditing}
        componentsEditing={componentsEditing}
        validatingComponents={validatingComponents}
      />
    </div>
  );
};
