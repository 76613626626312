import { useMemo } from 'react';
import { AddManipulatedRecipeModalBody } from '../Modals/AddManipulatedRecipe';
import { DetailsManipulatedRecipeModalBody } from '../Modals/DetailsManipulatedRecipe/DetailsManipulatedRecipeModalBody';
import { IUseManipulatedRecipeMemorizations } from './interfaces';
import { v4 as uuidV4 } from 'uuid';

export const useManipulatedRecipeMemorizations = ({
  componentsEditing,
  setComponentsEditing,
  validatingComponents,

  client,
  manipulatedRecipeExternalId,
}: IUseManipulatedRecipeMemorizations) => {
  const steps = useMemo(
    () => [
      <AddManipulatedRecipeModalBody
        setComponentsEditing={setComponentsEditing}
        componentsEditing={componentsEditing}
        validatingComponents={validatingComponents}
        manipulatedRecipeExternalId={manipulatedRecipeExternalId}
      />,
      <DetailsManipulatedRecipeModalBody
        manipulatedRecipeExternalId={manipulatedRecipeExternalId}
      />,
    ],
    [
      componentsEditing,
      validatingComponents,
      setComponentsEditing,
      manipulatedRecipeExternalId,
    ]
  );

  const initialValues = {
    pacienteExternalId: client?.externalId,
    itens: [{ id: uuidV4() }],
    pacienteExternalIdInitialValue: {
      id: client?.externalId,
      label: client?.name,
      avatar: { name: client?.name },
    },
  };

  return { steps, initialValues };
};
