import { Icon, Text } from '@/Components';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { ConferenceButtons } from '../TableRow/Components/ConferenceButtons';

interface ITableHeaderProps {
  orientation: string;
  toggleOrientation: () => void;
  hideQuantityColumns: boolean;
  isConferenceStatus: boolean;
}

export const TableHeader: React.FC<ITableHeaderProps> = ({
  orientation,
  toggleOrientation,
  hideQuantityColumns,
  isConferenceStatus,
}) => {
  const { t } = useTranslation();
  return (
    <Row gutter={[16, 0]} style={{ paddingLeft: '10px' }}>
      <Col span={2}>
        <Text type="paragraph-bold">{t('supply.inventory.release.code')}</Text>
      </Col>
      <Col span={isConferenceStatus ? 9 : 11}>
        <Text type="paragraph-bold">
          {t('supply.inventory.release.product')} {'    '}
          <div style={{ cursor: 'pointer' }} onClick={toggleOrientation}>
            <Icon
              color="black"
              icon={`arrow-${orientation === 'asc' ? 'up' : 'down'}`}
              size="ML"
            />
          </div>
        </Text>
      </Col>
      <Col span={4}>
        <Text type="paragraph-bold">{t('supply.inventory.release.lot')}</Text>
      </Col>
      {hideQuantityColumns == false && (
        <Col span={3}>
          <Text type="paragraph-bold">
            {t('supply.inventory.release.quantity')}
          </Text>
        </Col>
      )}
      <>
        <Col span={2}>
          <Text type="paragraph-bold">
            {' '}
            {t('supply.inventory.release.count')}
          </Text>
        </Col>
        {hideQuantityColumns == false && (
          <Col span={2}>
            <Text type="paragraph-bold">
              {t('supply.inventory.release.difference')}
            </Text>
          </Col>
        )}
      </>
      {isConferenceStatus && (
        <Col span={2}>
          <ConferenceButtons isStatic />
        </Col>
      )}
    </Row>
  );
};
