import { useCallback } from 'react';
import {
  FETCH_GET_ORDER_PURCHASE,
  FETCH_LIST_PURCHASE_ORDER,
} from '@/ControllerApiHook/UniqIds/Purchase/PurchaseOrderKeys';
import { history } from '@/Store';
import { useQueryClient } from '@tanstack/react-query';
import DateUtils from '@/Utils/DateUtils';
import { isEqualByProps } from '@/Utils/CompareEqualObj';
import { NotificationActions } from '@/Store/Notification/Notification.actions';
import { useDispatch } from 'react-redux';
import { FormikHelpers, FormikValues, useFormikContext } from 'formik';
import StorageUtils from '@/Utils/StorageUtils';
import { IUseFunctions } from './interfaces';

export const useCreateFunctions = ({
  createOrderPurchase,
  purchaseOrder,
  isEdit,
  setIsEdit,
  savePostDraftOrderPurchase,
  savePutDraftOrderPurchase,
  externalId,
  setOpenDeleteDraftModal,
}: IUseFunctions) => {
  const preferences = StorageUtils.getPreference();
  const queryClient = useQueryClient();
  const defaultDiscountType = 2;
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    async (values: any, helpers: FormikHelpers<FormikValues>) => {
      try {
        const body: any = { ...values };
        if (!preferences) return;
        if (body.previsaoEntrega)
          body.previsaoEntrega = DateUtils.convertDateToApi(
            body.previsaoEntrega,
            preferences.padraoData
          );
        const res = await createOrderPurchase.mutateAsync({
          externalId: purchaseOrder?.externalId
            ? purchaseOrder?.externalId
            : body.externalId,
          fornecedorExternalId: body.fornecedorExternalId,
          previsaoEntrega: body.previsaoEntrega || null,
          observacao: body.observacao,
          frete: body.frete,
          valorAdicional: body.valorAdicional,
          tipoDesconto: body.tipoDesconto ?? defaultDiscountType,
          valorDesconto: body.valorDesconto ?? 0,
          pedidoCompraItens: values.pedidoCompraItens.map((x: any) => ({
            produtoExternalId: x.produtoExternalId,
            quantidade: x.quantidade,
            unidadeMedidaId: x.unidadeMedidaId,
            precoUnitario: x.precoUnitario,
            valorDescontoUnitario: x.valorDescontoUnitario ?? 0,
            tipoDesconto: x.tipoDescontoId ?? defaultDiscountType,
          })),
        });
        if (res.success) {
          dispatch(NotificationActions.setCurrentFormIsDirty(false));
          setTimeout(() => {
            history.push('/order/purchase');
          }, 1);
          queryClient.invalidateQueries([FETCH_LIST_PURCHASE_ORDER]);
        }
      } catch {
      } finally {
        helpers.setSubmitting(false);
      }
    },
    [createOrderPurchase, purchaseOrder, preferences, externalId]
  );

  const onSaveDraft = useCallback(
    async (values: any, form: any) => {
      const body: any = { ...values };
      if (!preferences) return;
      if (body.previsaoEntrega)
        body.previsaoEntrega = DateUtils.convertDateToApi(
          body.previsaoEntrega,
          preferences.padraoData
        );

      const res =
        isEdit === true || !!externalId
          ? await savePutDraftOrderPurchase.mutateAsync({
              externalId: externalId,
              fornecedorExternalId: body.fornecedorExternalId,
              previsaoEntrega: body.previsaoEntrega || null,
              observacao: body.observacao,
              frete: body.frete,
              valorAdicional: body.valorAdicional,
              tipoDesconto: body.tipoDesconto ?? defaultDiscountType,
              valorDesconto: body.valorDesconto,
              pedidoCompraItens: body?.pedidoCompraItens[0]?.produtoExternalId
                ? values?.pedidoCompraItens?.map((x: any) => ({
                    pedidoCompraItemExternalId: x.pedidoCompraItemExternalId,
                    produtoExternalId: x.produtoExternalId,
                    quantidade: x.quantidade,
                    unidadeMedidaId: x.unidadeMedidaId,
                    precoUnitario: x.precoUnitario,
                    valorDescontoUnitario: x.valorDescontoUnitario,
                    tipoDesconto: x.tipoDescontoId ?? defaultDiscountType,
                  }))
                : [],
            })
          : await savePostDraftOrderPurchase.mutateAsync({
              externalId: body.externalId,
              fornecedorExternalId: body.fornecedorExternalId,
              previsaoEntrega: body.previsaoEntrega || null,
              observacao: body.observacao,
              frete: body.frete,
              valorAdicional: body.valorAdicional,
              tipoDesconto: body.tipoDescontoId,
              valorDesconto: body.valorDesconto,
              pedidoCompraItens: body?.pedidoCompraItens[0]?.produtoExternalId
                ? values?.pedidoCompraItens?.map((x: any) => ({
                    pedidoCompraItemExternalId: x.pedidoCompraItemExternalId,
                    produtoExternalId: x.produtoExternalId,
                    quantidade: x.quantidade,
                    unidadeMedidaId: x.unidadeMedidaId,
                    precoUnitario: x.precoUnitario,
                    valorDescontoUnitario: x.valorDescontoUnitario,
                    tipoDesconto: x.tipoDescontoId ?? defaultDiscountType,
                  }))
                : [],
            });
      if (res.success) {
        dispatch(NotificationActions.setCurrentFormIsDirty(false));
        setTimeout(() => {
          history.push(
            `/order/purchase/create/${res.externalId ?? externalId}/draft`
          );
        }, 1);
        setIsEdit(true);
        queryClient.invalidateQueries([
          FETCH_GET_ORDER_PURCHASE,
          res.externalId ?? externalId,
        ]);
        queryClient.invalidateQueries([FETCH_LIST_PURCHASE_ORDER]);
      }
    },
    [
      preferences,
      externalId,
      savePostDraftOrderPurchase,
      savePutDraftOrderPurchase,
      isEdit,
      setIsEdit,
    ]
  );

  const isEqual = useCallback(
    (form: any) => {
      return isEqualByProps(
        {
          ...form.values,
          frete: form.values.frete === undefined ? 0 : form.values.frete,
          valorAdicional:
            form.values.valorAdicional === undefined
              ? 0
              : form.values.valorAdicional,
          valorDesconto:
            form.values.valorDesconto === undefined
              ? 0
              : form.values.valorDesconto,
          pedidoCompraItens: form?.values?.pedidoCompraItens
            ?.slice(0, -1)
            .map((item: any) => ({
              ...item,
              valorDescontoUnitario:
                item.valorDescontoUnitario === undefined
                  ? 0
                  : item.valorDescontoUnitario,
            })),
        },
        purchaseOrder,
        [
          { name: 'fornecedorExternalId', type: 'string' },
          { name: 'previsaoEntrega', type: 'date' },
          { name: 'observacao', type: 'string' },
          { name: 'frete', type: 'number' },
          { name: 'valorAdicional', type: 'number' },
          { name: 'valorDesconto', type: 'number' },
          { name: 'tipoDescontoId', type: 'number' },
          {
            name: 'pedidoCompraItens',
            type: 'array',
            keys: [
              'produtoExternalId',
              'quantidade',
              'unidadeMedidaId',
              'precoUnitario',
              'valorDescontoUnitario',
              'tipoDescontoId',
            ],
          },
        ]
      );
    },
    [purchaseOrder]
  );

  const onClickCancelButton = () => history.push('/order/purchase');

  const changeVisibleDeleteDraftModal = (value: boolean) =>
    setOpenDeleteDraftModal(value);

  return {
    onSubmit,
    onSaveDraft,
    isEqual,
    onClickCancelButton,
    changeVisibleDeleteDraftModal,
  };
};
