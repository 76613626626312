import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { FETCH_LIST_FOR_DROPDOWN_GROUPS } from '@/ControllerApiHook/UniqIds/Supply/InventoryKeys';
import { InventoryAPI } from '@/Data/API/Supply/InventoryApit';

export const useListSubgroups = () => {
  const {
    data,
    fetchNewPage: fetchNewSubgroupsPage,
    refetch,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_FOR_DROPDOWN_GROUPS,
    entityApi: InventoryAPI.listDropdownSubgroups,
    autoIncrementCustomId: 'subGrupoExternalId',
    autoIncrement: true,
    pagination: {
      pageIndex: 1,
      pageSize: 10,
    },
  });

  const refetchPageSubGroup = (supplyPlaceId: string, groupId: string) => {
    if (!supplyPlaceId || !groupId) return;
    refetch({
      pageIndex: 1,
      pageSize: 10,
      filter: [
        { filterName: 'LocalEstoqueExternalId', value: supplyPlaceId },
        { filterName: 'GrupoExternalId', value: groupId },
      ],
    });
  };

  return {
    subgroupsData:
      data && data.data
        ? data.data.map((e: any) => {
            return {
              id: e.subGrupoExternalId,
              label: e.subGrupoDescricao,
            };
          })
        : [],
    fetchNewSubgroupsPage,
    refetchPageSubGroup,
  };
};
