import { useTranslation } from 'react-i18next';
import { DetailsTab } from '../Tabs/Details';
import { PrescribersTab } from '../Tabs/Prescribers';
import { IUseMemorizations } from './interfaces';
import { useMemo } from 'react';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { IButtonDropDownItems } from '@/Components/Button/ButtonDropdown';

export const useEditMemorizations = ({
  disabledForm,
  setDisabledForm,
  setVisibleModal,
  getShortcut,
  params,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const alternateTabDetailsHotkey = getShortcut('alternateTabDetails');
  const alternateTabPrescribersHotkey = getShortcut('alternateTabPrescribers');
  const editExpertiseHotkey = getShortcut('editExpertise');

  const items = useMemo(
    () => [
      {
        label: t('common.details'),
        key: 'details',
        children: (
          <DetailsTab
            disabledForm={disabledForm}
            onDisabledForm={() => setDisabledForm(true)}
            toogleDisabled={(x: boolean) => setDisabledForm(x)}
          />
        ),
        dataTestId: 'tab-expertise-details',
        shortCutTooltip: {
          title: alternateTabDetailsHotkey.tooltip,
          targetOffset: [0, 10],
        },
      },
      {
        label: t('specialties.edit.prescribers'),
        key: 'prescribers',
        children: <PrescribersTab />,
        dataTestId: 'tab-expertise-prescribers',
        shortCutTooltip: {
          title: alternateTabPrescribersHotkey.tooltip,
          targetOffset: [0, 10],
        },
      },
    ],
    [disabledForm, setDisabledForm, t]
  );

  const rightButton = {
    ...(disabledForm &&
      params.tab === 'details' && {
        onClick: () => setDisabledForm(false),
        isVisible: disabledForm,
        codePermissionEdit:
          PermissionEnum.Vendas_EspecialidadePrescritor_EditarDetalhes,
        messageTooltipPermission: t('noPermission.sale.specialties.edit'),
        dataTestId: 'edit-expertise-button',
        hotkeyTooltip: {
          title: editExpertiseHotkey.tooltip,
        },
      }),
  };

  const dropdownItems: IButtonDropDownItems | undefined = [
    {
      label: 'Excluir',
      icon: 'trash',
      onClick: () => setVisibleModal(true),
      permission: {
        permission: PermissionEnum.Vendas_EspecialidadePrescritor_Excluir,
        tooltip: 'noPermission.sale.specialties.delete',
      },
      dataTestId: 'dropdown-option-delete',
      key: 'backspace',
    },
  ];

  return {
    items,
    rightButton,
    dropdownItems,
  };
};
