import {
  BootysShortcuts,
  defaultShortcuts,
} from '@/Components/Shortcuts/keymaps/default';
import { TFunction } from 'react-i18next';
import { useHotkeys } from 'reakeys';
import { isVisible } from '@/Utils/DOMUtils';
import { getShortcut, getShortcuts } from '@/Components/Shortcuts';

export const pageKey = 'purchase-order-details';

const shortcuts = (
  t: TFunction<'translation', undefined>
): BootysShortcuts[] => [
  ...defaultShortcuts(t, {}),
  {
    id: 'editMoreActions',
    name: t('admin.editUserPage.shortcuts.moreActions'),
    keys: '.',
    action: 'keydown',
    withText: true,
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="more-actions-button"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'deletePurchaseOrder',
    name: t('admin.editUserPage.shortcuts.moreActions'),
    keys: 'backspace',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="dropdown-option-delete"]'
      );
      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'editPurchaseOrder',
    name: t('purchasing.purchaseOrder.shortcuts.editPurchaseOrder'),
    keys: 'e',
    action: 'keydown',
    withText: true,
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="edit-purchase-button"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'approvePurchaseOrder',
    name: t('purchasing.purchaseOrder.shortcuts.approvePurchaseOrder'),
    keys: 'a',
    action: 'keydown',
    withText: true,
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector('[data-testid="approve-button"]');

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'reprovePurchaseOrder',
    name: t('purchasing.purchaseOrder.shortcuts.reprovePurchaseOrder'),
    keys: 'r',
    action: 'keydown',
    withText: true,
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector('[data-testid="reprove-button"]');

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'confirmPurchaseOrder',
    name: t('purchasing.purchaseOrder.shortcuts.confirmPurchaseOrder'),
    keys: 'c',
    action: 'keydown',
    withText: true,
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector('[data-testid="confirm-button"]');

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
];

export const usePurchaseOrderDetailsShortcuts = (
  t: TFunction<'translation', undefined>
) => {
  const pageShortcuts = shortcuts(t);

  const hotkeys = useHotkeys(pageShortcuts);

  return {
    ...hotkeys,
    getShortcut: getShortcut(pageShortcuts),
    getShortcuts: getShortcuts(pageShortcuts),
  };
};
