import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { IField } from '../../interfaces/IField';
import { getScrollPercentage } from '@/Utils/ScrollUtils';
import _, { uniqBy } from 'lodash';
import { IFilterSearchItems, IItems } from '../Interface';
import { useField } from 'formik';

interface IInputWitchSearchHook {
  name: string;
  filterSearchItems?: IFilterSearchItems[];
  onScrollEnd?: () => void;
  isLoading?: boolean;

  actionOnFocus?: () => void;

  onChange?: (value: string, itemSelected?: any) => void;
  onSearch?: (search?: string, filters?: (string | number)[]) => void;

  itemsArray?: IItems[];

  currentItemsSelected?: string | string[];
  selectedItemFromForm?: string;
}

export const InputWitchSearchHook = ({
  name,
  filterSearchItems,
  onScrollEnd,
  isLoading,

  actionOnFocus,

  onChange,
  onSearch,

  itemsArray,
  currentItemsSelected,
  selectedItemFromForm,
}: IInputWitchSearchHook) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentSearch, setCurrentSearch] = useState('');
  const [items, setItems] = useState(itemsArray);
  const [selectedItems, setSelectedItems] = useState<string | string[]>([]);
  const [filterSearchItem, setFilterSearchItem] = useState(filterSearchItems);
  const [popoverIsVisible, setPopoverIsVisible] = useState(false);

  const [{ value: selectedItem }] = useField(name);
  const [{ value: initialValuesFromForm }] = useField(
    selectedItemFromForm || 'prop-not-set'
  );

  const onScroll = useCallback(
    (event: React.UIEvent<HTMLElement>) => {
      const scrollPercentage = getScrollPercentage(event);
      const scrollPercentageThreshold = 80;

      // Call hook when you scroll to 80% or more
      if (scrollPercentage > scrollPercentageThreshold && !isLoading) {
        onScrollEnd && onScrollEnd();
      }
    },
    [onScrollEnd, isLoading]
  );

  const setFocusState = useCallback(() => {
    actionOnFocus && actionOnFocus();
  }, [actionOnFocus]);

  const onChangeItemsDisabled = useCallback(() => {
    typeof selectedItems === 'object' &&
      setItems((item) =>
        item?.map((x) => ({
          ...x,
          disabledItem: selectedItems.some((y) => y === x.id) || x.disabledItem,
        }))
      );
  }, [selectedItems]);

  const onChangeFunc = useCallback(
    (value: string | string[] | null, props: IField) => {
      props.form.setFieldValue(name, _.isNil(value) ? null : value);

      if (_.isNil(value)) return;

      !currentItemsSelected && setSelectedItems(value);
      const externalIdChanged =
        typeof value === 'object' ? value[value.length - 1] : value;
      onChange &&
        onChange(
          externalIdChanged,
          items?.find((x: IItems) => x.id === externalIdChanged)?.allItem
        );
    },
    [name, onChange, items, currentItemsSelected]
  );

  const onChangeFilterFunc = useCallback(
    (filters: (string | number)[]) => {
      onSearch && onSearch('', filters);
    },
    [onSearch]
  );

  const onCloseTags = useCallback((id: string) => {
    setItems((prev) =>
      prev?.map((x) => (x.id === id ? { ...x, disabledItem: false } : x))
    );
  }, []);

  const itemFormated = useMemo(() => {
    return Array.isArray(itemsArray)
      ? [
          ...(itemsArray
            ? itemsArray?.map((x) => ({
                ...x,
                disabledItem: x.disabledItem
                  ? x.disabledItem
                  : Array.isArray(selectedItem)
                  ? selectedItem?.some((selected: string) => selected === x.id)
                  : selectedItem === x.id,
              }))
            : []),
          ...(initialValuesFromForm
            ? Array.isArray(initialValuesFromForm)
              ? initialValuesFromForm.map((x) => ({ ...x, disabledItem: true }))
              : [{ ...initialValuesFromForm, disabledItem: true }]
            : []),
        ]
      : items;
  }, [selectedItem, itemsArray, initialValuesFromForm]);

  useEffect(() => {
    let uniqItems = uniqBy(itemFormated, 'id');
    const lastItem = uniqItems[uniqItems.length - 1];

    if (isLoading) {
      if (lastItem && !lastItem?.loading) {
        uniqItems = [...uniqItems, { loading: true }];
      }
    } else {
      if (lastItem?.loading) {
        uniqItems.pop();
        uniqItems = [...uniqItems];
      }
    }
    setItems(uniqItems);
  }, [isLoading, itemFormated]);

  //set a uniq loading line
  useEffect(() => {
    setItems([{ loading: true }]);
  }, [currentSearch]);

  useEffect(() => {
    onChangeItemsDisabled();
  }, [onChangeItemsDisabled]);

  useEffect(() => {
    currentItemsSelected && setSelectedItems(currentItemsSelected);
  }, [currentItemsSelected]);

  const onSearchFunc = useMemo(
    () =>
      _.debounce(async (value: string) => {
        onSearch && onSearch(value);
      }, 1000),
    []
  );

  const randomId = useMemo(() => uuidV4(), []);

  return {
    randomId,

    onScroll,
    onChangeFunc,
    onChangeFilterFunc,
    onSearchFunc,
    onCloseTags,

    currentSearch,
    setCurrentSearch,
    setFocusState,

    isOpen,
    setIsOpen,

    filterSearchItem,
    setFilterSearchItem,
    items,

    popoverIsVisible,
    setPopoverIsVisible,
  };
};
