import { FC, useEffect } from 'react';
import { Button, Form, StatusCard, TextInput } from '@/Components';
import { useTranslation } from 'react-i18next';
import { useConferenceCallAPI } from './Hooks/useConferenceCallAPI';
import { TableCard } from './Components/TableCard';
import { useConferenceMemorizations } from './Hooks/useConferenceMemorizations';
import { useConferenceStates } from './Hooks/useConferenceStates';
import { Progress } from './Components/Progress';
import { ButtonDropdown } from '@/Components/Button/ButtonDropdown';
import { FinishButton } from './Components/FinishButton';
import FormBreakLine from '@/Components/FormBreakLine';
import { CardDates } from './Components/CardDates';
import DateUtils from '@/Utils/DateUtils';
import { SelectedAvatar } from '@/Components/SelectedAvatar';
import { PercentageInput } from '@/Components/Inputs/PercentageInput';
import { useConferenceFunctions } from './Hooks/useConferenceFunctions';
import { FinishModal } from './Components/FinishModal';
import { PrintViewer } from './Components/PrintViewer';
import { ReleaseAgainModal } from './Components/ReleaseAgainModal';
import { useConferenceUtils } from './Hooks/useConferenceUtils';
import { DndContext } from '@dnd-kit/core';
import { 
  SortableContext, 
  verticalListSortingStrategy, 
} from '@dnd-kit/sortable';
import { ITableItem } from './interfaces';

import styles from './styles.module.scss';

export const Conference: FC = () => {
  const { t } = useTranslation();

  const {
    inventory,
    inventoryDetails,
    inventoryRelease,
    saveConferenceDraft,
    finishConference,
  } = useConferenceCallAPI();

  const {
    isConferenceStatus,
    setIsConferenceStatus,
    itemsChecked,
    setItemsChecked,
    finishModalVisible,
    setFinishModalVisible,
    printAll,
    setPrintAll,
    printDivergent,
    setPrintDivergent,
    printViewerVisible,
    setPrintViewerVisible,
    supplyPlaceSelected,
    setSupplyPlaceSelected,
    tolerance,
    setTolerance,
    isReleaseAgainModalVisible,
    setIsReleaseAgainModalVisible,
    releaseSpecifications,
    setReleaseSpecifications,
  } = useConferenceStates();

  const {
    onCheckItem,
    onSaveConferenceDraft,
    onFinishConference,
    onCreateItemsChecked,
    handleDragEnd,
  } = useConferenceFunctions({
    itemsChecked,
    setItemsChecked,
    saveConferenceDraft,
    finishConference,
    setFinishModalVisible,
    setReleaseSpecifications,
  });

  const { calculateQuantityDivergent } = useConferenceUtils();

  const {
    CONFERENCE_STATUS,
    dropdownConferenceOptionsItems,
    supplyPlaceFormatted,
  } = useConferenceMemorizations({
    onSaveConferenceDraft,
    setPrintViewerVisible,
    supplyPlaceSelected,
    inventoryRelease,
    calculateQuantityDivergent,
    printDivergent,
    tolerance,
  });

  useEffect(() => {
    if (!inventoryRelease?.especificacao) return;

    if (itemsChecked.length === 0)
      setItemsChecked(onCreateItemsChecked(inventoryRelease?.especificacao));
  }, [inventoryRelease]);

  useEffect(() => {
    if (inventory?.statusInventarioId === CONFERENCE_STATUS) {
      setIsConferenceStatus(true);
    } else {
      setIsConferenceStatus(false);
    }
  }, [inventory]);

  useEffect(() => {
    if (inventoryRelease?.especificacao) {
      setReleaseSpecifications(
        inventoryRelease.especificacao.map((item, index) => ({
          ...item,
          index: index,
        }))
      );
    }
  }, [inventoryRelease]);

  return (
    <>
      <Form>
        <div className={styles['conference-card']}>
          <DndContext onDragEnd={handleDragEnd}>
            <SortableContext 
              items={
                releaseSpecifications.map(
                  item => item.localEstoqueExternalId
                ) || []
              }
              strategy={verticalListSortingStrategy}
            >
              <div className={styles['card-container']}>
                {inventoryDetails &&
                  inventory && 
                    releaseSpecifications.map(
                      (item: ITableItem) => (
                        <TableCard
                          key={item.localEstoqueExternalId}
                          index={item.index}
                          inventory={inventory}
                          inventoryDetails={inventoryDetails}
                          item={item}
                          hideQuantityColumns={
                            inventory?.ocultarQuantidadeLancamento
                          }
                          itemsChecked={itemsChecked}
                          onCheckItem={onCheckItem}
                          isConferenceStatus={isConferenceStatus}
                          printAll={printAll}
                          setPrintAll={setPrintAll}
                          printDivergent={printDivergent}
                          setPrintDivergent={setPrintDivergent}
                          setPrintViewerVisible={setPrintViewerVisible}
                          setSupplyPlaceSelected={setSupplyPlaceSelected}
                          tolerance={tolerance}
                        />    
                      )
                    )
                }
              </div>
            </SortableContext>
          </DndContext>
          <div className={styles['status-container']}>
            <StatusCard
              type="ui-main-semibold"
              children={
                isConferenceStatus
                  ? t('supply.inventory.release.inventoryConference')
                  : t('supply.inventory.conference.finishedStatus')
              }
              icon={{ icon: 'time' as any }}
              className={`${
                isConferenceStatus
                  ? styles[`conference-status`]
                  : styles[`finished-status`]
              } ${styles['status-card']}`}
            />
            {isConferenceStatus && <Progress itemsChecked={itemsChecked} />}
            {isConferenceStatus && (
              <div className={styles['container-buttons']}>
                <FinishButton
                  itemsChecked={itemsChecked}
                  setIsVisibleModal={setFinishModalVisible}
                />
                <Button
                  type="primary"
                  style={{ width: '100%' }}
                  status="warning"
                  leftIcon="return"
                  onClick={() => setIsReleaseAgainModalVisible(true)}
                >
                  {t('supply.inventory.conference.relaunchButton')}
                </Button>
                <ButtonDropdown
                  dropdownDirection="bottomRight"
                  leftIcon="menu-ellipsis-horizontal"
                  dropdownItens={dropdownConferenceOptionsItems}
                  dropdownTitle={t(
                    'supply.inventory.release.dropdownOptions.title'
                  )}
                  type="secondary"
                  dataTestId="conference-options"
                />
              </div>
            )}
            <FormBreakLine
              style={
                !isConferenceStatus
                  ? { marginBottom: '10px', marginTop: '10px' }
                  : {}
              }
            />
            <CardDates withMarginBottom>
              <PercentageInput
                label={t('supply.inventory.conference.tolerancePercentage')}
                name="tolerancePercentage"
                withTooltip={{
                  icon: 'exclamation-upside-down',
                  title: t(
                    'supply.inventory.conference.tolerancePercentageTooltip'
                  ),
                }}
                placeHolder="0%"
                onChange={(value) => {
                  let formattedValue = value
                    .replace(/[.%]/g, '')
                    .replace(',', '.');
                  setTolerance(parseFloat(formattedValue));
                }}
              />
            </CardDates>
            <CardDates>
              <TextInput
                label={t('supply.inventory.conference.conferenceDate')}
                name="conferenceDate"
                disabled
                value={
                  inventoryRelease?.historico &&
                  inventoryRelease?.historico?.length > 0
                    ? DateUtils.formatLongDate(
                        inventoryRelease?.historico.find(
                          (item: any) =>
                            item.status === inventory?.statusInventarioId
                        )?.data || '',
                        'dd/mm/yyyy'
                      )
                    : '-'
                }
              />
              <SelectedAvatar
                name={
                  inventoryRelease?.historico &&
                  inventoryRelease?.historico?.length > 0
                    ? inventoryRelease?.historico.find(
                        (item: any) =>
                          item.status === inventory?.statusInventarioId
                      )?.usuarioNomeCompleto
                    : '-'
                }
                label={t('supply.inventory.conference.responsible')}
              />
            </CardDates>
          </div>
        </div>
        <ReleaseAgainModal
          visible={isReleaseAgainModalVisible}
          setVisible={(value: boolean) => {
            setIsReleaseAgainModalVisible(value);
          }}
        />
      </Form>
      <FinishModal
        visible={finishModalVisible}
        onOkClick={onFinishConference}
        onCancel={() => setFinishModalVisible(false)}
        loading={finishConference.isLoading}
        inventoryCode={inventory?.codigo}
        incorrectItemsQuantity={
          itemsChecked.filter((item) => item.aprovado === false).length
        }
      />
      <PrintViewer
        isVisible={printViewerVisible}
        onBack={() => {
          setPrintViewerVisible(false);
          setSupplyPlaceSelected('');
          setPrintAll(false);
          setPrintDivergent(false);
        }}
        supplyPlaces={
          supplyPlaceSelected
            ? supplyPlaceFormatted
            : inventoryRelease?.especificacao
        }
        inventoryCode={inventory?.codigo.toString()}
        responsible={inventory?.responsavelNomeCompleto}
      />
    </>
  );
};
