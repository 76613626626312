import { NumberInput } from '@/Components/Inputs/NumberInput';
import { NumberInput as StateNumberInput } from '@/Components/StateInputs/NumberInput';
import { useControllerQueryListApiHook } from '@/ControllerApiHook/Controller';
import { UnitMeasurementAPI } from '@/Data/API/Supply/UnitMeasurementApi';
import { IPostManipulatedRecipeRequest } from '@/Data/Interfaces/request/Sales/Service/IServiceRequest';
import { IStandardFormulaProducts } from '@/Data/Interfaces/response/StandardFormula/IStandardFormulaResponse';
import { IListUnitPrescriptionMeasurementData } from '@/Data/Interfaces/response/UnitMeasurement/IUnitMeasurementResponse';
import { FETCH_LIST_UNIT_MEASUREMENT_PRESCRIPTION_FOR_DROPDOWN_TYPE } from '@/ControllerApiHook/UniqIds/Supply/UnitMeasurementKeys';
import { FormikContextType } from 'formik';
import { FC, MutableRefObject, useEffect } from 'react';

interface IAmountComponent {
  index: number;
  lastLine?: boolean;

  form: FormikContextType<IPostManipulatedRecipeRequest>;

  amountRef: MutableRefObject<null>;
}

export const AmountComponent: FC<IAmountComponent> = ({
  index,
  lastLine,
  form,

  amountRef,
}) => {
  const {
    data: amoutUnitMeasurementData,
    refetch: refetchAmoutUnitMeasurement,
    isLoading: isLoadingAmoutUnitMeasurement,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_UNIT_MEASUREMENT_PRESCRIPTION_FOR_DROPDOWN_TYPE,
    entityApi: UnitMeasurementAPI.getListUnitMeasurementPrescriptionDropDown,
    pagination: {
      pageSize: 50,
      sorter: { column: 'abreviacao', direction: 'DESC' },
      filter: [
        {
          filterName: 'produtoExternalId',
        },
        {
          filterName: 'formaFarmaceuticaExternalId',
        },
      ],
    },
  });

  useEffect(
    () =>
      refetchAmoutUnitMeasurement({
        filter: [
          {
            filterName: 'produtoExternalId',
            value:
              form.values.itens && form.values.itens[index].produtoExternalId,
          },
          {
            filterName: 'formaFarmaceuticaExternalId',
            value: form.values.formaFarmaceuticaExternalId,
          },
        ],
      }),
    [
      refetchAmoutUnitMeasurement,
      form?.values?.formaFarmaceuticaExternalId,
      form?.values?.itens,
      index,
    ]
  );

  useEffect(() => {
    form.setFieldValue(`itens[${index}].unidadeMedidaId`, undefined);
  }, [form.values.formaFarmaceuticaExternalId]);

  return (
    <NumberInput
      name={`itens[${index}].quantidade`}
      required
      withoutMarginBottom
      placeHolder="0,0000"
      dropdownRight={{
        name: `itens[${index}].unidadeMedidaId`,
        options:
          amoutUnitMeasurementData?.data?.map(
            (x: IListUnitPrescriptionMeasurementData) => ({
              id: x.unidadeMedidaId,
              content: x.abreviacao,
            })
          ) || [],
        loading:
          form.values.itens &&
          isLoadingAmoutUnitMeasurement &&
          !!form.values.itens[index].produtoExternalId &&
          !!form.values.formaFarmaceuticaExternalId,
        disabled:
          !form.values.formaFarmaceuticaExternalId ||
          (form.values.itens && !form.values.itens[index].produtoExternalId),
      }}
      disabled={lastLine}
      ref={amountRef}
    />
  );
};

interface IAmountComponentOnStandardFormula {
  standardFormula: IStandardFormulaProducts;
}
export const AmountComponentOnStandardFormula: FC<
  IAmountComponentOnStandardFormula
> = ({ standardFormula }) => {
  return (
    <StateNumberInput
      value={standardFormula?.quantidade}
      dropdownRight={{
        defaultValue: standardFormula?.unidadeMedidaAbreviacao,
      }}
      disabled
    />
  );
};
