import LabelTemplatesList from '@/Pages/Sectors/Production/LabelTemplates/List';
import { CreateLabelTemplate } from './../../../../../Pages/Sectors/Production/LabelTemplates/Create/index';
import { EditPackingClassification } from '@/Pages/Sectors/Production/CapsuleColors/Form';
import { Route, Switch } from 'react-router-dom';

export const LabelTemplatesRoutes = () => (
  <Switch>
    <Route
      path="/production/label-templates/create"
      component={CreateLabelTemplate}
    />
    <Route
      path="/production/label-templates/:externalId/:tab"
      component={EditPackingClassification}
    />
    <Route path="/production/label-templates" component={LabelTemplatesList} />
  </Switch>
);
