import { FC, useState } from 'react';
import { Button, Text } from '@/Components';
import { Loading } from '@/Components/Loading';
import { useTranslation } from 'react-i18next';
import { PDFViewer } from '@react-pdf/renderer';
import { PrintItems } from './Components/PrintItems';
import { IPrintViewer } from './interfaces';

import styles from './styles.module.scss';

export const PrintViewer: FC<IPrintViewer> = ({
  isVisible,
  onBack,
  inventoryCode,
  supplyPlaces,
  responsible,
}) => {
  const { t } = useTranslation();

  const [isGeneratingPdf, setIsGeneratingPdf] = useState<boolean>(true);

  return (
    <>
      {
        isVisible && (
          <div className={styles['print-viewer-container']}>
            {
              isGeneratingPdf && (
                <div className={styles['loading']}>
                  <Loading size='30px' />
                  <Text type="heading1">{t('common.loading')}</Text>
                </div>
              )
            }
            <PDFViewer
              style={{
                width: '100%',
                height: '100%',
              }}
            >
              <PrintItems 
                inventoryCode={inventoryCode}
                supplyPlaces={supplyPlaces}
                onRender={() => setIsGeneratingPdf(false)}
                responsible={responsible}
              />
            </PDFViewer>
            <div className={styles['back-button-container']}>
              {
                !isGeneratingPdf && (
                  <Button
                    type="primary"
                    onClick={() => {
                      setIsGeneratingPdf(true);
                      onBack();
                    }}
                  >
                    {t('common.back')}
                  </Button>
                )
              } 
            </div>
          </div>
        )
      }
    </>
  );
};
