import { Dropdown } from '@/Components';
import { Col, Row } from 'antd';
import { TextInput } from '@/Components';
import { useTranslation } from 'react-i18next';
import { labelType } from '../../Utils/utils';

import styles from '../../FooterButtons/LabelTemplate.module.scss';

export const RowDescription = () => {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 0]} justify="start">
      <Col span={12}>
        <TextInput
          className={styles['text-input']}
          name="descricao"
          label={t('production.labelTemplates.create.labelTemplateName')}
          placeHolder={t(
            'production.labelTemplates.create.labelTemplateName'
          )}
          required
          dataTestId="label-template-description"
        />
      </Col>
      <Col span={5}>
        <Dropdown
          name="tipoRotulo"
          items={labelType?.map((values) => ({
            id: values.id,
            label: values.name,
          }))}
          placeHolder={t(
            'production.labelTemplates.create.labelTypePlaceholder'
          )}
          required
          label={t('production.labelTemplates.create.labelType')}
        />
      </Col>
    </Row>
  );
};
