import { useCallback, useState } from 'react';
import { PermissionWrapper } from '../../../../../../PermissionWrapper';
import { Status } from '../../../Status';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../../../Modal';
import { Divflex } from '../../../../../../../Layouts/DivWhithFlex';
import { Text } from '../../../../../../Text';
import {
  FETCH_LIST_STOCK_LOCATION,
  FETCH_GET_STOCK_LOCATION,
  EDIT_STATUS_STOCK_LOCATION,
} from '../../../../../../../ControllerApiHook/UniqIds/Supply/SupplyKeys';
import { history } from '../../../../../../../Store';
import { useQueryClient } from '@tanstack/react-query';
import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
} from '@/ControllerApiHook/Controller';
import { StorageLocationApi } from '@/Data/API/Supply/StorageLocationApi';
import { IGetStorageLocationResponse } from '@/Data/Interfaces/response/StorageLocation/IStorageLocationResponse';

import ReactMarkdown from 'react-markdown';
import { useSupplyPlaceDetailsShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/inventory/supplyPlaces/details';
import { Tooltip } from '@/Components/Tooltip';

export const DynamicEditStorageLocationBreadcrumb = ({ match }: any) => {
  const { data: storageLocation } =
    useControllerQueryApiHook<IGetStorageLocationResponse>({
      uniqId: FETCH_GET_STOCK_LOCATION,
      entityApi: StorageLocationApi.getCurrentStorageLocation,
      externalIds: [match.params.externalId],
    });

  return <div style={{ display: 'flex' }}>{storageLocation?.descricao}</div>;
};

export const DynamicEditStoragePlaceStatusBreadcrumb = ({ match }: any) => {
  const { data: storageLocation } =
    useControllerQueryApiHook<IGetStorageLocationResponse>({
      uniqId: FETCH_GET_STOCK_LOCATION,
      entityApi: StorageLocationApi.getCurrentStorageLocation,
      externalIds: [match.params.externalId],
    });

  const editStatus: any = useControllerMutateApiHook({
    uniqId: EDIT_STATUS_STOCK_LOCATION,
    entityApi: StorageLocationApi.patchStorageLocationStatus,
  });

  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const { getShortcut } = useSupplyPlaceDetailsShortcuts(t, {});

  const editStatusHotkey = getShortcut('editStatus');

  const [modalVisible, setModalVisible] = useState(false);
  const [status, setStatus] = useState(undefined);

  const changeStatus = useCallback(
    async (value: boolean) => {
      const res = await editStatus.mutateAsync({
        externalId: [match.params.externalId],
        ativo: value,
      });

      if (res.success) {
        queryClient.invalidateQueries([FETCH_LIST_STOCK_LOCATION]);
        history.push('/inventory/supply-places/');
      }
    },
    [editStatus, match.params.externalId, queryClient]
  );

  const onEditStatus = useCallback(
    async (status: any) => {
      const res = await editStatus.mutateAsync({
        externalId: [match.params.externalId],
        ativo: status,
      });
      if (res.success) {
        queryClient.invalidateQueries([FETCH_LIST_STOCK_LOCATION]);
        history.push('/inventory/supply-places/');
      }
    },
    [queryClient, match.params.externalId, editStatus]
  );

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {`${t('breadCrumb.supplyPlaces.detail.title')} | ${
        storageLocation?.descricao
      }`}
      <PermissionWrapper
        permission={1005}
        tooltip="noPermission.administrative.users.statusEdit"
      >
        <Tooltip showMe title={editStatusHotkey.tooltip}>
          <Status
            type={storageLocation?.ativo ? 'success' : 'disabled'}
            ative={storageLocation?.ativo || false}
            onClick={(value) => {
              value ? changeStatus(value) : setModalVisible(true);
              setStatus(value);
            }}
            dataTestId="status-dropdown-button"
          />
        </Tooltip>
      </PermissionWrapper>
      <Modal
        body={
          <Divflex style={{ padding: '20px' }}>
            <Text type="ui-tiny-content">
              <ReactMarkdown>
                {t('supply.supplyPlaces.details.changeStatusDescription', {
                  storageLocation: `**${storageLocation?.descricao}**`,
                })}
              </ReactMarkdown>
            </Text>
          </Divflex>
        }
        title={`Inativar "${storageLocation?.descricao}"?`}
        onCancelClick={() => setModalVisible(false)}
        visible={modalVisible}
        onOkClick={() => status !== undefined && onEditStatus(status)}
        onClose={() => setModalVisible(false)}
        okButtonName={t('supply.supplyPlaces.editStatus.status.inactivate')}
      />
    </div>
  );
};
