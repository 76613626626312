import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { HorizontalTabs } from '../../../../../Components';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { DeleteStorageLocationModal } from '../Edit/Details/Components/DeleteModal/deleteModal';
import { useEditCallAPI } from '../Edit/Details/Hooks/useEditCallAPI';
import { useEditStates } from '../Edit/Details/Hooks/useEditStates';
import { useEditFunctions } from '../Edit/Details/Hooks/useEditFunctions';
import { StorageLocationDetail } from '../Edit/Details';
import { StorageLocationInventory } from '../Edit/Inventory';
import { useSupplyPlaceDetailsShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/inventory/supplyPlaces/details';

const FormStorageLocation: React.FC = () => {
  const { externalId, tab } = useParams<{ externalId: string; tab: string }>();
  const { t } = useTranslation();

  const { getShortcut } = useSupplyPlaceDetailsShortcuts(t, {});

  const alternateTabDetailsHotkey = getShortcut('alternateTabDetails');
  const alternateTabStockHotkey = getShortcut('alternateTabStock');
  const editMoreActionsHotkey = getShortcut('editMoreActions');
  const editSupplyPlaceHotkey = getShortcut('editSupplyPlace');

  const { disabled, setDisabled, visibleModal, setVisibleModal } =
    useEditStates();

  const { storageLocation, deleteStorageLocation } = useEditCallAPI({
    externalId,
    t,
  });

  const { onDelete } = useEditFunctions({
    deleteStorageLocation,
    storageLocation,
    setVisibleModal,
  });

  return (
    <PageLoading>
      <HorizontalTabs
        type="secondary"
        items={[
          {
            label: t('supply.supplyPlaces.editSupplyPlaces.tab.details'),
            key: 'details',
            children: (
              <StorageLocationDetail
                disabled={disabled}
                onChangeDisabled={(x: boolean) => setDisabled(x)}
              />
            ),
            dataTestId: 'tab-supply-place-details',
            shortCutTooltip: {
              title: alternateTabDetailsHotkey.tooltip,
              targetOffset: [0, 10],
            },
          },
          {
            label: t('supply.supplyPlaces.editSupplyPlaces.tab.inventory'),
            key: 'inventory',
            children: <StorageLocationInventory />,
            dataTestId: 'tab-supply-place-stock',
            shortCutTooltip: {
              title: alternateTabStockHotkey.tooltip,
              targetOffset: [0, 10],
            },
          },
        ]}
        dropdownItens={[
          {
            label: t(
              'supply.supplyPlaces.editSupplyPlaces.buttonActions.delete'
            ),
            icon: 'trash',
            permission: {
              permission: 2012,
              tooltip: 'noPermission.supply.groups.delete',
            },
            onClick: () => setVisibleModal(true),
            dataTestId: 'dropdown-option-delete',
            key: 'backspace',
          },
        ]}
        dropdownDirection="bottomRight"
        dropdownTitle={t('admin.editUserPage.tab.buttonActions.title')}
        dataTestIdDropdownButton="more-actions-button"
        moreActionsButton={{ title: editMoreActionsHotkey.tooltip }}
        leftIcon="menu-ellipsis-horizontal"
        rightButton={{
          ...(disabled &&
            tab === 'details' &&
            storageLocation && {
              onClick: () => setDisabled(false),
              isVisible: disabled,
              codePermissionEdit: 2010,
              messageTooltipPermission: 'noPermission.supply.supplyPlaces.edit',
            }),
          dataTestId: 'edit-supply-place-button',
          hotkeyTooltip: {
            title: editSupplyPlaceHotkey.tooltip,
          },
        }}
      />
      {storageLocation && (
        <DeleteStorageLocationModal
          storageLocation={storageLocation}
          visibleModal={visibleModal}
          changeVisibleModal={(value: boolean) => setVisibleModal(value)}
          onDelete={onDelete}
        />
      )}
    </PageLoading>
  );
};

export default FormStorageLocation;
