import {
    BootysShortcuts,
    defaultShortcuts,
    DefaultShortcuts,
  } from '@/Components/Shortcuts/keymaps/default';
  import { TFunction } from 'react-i18next';
  import { useHotkeys } from 'reakeys';
  import { isVisible } from '@/Utils/DOMUtils';
  import { getShortcut, getShortcuts } from '@/Components/Shortcuts';
  export const pageKey = 'supply-place-list';

  export interface LinkedProductsListCallbacks extends DefaultShortcuts {}
  
  const shortcuts = (
    t: TFunction<'translation', undefined>,
    callbacks: LinkedProductsListCallbacks
  ): BootysShortcuts[] => [
    ...defaultShortcuts(t, callbacks),
    {
      id: 'selectAll',
      name: t('production.packingClassification.linkedList.shortcuts.selectAll'),
      keys: 'alt+x',
      action: 'keydown',
      callback: (event: any) => {
        event.preventDefault();
        const element = document.querySelector(
          '[data-testid="table-select-all"]'
        );
        //@ts-ignore
        element?.click();
      },
    },
    {
      id: 'search',
      name: 'search',
      keys: 'p',
      action: 'keydown',
      callback: (event: any) => {
        event.preventDefault();
        const element = document.querySelector('[data-testid="table-search"]');
        if (isVisible(element)) {
          //@ts-ignore
          element?.focus();
        }
      },
    },
    {
      id: 'deleteSelected',
      name: t('production.packingClassification.linkedList.shortcuts.unlinkSelected'),
      keys: 'backspace',
      action: 'keydown',
      callback: (event: any) => {
        event.preventDefault();
        const element = document.querySelector('#delete-select-items');
        if (isVisible(element)) {
          //@ts-ignore
          element?.click();
        }
      },
    },
  ];
  export const useLinkedProductsListShortcuts = (
    t: TFunction<'translation', undefined>,
    callbacks: LinkedProductsListCallbacks
  ) => {
    const pageShortcuts = shortcuts(t, callbacks);
    const hotkeys = useHotkeys(pageShortcuts);
    return {
      ...hotkeys,
      getShortcut: getShortcut(pageShortcuts),
      getShortcuts: getShortcuts(pageShortcuts),
    };
  };