import { FC } from 'react';
import { Button, Tooltip } from '@/Components';
import { IApproveButton } from './interfaces';
import { useApproveButtonMemorizations } from './Hooks/useApproveButtonMemorizations';
import { useApproveButtonFunctions } from './Hooks/useApproveButtonFunctions';

export const ApproveButton: FC<IApproveButton> = ({
  statusPurchaseOrder,
  setApproveModal,
  setConfirmWithSupplierModal,
  dataTestId,
  hotkeyTooltip,
}) => {
  const { leftIcon, permission, isDisabled, children } =
    useApproveButtonMemorizations({
      statusPurchaseOrder,
    });

  const { onClick } = useApproveButtonFunctions({
    statusPurchaseOrder,
    setApproveModal,
    setConfirmWithSupplierModal,
  });

  return (
    <>
      <Tooltip
        showMe
        title={hotkeyTooltip?.title}
        targetOffset={hotkeyTooltip?.targetOffset}
      >
        <Button
          leftIcon={leftIcon}
          permission={permission}
          type="primary"
          style={{ width: '100%' }}
          fullWidth
          status="success"
          disabled={isDisabled}
          children={children}
          onClick={onClick}
          dataTestId={dataTestId}
        />
      </Tooltip>
    </>
  );
};
