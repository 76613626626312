import { IInvoiceListData } from '@/Data/Interfaces/response/Purchase/IInvoiceResponse';
import { useState } from 'react';

export const useInvoiceListStates = () => {
  const [selectedRowsOnTable, setSelectedRowsOnTable] = useState<
    IInvoiceListData[]
  >([]);

  const [selectedItems, setSelectedItems] = useState<string[] | undefined>(
    undefined
  );

  return {
    selectedRowsOnTable,
    setSelectedRowsOnTable,
    selectedItems,
    setSelectedItems,
  };
};
