import { DeleteModalOnTable } from '../../../../../../Utils/DeleteModalUtils';
import { DeleteModal } from './deleteModal';

export const TitleHandler = (selectedRowsOnTable: any, t: any) =>
  selectedRowsOnTable &&
  `${t('common.delete')}  ${
    selectedRowsOnTable.length > 1
      ? `${t('common.deleteLabelTemplateModal.labelTemplates')}?`
      : `${t('common.deleteLabelTemplateModal.labelTemplate')} "${
          selectedRowsOnTable[0]?.descricao
        }"?`
  }`;

export const BodyHandler = (selectedRowsOnTable: any, t: any) =>
  selectedRowsOnTable && selectedRowsOnTable?.length > 1 ? (
    DeleteModalOnTable(
      selectedRowsOnTable,
      `${t('modal.deleteModal.many.title')} ${t('common.theLabelTemplate')}:`,
      'descricao',
      t('common.labelTemplate'),
    )
  ) : (
    <DeleteModal selectedRowsOnTable={selectedRowsOnTable} />
  );
