import React from 'react';
import { Table } from '../../../../../Components';
import { useTranslation } from 'react-i18next';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { providerColumn } from './provider.columns';
import { editTableStatus } from '../../../../../Utils/EditModalTableUtils';
import { useListStates } from './Hooks/useListStates';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListMemorizations } from './Hooks/useListMemorizations';
import { useListFunctions } from './Hooks/useListFunctions';
import { useSupplierListShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/Order/Supplier/list';

export const SupplierList: React.FC = () => {
  const { t } = useTranslation();

  const { selectedRowsOnTable, setSelectedRowsOnTable, selectedItems } =
    useListStates();

  const {
    deleteSupplier,
    editSupplierStatus,
    supplierList,
    isLoadingList,
    refetchSupplierList,
  } = useListCallAPI();

  const { getShortcut } = useSupplierListShortcuts(t, {});

  const {
    headerOptions,
    rowOptions,
    deleteModal,
    editModal,
    tableHeaderHotKeyTooltip,
  } = useListMemorizations({
    selectedRowsOnTable,
    getShortcut,
  });

  const { onEdit, onDelete } = useListFunctions({
    setSelectedRowsOnTable,
    editSupplierStatus,
    deleteSupplier,
  });

  return (
    <PageLoading loading={isLoadingList}>
      <Table
        columns={providerColumn(t)}
        headerOptions={headerOptions}
        loading={isLoadingList}
        fieldStatus="ativo"
        hasSelection
        rowsOptions={rowOptions}
        pagination={supplierList}
        onRefetch={refetchSupplierList}
        editOptions={editTableStatus()}
        onEdit={onEdit}
        editModal={editModal}
        editStatusModalLoading={editSupplierStatus.isLoading}
        deleteModal={deleteModal}
        onDelete={onDelete}
        selectedRowsOnTable={selectedRowsOnTable}
        setSelectedRowsOnTable={setSelectedRowsOnTable}
        tableHeaderTooltip={tableHeaderHotKeyTooltip}
        keyToSelect="externalId"
        initialRowsSelected={selectedItems}
      />
    </PageLoading>
  );
};
