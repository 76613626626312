import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@/Components/Table';
import { useParams } from 'react-router';
import { PageLoading } from '@/Layouts/PageLoading';
import { ContainerPage } from '@/Layouts/ContainerPage';
import { linkedProductMessageCollumn } from './Linked.column';
import { useListStates } from './Hooks/useListStates';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListFunctions } from './Hooks/useListFunctions';
import { useListMemorizations } from './Hooks/useListMemorizations';
import { useRelatedProductsTabShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/inventory/productMessage/relatedProducts';

interface ILinkedMessage {
  messageLinked?: string;
}

export const ProductLinkedMessage: FC<ILinkedMessage> = ({ messageLinked }) => {
  const { externalId } = useParams<{ externalId: string }>();
  const { t } = useTranslation();

  const { selectedRowsOnTable, setSelectedRowsOnTable, selectedItems } =
    useListStates();

  const { getShortcut } = useRelatedProductsTabShortcuts(t, {});

  const {
    productLinkedByMessageList,
    isLoadingProductLinkedByMessageList,
    deleteProductLinkedByMessage,
    refetchProductLinkedByMessageList,
  } = useListCallAPI();

  const { onDelete } = useListFunctions({
    deleteProductLinkedByMessage,
    externalId,
  });

  const { deleteModal, headerOptions, tableHeaderHotKeyTooltip } =
    useListMemorizations({
      selectedRowsOnTable,
      messageLinked,
      getShortcut,
    });

  return (
    <PageLoading loading={isLoadingProductLinkedByMessageList}>
      <ContainerPage>
        <Table
          columns={linkedProductMessageCollumn(t)}
          headerOptions={headerOptions}
          pagination={productLinkedByMessageList}
          onRefetch={refetchProductLinkedByMessageList}
          loading={isLoadingProductLinkedByMessageList}
          hasSelection
          onDelete={onDelete}
          deleteModal={deleteModal}
          paginationCustomExternalId="produtoExternalId"
          selectedRowsOnTable={selectedRowsOnTable}
          setSelectedRowsOnTable={setSelectedRowsOnTable}
          tableHeaderTooltip={tableHeaderHotKeyTooltip}
          keyToSelect="externalId"
          initialRowsSelected={selectedItems}
        ></Table>
      </ContainerPage>
    </PageLoading>
  );
};
