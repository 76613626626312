import { IButtonDropDownItems } from '@/Components/Button/ButtonDropdown';
import { IconName } from '@/Components/Icon/IconClasses';
import { useTranslation } from 'react-i18next';
import { IUseMemorizations } from './interfaces';
import { useFormikContext } from 'formik';
import { useSaveDraftRelease } from './useSaveDraftRelease';
import { useParams } from 'react-router';

export const useReleaseMemorizations = ({
  setIsVisibleCancelModal,
  releaseHistory,
}: IUseMemorizations) => {
  const { t } = useTranslation();
  const CONFERENCE_STATUS = 3;
  const CANCELED_STATUS = 5;
  const FINISHED_STATUS = 4;
  const form: any = useFormikContext();
  const { saveDraftRelease } = useSaveDraftRelease();
  const { externalId } = useParams<{ externalId: string }>();

  const onSaveDraft = async () => {
    await saveDraftRelease.mutate({
      ...form.values,
      inventarioExternalId: externalId,
    });
  };

  const dropdownReleaseOptionsItems: IButtonDropDownItems = [
    {
      label: t('supply.inventory.release.dropdownOptions.save'),
      icon: 'diskette' as IconName,
      targetOffsetTooltip: [35, -15],
      onClick: () => {
        onSaveDraft();
      },
    },
    {
      label: t('supply.inventory.release.dropdownOptions.print'),
      icon: 'printer' as IconName,
      targetOffsetTooltip: [35, -15],
      onClick: () => {
        const printButton =
          document.querySelector<HTMLButtonElement>('#printAllButton');
        if (printButton && typeof printButton.click === 'function') {
          printButton.click();
        }
      },
    },
    {
      label: t('supply.inventory.release.dropdownOptions.cancel'),
      icon: 'close-x' as IconName,
      targetOffsetTooltip: [35, -15],
      onClick: () => setIsVisibleCancelModal(true),
    },
  ];

  const releaseHistoryReverted = releaseHistory?.reverse();

  return {
    dropdownReleaseOptionsItems,
    releaseHistoryReverted,
    CONFERENCE_STATUS,
    CANCELED_STATUS,
    FINISHED_STATUS,
  };
};
