import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PopOver } from '../../../PopOver';
import { Text } from '../../../Text';
import { CompanyButton } from '../../../Button/CompanyButton';

import styles from './ContextButton.module.scss';
import { contextItems } from './ContextButtons.items';
import { Hotkey, PossibleKey } from '@/Components/Shortcuts/Hotkey';

const HeaderMenutitle = (t: any) => {
  return (
    <div className={styles['divTitle']}>
      <Text
        className={styles['title']}
        type="paragraph2"
        color="text-400"
        children={t('header.contextTitle')}
      />
      <div className={styles['line']} />
    </div>
  );
};

const HeaderMenucontent = (
  company: (isCompany: any) => void,
  visible: (isVisible: boolean) => void
) => {
  return (
    <div className={styles['divContent']}>
      {contextItems.map((item, index) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <CompanyButton
            className={styles['button']}
            type="secondary-company"
            children={item.company}
            company={item.context}
            onClick={() => {
              company(item.id);
              visible(false);
            }}
            key={`content-contextButton-${index}`}
          />
          <Hotkey
            hotKey={(index + 1).toString() as PossibleKey}
            height={20}
            width={20}
            fontSize={12}
          />
        </div>
      ))}
    </div>
  );
};

interface IContextButton {
  hotkeyTooltip?: {
    title: ReactNode | string;
    targetOffset?: number[];
  };
}

export const ContextButton: React.FC<IContextButton> = ({ hotkeyTooltip }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [company, setCompany] = useState<number>(0);

  const { t } = useTranslation();

  return (
    <PopOver
      visible={visible}
      onOpenChange={() => setVisible(!visible)}
      placement="bottomRight"
      trigger="click"
      title={HeaderMenutitle(t)}
      content={HeaderMenucontent(
        (isComapny) => setCompany(isComapny),
        (isVisible) => setVisible(isVisible)
      )}
    >
      <CompanyButton
        className={styles['company-button']}
        compapyButton={contextItems[company].context}
        type="primary-company"
        children={contextItems[company].company}
        rightIcon="chevron-down"
        tooltip={hotkeyTooltip}
      />
    </PopOver>
  );
};
