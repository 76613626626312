import { Button, Icon, Form, TextInput, i18n, Dropdown } from '@/Components';
import { useTranslation } from 'react-i18next';
import { StatusCard as StatusCardComponent } from '@/Components';
import FormBreakLine from '@/Components/FormBreakLine';
import { CardDates } from './components/CardDates';
import { useParams } from 'react-router';
import { useGetInventoryDetails } from './hooks/useGetInventoryDetails';
import { useEffect, useState } from 'react';
import { StartReleaseModal } from './components/StartReleaseModal';
import { useGetInventory } from './hooks/useGetInventory';
import { useStartRelease } from './hooks/useStartRelease';
import { useQueryClient } from '@tanstack/react-query';
import {
  FETCH_GET_INVENTORY,
  FETCH_GET_INVENTORY_DETAILS,
  FETCH_INVENTORY_RELEASE,
  FETCH_LIST_INVENTORY,
} from '@/ControllerApiHook/UniqIds/Inventory/Inventory';
import { Progress } from './components/Progress';
import { TableCard } from './components/TableCard';
import { FinishReleaseModal } from './components/FinishReleaseModal';
import { FinishButton } from './components/FinishButton';
import { useGetInventoryRelease } from './hooks/useGetInventoryRelease';
import { useReleaseMemorizations } from './hooks/useReleaseMemorizations';
import { CancelReleaseModal } from './components/CancelReleaseModal';
import { useReleaseCallAPI } from './hooks/useReleseCallAPI';
import { useReleaseFunctions } from './hooks/useReleaseFunctions';
import DateUtils from '@/Utils/DateUtils';
import { IReleaseHistory } from './interfaces';
import { SelectedAvatar } from '@/Components/SelectedAvatar';
import { DndContext } from '@dnd-kit/core';
import styles from './styles.module.scss';
import { PrintAllButton } from './components/PrintAllButton';

import { DropdownButton } from './components/DropdownButton';

export const Release: React.FC = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { externalId } = useParams<{ externalId: string }>();
  const { inventory } = useGetInventory(externalId);
  const { inventoryDetails } = useGetInventoryDetails(externalId);
  const { inventoryRelease } = useGetInventoryRelease(externalId);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [isVisibleCancelModal, setIsVisibleCancelModal] = useState(false);
  const [startRelease, setStartRelease] = useState(false);
  const [inventoryIsCanceled, setInventoryIsCanceled] = useState(false);
  const [inventoryConference, setInventoryConference] = useState(false);
  const [inventoryFinish, setInventoryFinish] = useState(false);
  const { createRelease } = useStartRelease();
  const [isFinishReleaseModalVisible, setIsFinishReleaseModalVisible] =
    useState(false);

  const {
    releaseHistoryReverted,
    CONFERENCE_STATUS,
    CANCELED_STATUS,
    FINISHED_STATUS,
  } = useReleaseMemorizations({
    setIsVisibleCancelModal,
    releaseHistory: inventoryRelease?.historico,
  });

  const { cancelRelease } = useReleaseCallAPI();

  const { onOkClickCancelReleaseModal } = useReleaseFunctions({
    externalId,
    setIsVisibleCancelModal,
    setStartRelease,
  });

  const [inventoryMap, setInventoryMap] = useState<any>(null);

  useEffect(() => {
    const inventoryReleaseId = 2;

    if (inventory && inventory.statusInventarioId === inventoryReleaseId) {
      setStartRelease(true);
    } else {
      setStartRelease(false);
    }

    if (inventory && inventory.statusInventarioId === CANCELED_STATUS) {
      setInventoryIsCanceled(true);
    } else {
      setInventoryIsCanceled(false);
    }

    if (inventory && inventory.statusInventarioId === CONFERENCE_STATUS) {
      setInventoryConference(true);
    } else {
      setInventoryConference(false);
    }

    if (inventory && inventory.statusInventarioId === FINISHED_STATUS) {
      setInventoryFinish(true);
    } else {
      setInventoryFinish(false);
    }
  }, [inventory]);

  useEffect(() => {
    if (startRelease || inventoryConference) {
      setInventoryMap(inventoryRelease);
    } else {
      setInventoryMap(inventoryDetails);
    }
  }, [inventoryDetails, inventoryRelease]);

  return (
    <>
      <Form>
        <div className={styles['releaseCard']}>
          <DndContext onDragEnd={({ active, over }) => {}}>
            <div className={styles['cardContainer']}>
              {inventoryDetails &&
                inventory &&
                inventoryMap?.especificacao?.map((item: any, index: number) => (
                  <TableCard
                    index={index}
                    inventory={inventory}
                    inventoryDetails={inventoryDetails}
                    item={item}
                    startRelease={
                      startRelease || inventoryConference || inventoryFinish
                    }
                    isConferenceStatus={inventoryConference || inventoryFinish}
                    inventoryIsCanceled={inventoryIsCanceled}
                    hideQuantityColumns={inventory?.ocultarQuantidadeLancamento}
                    inventoryCode={String(inventory?.codigo).padStart(5, '0')}
                    hideQuantityPrint={inventory?.ocultarQuantidadeImpressao}
                    inventoryFinish={inventoryFinish}
                  />
                ))}
            </div>
          </DndContext>
          <div className={styles['statusContainer']}>
            <StatusCardComponent
              type="ui-main-semibold"
              children={
                startRelease
                  ? t('supply.inventory.release.release')
                  : inventoryIsCanceled
                  ? t('supply.inventory.release.inventoryCanceled')
                  : inventoryConference
                  ? t('supply.inventory.release.inventoryConference')
                  : inventoryFinish
                  ? t('supply.inventory.conference.finishedStatus')
                  : t('supply.inventory.release.waitingRelease')
              }
              icon={{ icon: inventoryFinish ? 'check' : ('time' as any) }}
              className={`${
                startRelease
                  ? styles[`releasing-status`]
                  : inventoryIsCanceled
                  ? styles['canceled-status']
                  : inventoryConference
                  ? styles[`conference-status`]
                  : inventoryFinish
                  ? styles[`finished-status`]
                  : styles[`waitingApproval-status`]
              } ${styles['status-card']}`}
            />
            {startRelease && <Progress />}

            {!inventoryIsCanceled && (
              <div
                style={{
                  justifyContent: 'space-between',
                  margin: '15px 0 15px 0',
                  width: '100%',
                  display: 'grid',
                  gridTemplateColumns:
                    !inventoryConference && !inventoryFinish
                      ? '7fr 1fr'
                      : '1fr',
                  gap: 5,
                }}
              >
                {!inventoryConference && !inventoryFinish && (
                  <FinishButton
                    setIsVisibleModal={
                      startRelease
                        ? setIsFinishReleaseModalVisible
                        : setIsVisibleModal
                    }
                    startRelease={startRelease}
                  />
                )}
                <PrintAllButton
                  inventoryConference={inventoryConference || inventoryFinish}
                  supplyPlaces={inventoryMap?.especificacao}
                  inventoryCode={String(inventory?.codigo).padStart(5, '0')}
                  startRelease={startRelease}
                  hideQuantityPrint={inventory?.ocultarQuantidadeImpressao}
                />
                {startRelease && (
                  <DropdownButton
                    releaseHistory={inventoryRelease?.historico}
                    setIsVisibleCancelModal={setIsVisibleCancelModal}
                  />
                )}
              </div>
            )}
            <FormBreakLine
              style={
                inventoryIsCanceled
                  ? { marginBottom: '10px', marginTop: '10px' }
                  : {}
              }
            />
            <CardDates>
              {(inventoryConference || inventoryFinish) && (
                <Dropdown
                  label={t('supply.inventory.release.previousReleases')}
                  name="previousReleases"
                  items={
                    releaseHistoryReverted?.map(
                      (item: IReleaseHistory, index) => ({
                        id: item.codigoLancamento,
                        label: `
                        ${t('supply.inventory.release.previousReleasesLabel')}
                        ${releaseHistoryReverted.length - index}
                        ${
                          index === 0
                            ? ` ${t(
                                'supply.inventory.release.previousReleasesExtraLabel'
                              )}`
                            : ''
                        }
                      `,
                      })
                    ) || []
                  }
                />
              )}
              {!inventoryIsCanceled && (
                <TextInput
                  label={t('supply.inventory.release.releaseDate')}
                  name="releaseDate"
                  disabled
                  value={
                    inventoryRelease?.historico?.length > 0
                      ? DateUtils.formatLongDate(
                          inventoryRelease?.historico.find(
                            (item: any) =>
                              item.status === inventory.statusInventarioId
                          )?.data,
                          'dd/mm/yyyy'
                        )
                      : '-'
                  }
                />
              )}
              {inventoryIsCanceled && (
                <TextInput
                  label={t('supply.inventory.release.cancellationDate')}
                  name="cancellationDate"
                  disabled
                  value={
                    inventoryRelease?.historico?.length > 0
                      ? DateUtils.formatLongDate(
                          inventoryRelease?.historico[
                            inventoryRelease?.historico.length - 1
                          ]?.data,
                          'dd/mm/yyyy'
                        )
                      : '-'
                  }
                />
              )}
              <SelectedAvatar
                name={inventory?.responsavelNomeCompleto}
                label={
                  inventoryFinish
                    ? t('supply.inventory.release.operator')
                    : t('supply.inventory.release.responsible')
                }
              />
            </CardDates>
          </div>
        </div>
        <FinishReleaseModal
          visible={isFinishReleaseModalVisible}
          changeVisibility={() => {
            setIsFinishReleaseModalVisible((prev) => !prev);
          }}
        />
      </Form>
      <StartReleaseModal
        onCancel={() => setIsVisibleModal(false)}
        visible={isVisibleModal}
        onOk={async () => {
          await createRelease.mutateAsync({ externalId });
          queryClient.invalidateQueries([FETCH_GET_INVENTORY]);
          queryClient.invalidateQueries([FETCH_GET_INVENTORY_DETAILS]);
          queryClient.invalidateQueries([FETCH_INVENTORY_RELEASE]);
          queryClient.invalidateQueries([FETCH_LIST_INVENTORY]);
          setIsVisibleModal(false);
          setStartRelease(true);
        }}
        loading={createRelease.isLoading}
      />
      <CancelReleaseModal
        onCancel={() => setIsVisibleCancelModal(false)}
        visible={isVisibleCancelModal}
        onOk={() => onOkClickCancelReleaseModal(cancelRelease)}
        loading={cancelRelease.isLoading}
      />
    </>
  );
};
