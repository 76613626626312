import { Modal } from '@/Components';
import { ModalBody } from './components/ModalBody';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useSaveProduct } from '../../hooks/useSaveProduct';
import { useParams } from 'react-router';

interface IAddProductModalProps {
  visible: boolean;
  changeVisibility: () => void;
  supplyPlaceId: string;
}

export const AddProductModal: React.FC<IAddProductModalProps> = ({
  changeVisibility,
  visible,
  supplyPlaceId,
}) => {
  const { t } = useTranslation();
  const { externalId } = useParams<{ externalId: string }>();
  const form: any = useFormikContext();
  const { saveProductRelease } = useSaveProduct();

  const saveProduct = async () => {
    const body = {
      inventarioExternalId: externalId,
      localEstoqueExternalId: supplyPlaceId,
      produtos: form.values.product,
    };

    await saveProductRelease.mutate(body);
    form.setFieldValue('product', []);
    changeVisibility();
  };

  return (
    <Modal
      title={t('supply.inventory.release.addProduct')}
      visible={visible}
      onCancelClick={() => {
        form.setFieldValue('product', []);
        changeVisibility();
      }}
      onClose={() => {
        form.setFieldValue('product', []);
        changeVisibility();
      }}
      onOkClick={saveProduct}
      body={<ModalBody />}
      widthModal={900}
      loading={saveProductRelease.isLoading}
    />
  );
};
