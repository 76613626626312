import { FC } from "react";
import { IConferenceButton } from "./interfaces";
import { Icon } from "@/Components";

import styles from './styles.module.scss';

export const ConferenceButton: FC<IConferenceButton> = ({
  isStatic = false,
  isActive = false,
  onClick,
  type
}) => {
  return (
    <div
      className={`
        ${styles['conference-button-default']}
        ${!isStatic && !isActive ? styles['conference-button-clickable'] : ''}
        ${!isStatic && isActive && type === 'correct' ? styles['correct-active'] : ''}
        ${!isStatic && isActive && type === 'incorrect' ? styles['incorrect-active'] : ''}
      `}
      onClick={onClick}
    >
      <Icon 
        icon={type === 'correct' ? 'check' : 'close-x'}
        color={
          !isStatic && isActive ? 'text-800' :
          type === 'correct' ? 'status-success-base' :
            'status-danger-base'
        }
        className={isStatic ? styles['icon-customized'] : ''}
      />
    </div>
  );
};
