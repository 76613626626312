import React, { ReactNode, useState } from 'react';
import { Collapse as AntdCollapse } from 'antd';
import { IFChildren } from '../Form';
import { Button, Tooltip } from '@/Components';

import { Icon, Text } from '..';

import styles from './Card.module.scss';
import './Card.override.scss';
import { IconName } from '../Icon/IconClasses';

const { Panel } = AntdCollapse;

export const getCardStatus = (
  fieldNames: string[],
  formProps: IFChildren
): 'brand' | 'default' | 'success' | 'error' => {
  if (!formProps) return 'default';

  const errorKeys = Object.keys(formProps.errors || {});
  const touchedKeys = getKeysAsString(formProps.touched);
  const hasErrors = fieldNames.some((x) => errorKeys.includes(x));

  if (hasErrors) return 'error';
  if (formProps.submitCount > 0) return 'success';

  const isTouched = fieldNames.some((x) => touchedKeys.includes(x));
  return isTouched ? 'brand' : 'default';
};

const getKeysAsString = (obj: any) => {
  const arr: string[] = [];

  const keys = Object.keys(obj);

  keys.forEach((key) => {
    if (typeof obj[key] === 'object') {
      const deepKeys = getKeysAsString(obj[key]);

      arr.push(...deepKeys.map((deepKey) => `${key}.${deepKey}`));
    } else {
      arr.push(key);
    }
  });

  return arr;
};

export interface ICard {
  id?: string;
  title?: any;
  status?: 'brand' | 'default' | 'success' | 'error';
  onClose?: boolean;
  children: ReactNode;
  withoutCollapse?: boolean;
  showButtonTitle?: boolean;
  showLeftButton?: boolean;
  leftButtonText?: any;
  showLeftActionElement?: boolean;
  leftActionElement?: ReactNode;
  buttonTittleText?: any;
  isButtonTitleDisabled?: boolean;
  onButtonTitleClick?: () => void;
  onButtonLeftClick?: () => void;
  withIconTooltip?: {
    title: string;
    icon: IconName;
  };

  draggable?: boolean;
  className?: string;
  icon?: IconName;
  dragAttributes?: any;
  dragListeners?: any;
}

export const Card: React.FC<ICard> = ({
  id,
  title,
  status,
  onClose,
  children,
  withoutCollapse,
  showButtonTitle = false,
  buttonTittleText = 'your text here',
  leftButtonText = 'your text here',
  showLeftButton,
  showLeftActionElement = false,
  leftActionElement,
  isButtonTitleDisabled = false,
  onButtonTitleClick,
  onButtonLeftClick,
  withIconTooltip,
  className,
  draggable = false,
  dragAttributes,
  dragListeners,
}) => {
  const [isActiveIcon, setIsActiveIcon] = useState(false);

  return (
    <AntdCollapse
      className={`${styles['collapse']} ${
        withoutCollapse ? 'collapse-withoutCollapse' : ''
      } ${className}`}
      defaultActiveKey={onClose ? undefined : ['1']}
      collapsible={withoutCollapse ? 'disabled' : 'header'}
      expandIcon={({ isActive }) => {
        setIsActiveIcon(isActive ?? false);
        return <></>;
      }}
    >
      <Panel
        id={id}
        className={styles['panel']}
        header={
          <div
            className={styles['panel-header']}
            style={{ paddingBottom: isActiveIcon ? '15px' : '20px' }}
          >
            <Text
              className={styles['title']}
              type="heading4"
              color={'text-50'}
              children={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {draggable && (
                    <div
                      {...dragAttributes}
                      {...dragListeners}
                      style={{ cursor: 'grab' }}
                    >
                      <Icon
                        color="text-50"
                        icon={'drag'}
                        style={{ marginRight: '10px', cursor: 'grab' }}
                      />
                    </div>
                  )}

                  {title}
                  {withIconTooltip && withIconTooltip.icon && (
                    <Tooltip
                      title={withIconTooltip.title}
                      showMe
                      targetOffset={[-5, 0]}
                      children={
                        <>
                          <Icon
                            className={styles['icon-with-tooltip']}
                            icon={withIconTooltip.icon}
                            color="text-300"
                            size="SM"
                          />
                        </>
                      }
                    />
                  )}
                  {!withoutCollapse && !showLeftActionElement && !showLeftButton && !showButtonTitle && (
                    <Icon
                      className={styles['rotate-' + isActiveIcon]}
                      size="L"
                      icon="chevron-down"
                      color="text-50"
                    />
                  )}
                </div>
              }
            />
            <div
              style={{
                display: 'flex',
                gap: '5px',
                alignItems: 'center',
              }}
              onClick={(e) => e.stopPropagation()}
            >
              {showLeftActionElement && leftActionElement}
              {showLeftButton && (
                <Button
                  type="secondary"
                  disabled={isButtonTitleDisabled}
                  size="xl"
                  onClick={() => onButtonLeftClick?.()}
                  children={leftButtonText}
                />
              )}
              {showButtonTitle && (
                <Button
                  type="secondary"
                  disabled={isButtonTitleDisabled}
                  size="xl"
                  onClick={() => onButtonTitleClick?.()}
                  children={buttonTittleText}
                />
              )}
              {!withoutCollapse && (showLeftActionElement || showLeftButton || showButtonTitle) && (
                <Icon
                  className={styles['rotate-' + isActiveIcon]}
                  size="L"
                  icon="chevron-down"
                  color="text-50"
                />
              )}
            </div>
          </div>
        }
        key="1"
      >
        <div className={styles['body']}>{children}</div>
      </Panel>
      {!withoutCollapse && (
        <div
          className={`${styles['div-color']} ${status ? styles[status] : ''}`}
        />
      )}
    </AntdCollapse>
  );
};
