import { IUseMemorizations } from './interfaces';
import { useTranslation } from 'react-i18next';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { useMemo } from 'react';
import { IDeleteModalTable } from '@/Components/Table';
import { DeleteModalOnTable } from '@/Utils/DeleteModalUtils';
import { RemoveLinkedProductModal } from '../Components/DeleteModal';
import { Placements } from '@/Components/Tooltip';

export const useListMemorizations = ({
  selectedRowsOnTable,
  messageLinked,
  getShortcut,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const selectedAllItemsHotkey = getShortcut('selectAll');
  const removeItemHotkey = getShortcut('deleteSelected');

  const headerOptions = {
    nameKey: 'descricao',
    hasSearch: true,
    searchPlaceholder: t('products.productList.searchPlaceholder'),
    deleteButton: {
      label: t('common.remove'),
      permission: {
        permission: PermissionEnum.Estoque_MensagemProduto_RemoverProduto,
        tooltip: 'noPermission.supply.messageProduct.removeLink',
      },
      tooltip: {
        title: removeItemHotkey.tooltip,
        placement: 'topRight' as Placements,
      },
    },
  };

  const deleteModal = useMemo(
    (): IDeleteModalTable | undefined => ({
      title:
        selectedRowsOnTable &&
        `${t('common.remove')} ${
          selectedRowsOnTable.length > 1
            ? `${selectedRowsOnTable.length} ${t('common.productsUppercase')}?`
            : `"${selectedRowsOnTable[0]?.descricao}" ${t(
                'supply.productMessage.details.modalRemoveLinked.messageTitle'
              )}?`
        }`,
      body:
        selectedRowsOnTable && selectedRowsOnTable?.length > 1 ? (
          DeleteModalOnTable(
            selectedRowsOnTable,
            `${t('modal.removeLinkedModal.removeQuestion')} ${t(
              'common.forTheProducts'
            )}:`,
            'descricao',
            ''
          )
        ) : (
          <RemoveLinkedProductModal
            selectedRowsOnTable={selectedRowsOnTable}
            messageSelected={messageLinked}
          />
        ),
      buttons: {
        okButtonColor: 'status-danger-base',
      },
      okButtonName: 'common.remove',
      widthModal: 400,
    }),
    [selectedRowsOnTable, t, messageLinked]
  );

  const tableHeaderHotKeyTooltip = {
    title: selectedAllItemsHotkey.tooltip,
    placement: 'topLeft' as Placements,
    targetOffset: [11, 0],
  };

  return {
    headerOptions,
    deleteModal,
    tableHeaderHotKeyTooltip,
  };
};
