import styles from './styles.module.scss';

interface ICardDates {
  children: React.ReactNode;
  withMarginBottom?: boolean;
}

export const CardDates: React.FC<ICardDates> = ({ 
  children, 
  withMarginBottom = false,
}) => {
  return (
    <div 
      className={
        `${styles['card-dates']} 
         ${withMarginBottom ? styles['margin-bottom'] : ''}`
      }>
      <div>{children}</div>
    </div>
  );
};
