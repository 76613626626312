import { FC } from 'react';
import { Table } from '@/Components';
import { useTranslation } from 'react-i18next';
import { editTableStatus } from '@/Utils/EditModalTableUtils';
import { PageLoading } from '@/Layouts/PageLoading';
import { customersCollums } from './customers.columns';
import { useListStates } from './Hooks/useListStates';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListMemorizations } from './Hooks/useListMemorizations';
import { useListFunctions } from './Hooks/useListFunctions';
import { useCustomersListShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/Sales/Customers/list';

export const List: FC = () => {
  const { t } = useTranslation();

  const { selectedRowsOnTable, setSelectedRowsOnTable, selectedItems } =
    useListStates();

  const {
    changeStatus,
    deleteClient,
    clientList,
    isLoading,
    refetchClientList,
  } = useListCallAPI();

  const { getShortcut } = useCustomersListShortcuts(t);

  const { onEdit, onRowDoubleClick, onDelete } = useListFunctions({
    changeStatus,
    setSelectedRowsOnTable,
    deleteClient,
  });

  const { headerOptions, rowsOptions, deleteModal, tableHeaderHotKeyTooltip } =
    useListMemorizations({
      onRowDoubleClick,
      selectedRowsOnTable,
      getShortcut,
    });

  return (
    <>
      <PageLoading loading={isLoading}>
        <Table
          columns={customersCollums(t)}
          headerOptions={headerOptions}
          onEdit={onEdit}
          editStatusModalLoading={changeStatus.isLoading}
          rowsOptions={rowsOptions}
          editOptions={editTableStatus()}
          loading={isLoading}
          hasSelection
          fieldStatus="ativo"
          pagination={clientList}
          onRefetch={refetchClientList}
          onDelete={onDelete}
          deleteModal={deleteModal}
          selectedRowsOnTable={selectedRowsOnTable}
          setSelectedRowsOnTable={setSelectedRowsOnTable}
          tableHeaderTooltip={tableHeaderHotKeyTooltip}
          keyToSelect="externalId"
          initialRowsSelected={selectedItems}
        />
      </PageLoading>
    </>
  );
};
