import { Button, Card, Icon, Text } from '@/Components';
import { ReleaseTable } from '../ReleaseTable';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { AddProductModal } from '../AddProductModal';
import { OneItemPdf } from '../PdfComponent';
import { PDFViewer } from '@react-pdf/renderer';
import { Loading } from '@/Components/Loading';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';

interface ITableCardProps {
  index: number;
  item: any;
  inventory: any;
  inventoryDetails: any;
  startRelease: boolean;
  inventoryIsCanceled: boolean;
  hideQuantityColumns: boolean;
  inventoryCode: string;
  hideQuantityPrint?: boolean;
  isConferenceStatus?: boolean;
  inventoryFinish?: boolean;
}

export const TableCard: React.FC<ITableCardProps> = ({
  index,
  inventory,
  inventoryDetails,
  item,
  startRelease,
  inventoryIsCanceled,
  hideQuantityColumns,
  inventoryCode,
  isConferenceStatus,
  hideQuantityPrint,
  inventoryFinish,
}) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [isPdfVisible, setIsPdfVisible] = useState(false);
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
  const waitingRelease = 1;
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: item.localEstoqueExternalId,
  });
  const style = {
    transform: CSS.Translate.toString(transform),
  };

  return (
    <>
      {isPdfVisible && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            zIndex: 1033,
            background: 'white',
          }}
        >
          {isGeneratingPdf && (
            <div
              style={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '5px',
              }}
            >
              <Loading size="30px" />
              <Text type="heading1">{t('common.loading')}</Text>
            </div>
          )}

          <PDFViewer
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          >
            <OneItemPdf
              supplyPlace={item.localEstoqueDescricao}
              tableItems={item.itens}
              inventoryCode={inventoryCode}
              onRenderPdf={() => setIsGeneratingPdf(false)}
              hideQuantityPrint={hideQuantityPrint}
            />
          </PDFViewer>
          <div
            style={{
              position: 'fixed',
              bottom: '10px',
              left: '0px',
              zIndex: 1034,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {!isGeneratingPdf && (
              <Button
                type="primary"
                onClick={() => {
                  setIsGeneratingPdf(false);
                  setIsPdfVisible(false);
                }}
              >
                {t('common.back')}
              </Button>
            )}
          </div>
        </div>
      )}
      <div ref={setNodeRef} style={style}>
        <Card
          key={index}
          title={item.localEstoqueDescricao}
          showButtonTitle={!inventoryIsCanceled ? true : false}
          dragAttributes={attributes}
          dragListeners={listeners}
          buttonTittleText={
            <div style={{ display: 'flex', gap: '2px' }}>
              <Icon color="text-50" icon="printer" /> {t('common.print')}
            </div>
          }
          icon="drag"
          showLeftButton={
            !inventoryIsCanceled &&
            !inventoryFinish &&
            inventory?.statusInventarioId !== waitingRelease
              ? true
              : false
          }
          leftButtonText={
            <div style={{ display: 'flex', gap: '2px' }}>
              <Icon color="text-50" icon="plus" />{' '}
              {t('supply.inventory.release.addProduct')}
            </div>
          }
          onButtonLeftClick={() => setVisible(!visible)}
          onButtonTitleClick={() => {
            setIsGeneratingPdf(true);
            setIsPdfVisible(true);
          }}
          draggable
        >
          <ReleaseTable
            inventoryItem={item}
            startRelease={startRelease}
            tableItems={item.itens}
            showQuantity={!inventory.ocultarQuantidadeLancamento}
            initialIndexCount={
              index === 0
                ? 0
                : inventoryDetails.especificacao
                    .slice(0, index)
                    .reduce(
                      (acc: number, curr: any) => acc + curr.itens.length,
                      0
                    )
            }
            hideQuantityColumns={hideQuantityColumns}
            isConferenceStatus={!!isConferenceStatus}
          />
        </Card>
      </div>
      <AddProductModal
        visible={visible}
        changeVisibility={() => setVisible(!visible)}
        supplyPlaceId={item.localEstoqueExternalId}
      />
    </>
  );
};
