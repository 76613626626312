import { FC } from 'react';
import { ContactCard } from '@/Components/EntityInformation/ContactCard';
import { AdressCard } from '@/Components/EntityInformation/AddressCard';
import { DocumentCard } from '@/Components/EntityInformation/DocumentCard';
import { InfoCard } from './Components/InfoCard';
import { IDetailsTab } from './interfaces';
import { useDetailsCallAPI } from './Hooks/useDetailsCallAPI';
import { useDetailsMemorizations } from './Hooks/useDetailsMemorizations';

import styles from './PrescribersDetails.module.scss';
import { ContactCardSchema } from '@/Pages/Sectors/Order/Supplier/Create/Components/Cards/ContactCard/contactCard.schema';
import { DocumentCardSchema } from '@/Pages/Sectors/Order/Supplier/Create/Components/Cards/DocumentCard/documentCard.schema';

export const DetailsTab: FC<IDetailsTab> = ({ prescriber, getShortcut }) => {
  const {
    addContact,
    editContact,
    deleteContact,
    changeFavoriteContact,
    addAdress,
    editAdress,
    deleteAdress,
    changeFavoriteAddress,
    editDocument,
    addDocument,
    deleteDocument,

    documentList,
    fetchNewPageDocument,
  } = useDetailsCallAPI();

  const {
    entityContactPermission,
    entityAddressPermission,
    entityDocumentPermission,
  } = useDetailsMemorizations();

  const editPrescriberDetailsHotkey = getShortcut('editPrescriberDetails');
  const editPrescriberContactHotkey = getShortcut('editPrescriberContact');
  const editPrescriberAddressHotkey = getShortcut('editPrescriberAddress');
  const editPrescriberDocumentHotkey = getShortcut('editPrescriberDocument');

  return (
    <div className={styles['content']}>
      <div className={styles['side-left']}>
        <InfoCard
          prescriber={prescriber}
          tooltip={{
            title: editPrescriberDetailsHotkey.tooltip,
            targetOffset: [0, -15],
          }}
          dataTestIdEditButton="details-edit-button"
        />
        <ContactCard
          addEntity={addContact}
          editEntity={editContact}
          deleteEntity={deleteContact}
          entityContact={prescriber?.prescritorContatos}
          entityExternalId={prescriber?.externalId}
          entityContactExternalIdName="prescritorContatoExternalId"
          entityExternalIdName="prescritorExternalId"
          changeFavorite={changeFavoriteContact}
          entityContactPermission={entityContactPermission}
          schema={ContactCardSchema}
          dataTestIdEditButton="contact-edit-button"
          tooltipEditButton={{
            title: editPrescriberContactHotkey.tooltip,
            targetOffset: [120, -15],
          }}
        />
      </div>
      <div className={styles['side-right']}>
        <AdressCard
          addEntity={addAdress}
          editEntity={editAdress}
          deleteEntity={deleteAdress}
          entityAdress={prescriber?.prescritorEnderecos}
          entityExternalId={prescriber?.externalId}
          entityAddressExternalIdName="prescritorEnderecoExternalId"
          entityExternalIdName="prescritorExternalId"
          changeFavorite={changeFavoriteAddress}
          entityAddressPermission={entityAddressPermission}
          dataTestIdEditButton="address-edit-button"
          tooltipEditButton={{
            title: editPrescriberAddressHotkey.tooltip,
            targetOffset: [270, -15],
          }}
        />
        <DocumentCard
          addEntity={addDocument}
          editEntity={editDocument}
          deleteEntity={deleteDocument}
          entityDocuments={prescriber?.prescritorDocumentos}
          entityExternalId={prescriber?.externalId}
          entityDocumentExternalIdName="prescritorDocumentoExternalId"
          entityExternalIdName="prescritorExternalId"
          entityDocumentPermission={entityDocumentPermission}
          documentList={documentList}
          fetchNewPageDocument={fetchNewPageDocument}
          schema={DocumentCardSchema}
          dataTestIdEditButton="document-edit-button"
          tooltipEditButton={{
            title: editPrescriberDocumentHotkey.tooltip,
            targetOffset: [270, -15],
          }}
        />
      </div>
    </div>
  );
};
