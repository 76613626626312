export const shortcutsEN = {
  headerMenu: {
    openUserMenu: 'Open user menu',
    changeCompany: 'Change company',
  },
  sectorMenu: {
    openSectorMenu: 'Open sectors menu',
  },
  sale: {
    tickets: {
      list: {
        addTickets: 'Add new service',
      },
      details: {
        addFinishedProduct: 'Adicionar produto acabado',
        viewHistory: 'View history',
        approveSalesOrder: 'Approve sales order',
        rejectSalesOrder: 'Reject sales order',
        addManipulatedRecipe: 'Add manipulated recipe',
      },
    },
  },
};

export const shortcutsPT = {
  headerMenu: {
    openUserMenu: 'Abrir menu de usuário',
    changeCompany: 'Trocar empresa',
  },
  sectorMenu: {
    openSectorMenu: 'Abrir menu de setores',
  },
  sale: {
    tickets: {
      list: {
        addTickets: 'Adicionar novo atendimento',
      },
      details: {
        addFinishedProduct: 'Adicionar produto acabado',
        viewHistory: 'Visualizar histórico',
        approveSalesOrder: 'Aprovar pedido de venda',
        rejectSalesOrder: 'Reprovar pedido de venda',
        addManipulatedRecipe: 'Adicionar receita manipulada',
      },
    },
  },
};
