import { FC } from 'react';
import { Icon, Modal, Text } from '@/Components';
import { IFinishModal } from './interfaces';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { Trans, useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

export const FinishModal: FC<IFinishModal> = ({
  visible,
  onOkClick,
  loading,
  onCancel,
  inventoryCode,
  incorrectItemsQuantity,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('supply.inventory.conference.finishModal.title')}
      visible={visible}
      body={
        <div style={{ padding: '20px' }}>         
          <Text color="text-50" type="ui-tiny-content">
            <span style={{ marginBottom: '10px' }}>
              {t('supply.inventory.conference.finishModal.body', { inventoryCode })}
              <br/>
              <Trans
                i18nKey={
                  incorrectItemsQuantity !== 1
                    ? 'supply.inventory.conference.finishModal.complementBodyForMany'
                    : 'supply.inventory.conference.finishModal.complementBodyForOne'
                }
                components={[ 
                  <strong />,
                  <span 
                    style={{ 
                      color: 'var(--color-status-danger-base)' 
                    }} 
                  />,
                ]}
                values={{ incorrectItemsQuantity }}
              />
            </span>
          </Text>
          <Divflex>
            <Icon
              icon={'exclamation-upside-down'}
              size={'SM'}
              color={'text-400'}
              style={{ padding: '18px 18px 0 8px' }}
            />
            <Text
              type='paragraph2'
              color={'text-400'}
              children={t('supply.inventory.conference.finishModal.footer')}
              className={styles['text']}
            />
          </Divflex>     
        </div>
      }
      okButtonColor='primary-base'
      okButtonName={t('supply.inventory.conference.finishModal.okButtonName')}
      onCancelClick={onCancel}
      onClose={onCancel}
      onOkClick={onOkClick}
      loading={loading}
    />
  );
};
