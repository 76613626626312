import { IUseMemorizations } from './interfaces';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '@/Store';
import { IDeleteModalTable } from '@/Components/Table';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { Placements } from '@/Components/Tooltip';

import {
  TitleHandler,
  BodyHandler,
} from '../Modal/packingClassificationListUtils';

export const useListMemorizations = ({
  selectedRowsOnTable,
  getShortcut,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const addPackingClassificationHotkey = getShortcut('addPackingClassification');
  const selectAllTableHotKey = getShortcut('selectAll');
  const deletePackingClassificationHotkey = getShortcut('deleteSelected');
  const editPackingClassificationHotkey = getShortcut('editSelected');

  const headerOptions = {
    hasSearch: true,
    newButton: {
      label: t('production.packingClassification.list.newButton'),
      onClick: () => history.push('/production/packing-classification/create'),
      permission: {
        permission: PermissionEnum.Producao_ClassificacaoEmbalagem_Cadastrar,
        tooltip: 'noPermission.production.packingClassification.create',
      },
      tooltip: {
        title: addPackingClassificationHotkey.tooltip,
        placement: 'topRight' as Placements,
      },
      dataTestId: 'production-packaging-classification-new-button',
    },
    editButton: {
      options: [
        {
          label: t('production.packingClassification.list.editStatus'),
          key: 's',
          isStatusOnly: true,
          permission: {
            permission:
              PermissionEnum.Producao_ClassificacaoEmbalagem_AlterarStatus,
            tooltip: 'noPermission.production.packingClassification.statusEdit',
          },
        },
      ],
      permission: {
        permission:
          PermissionEnum.Producao_ClassificacaoEmbalagem_EditarDetalhes,
        tooltip: 'noPermission.production.packingClassification.edit',
      },
      tooltip: {
        title: editPackingClassificationHotkey.tooltip,
        placement: 'topRight' as Placements,
      },
    },
    searchPlaceholder: t(
      'production.packingClassification.list.searchPlaceholder'
    ),
    deleteButton: {
      permission: {
        permission: PermissionEnum.Producao_ClassificacaoEmbalagem_Excluir,
        tooltip: 'noPermission.production.packingClassification.delete',
      },
      tooltip: {
        title: deletePackingClassificationHotkey.tooltip,
        placement: 'topRight' as Placements,
      },
      dataTestId: 'delete-packaging-classification-button',
    },
    nameKey: 'descricao',
  };

  const rowOptions = {
    onRowDoubleClick: (x: any) =>
      history.push(
        `/production/packing-classification/${x.externalId}/details`
      ),
  };

  const deleteModal = useMemo(
    (): IDeleteModalTable | undefined => ({
      title: TitleHandler(selectedRowsOnTable, t),
      body: BodyHandler(selectedRowsOnTable, t),
      buttons: {
        okButtonColor: 'status-danger-base',
        okButtonDataTestId: 'delete-packaging-classification-submit-button',
      },
      widthModal: 400,
    }),
    [t, selectedRowsOnTable]
  );

  const tableHeaderHotKeyTooltip = {
    title: selectAllTableHotKey.tooltip,
    placement: 'topLeft' as Placements,
    targetOffset: [11, 0],
  };

  return {
    headerOptions,
    rowOptions,
    deleteModal,
    tableHeaderHotKeyTooltip,
  };
};
