import { useQueryClient } from '@tanstack/react-query';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import {
  DELETE_LABEL_TEMPLATES,
  FETCH_LIST_LABEL_TEMPLATES,
  EDIT_STATUS_LABEL_TEMPLATES,
} from '@/ControllerApiHook/UniqIds/Production/LabelTemplatesKeys';
import { LabelTemplatesAPI } from '@/Data/API/Production/LabelTemplates';

export const useListCallAPI = () => {
  const queryClient = useQueryClient();

  const {
    data: labelTemplatesList,
    isLoading: isLoadingLabelTemplatesList,
    refetch: refetchLabelTemplatesList,
  } = useControllerQueryListApiHook({
    uniqId: FETCH_LIST_LABEL_TEMPLATES,
    entityApi: LabelTemplatesAPI.getLabelTemplatesList,
    pagination: {
      sorter: { column: 'descricao', direction: 'ASC' },
    },
  });

  const deleteLabelTemplates = useControllerMutateApiHook({
    uniqId: DELETE_LABEL_TEMPLATES,
    entityApi: LabelTemplatesAPI.deleteLabelTemplate,
    options: {
      onSuccess: () =>
        queryClient.invalidateQueries([FETCH_LIST_LABEL_TEMPLATES]),
    },
  });

  const editLabelTemplateStatus = useControllerMutateApiHook({
    uniqId: EDIT_STATUS_LABEL_TEMPLATES,
    entityApi: LabelTemplatesAPI.editLabelTemplateStatus,
    options: {
      onSuccess: () =>
        queryClient.invalidateQueries([FETCH_LIST_LABEL_TEMPLATES]),
    },
  });

  return {
    deleteLabelTemplates,
    editLabelTemplateStatus,
    labelTemplatesList,
    isLoadingLabelTemplatesList,
    refetchLabelTemplatesList,
  };
};
