import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { HorizontalTabs } from '@/Components';
import { PageLoading } from '@/Layouts/PageLoading';
import { useParams } from 'react-router-dom';
import { DeleteLotModal } from './Modal';
import { useEditCallAPI } from './Hooks/useEditCallAPI';
import { useEditStates } from './Hooks/useEditStates';
import { useEditMemorizations } from './Hooks/useEditMemorizations';
import { useEditFunctions } from './Hooks/useEditFunctions';
import { useLotDetailsShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/inventory/lots/details';

export const EditLot: FC = () => {
  const { t } = useTranslation();

  const { externalId } = useParams<{ externalId: string }>();

  const { disabled, setDisabled, visibleModal, setVisibleModal } =
    useEditStates();

  const { lot, isLoading } = useEditCallAPI({ externalId });

  const { getShortcut } = useLotDetailsShortcuts(t, {});

  const editMoreActions = getShortcut('editMoreActions');

  const { items, rightButton, leftButton, dropdownItems } =
    useEditMemorizations({
      disabled,
      setDisabled,
      setVisibleModal,
      lot,
      getShortcut,
    });

  const { onChangeVisibleModal } = useEditFunctions({ setVisibleModal });

  return (
    <PageLoading loading={isLoading}>
      <HorizontalTabs
        type="secondary"
        items={items}
        dropdownDirection="bottomRight"
        dropdownTitle={t('admin.editUserPage.tab.buttonActions.title')}
        leftIcon="menu-ellipsis-horizontal"
        rightButton={rightButton}
        leftButton={leftButton}
        dropdownItens={dropdownItems}
        dataTestIdDropdownButton="more-actions-button"
        moreActionsButton={{ title: editMoreActions.tooltip }}
      />
      <DeleteLotModal
        lot={lot}
        visibleModal={visibleModal}
        changeVisibleModal={onChangeVisibleModal}
      />
    </PageLoading>
  );
};
