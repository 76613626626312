export const FETCH_LIST_INVENTORY = '[INVENTORY] FETCH_LIST_INVENTORY';
export const CREATE_INVENTORY = '[INVENTORY] CREATE_INVENTORY';
export const FETCH_GET_INVENTORY = '[INVENTORY] FETCH_GET_INVENTORY';
export const SAVE_PUT_INVENTORY = '[INVENTORY] SAVE_PUT_INVENTORY';
export const DELETE_INVENTORY = '[INVENTORY] DELETE_INVENTORY';
export const FETCH_GET_INVENTORY_DETAILS =
  '[INVENTORY] FETCH_GET_INVENTORY_DETAILS';
export const FETCH_INVENTORY_RELEASE = '[INVENTORY] FETCH_INVENTORY_RELEASE';
export const CANCEL_RELEASE_INVENTORY = '[INVENTORY] CANCEL_RELEASE_INVENTORY';
export const CANCEL_INVENTORY = '[INVENTORY] CANCEL_INVENTORY';
export const SAVE_PUT_FINISH_RELEASE = '[INVENTORY] SAVE_FINISH_RELEASE';
export const SAVE_PUT_DRAFT_RELEASE = '[INVENTORY] SAVE_DRAFT_RELEASE';
export const CREATE_CONFERENCE_DRAFT = '[INVENTORY] CREATE_CONFERENCE_DRAFT';
export const FINISH_CONFERENCE = '[INVENTORY] FINISH_CONFERENCE';
export const SAVE_PRODUCT_RELEASE = '[INVENTORY] SAVE_PRODUCT_RELEASE';
export const SAVE_RELAUNCH_INVENTORY = '[INVENTORY] SAVE_RELAUNCH_INVENTORY';
