import React, { FC, useCallback, useMemo, useState } from 'react';
import { Button, Text, i18n, Skeleton, Tooltip } from '../../';
import { Colors } from '../../../Utils/ColorUtils';
import { PopOver } from '../../PopOver';
import { getMenuById, SideMenuItems } from '../SideMenu.items';
import { SectorItem } from './components/SectorItem';
import { history } from '../../../Store';
import { motion } from 'framer-motion';
import { IMenuItem } from '../MenuItem';
import { getCurrentUserRoles } from '@/Utils/PermissionUtils';
import { Context, CurrentContextType } from '@/Contexts/context';
import { getKey } from '@/Utils/OnKeyDownKeys';
import { useTranslation } from 'react-i18next';
import { useSectorMenuShortcuts } from '@/Components/Shortcuts/keymaps/Components/SectorMenu/sectorMenu';
import { Hotkey } from '@/Components/Shortcuts/Hotkey';
import styles from './SectorMenu.module.scss';

export interface ISectorMenu {
  isCollapsed: boolean;
  modules?: string[];
}

export const SectorMenu: FC<ISectorMenu> = ({ isCollapsed, modules }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const isSingleModule = useMemo(() => modules?.length === 1, [modules]);
  const { currentContext } = React.useContext(Context) as CurrentContextType;

  const menu = getMenuById(currentContext);

  const tabIndexSectorMenu = 2;

  const { getShortcut } = useSectorMenuShortcuts(t);

  const openSectorMenuHotkey = getShortcut('openSectorMenu');

  return (
    <>
      <Tooltip
        showMe={!!openSectorMenuHotkey.tooltip}
        title={openSectorMenuHotkey.tooltip}
        placement="right"
        targetOffset={[5, -1]}
      >
        <PopOver
          visible={open}
          onOpenChange={() => !isSingleModule && setOpen(!open)}
          placement="rightBottom"
          title={headerSelectorMenu()}
          content={
            <ContentSelectorMenu modules={modules || []} setOpen={setOpen} />
          }
          trigger="click"
        >
          {!!currentContext ? (
            <Button
              tabIndex={tabIndexSectorMenu}
              id="sector-menu"
              type="no-color"
              noIconPadding={true}
              leftIcon={menu?.context?.icon || 'setting'}
              rightIcon={'chevron-right'}
              className={styles['btn-color']}
              fullWidth={true}
              style={{
                backgroundColor: `${
                  Colors[menu?.context.backgroundColor || 'white']
                }`,
              }}
              disabled={isSingleModule}
              contentChild="start"
              dataTestId="open-sector-menu-button"
            >
              <motion.div
                style={{ overflow: 'hidden' }}
                animate={{ width: isCollapsed ? '0px' : '100%' }}
              >
                <div style={{ marginLeft: 10, width: 100, display: 'flex' }}>
                  <Text
                    type="ui-tiny-bold"
                    children={menu ? (i18n.t(menu.context.name) as string) : ''}
                  />
                </div>
              </motion.div>
            </Button>
          ) : (
            <div className={styles['skeleton']}>
              <Skeleton size="gg" />
            </div>
          )}
        </PopOver>
      </Tooltip>
    </>
  );
};

export const headerSelectorMenu = () => {
  return (
    <div className={styles['header']}>
      <Text type="paragraph2" color="text-400" className={styles['text']}>
        Alterar para setor
      </Text>
      <div className={styles['separator']}></div>
    </div>
  );
};

interface IContentSelectorMenu {
  setOpen: (open: boolean) => void;
  modules: string[];
}

export const ContentSelectorMenu: FC<IContentSelectorMenu> = ({
  modules,
  setOpen,
}) => {
  const getDefaultPage = useCallback((items: IMenuItem[]) => {
    const currentPermissions = getCurrentUserRoles();
    return items.find((x) =>
      currentPermissions.some((y) => y === x.permission?.permission)
    );
  }, []);

  const items = SideMenuItems.filter((item) =>
    modules.includes(item.context.id)
  );

  const tabIndexOffset = 3;

  return (
    <div className={styles['content']}>
      {items.map((item, index) => (
        <div
          className={styles['SectorItem']}
          key={item.context.id}
          tabIndex={index + tabIndexOffset}
          onClick={() => {
            const defaultPage = getDefaultPage(item.items)?.link;
            defaultPage && history.push(defaultPage);
            setOpen(false);
          }}
          onKeyDown={(e) => {
            if (e.key === getKey.enterKey) {
              const defaultPage = getDefaultPage(item.items)?.link;
              defaultPage && history.push(defaultPage);
              setOpen(false);
            }
          }}
          data-testid={`sector-item-${index}`}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <SectorItem
              icon={item.context.icon}
              backgroundColor={item.context.backgroundColor}
              text={i18n.t(item.context.name)}
            />
          </div>
          <Hotkey
            hotKey={
              item.context.id === 'Administrativo'
                ? 'a'
                : item.context.id === 'Estoque'
                ? 'e'
                : item.context.id === 'Produção'
                ? 'p'
                : item.context.id === 'Compras'
                ? 'c'
                : 'v'
            }
            height={17}
            width={16}
            fontSize={10}
          />
        </div>
      ))}
    </div>
  );
};
