import { IButtonDropDownItems } from "@/Components/Button/ButtonDropdown";
import { IconName } from "@/Components/Icon/IconClasses";
import { useTranslation } from "react-i18next";
import { IUseMemorizations } from "./interfaces";
import { useMemo } from "react";
import { useFormikContext } from "formik";

export const useConferenceMemorizations = ({
  onSaveConferenceDraft,
  setPrintViewerVisible,
  supplyPlaceSelected,
  inventoryRelease,
  calculateQuantityDivergent,
  printDivergent,
  tolerance,
}: IUseMemorizations) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  const CONFERENCE_STATUS = 3;

  const dropdownConferenceOptionsItems: IButtonDropDownItems = [
    {
      label: t('supply.inventory.release.dropdownOptions.save'),
      icon: 'diskette' as IconName,
      targetOffsetTooltip: [35, -15],
      onClick: onSaveConferenceDraft,
    },
    {
      label: t('supply.inventory.release.dropdownOptions.print'),
      icon: 'printer' as IconName,
      targetOffsetTooltip: [35, -15],
      onClick: () => setPrintViewerVisible(true),
    },
  ];

  const supplyPlaceFormatted = useMemo(() => {
    if(!supplyPlaceSelected)
      return [];

    let supplyPlace = inventoryRelease?.especificacao.filter(
      (item) => item.localEstoqueExternalId === supplyPlaceSelected
    );
    
    if (tolerance > 0 && printDivergent) {
      return supplyPlace
        ?.map((item) => ({
          ...item,
          itens: item.itens.filter((produto) =>
            calculateQuantityDivergent(
              produto.quantidade, 
              produto.quantidadeInventariada, 
              tolerance
            )
          ),
        }))
        .filter((item) => item.itens.length > 0);
    }

    return supplyPlace;
  }, [supplyPlaceSelected, tolerance, printDivergent]);

  return {
    CONFERENCE_STATUS,
    dropdownConferenceOptionsItems,
    supplyPlaceFormatted,
  };
};
