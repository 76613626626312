import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { HorizontalTabs } from '../../../../../Components';
import { GET_COMPANY_ADMIN_TYPE } from '../../../../../ControllerApiHook/UniqIds/Accountmanager/CompanyKeys';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { DeleteCompanyModal } from './Modals/deleteModal';
import { CompanyDetails } from './Tabs/Details';
import { history } from '../../../../../Store';
import { useControllerQueryApiHook } from '@/ControllerApiHook/Controller';
import { IGetCompanyResponse } from '@/Data/Interfaces/response/Company/ICompanyResponse';
import { CompanyAPI } from '@/Data/API/Accountmanager/CompanyApi';
import { useAdminCompanyDetailsShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/AdminBootis/Company/details';

export const EditCompany: FC = () => {
  const { t } = useTranslation();

  const [visibleModal, setVisibleModal] = useState(false);

  const { externalId } = useParams<{ externalId: string }>();
  const { data, isLoading } = useControllerQueryApiHook<IGetCompanyResponse>({
    uniqId: GET_COMPANY_ADMIN_TYPE,
    entityApi: CompanyAPI.getCompanyAdmin,
    externalIds: [externalId],
  });

  const { getShortcut } = useAdminCompanyDetailsShortcuts(t);

  const alternateTabDetailsHotkey = getShortcut('alternateTabDetails');
  const editMoreActionsHotkey = getShortcut('editMoreActions');

  return (
    <PageLoading loading={isLoading}>
      <HorizontalTabs
        type="secondary"
        items={[
          {
            label: t('common.details'),
            key: 'details',
            children: data && (
              <CompanyDetails company={data} getShortcut={getShortcut} />
            ),
            dataTestId: 'company-details-tab',
            shortCutTooltip: { title: alternateTabDetailsHotkey.tooltip },
          },
        ]}
        dropdownDirection="bottomRight"
        dropdownTitle={t('admin.editUserPage.tab.buttonActions.title')}
        leftIcon="menu-ellipsis-horizontal"
        leftButton={{
          onClick: () => history.push(`/inventory/products//details`),
          leftIcon: 'Box',
          children: t('supply.lot.details.goToProducts'),
        }}
        moreActionsButton={{ title: editMoreActionsHotkey.tooltip }}
        dataTestIdDropdownButton="more-actions-button"
        dropdownItens={[
          {
            label: 'Excluir',
            icon: 'trash',
            onClick: () => setVisibleModal(true),
            key: 'backspace',
            dataTestId: 'dropdown-option-delete',
          },
        ]}
      />
      {data && (
        <DeleteCompanyModal
          company={data}
          visibleModal={visibleModal}
          changeVisibleModal={(value) => setVisibleModal(value)}
        />
      )}
    </PageLoading>
  );
};
