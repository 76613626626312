import { FC, useCallback, useState } from 'react';
import { history } from '../../../../../Store';
import { useTranslation } from 'react-i18next';
import { editTableStatus } from '../../../../../Utils/EditModalTableUtils';
import { companyCollums } from './company.columns';
import { DeleteModalOnTable } from '../../../../../Utils/DeleteModalUtils';
import {
  CHANGE_STATUS_TYPE,
  FETCH_DELETE_TYPE,
  LIST_COMPANY_ADMIN_TYPE,
} from '../../../../../ControllerApiHook/UniqIds/Accountmanager/CompanyKeys';
import { ConfirmEditModal, DeleteModal } from './List.helper';
import { useQueryClient } from '@tanstack/react-query';
import { IQueryListFilter } from '../../../../../Utils/Http';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { CompanyAPI } from '@/Data/API/Accountmanager/CompanyApi';
import { messageErrorToast } from '@/Utils/MessageErrorToast';
import { handleModalError } from '@/Utils/ErrorCodeUtils/ErrorHandlingUtils';
import { App } from 'antd';
import { cannotDeleteCompanyWithMovementsInTheSystemProps } from '@/Utils/ErrorCodeUtils/ErrorModalUtils/ErrorModalCompany';
import { useAdminCompanyListShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/AdminBootis/Company/list';
import { Placements } from '@/Components/Tooltip';
import { Table } from '@/Components/Table';

interface ICompanyAdminList {
  filter?: IQueryListFilter[];
  hasNewButton?: boolean;
}

export const CompanyAdminList: FC<ICompanyAdminList> = ({
  filter,
  hasNewButton = true,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const app = App.useApp();

  const [selectedRowsOnTable, setSelectedRowsOnTable] = useState<any>([]);
  const [selectedItems, setSelectedItems] = useState<string[] | undefined>(
    undefined
  );

  const { getShortcut } = useAdminCompanyListShortcuts(t);

  const addAdminCompanyHotkey = getShortcut('addAdminCompany');
  const selectAllTableHotKey = getShortcut('selectAll');
  const deleteAdminCompanyHotkey = getShortcut('deleteSelected');
  const editAdminCompanyHotkey = getShortcut('editSelected');

  const changeStatus = useControllerMutateApiHook<any>({
    uniqId: CHANGE_STATUS_TYPE,
    entityApi: CompanyAPI.patchCompanyStatus,
  });

  const deleteCompany = useControllerMutateApiHook<any>({
    uniqId: FETCH_DELETE_TYPE,
    entityApi: CompanyAPI.deleteCompany,
    options: {
      onError: (x) => messageErrorToast(x),
    },
  });

  const { data, isLoading, refetch } = useControllerQueryListApiHook({
    uniqId: LIST_COMPANY_ADMIN_TYPE,
    entityApi: CompanyAPI.listCompanyAdmin,
    pagination: {
      filter,
      sorter: { column: 'nomeFantasia', direction: 'ASC' },
    },
  });

  const onDeleteCompany = useCallback(
    async (company: any) => {
      if (!company) return;
      return await deleteCompany.mutateAsync(
        { empresasExternalId: company.map((x: any) => x.externalId) },
        {
          onError: handleModalError(
            app,
            cannotDeleteCompanyWithMovementsInTheSystemProps(t)
          ),
        }
      );
    },
    [deleteCompany, t, app]
  );

  const onEditCompany = useCallback(
    async (company: any, status: any) => {
      const res = await changeStatus.mutateAsync({
        empresasExternalId: company?.map((x: any) => x.externalId),
        ativa: status?.status === 'success',
      });
      if (res.success) queryClient.invalidateQueries([LIST_COMPANY_ADMIN_TYPE]);
    },
    [changeStatus, queryClient]
  );

  const editButtonProps = {
    tooltip: {
      title: editAdminCompanyHotkey.tooltip,
      placement: 'topRight' as Placements,
    },
    options: [
      {
        label: t('common.status'),
        isStatusOnly: true,
        key: 's',
      },
    ],
  };

  return (
    <>
      <Table
        dataTestId="admin-bootis-comapany"
        columns={companyCollums(t)}
        headerOptions={{
          hasSearch: true,
          ...(hasNewButton && {
            newButton: {
              onClick: () => history.push('/admin-bootis/company/create'),
              label: t('adminBootis.companyList.newButton'),
              dataTestId: 'admin-bootis-company-new-button',
              tooltip: {
                title: addAdminCompanyHotkey.tooltip,
                placement: 'topRight' as Placements,
              },
            },
          }),
          editButton: editButtonProps,
          deleteButton: {
            dataTestId: 'delete-button',
            tooltip: {
              title: deleteAdminCompanyHotkey.tooltip,
              placement: 'topRight' as Placements,
            },
          },
          searchPlaceholder: t('admin.companyList.searchPlaceholder'),
          nameKey: 'nomeFantasia',
        }}
        loading={isLoading}
        hasSelection
        pagination={data}
        fieldStatus="ativa"
        editOptions={editTableStatus()}
        onDelete={onDeleteCompany}
        onEdit={onEditCompany}
        confirmEditModal={ConfirmEditModal(selectedRowsOnTable, t)}
        editStatusModalLoading={changeStatus.isLoading}
        rowsOptions={{
          onRowDoubleClick: (x) =>
            history.push(`/admin-bootis/company/${x.externalId}/details`),
        }}
        onRefetch={refetch}
        deleteModal={{
          customEndTitle: t('common.companys'),
          body:
            selectedRowsOnTable?.length > 1 ? (
              DeleteModalOnTable(
                selectedRowsOnTable,
                `${t('modal.deleteModal.many.title')} ${t(
                  'common.theCompanys'
                )}:`,
                'nomeFantasia',
                t('common.companys')
              )
            ) : (
              <DeleteModal selectedRowsOnTable={selectedRowsOnTable} />
            ),
          buttons: {
            okButtonColor: 'status-danger-base',
            okButtonDataTestId: 'ok-delete-modal-button',
          },
        }}
        translations={{
          editModal: { customEndTitle: t('common.companys') },
        }}
        selectedRowsOnTable={selectedRowsOnTable}
        setSelectedRowsOnTable={setSelectedRowsOnTable}
        tableHeaderTooltip={{
          title: selectAllTableHotKey.tooltip,
          placement: 'topLeft' as Placements,
          targetOffset: [11, 0],
        }}
        keyToSelect="externalId"
        initialRowsSelected={selectedItems}
      />
    </>
  );
};
