import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { THERE_IS_LABEL_TEMPLATE_TYPE } from '@/ControllerApiHook/UniqIds/Production/LabelTemplatesKeys';
import { debounce } from 'lodash';
import { LabelTemplatesAPI } from '@/Data/API/Production/LabelTemplates';

export const useCheckNameDuplicationCallAPI = () => {
  const { mutateAsync }: any = useControllerMutateApiHook({
    uniqId: THERE_IS_LABEL_TEMPLATE_TYPE,
    entityApi: LabelTemplatesAPI.isLabelTemplateNameAlreadyRegistred,
  });

  const checkNameDuplicationsDebounced: any = debounce(
    async (
      descricao: string | null | undefined,
      tipoRotulo: number | null | undefined,
      callback: (success: boolean) => void
    ) => {
      try {
        const result = (await mutateAsync({
          descricao,
          tipoRotulo,
        })) as boolean;
        callback(result);
      } catch {
        callback(false);
      }
    },
    500
  );

  return { 
    mutateAsync,
    checkNameDuplicationsDebounced 
  };
};
