import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { HorizontalTabs } from '@/Components';
import { PageLoading } from '@/Layouts/PageLoading';
import { CancelOrderPurchaseModal } from './Modal/CancelModal';
import { useParams } from 'react-router';
import { useDetailsCallAPI } from './Hooks/useDetailsCallAPI';
import { useDetailsStates } from './Hooks/useDetailsStates';
import { useDetailsMemorizations } from './Hooks/useDetailsMemorizations';
import { usePurchaseOrderDetailsShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/Order/PurchaseOrder/details';

export const PurchaseDetails: FC = () => {
  const { t } = useTranslation();
  const { externalId } = useParams<{ externalId: string }>();

  const { cancelModal, setCancelModal } = useDetailsStates();

  const { orderPurchase } = useDetailsCallAPI({ externalId });

  const { getShortcut } = usePurchaseOrderDetailsShortcuts(t);

  const editMoreActionsHotkey = getShortcut('editMoreActions');

  const { items, dropdownItems, rightButton } = useDetailsMemorizations({
    orderPurchase,
    setCancelModal,
    externalId,
    getShortcut,
  });

  return (
    <PageLoading>
      <HorizontalTabs
        type="secondary"
        items={items}
        withoutTabs
        dropdownDirection="bottomRight"
        dropdownTitle={t('admin.editUserPage.tab.buttonActions.title')}
        leftIcon="menu-ellipsis-horizontal"
        dropdownItens={dropdownItems}
        rightButton={rightButton}
        dataTestIdDropdownButton="more-actions-button"
        moreActionsButton={{ title: editMoreActionsHotkey.tooltip }}
      />
      <CancelOrderPurchaseModal
        purchaseOrder={orderPurchase}
        visibleModal={cancelModal}
        changeVisibleModal={(value) => setCancelModal(value)}
      />
    </PageLoading>
  );
};
