import React from 'react';
import { Table } from '../../../../../Components';
import { editTableStatus } from '../../../../../Utils/EditModalTableUtils';
import { userCollums } from './user.columns';
import { useUserListCallAPI } from './Hooks/useUserListCallAPI';
import { useUserListMemorizations } from './Hooks/useUserListMemorizations';
import { useUserListFunctions } from './Hooks/useUserListFunctions';
import { useTranslation } from 'react-i18next';
import { useUserListStates } from './Hooks/useUserListStates';
import { PageLoading } from '@/Layouts/PageLoading';
import { useUserListShortcuts } from '@/Components/Shortcuts';

export const List: React.FC = () => {
  const { t } = useTranslation();

  const { selectedRowsOnTable, setSelectedRowsOnTable } = useUserListStates();

  const { userList, changeUserStatus, deleteUser } = useUserListCallAPI();

  const { onEdit, onDelete } = useUserListFunctions({
    changeUserStatus,
    deleteUser,
  });

  const { getShortcuts, getShortcut } = useUserListShortcuts(t, {});

  const {
    headerOptions,
    rowOptions,
    editModal,
    deleteModal,
    confirmEditModal,
    translations,
    tableHeaderHotKeyTooltip,
  } = useUserListMemorizations({
    selectedRowsOnTable,
    getShortcut,
    getShortcuts,
  });

  return (
    <PageLoading loading={userList.isLoading}>
      <Table
        columns={userCollums(t)}
        fieldStatus="ativo"
        headerOptions={headerOptions}
        loading={userList.isLoading}
        hasSelection
        keyToSelect="externalId"
        rowsOptions={rowOptions}
        pagination={userList.data}
        onRefetch={userList.refetch}
        editOptions={editTableStatus()}
        onEdit={onEdit}
        onDelete={onDelete}
        selectedRowsOnTable={selectedRowsOnTable}
        setSelectedRowsOnTable={setSelectedRowsOnTable}
        tableHeaderTooltip={tableHeaderHotKeyTooltip}
        editModal={editModal}
        deleteModal={deleteModal}
        editStatusModalLoading={changeUserStatus.isLoading}
        confirmEditModal={confirmEditModal}
        translations={translations}
      />
    </PageLoading>
  );
};
