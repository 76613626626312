import {
    BootysShortcuts,
    defaultShortcuts,
    DefaultShortcuts,
  } from '@/Components/Shortcuts/keymaps/default';
  import { TFunction } from 'react-i18next';
  import { useHotkeys } from 'reakeys';
  import { isVisible } from '@/Utils/DOMUtils';
  import { getShortcut, getShortcuts } from '@/Components/Shortcuts';

  export const pageKey = 'capsule-color-details';
  
  export interface CapsuleColorDetailsTabCallbacks extends DefaultShortcuts {}
  
  const shortcuts = (
    t: TFunction<'translation', undefined>,
    callbacks: CapsuleColorDetailsTabCallbacks
  ): BootysShortcuts[] => [
    ...defaultShortcuts(t, callbacks),
    {
      id: 'editMoreActions',
      name: t('admin.editUserPage.shortcuts.moreActions'),
      keys: '.',
      action: 'keydown',
      withText: true,
      callback: (event: any) => {
        event.preventDefault();
        const element = document.querySelector(
          '[data-testid="tab-dropdown-options"]'
        );
        if (isVisible(element)) {
          //@ts-ignore
          element?.click();
        }
      },
    },
    {
      id: 'deleteCapsuleColor',
      name: t('admin.editUserPage.shortcuts.moreActions'),
      keys: 'backspace',
      action: 'keydown',
      callback: (event: any) => {
        event.preventDefault();
        const element = document.querySelector(
          '[data-testid="delete-capsule-color-option"]'
        );
        if (isVisible(element)) {
          //@ts-ignore
          element?.click();
        }
      },
    },
    {
      id: 'editCapsuleColor',
      name: t('production.capsuleColors.details.shortcuts.editCapsuleColor'),
      keys: 'e',
      action: 'keydown',
      callback: (event: any) => {
        event.preventDefault();
        const element = document.querySelector(
          '[data-testid="production-capsule-colors-edit-button"]'
        );
        if (isVisible(element)) {
          //@ts-ignore
          element?.click();
        }
      },
    },
  ];
  export const useCapsuleColorDetailsShortcuts = (
    t: TFunction<'translation', undefined>,
    callbacks: CapsuleColorDetailsTabCallbacks
  ) => {
    const pageShortcuts = shortcuts(t, callbacks);
    const hotkeys = useHotkeys(pageShortcuts);
    return {
      ...hotkeys,
      getShortcut: getShortcut(pageShortcuts),
      getShortcuts: getShortcuts(pageShortcuts),
    };
  };