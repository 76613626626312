import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { currencyFormater } from '@/Utils/Currency';
import { InputLabel } from '@/Components/Inputs/LabelTotal';
import { IUnitaryDiscount } from './interfaces';
import { useUnitaryDiscountMemorizations } from './Hooks/useUnitaryDiscountMemorizations';

export const UnitaryDiscount: FC<IUnitaryDiscount> = ({
  totalProductValue,
  orderPurchase,
  totalUnitaryDiscountValue,
}) => {
  const { t } = useTranslation();

  const { withStatusIconInLabel, withTooltip } =
    useUnitaryDiscountMemorizations({
      totalUnitaryDiscountValue,
      totalProductValue,
      orderPurchase,
    });

  return (
    <>
      <InputLabel
        labelTitle={t('purchasing.purchaseOrder.create.unitaryDiscount')}
        withStatusIconInLabel={withStatusIconInLabel}
        withTooltip={withTooltip}
        labelValue={currencyFormater(totalUnitaryDiscountValue)}
        labelTitleStyle={{
          width: '100%',
          display: 'flex',
          justifyContent: 'start',
        }}
        labelValueStyle={{ width: '150px' }}
      />
    </>
  );
};
