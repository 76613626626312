import React, { ReactNode, useMemo } from 'react';
import { Tabs } from 'antd';
import { ITabs } from '../Tabs';
import { useLocation } from 'react-router-dom';
import { history } from '../../Store';
import { IButtonDropdown, ButtonDropdown } from '../Button/ButtonDropdown';
import { Button } from '../Button';
import { IconName } from '../Icon/IconClasses';
import { useTranslation } from 'react-i18next';
import {
  hasPermission,
  PermissionWrapper,
} from '../../Components/PermissionWrapper';
import { Tooltip } from '..';

import './HorizontalTabs.override.scss';
import styles from './HorizontalTabs.module.scss';
import { DropdownTab } from '../DropdownTab';
import { getCurrentUserRoles } from '@/Utils/PermissionUtils';

export interface IPermissionEditButon {
  onClick?: () => void;

  leftIcon?: IconName;
  children?: string;
  isVisible?: boolean;
  codePermissionEdit?: number;
  messageTooltipPermission?: string;
  targetOffsetTooltip?: number[];
  disabled?: boolean;
  messageTooltipDisabled?: string;
  tooltipPosition?:
    | 'topRight'
    | 'topLeft'
    | 'bottomLeft'
    | 'bottomRight'
    | 'top';
  dataTestId?: string;
  hotkeyTooltip?: {
    title: ReactNode | string;
    targetOffset?: number[];
  };
}

interface IHorizontalTabs extends ITabs, IButtonDropdown {
  rightButton?: IPermissionEditButon;
  leftButton?: IPermissionEditButon;
  dataTestIdDropdownButton?: string;
  moreActionsButton?: {
    title?: ReactNode | string;
    targetOffsetTooltip?: number[];
  };
}

export const HorizontalTabs: React.FC<IHorizontalTabs> = ({
  items,
  withoutTabs,
  dropdownItens,
  dropdownTitle,
  leftIcon,
  rightButton,
  leftButton,
  type,
  className,
  dataTestIdDropdownButton,
  moreActionsButton,
}) => {
  const { t } = useTranslation();
  const cuurentRoute = useLocation().pathname.split('/');
  const permissionEdit = !hasPermission(rightButton?.codePermissionEdit);
  const currentUserPermissions = useMemo(() => getCurrentUserRoles(), []);

  const definePermissionTab = (
    permissionNumber?: number,
    itemDisabled?: boolean
  ) => {
    if (itemDisabled) return itemDisabled;
    if (!permissionNumber) return false;
    return !currentUserPermissions.includes(permissionNumber);
  };

  return (
    <div style={{ position: 'relative' }}>
      <Tabs
        className={`${styles['container']} ${className} ${
          withoutTabs ? 'without-horizontal-tabs' : ''
        }`}
        activeKey={cuurentRoute[cuurentRoute.length - 1] || '1'}
        onChange={(key) => key && history.push(key)}
        items={items?.map((x) => ({
          label: x.dropdownLinks ? (
            <DropdownTab
              tabLabel={x.label as string}
              tabItems={x.dropdownLinks}
            />
          ) : (
            <PermissionWrapper
              permission={x.tabPermission}
              tooltip={x.permissionTooltip}
              placement="top"
            >
              <Tooltip
                showMe={!!x.shortCutTooltip?.title}
                title={x.shortCutTooltip?.title}
                targetOffset={x.shortCutTooltip?.targetOffset}
              >
                <div style={{ margin: '0 16px' }} data-testid={x.dataTestId}>
                  {x.label}
                </div>
              </Tooltip>
            </PermissionWrapper>
          ),
          key: x.key,
          children: x.children,
          disabled: definePermissionTab(x.tabPermission, x.disabled),
        }))}
        tabBarExtraContent={{
          right: (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {leftButton?.isVisible && (
                <PermissionWrapper
                  permission={leftButton.codePermissionEdit}
                  tooltip={leftButton.messageTooltipPermission}
                >
                  <Tooltip
                    title={leftButton.messageTooltipDisabled}
                    showMe={!!leftButton.messageTooltipDisabled}
                    placement={leftButton.tooltipPosition ?? 'topLeft'}
                    targetOffset={leftButton.targetOffsetTooltip}
                  >
                    <Button
                      className={`${
                        !rightButton?.isVisible
                          ? styles['buttonLeft-without-buttonRight']
                          : ''
                      } ${styles['rigth-button-two']}`}
                      type="secondary"
                      children={leftButton.children || t('common.edit')}
                      leftIcon={leftButton.leftIcon || t('common.edit')}
                      onClick={leftButton.onClick}
                    />
                  </Tooltip>
                </PermissionWrapper>
              )}

              {rightButton?.isVisible && (
                <PermissionWrapper
                  permission={rightButton.codePermissionEdit}
                  tooltip={rightButton.messageTooltipPermission}
                  style={{ marginRight: '46px' }}
                  targetOffset={[-42, 0]}
                >
                  {rightButton?.disabled ? (
                    <Tooltip
                      title={rightButton.messageTooltipDisabled}
                      showMe={!!rightButton.messageTooltipDisabled}
                      placement={rightButton.tooltipPosition ?? 'topLeft'}
                      targetOffset={rightButton.targetOffsetTooltip}
                    >
                      <Button
                        className={`${
                          dropdownItens && !permissionEdit
                            ? styles['button-with-permission']
                            : ''
                        } ${styles['button-edit']}`}
                        type="secondary"
                        children={rightButton.children || t('common.edit')}
                        leftIcon={rightButton.leftIcon || 'edit'}
                        onClick={rightButton.onClick}
                        disabled={permissionEdit || rightButton.disabled}
                        dataTestId={rightButton.dataTestId}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      showMe={!!rightButton.hotkeyTooltip?.title}
                      title={rightButton.hotkeyTooltip?.title}
                      targetOffset={
                        !rightButton.hotkeyTooltip?.targetOffset
                          ? [42, 3]
                          : rightButton.hotkeyTooltip?.targetOffset
                      }
                      placement="topRight"
                    >
                      <Button
                        className={`${
                          dropdownItens && !permissionEdit
                            ? styles['button-with-permission']
                            : ''
                        } ${styles['button-edit']}`}
                        type="secondary"
                        children={rightButton.children || t('common.edit')}
                        leftIcon={rightButton.leftIcon || 'edit'}
                        onClick={rightButton.onClick}
                        dataTestId={rightButton.dataTestId}
                        disabled={permissionEdit}
                      />
                    </Tooltip>
                  )}
                </PermissionWrapper>
              )}
            </div>
          ),
        }}
      />

      {dropdownItens && (
        <ButtonDropdown
          className={styles['button-action']}
          dropdownDirection="bottomRight"
          dropdownItens={dropdownItens}
          dropdownTitle={dropdownTitle}
          leftIcon={leftIcon}
          type={type}
          dataTestId={dataTestIdDropdownButton}
          moreActionsButton={moreActionsButton}
        />
      )}
    </div>
  );
};
