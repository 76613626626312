import { Text, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  pageCount: {
    width: '100%',
    padding: '0px 20px',
    fontSize: '13px',
    fontFamily: 'OpenSans',
    fontWeight: 'bold',
    marginTop: '10px',
    position: 'absolute',
    bottom: 10,
    left: 0,
  },
});

export const PageCount = () => {
  return (
    <Text
      style={styles.pageCount}
      render={({ pageNumber, totalPages }) =>
        `Página ${pageNumber} de ${totalPages}`
      }
      fixed
    />
  );
};
