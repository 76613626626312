import { FC } from 'react';
import { Table } from '@/Components';
import { useTranslation } from 'react-i18next';
import { editTableStatus } from '@/Utils/EditModalTableUtils';
import { PageLoading } from '@/Layouts/PageLoading';
import { prescribersCollums } from './prescribers.columns';
import { useListStates } from './Hooks/useListStates';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListFunctions } from './Hooks/useListFunctions';
import { useListMemorizations } from './Hooks/useListMemorizations';
import { usePrescriberListShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/Sales/Prescribers/list';

export const List: FC = () => {
  const { t } = useTranslation();

  const { selectedRowsOnTable, setSelectedRowsOnTable, selectedItems } =
    useListStates();

  const {
    changeStatus,
    prescribersList,
    changePrescriberStatus,
    deletePrescribers,
  } = useListCallAPI();

  const { getShortcut } = usePrescriberListShortcuts(t);

  const { onDelete, onEdit } = useListFunctions({
    deletePrescribers,
    changePrescriberStatus,
  });

  const { headerOptions, rowOptions, deleteModal, tableHeaderHotKeyTooltip } =
    useListMemorizations({
      selectedRowsOnTable,
      getShortcut,
    });

  return (
    <>
      <PageLoading loading={prescribersList.isLoading}>
        <Table
          columns={prescribersCollums(t)}
          headerOptions={headerOptions}
          onDelete={onDelete}
          onEdit={onEdit}
          editStatusModalLoading={changeStatus.isLoading}
          rowsOptions={rowOptions}
          editOptions={editTableStatus()}
          loading={prescribersList.isLoading}
          hasSelection
          fieldStatus="ativo"
          pagination={prescribersList.data}
          onRefetch={prescribersList.refetch}
          deleteModal={deleteModal}
          selectedRowsOnTable={selectedRowsOnTable}
          setSelectedRowsOnTable={setSelectedRowsOnTable}
          tableHeaderTooltip={tableHeaderHotKeyTooltip}
          keyToSelect="externalId"
          initialRowsSelected={selectedItems}
        />
      </PageLoading>
    </>
  );
};
