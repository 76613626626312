import { Svg, G, Path, ClipPath, Rect, Defs } from '@react-pdf/renderer';

export const PdfLogo = () => {
  return (
    <Svg width="96" height="26" viewBox="0 0 96 26" fill="none">
      <G clip-Path="url(#clip0_2887_61758)">
        <G clip-Path="url(#clip1_2887_61758)">
          <Path
            d="M20.4638 8.285C17.0769 8.285 15.0744 9.10083 13.9965 11.0391C12.9038 9.10083 10.8914 8.285 7.51928 8.285C1.76395 8.285 -0.0111084 10.6089 -0.0111084 16.8537C-0.0111084 23.1282 1.76395 25.4224 7.51928 25.4224C10.8914 25.4224 12.9038 24.6165 13.9965 22.6783C15.0744 24.6165 17.0769 25.4224 20.4638 25.4224C26.1846 25.4224 28.0239 23.1282 28.0239 16.8537C28.0189 10.6089 26.1846 8.285 20.4638 8.285ZM7.51928 20.7697C5.68489 20.7697 5.43767 20.5225 5.43767 16.8537C5.43767 13.2146 5.68489 12.9377 7.51928 12.9377C9.35367 12.9377 9.60089 13.2146 9.60089 16.8537C9.59595 20.5225 9.35367 20.7697 7.51928 20.7697ZM20.4638 20.7697C18.6294 20.7697 18.3822 20.5225 18.3822 16.8537C18.3822 13.2146 18.6294 12.9377 20.4638 12.9377C22.2982 12.9377 22.5454 13.2146 22.5454 16.8537C22.5405 20.5225 22.2982 20.7697 20.4638 20.7697Z"
            fill="#5E50F2"
          />
          <Path
            d="M17.2846 6.45061C19.114 6.45061 19.6974 5.71883 19.6974 3.71139C19.6974 1.71878 19.1091 0.972168 17.2846 0.972168C15.4452 0.972168 14.8816 1.71383 14.8816 3.71139C14.8766 5.71389 15.4452 6.45061 17.2846 6.45061Z"
            fill="#5E50F2"
          />
          <Path
            d="M23.6332 6.45061C25.4627 6.45061 26.0461 5.71883 26.0461 3.71139C26.0461 1.71878 25.4577 0.972168 23.6332 0.972168C21.7939 0.972168 21.2302 1.71383 21.2302 3.71139C21.2253 5.71389 21.7939 6.45061 23.6332 6.45061Z"
            fill="#5E50F2"
          />
        </G>
        <G clip-Path="url(#clip2_2887_61758)">
          <Path
            d="M38.6754 6.83337H40.615C41.1954 6.83337 41.4725 7.11337 41.4695 7.69087V14.0638L42.0441 12.8796C42.3708 12.2 42.9512 11.7713 45.092 11.7742C48.9187 11.7742 50.2283 13.0634 50.2254 18.6284C50.2225 24.1934 48.9362 25.4767 44.2287 25.4738C40.6004 25.4709 38.6637 24.3859 38.6666 18.8209L38.6754 6.83337ZM44.2375 14.1134C41.5687 14.1134 41.4666 15.1692 41.4637 18.3921V18.8209C41.4637 22.245 41.9887 23.1025 44.2316 23.1025C46.9266 23.1025 47.4545 22.9042 47.4575 18.6225C47.4604 14.3409 46.9325 14.1134 44.2375 14.1134Z"
            fill="#5E50F2"
          />
          <Path
            d="M50.6337 18.6255C50.6366 13.0605 51.6954 11.7771 56.2045 11.78C60.7137 11.78 61.7695 13.0692 61.7666 18.6342C61.7637 24.1759 60.705 25.4592 56.1958 25.4563C51.6895 25.4563 50.6308 24.1671 50.6337 18.6284V18.6255ZM53.2033 18.6255C53.2033 22.6796 53.4016 23.1084 56.1958 23.1113C58.9666 23.1113 59.1941 22.6855 59.197 18.6313C59.197 14.5275 58.9725 14.1221 56.2016 14.1221C53.4075 14.1221 53.2033 14.5246 53.2033 18.6284V18.6255Z"
            fill="#5E50F2"
          />
          <Path
            d="M60.3287 18.6342C60.3316 13.0692 61.3904 11.7859 65.8995 11.7888C70.4087 11.7888 71.4645 13.078 71.4616 18.643C71.4587 24.1846 70.4 25.468 65.8908 25.465C61.3845 25.465 60.3258 24.1759 60.3287 18.6371V18.6342ZM62.8983 18.6342C62.8983 22.6884 63.0966 23.1171 65.8908 23.12C68.6616 23.12 68.8891 22.6942 68.8891 18.64C68.8891 14.5363 68.6645 14.1309 65.8966 14.1309C63.1287 14.1309 62.8983 14.5334 62.8983 18.6371V18.6342Z"
            fill="#5E50F2"
          />
          <Path
            d="M74.8362 20.1275C74.8362 22.7467 75.7433 22.8984 79.2695 22.9013C79.85 22.9013 79.9637 23.2425 80.1241 23.785L80.6025 25.2696H76.5687C72.6895 25.2638 72.0625 23.578 72.0654 20.1275L72.0741 7.28546H74.0137C74.5679 7.28546 74.845 7.58879 74.845 8.16921V11.9959H78.065V13.4805C78.065 14.0609 77.7879 14.3613 77.2075 14.3613H74.8391L74.8362 20.1275Z"
            fill="#5E50F2"
          />
          <Path
            d="M90.3675 12.0075H94.6725V13.495C94.6725 14.0755 94.3954 14.3759 93.815 14.3759H90.3645C88.5766 14.373 88.0225 14.7259 88.0225 15.8575V16.8142L92.2516 17.7475C94.8445 18.3542 95.072 19.1592 95.0691 21.4517C95.0691 23.7938 93.8062 25.2784 90.3558 25.2755H83.9041L84.2483 23.785C84.4058 23.1521 84.5516 22.9042 85.1291 22.9042H90.3558C92.17 22.91 92.7241 22.5571 92.7241 21.2213V20.2646L88.5183 19.3313C85.9254 18.7246 85.6483 17.9955 85.6483 15.8546C85.6483 13.3113 86.9608 12.0017 90.3616 12.0046L90.3675 12.0075Z"
            fill="#5E50F2"
          />
          <Path
            d="M62.2683 8.98587C62.2683 7.59754 62.5337 7.27671 63.6566 7.27671C64.7795 7.27671 65.045 7.59754 65.045 8.98587C65.045 10.3684 64.7795 10.6892 63.6566 10.6863C62.5337 10.6834 62.2683 10.3655 62.2683 8.98296V8.98587Z"
            fill="#5E50F2"
          />
          <Path
            d="M66.7833 8.98879C66.7833 7.60046 67.0487 7.27962 68.1716 7.27962C69.2975 7.27962 69.56 7.60046 69.56 8.98879C69.56 10.3713 69.2945 10.6921 68.1716 10.6892C67.0487 10.6892 66.7833 10.3684 66.7833 8.98587V8.98879Z"
            fill="#5E50F2"
          />
          <Path
            d="M83.5162 12.868V25.3075H81.6058C81.0312 25.3075 80.7425 25.0188 80.7425 24.4442V12.0163H82.6645C83.2304 12.0163 83.5133 12.2992 83.5133 12.865L83.5162 12.868Z"
            fill="#5E50F2"
          />
        </G>
      </G>
      <Defs>
        <ClipPath id="clip0_2887_61758">
          <Rect
            width="95.3333"
            height="25"
            fill="white"
            transform="translate(0 0.5)"
          />
        </ClipPath>
        <ClipPath id="clip1_2887_61758">
          <Rect
            width="29.6667"
            height="29.6667"
            fill="white"
            transform="translate(-1 -1.5)"
          />
        </ClipPath>
        <clipPath id="clip2_2887_61758">
          <Rect
            width="56.5833"
            height="18.6667"
            fill="white"
            transform="translate(38.6666 6.83337)"
          />
        </clipPath>
      </Defs>
    </Svg>
  );
};
