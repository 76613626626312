import React, { useState } from 'react';
import { Status } from '../../../Status';
import { MaskForCNPJ } from '../../../../../../../Utils/MasksUtils';
import { Skeleton, Tooltip } from '../../../../../..';
import {
  CHANGE_STATUS_TYPE,
  GET_COMPANY_ADMIN_TYPE,
  GET_COMPANY_TYPE,
} from '../../../../../../../ControllerApiHook/UniqIds/Accountmanager/CompanyKeys';
import { useQueryClient } from '@tanstack/react-query';
import {
  CHANGE_STATUS_CONGLOMERATE_TYPE,
  GET_CONGLOMERATE_TYPE,
} from '../../../../../../../ControllerApiHook/UniqIds/Accountmanager/ConglomerateKeys';
import {
  ConfirmationChangeStatusCompanyModal,
  InativeConglomerateModal,
} from './modals';
import StorageUtils from '../../../../../../../Utils/StorageUtils';
import {
  useControllerMutateApiHook,
  useControllerQueryApiHook,
} from '@/ControllerApiHook/Controller';
import { CompanyAPI } from '@/Data/API/Accountmanager/CompanyApi';
import { IGetCompanyResponse } from '@/Data/Interfaces/response/Company/ICompanyResponse';
import { ConglomerateAPI } from '@/Data/API/Accountmanager/ConglomerateApi';
import { IGetConglomerateResponse } from '@/Data/Interfaces/response/Conglomerate/IConglomerateResponde';
import { IPreferences } from '@/Utils/ThemeUtils';
import { useAdminCompanyDetailsShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/AdminBootis/Company/details';
import { useTranslation } from 'react-i18next';
import { useAdminGroupsOfCompanyDetailsShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/AdminBootis/GroupsOfCompany/details';

export const DynamicEditCompanyStatusBreadcrumb = ({ match }: any) => {
  const { t } = useTranslation();
  const [visibleModal, setVisibleModal] = useState({
    visible: false,
    status: undefined,
  });

  const { data } = useControllerQueryApiHook<IGetCompanyResponse>({
    uniqId: GET_COMPANY_ADMIN_TYPE,
    entityApi: CompanyAPI.getCompanyAdmin,
    externalIds: [match.params.externalId],
  });

  const changeStatus = useControllerMutateApiHook<any>({
    uniqId: CHANGE_STATUS_TYPE,
    entityApi: CompanyAPI.patchCompanyStatus,
  });

  const queryClient = useQueryClient();

  const { getShortcut } = useAdminCompanyDetailsShortcuts(t);

  const editStatusHotkey = getShortcut('editStatus');

  if (!data)
    return (
      <div style={{ width: 200 }}>
        <Skeleton />
      </div>
    );

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {data?.nomeFantasia}
      <Tooltip showMe title={editStatusHotkey.tooltip}>
        <Status
          type={data?.ativa ? 'success' : 'disabled'}
          ative={data?.ativa || false}
          onClick={async (value) => {
            if (!value) {
              setVisibleModal({ status: value, visible: true });
            } else {
              const res = await changeStatus.mutateAsync({
                empresasExternalId: [data.externalId],
                ativa: value,
              });

              if (res.success)
                queryClient.invalidateQueries([GET_COMPANY_ADMIN_TYPE]);
            }
          }}
          loading={changeStatus.isLoading}
          disabled={changeStatus.isLoading}
          dataTestId="status-dropdown-button"
        />
      </Tooltip>
      <ConfirmationChangeStatusCompanyModal
        company={data}
        visibleModal={visibleModal.visible}
        changeVisibleModal={() =>
          setVisibleModal({ visible: false, status: undefined })
        }
        status={visibleModal.status}
      />
    </div>
  );
};

export const DynamicEditCompanyBreadcrumbCNPJ = ({ match }: any) => {
  const { data } = useControllerQueryApiHook<IGetCompanyResponse>({
    uniqId: GET_COMPANY_ADMIN_TYPE,
    entityApi: CompanyAPI.getCompanyAdmin,
    externalIds: [match.params.externalId],
  });
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {MaskForCNPJ(data?.cnpj)}
    </div>
  );
};

export const DynamicEditCompanyBreadcrumb = ({ match }: any) => {
  const { data } = useControllerQueryApiHook<IGetCompanyResponse>({
    uniqId: GET_COMPANY_TYPE,
    entityApi: CompanyAPI.getCompany,
    externalIds: [match.params.externalId],
  });
  return <>{data?.nomeFantasia}</>;
};

export const DynamicUserBreadcrumbStatusConglomerate = ({ match }: any) => {
  const { t } = useTranslation();

  const [visibleConfirmationStatusModal, setVisibleConfirmationStatusModal] =
    useState(false);

  const userPreferences: IPreferences = StorageUtils.getPreference();

  const { data: conglomerate } =
    useControllerQueryApiHook<IGetConglomerateResponse>({
      uniqId: GET_CONGLOMERATE_TYPE,
      entityApi: ConglomerateAPI.getConglomerate,
      externalIds: [match.params.externalId],
    });

  const changeStatus = useControllerMutateApiHook<any>({
    uniqId: CHANGE_STATUS_CONGLOMERATE_TYPE,
    entityApi: ConglomerateAPI.changeStatusConglomerate,
  });

  const queryClient = useQueryClient();

  const { getShortcut } = useAdminGroupsOfCompanyDetailsShortcuts(t);

  const editStatusHotkey = getShortcut('editStatus');

  if (!conglomerate)
    return (
      <div style={{ width: 200 }}>
        <Skeleton />
      </div>
    );

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {conglomerate?.nome}
      {conglomerate && (
        <Tooltip showMe title={editStatusHotkey.tooltip}>
          <Status
            type={conglomerate?.ativo ? 'success' : 'disabled'}
            ative={conglomerate?.ativo || false}
            onClick={async (value) => {
              if (!value) setVisibleConfirmationStatusModal(true);
              else {
                const res = await changeStatus.mutateAsync({
                  conglomeradosExternalId: [match.params.externalId],
                  ativa: value,
                });

                if (res.success)
                  queryClient.invalidateQueries([GET_CONGLOMERATE_TYPE]);
              }
            }}
            loading={changeStatus.isLoading}
            dataTestId="status-button"
            popoverDataTestId="status-popover"
          />
        </Tooltip>
      )}
      <InativeConglomerateModal
        changeStatus={changeStatus}
        conglomerate={conglomerate}
        onClose={() => setVisibleConfirmationStatusModal(false)}
        preferenceTheme={userPreferences.temaUsuario}
        queryClient={queryClient}
        visibleConfirmationStatusModal={visibleConfirmationStatusModal}
        dataTestIdOkButton="inativate-button"
      />
    </div>
  );
};
export const DynamicUserBreadcrumbConglomerate = ({ match }: any) => {
  const { data: conglomerate } =
    useControllerQueryApiHook<IGetConglomerateResponse>({
      uniqId: GET_CONGLOMERATE_TYPE,
      entityApi: ConglomerateAPI.getConglomerate,
      externalIds: [match.params.externalId],
    });

  if (!conglomerate)
    return (
      <div style={{ width: 200 }}>
        <Skeleton />
      </div>
    );
  return <span>{conglomerate?.nome}</span>;
};
