import { Text } from '@/Components';
import FormBreakLine from '@/Components/FormBreakLine';
import { Col, Row } from 'antd';
import { useListSupplyPlace } from '../hooks/useListSupplyPlace';
import { useTranslation } from 'react-i18next';
import { useListGroups } from '../hooks/useListGroups';
import { useFormikContext } from 'formik';
import { useListSubgroups } from '../hooks/useListSubgroups';
import { useFormRows } from '@/Hooks/UtilHooks/useFormRows';
import { RowElements } from './RowElements';

export const SupplyPlaceRow = () => {
  const form: any = useFormikContext();
  const { addRow, arrayControl, removeRow } = useFormRows({
    key: 'inventarioEspecificacao',
  });

  const { t } = useTranslation();
  const { fetchNewSupplyPlacePage, supplyPlaceData } = useListSupplyPlace();
  const { refetchPage, groupsData, fetchNewGroupsPage } = useListGroups();
  const { fetchNewSubgroupsPage, refetchPageSubGroup, subgroupsData } =
    useListSubgroups();

  return (
    <div>
      {arrayControl.map((e, index) => {
        const supplyPlace = form.values.inventarioEspecificacao[index]
          ? form.values.inventarioEspecificacao[index].localEstoqueExternalId
          : null;
        const group = form.values.inventarioEspecificacao[index]
          ? form.values.inventarioEspecificacao[index].grupoExternalId
          : null;

        return (
          <div key={e}>
            <Row
              gutter={16}
              style={index !== 0 ? { display: 'none' } : undefined}
            >
              <Col span={14}>
                <Text type="paragraph-bold">
                  {t('supply.inventory.create.supplyPlaces')}
                </Text>
              </Col>
              <Col span={5}>
                <Text type="paragraph-bold">
                  {t('supply.inventory.create.groups')}
                </Text>
              </Col>
              <Col span={5}>
                <Text type="paragraph-bold">
                  {t('supply.inventory.create.subgroups')}
                </Text>
              </Col>
            </Row>
            <FormBreakLine
              marginTop="10px"
              style={index !== 0 ? { display: 'none' } : undefined}
            />
            <Row gutter={16}>
              <RowElements
                index={index}
                addRow={addRow}
                edit={true}
                supplyPlace={supplyPlace}
                group={group}
                removeRow={removeRow}
              />
            </Row>
          </div>
        );
      })}
    </div>
  );
};
