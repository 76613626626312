import { useMemo } from 'react';
import { IUseMemorizations } from './interfaces';
import { useTranslation } from 'react-i18next';
import { history } from '@/Store';
import { BodyHandler, TitleHandler } from '../lotListUtils';
import { IDeleteModalTable } from '@/Components/Table';
import { IListStatusLotResponse } from '@/Data/Interfaces/response/Lot/IListLot';
import { lotStatusTypes } from '../lot.columns';
import { Placements } from '@/Components/Tooltip';

export const useListMemorizations = ({
  selectedRowsOnTable,
  statusList,
  getShortcut,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const addLotHotkey = getShortcut('addLot');
  const selectAllTableHotKey = getShortcut('selectAll');
  const deleteLotHotkey = getShortcut('deleteSelected');
  const editLotHotkey = getShortcut('editSelected');

  const headerOptions = useMemo(
    () => ({
      searchPlaceholder: t('supply.lot.placeholder.searchPlaceholder'),
      hasSearch: true,
      newButton: {
        onClick: () => history.push('/inventory/lot/create'),
        permission: {
          permission: 2003,
          tooltip: 'noPermission.supply.lot.create',
        },
        dataTestId: 'create-product',
        tooltip: {
          title: addLotHotkey.tooltip,
          placement: 'topRight' as Placements,
        },
      },
      deleteButton: {
        permission: {
          permission: 2006,
          tooltip: 'noPermission.supply.lot.delete',
        },
        tooltip: {
          title: deleteLotHotkey.tooltip,
          placement: 'topRight' as Placements,
        },
      },
      editButton: {
        permission: {
          permission: 2005,
          tooltip: 'noPermission.supply.lot.statusEdit',
        },
        tooltip: {
          title: editLotHotkey.tooltip,
          placement: 'topRight' as Placements,
        },
        options: [
          {
            label: t('common.status'),
            isStatusOnly: true,
            key: 's',
          },
        ],
      },
      nameKey: 'produto',
    }),
    [t]
  );

  const deleteModal = useMemo(
    (): IDeleteModalTable | undefined => ({
      customEndTitle: t('common.lots'),
      title: TitleHandler(selectedRowsOnTable, t),
      body: BodyHandler(selectedRowsOnTable, t),
      buttons: {
        okButtonColor: 'status-danger-base',
      },
      widthModal: 400,
    }),
    [selectedRowsOnTable, t]
  );

  const rowOptions = useMemo(
    () => ({
      onRowDoubleClick: (x: any) =>
        history.push(`/inventory/lot/${x.externalId}/details`),
      permission: {
        permission: 2002,
        tooltip: 'noPermission.supply.lot.detail',
      },
    }),
    []
  );

  const editOptions = useMemo(() => {
    const selectedRowStatusId = selectedRowsOnTable[0]?.situacao;
    return statusList?.map((x: IListStatusLotResponse) => {
      return {
        id: x.id,
        status: lotStatusTypes[x.id - 1],
        name: x.descricao,
        disabledItem:
          selectedRowsOnTable?.length === 1 ||
          selectedRowsOnTable?.every(
            (item) => item.situacao === selectedRowsOnTable[0]?.situacao
          )
            ? selectedRowStatusId === x.id
            : false,
      };
    });
  }, [statusList, selectedRowsOnTable]);

  const editModal = useMemo(
    () => ({
      title:
        selectedRowsOnTable?.length === 1
          ? `${t('modal.editModal.single.title.before')} "${
              selectedRowsOnTable ? selectedRowsOnTable[0]?.produto : ''
            }"`
          : `${t('modal.editModal.many.title.before')} ${
              selectedRowsOnTable?.length
            } ${t('common.lots')}`,
    }),
    [selectedRowsOnTable, t]
  );

  const tableHeaderHotKeyTooltip = {
    title: selectAllTableHotKey.tooltip,
    placement: 'topLeft' as Placements,
    targetOffset: [11, 0],
  };

  return {
    headerOptions,
    deleteModal,
    rowOptions,
    editOptions,
    editModal,
    tableHeaderHotKeyTooltip,
  };
};
