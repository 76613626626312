import { i18n } from '../../../../../../../Components';

export const labelType = [
  {
    id: 1,
    name: i18n.t('production.labelTemplates.create.labelTypes.products'),
  },
  {
    id: 2,
    name: i18n.t('production.labelTemplates.create.labelTypes.prescriptions'),
  },
  {
    id: 3,
    name: i18n.t('production.labelTemplates.create.labelTypes.inventory'),
  },
];
