import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import {
  ADD_MANIPULATED_RECIPE_ON_SALES_ORDER,
  CREATE_MANIPULATED_RECIPE,
} from '@/ControllerApiHook/UniqIds/Sales/SalesOrderKeys';
import { SalesOrderAPI } from '@/Data/API/Sales/SalesOrderApi';
import { IUseManipulatedRecipeCallAPI } from './interfaces';

export const useManipulatedRecipeCallAPI = ({
  setStepCurrent,
  setManipulatedRecipeExternalId,
  changeVisibleModal,
}: IUseManipulatedRecipeCallAPI) => {
  const {
    mutateAsync: createManipulatedRecipe,
    isLoading: createManipulatedRecipeIsLoading,
  } = useControllerMutateApiHook({
    uniqId: CREATE_MANIPULATED_RECIPE,
    entityApi: SalesOrderAPI.createManipulatedRecipe,
    options: {
      onSuccess: (data) => {
        setManipulatedRecipeExternalId(data.externalId);
        setStepCurrent((x) => x + 1);
      },
    },
  });
  const { mutateAsync: addManipulatedRecipeOnSaleOrder } =
    useControllerMutateApiHook({
      uniqId: ADD_MANIPULATED_RECIPE_ON_SALES_ORDER,
      entityApi: SalesOrderAPI.addManipulatedRecipeOnSaleOrder,
      options: {
        onSuccess: () => changeVisibleModal(false),
      },
    });

  return {
    createManipulatedRecipe,
    createManipulatedRecipeIsLoading,
    addManipulatedRecipeOnSaleOrder,
  };
};
