import {
  BootysShortcuts,
  defaultShortcuts,
  DefaultShortcuts,
} from '@/Components/Shortcuts/keymaps/default';
import { TFunction } from 'react-i18next';
import { useHotkeys } from 'reakeys';
import { isVisible } from '@/Utils/DOMUtils';
import { getShortcut, getShortcuts } from '@/Components/Shortcuts';

export const pageKey = 'user-details';

export interface TicketsDetailsCallbacks extends DefaultShortcuts {}

const shortcuts = (
  t: TFunction<'translation', undefined>,
  callbacks?: TicketsDetailsCallbacks
): BootysShortcuts[] => [
  ...defaultShortcuts(t, callbacks),
  {
    id: 'addFinishedProduct',
    name: t('shortcuts.sale.tickets.details.addFinishedProduct'),
    keys: 'n',
    action: 'keydown',
    withText: true,
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="finished-product-test-id"]'
      );

      //@ts-ignore
      element?.click();
    },
  },
  {
    id: 'viewHistory',
    name: t('shortcuts.sale.tickets.details.viewHistory'),
    keys: 'h',
    action: 'keydown',
    withText: true,
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="history-data-test-id"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'approveSaleOrder',
    name: t('shortcuts.sale.tickets.details.approveSalesOrder'),
    keys: 'a',
    action: 'keydown',
    withText: true,
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="approve-sale-order-button-data-test-id"]'
      );

      //@ts-ignore
      element?.click();
    },
  },
  {
    id: 'addManipulatedRecipe',
    name: t('shortcuts.sale.tickets.details.addManipulatedRecipe'),

    keys: 'm',
    action: 'keydown',
    withText: true,
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="manipulated-recipe-test-id"]'
      );

      //@ts-ignore
      element?.click();
    },
  },
  {
    id: 'reproveSaleOrder',
    name: t('shortcuts.sale.tickets.details.rejectSalesOrder'),
    keys: 'r',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="reprove-sale-order-button-data-test-id"]'
      );

      //@ts-ignore
      element?.click();
    },
  },
];

export const useTicketsDetailsShortcuts = (
  t: TFunction<'translation', undefined>,
  callbacks?: TicketsDetailsCallbacks
) => {
  const pageShortcuts = shortcuts(t, callbacks);

  const hotkeys = useHotkeys(pageShortcuts);

  return {
    ...hotkeys,
    getShortcut: getShortcut(pageShortcuts),
    getShortcuts: getShortcuts(pageShortcuts),
  };
};
