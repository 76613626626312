import { FC, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { ContactCard } from '../../../../../../../Components/EntityInformation/ContactCard';
import { AdressCard } from '../../../../../../../Components/EntityInformation/AddressCard';
import { DocumentCard } from '../../../../../../../Components/EntityInformation/DocumentCard';
import { InfoCard } from './Components/InfoCard';
import {
  ADD_ADRESS_CLIENT,
  ADD_CONTACT_CLIENT,
  ADD_DOCUMENT_CLIENT,
  CHANGE_FAVORITE_ADRESS_CLIENT,
  CHANGE_FAVORITE_CONTACT_CLIENT,
  DELETE_ADRESS_CLIENT,
  DELETE_CONTACT_CLIENT,
  DELETE_DOCUMENT_CLIENT,
  EDIT_ADRESS_CLIENT,
  EDIT_CONTACT_CLIENT,
  EDIT_DOCUMENT_CLIENT,
  GET_CLIENT,
} from '../../../../../../../ControllerApiHook/UniqIds/People/ClientKeys';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import {
  useControllerMutateApiHook,
  useControllerQueryListApiHook,
} from '@/ControllerApiHook/Controller';
import { ClientAPI } from '@/Data/API/People/ClientApi';
import {
  messageErrorToast,
  messageSucessToast,
} from '@/Utils/MessageErrorToast';
import { i18n } from '@/Components';
import { LIST_DOCUMENT } from '@/ControllerApiHook/UniqIds/People/DocumentKeys';
import { DocumentAPI } from '@/Data/API/People/DocumentApi';
import { isNil } from 'lodash';
import { IGetClientResponse } from '@/Data/Interfaces/response/Person/Client';
import { ContactCardSchema } from '@/Pages/Sectors/Order/Supplier/Create/Components/Cards/ContactCard/contactCard.schema';
import { DocumentCardSchema } from '@/Pages/Sectors/Order/Supplier/Create/Components/Cards/DocumentCard/documentCard.schema';

import styles from './ClientDetails.module.scss';
import { BootysShortcut } from '@/Components/Shortcuts';

interface IDetailsTab {
  client: IGetClientResponse;
  getShortcut: (id: string) => BootysShortcut;
}

export const DetailsTab: FC<IDetailsTab> = ({ client, getShortcut }) => {
  const queryClient = useQueryClient();

  const addContact: any = useControllerMutateApiHook({
    uniqId: ADD_CONTACT_CLIENT,
    entityApi: ClientAPI.addContactClient,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('contact.messages.createContact'));

        queryClient.invalidateQueries([GET_CLIENT]);
      },
    },
  });

  const editContact: any = useControllerMutateApiHook({
    uniqId: EDIT_CONTACT_CLIENT,
    entityApi: ClientAPI.editContactClient,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('contact.messages.changeContact'));

        queryClient.invalidateQueries([GET_CLIENT]);
      },
    },
  });

  const deleteContact: any = useControllerMutateApiHook({
    uniqId: DELETE_CONTACT_CLIENT,
    entityApi: ClientAPI.deleteContactClient,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('contact.messages.deleteContact'));

        queryClient.invalidateQueries([GET_CLIENT]);
      },
    },
  });

  const changeFavoriteContact: any = useControllerMutateApiHook({
    uniqId: CHANGE_FAVORITE_CONTACT_CLIENT,
    entityApi: ClientAPI.changeFavoriteContactClient,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('contact.messages.changeFavoriteContact'));

        queryClient.invalidateQueries([GET_CLIENT]);
      },
    },
  });

  const addAdress: any = useControllerMutateApiHook({
    uniqId: ADD_ADRESS_CLIENT,
    entityApi: ClientAPI.addAddressClient,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('address.messages.createAddress'));

        queryClient.invalidateQueries([GET_CLIENT]);
      },
    },
  });

  const editAdress: any = useControllerMutateApiHook({
    uniqId: EDIT_ADRESS_CLIENT,
    entityApi: ClientAPI.editAddressClient,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('address.messages.changeAddress'));

        queryClient.invalidateQueries([GET_CLIENT]);
      },
    },
  });

  const deleteAdress: any = useControllerMutateApiHook({
    uniqId: DELETE_ADRESS_CLIENT,
    entityApi: ClientAPI.deleteAddressClient,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('address.messages.deleteAddress'));

        queryClient.invalidateQueries([GET_CLIENT]);
      },
    },
  });

  const changeFavoriteAddress: any = useControllerMutateApiHook({
    uniqId: CHANGE_FAVORITE_ADRESS_CLIENT,
    entityApi: ClientAPI.changeFavoriteAddressClient,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('address.messages.changeFavoriteAddress'));

        queryClient.invalidateQueries([GET_CLIENT]);
      },
    },
  });

  const addDocument = useControllerMutateApiHook({
    uniqId: ADD_DOCUMENT_CLIENT,
    entityApi: ClientAPI.addDocumentClient,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('document.messages.changeDocument'));

        queryClient.invalidateQueries([GET_CLIENT]);
      },
    },
  });

  const editDocument = useControllerMutateApiHook({
    uniqId: EDIT_DOCUMENT_CLIENT,
    entityApi: ClientAPI.editDocumentClient,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('document.messages.createDocument'));

        queryClient.invalidateQueries([GET_CLIENT]);
      },
    },
  });

  const deleteDocument = useControllerMutateApiHook({
    uniqId: DELETE_DOCUMENT_CLIENT,
    entityApi: ClientAPI.deleteDocumentClient,
    options: {
      onError: (x) => messageErrorToast(x),
      onSuccess: () => {
        messageSucessToast(i18n.t('document.messages.deleteDocument'));

        queryClient.invalidateQueries([GET_CLIENT]);
      },
    },
  });

  const {
    data: documentList,
    fetchNewPage: fetchNewPageDocument,
    refetch,
  } = useControllerQueryListApiHook({
    uniqId: LIST_DOCUMENT,
    entityApi: DocumentAPI.listDocument,
    autoIncrement: true,
    pagination: {
      sorter: { column: 'nome', direction: 'ASC' },
    },
    enabled: !isNil(client?.tipoPessoa),
  });

  useEffect(() => {
    refetch({
      pageIndex: 1,
      pageSize: 10,
      filter: [
        {
          filterName: 'tipoPessoa',
          value: client?.tipoPessoa === 1 ? 1 : '0',
        },
        { filterName: 'ordem', value: [0, 1, 2] },
      ],
    });
  }, [client?.tipoPessoa]);

  const editCustomerDetailsHotkey = getShortcut('editCustomerDetails');
  const editCustomerContactHotkey = getShortcut('editCustomerContact');
  const editCustomerAddressHotkey = getShortcut('editCustomerAddress');
  const editCustomerDocumentHotkey = getShortcut('editCustomerDocument');

  return (
    <div className={styles['content']}>
      <div className={styles['side-left']}>
        <InfoCard
          client={client}
          tooltip={{
            title: editCustomerDetailsHotkey.tooltip,
            targetOffset: [0, -15],
          }}
          dataTestIdEditButton="details-edit-button"
        />
        <ContactCard
          addEntity={addContact}
          editEntity={editContact}
          deleteEntity={deleteContact}
          entityContact={client?.clienteContatos}
          entityExternalId={client?.externalId}
          entityContactExternalIdName="clienteContatoExternalId"
          entityExternalIdName="clienteExternalId"
          changeFavorite={changeFavoriteContact}
          entityContactPermission={{
            permission: PermissionEnum.Vendas_Clientes_EditarContatos,
            tooltip: 'noPermission.sale.client.editContacts',
          }}
          schema={ContactCardSchema}
          dataTestIdEditButton="contact-edit-button"
          tooltipEditButton={{
            title: editCustomerContactHotkey.tooltip,
            targetOffset: [120, -15],
          }}
        />
      </div>
      <div className={styles['side-right']}>
        <AdressCard
          addEntity={addAdress}
          editEntity={editAdress}
          deleteEntity={deleteAdress}
          entityAdress={client?.clienteEnderecos}
          entityExternalId={client?.externalId}
          entityAddressExternalIdName="clienteEnderecoExternalId"
          entityExternalIdName="clienteExternalId"
          changeFavorite={changeFavoriteAddress}
          entityAddressPermission={{
            permission: PermissionEnum.Vendas_Clientes_EditarEndereco,
            tooltip: 'noPermission.sale.client.editAddress',
          }}
          dataTestIdEditButton="address-edit-button"
          tooltipEditButton={{
            title: editCustomerAddressHotkey.tooltip,
            targetOffset: [270, -15],
          }}
        />
        <DocumentCard
          addEntity={addDocument}
          editEntity={editDocument}
          deleteEntity={deleteDocument}
          entityDocuments={client?.clienteDocumentos}
          entityExternalId={client?.externalId}
          entityDocumentExternalIdName="clienteDocumentoExternalId"
          entityExternalIdName="clienteExternalId"
          entityDocumentPermission={{
            permission: PermissionEnum.Vendas_Clientes_EditarDocumento,
            tooltip: 'noPermission.sale.client.editDocuments',
          }}
          documentList={documentList}
          fetchNewPageDocument={fetchNewPageDocument}
          schema={DocumentCardSchema}
          dataTestIdEditButton="document-edit-button"
          tooltipEditButton={{
            title: editCustomerDocumentHotkey.tooltip,
            targetOffset: [270, -15],
          }}
        />
      </div>
    </div>
  );
};
