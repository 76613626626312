import { useTranslation } from 'react-i18next';
import { Table } from '@/Components/Table';
import { PageLoading } from '@/Layouts/PageLoading';
import { standardFormulaColumns } from './StandardFormula.column';
import { editTableStatus } from '@/Utils/EditModalTableUtils';
import { useListStates } from './Hooks/useListStates';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListFunctions } from './Hooks/useListFunctions';
import { useListMemorizations } from './Hooks/useListMemorizations';
import { useStandardFormulaListShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/Production/StandardFormula/list';

const StandardFormulaList: React.FC = () => {
  const { t } = useTranslation();

  const { selectedRowsOnTable, setSelectedRowsOnTable, selectedItems } =
  useListStates();

  const {
    standardFormulaList,
    isLoadingStandardList,
    refetchStandardFormulaList,
    deleteStandardFormula,
  } = useListCallAPI();

  const { getShortcut } = useStandardFormulaListShortcuts(t, {});

  const { onDeleteStandardFormula } = useListFunctions({
    deleteStandardFormula,
  });

  const { headerOptions, rowsOptions, deleteModal, tableHeaderHotKeyTooltip  } = useListMemorizations({
    selectedRowsOnTable,
    getShortcut,
  });

  return (
    <PageLoading loading={isLoadingStandardList}>
      <Table
        columns={standardFormulaColumns(t)}
        loading={isLoadingStandardList}
        headerOptions={headerOptions}
        editOptions={editTableStatus()}
        hasSelection
        rowsOptions={rowsOptions}
        pagination={standardFormulaList}
        onRefetch={refetchStandardFormulaList}
        onDelete={onDeleteStandardFormula}
        selectedRowsOnTable={selectedRowsOnTable}
        setSelectedRowsOnTable={setSelectedRowsOnTable}
        tableHeaderTooltip={tableHeaderHotKeyTooltip}
        keyToSelect="externalId"
        initialRowsSelected={selectedItems}
        deleteModal={deleteModal}
      ></Table>
    </PageLoading>
  );
};

export default StandardFormulaList;
