import { FC } from 'react';
import { NumberInput, Tooltip } from '@/Components';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { IDensity } from './interfaces';

export const Density: FC<IDensity> = ({ product, disabledForm }) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();

  return (
    <Tooltip
      title={`${t('products.create.originalDensityTooltip')} ${
        product && product.materiaPrima ? product.materiaPrima?.densidade : ''
      }`}
      showMe={
        product &&
        !disabledForm &&
        product.materiaPrima?.densidade !==
          form?.values?.informacaoTecnica?.densidade
      }
    >
      <NumberInput
        label={t('products.create.density')}
        name="informacaoTecnica.densidade"
        withDecimalSeparator
        placeHolder="000"
        maxLength={6}
        acceptOnlyNumbers
        disabled={disabledForm}
        withStatusIcon={!!product}
      />
    </Tooltip>
  );
};
