import { IUseMemorizations } from './interfaces';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '@/Store';
import { IDeleteModalTable } from '@/Components/Table';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { IPermissionWrapper } from '@/Components/PermissionWrapper';
import { TitleHandler, BodyHandler } from '../Modal/labelTemplatesListUtils';
import { Placements } from '@/Components/Tooltip';

export const useListMemorizations = ({
  selectedRowsOnTable,
  getShortcut,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const addLabelTemplateHotkey = getShortcut('addLabelTemplate');
  const selectAllTableHotKey = getShortcut('selectAll');
  const deleteLabelTemplatetHotkey = getShortcut('deleteSelected');
  const editLabelTemplateHotkey = getShortcut('editSelected');

  const headerOptions = {
    hasSearch: true,
    newButton: {
      label: t('production.labelTemplates.list.newButton'),
      onClick: () => history.push('/production/label-templates/create'),
      permission: {
        permission: PermissionEnum.Producao_ModeloRotulo_Cadastrar,
        tooltip: 'noPermission.production.labelTemplates.create',
      },
      tooltip: {
        title: addLabelTemplateHotkey.tooltip,
        placement: 'topRight' as Placements,
      },
      dataTestId: 'production-label-templates-new-button',
    },
    editButton: {
      tooltip: {
        title: editLabelTemplateHotkey.tooltip,
        placement: 'topRight' as Placements,
      },
      dataTestId: 'production-label-templates-edit-button',
      options: [
        {
          permission: {
            permission: PermissionEnum.Producao_ModeloRotulo_AlterarStatus,
            tooltip: 'noPermission.production.labelTemplates.statusEdit',
          },
          label: t('production.labelTemplates.list.status'),
          isStatusOnly: true,
          key: 's',
        },
      ],
    },
    deleteButton: {
      permission: {
        permission: PermissionEnum.Producao_ModeloRotulo_Excluir,
        tooltip: 'noPermission.production.labelTemplates.delete',
      },
      tooltip: {
        title: deleteLabelTemplatetHotkey.tooltip,
        placement: 'topRight' as Placements,
      },
      dataTestId: 'delete-label-templates-button',
    },
    searchPlaceholder: t('production.labelTemplates.list.searchPlaceholder'),
    nameKey: 'descricao',
  };

  const translationsEditModal = {
    editModal: {
      customEndTitle: t(
        'production.labelTemplates.list.customEndTitleEditStatusModal'
      ),
    },
  };

  const rowOptions = useMemo(
    (): {
      permission: IPermissionWrapper;
      onRowDoubleClick?: (record: any) => void;
    } => ({
      permission: {
        permission: PermissionEnum.Producao_ModeloRotulo_VerDetalhes,
        tooltip: 'noPermission.production.labelTemplates.details',
      },
      onRowDoubleClick: (x: any) =>
        history.push(`/production/label-templates/${x.externalId}/details`),
    }),
    []
  );

  const deleteModal = useMemo((): IDeleteModalTable | undefined => {
    return {
      title: TitleHandler(selectedRowsOnTable, t),
      body: BodyHandler(selectedRowsOnTable, t),
      buttons: {
        okButtonColor: 'status-danger-base',
      },
      widthModal: 400,
    };
  }, [selectedRowsOnTable, t]);

  const tableHeaderHotKeyTooltip = {
    title: selectAllTableHotKey.tooltip,
    placement: 'topLeft' as Placements,
    targetOffset: [11, 0],
  };

  return {
    headerOptions,
    rowOptions,
    deleteModal,
    tableHeaderHotKeyTooltip,
    translationsEditModal,
    selectedRowsOnTable,
  };
};
