import FormBreakLine from '@/Components/FormBreakLine';
import { useOrderTable } from './Hooks/useOrderTable';
import { TableRow } from './Components/TableRow';
import { TableHeader } from './Components/TableHeader';
import { IItemChecked } from '../../../../Hooks/interfaces';

interface IConferenceTableProps {
  tableItems: any[];
  showQuantity: boolean;
  initialIndexCount: number;
  hideQuantityColumns: boolean;
  itemsChecked: IItemChecked[];
  onCheckItem: (isCorrect: boolean | null, index: number) => void;
  isConferenceStatus: boolean;
}

export const ConferenceTable: React.FC<IConferenceTableProps> = ({
  tableItems,
  initialIndexCount,
  hideQuantityColumns,
  itemsChecked,
  onCheckItem,
  isConferenceStatus,
}) => {
  const { orderItems, orientation, toggleOrientation } = useOrderTable(
    initialIndexCount,
    initialIndexCount + tableItems?.length
  );

  return (
    <>
      <TableHeader
        orientation={orientation}
        toggleOrientation={() => toggleOrientation()}
        hideQuantityColumns={hideQuantityColumns}
        isConferenceStatus={isConferenceStatus}
      />
      <FormBreakLine marginTop="10px" />
      {orderItems(tableItems)?.map((item: any, index: number) => (
        <TableRow
          index={index + initialIndexCount}
          item={item}
          key={JSON.stringify(item)}
          hideQuantityColumns={hideQuantityColumns}
          itemsChecked={itemsChecked}
          onCheckItem={onCheckItem}
          isConferenceStatus={isConferenceStatus}
        />
      ))}
    </>
  );
};
