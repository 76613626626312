import React, { FC } from 'react';
import { Switch as AntSwitch } from 'antd';
import { Field } from 'formik';
import { IField } from '../interfaces/IField';
import { Text } from '../../Text';

import styles from './Switch.module.scss';
import './Switch.override.scss';

export interface ISwitch {
  name: string;
  label?: string;
  disabled?: boolean;
  value: any;
  checked?: boolean;
  onChange?: (e: any) => void;
  className?: string;
}

export const Switch: FC<ISwitch> = ({
  name,
  label,
  disabled,
  value,
  checked,
  onChange,
  className,
}) => {
  return (
    <Field type="checkbox" name={name} value={value}>
      {(props: IField) => (
        <div className={`${styles['Switch']} ${className}`}>
          <AntSwitch
            disabled={disabled || props.form.isSubmitting}
            checked={checked}
            onClick={onChange}
          />
          {label ? (
            <Text type="ui-sub-content" color="text-50">
              {label}
            </Text>
          ) : null}
        </div>
      )}
    </Field>
  );
};
