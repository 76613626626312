import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { HorizontalTabs } from '@/Components';
import { PageLoading } from '@/Layouts/PageLoading';
import { DeletePrescriberModal } from './Modal/DeleteModal';
import { useEditStates } from './Hooks/useEditStates';
import { useEditCallAPI } from './Hooks/useEditCallAPI';
import { useEditMemorizations } from './Hooks/useEditMemorizations';
import { useEditFunctions } from './Hooks/useEditFunctions';
import { usePrescriberDetailsShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/Sales/Prescribers/details';

export const EditPrescribers: FC = () => {
  const { t } = useTranslation();

  const { visibleDeleteModal, setVisibleDeleteModal } = useEditStates();

  const { prescriber } = useEditCallAPI();

  const { getShortcut } = usePrescriberDetailsShortcuts(t);

  const editMoreActionsHotkey = getShortcut('editMoreActions');

  const { items, leftButton, dropdownItems } = useEditMemorizations({
    prescriber,
    setVisibleDeleteModal,
    getShortcut,
  });

  const { changeVisibleModal } = useEditFunctions({ setVisibleDeleteModal });

  return (
    <PageLoading>
      <HorizontalTabs
        type="secondary"
        items={items}
        dropdownDirection="bottomRight"
        dropdownTitle={t('admin.editUserPage.tab.buttonActions.title')}
        leftIcon="menu-ellipsis-horizontal"
        leftButton={leftButton}
        dropdownItens={dropdownItems}
        moreActionsButton={{ title: editMoreActionsHotkey.tooltip }}
        dataTestIdDropdownButton="more-actions-button"
      />
      {prescriber && (
        <DeletePrescriberModal
          prescriberName={prescriber.nomeCompleto}
          prescriberExternalId={prescriber.externalId}
          visibleModal={visibleDeleteModal}
          changeVisibleModal={changeVisibleModal}
        />
      )}
    </PageLoading>
  );
};
