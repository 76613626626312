import { IUseMemorizations } from './interfaces';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '@/Store';
import { IDeleteModalTable } from '@/Components/Table';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { DeleteModalOnTable } from '@/Utils/DeleteModalUtils';
import { DeleteModal } from '../Modal/deleteModal';
import { Placements } from '@/Components/Tooltip';

export const useListMemorizations = ({
  pharmaceuticalFormList,
  selectedRowsOnTable,
  getShortcut,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const addPharmaceuticalFormHotkey = getShortcut('addPharmaceuticalForm');
  const selectAllTableHotKey = getShortcut('selectAll');
  const deletePharmaceuticalFormHotkey = getShortcut('deleteSelected');
  const editPharmaceuticalFormHotkey = getShortcut('editSelected');

  const headerOptions = {
    nameKey: 'descricao',
    hasSearch: true,
    newButton: {
      label: t('production.pharmaceuticalForm.list.newButton'),
      onClick: () => history.push('/production/dosage-form/create'),
      permission: {
        permission: PermissionEnum.Producao_FormaFarmaceutica_Cadastrar,
        tooltip: 'noPermission.production.pharmaceuticalForm.create',
      },
      tooltip: {
        title: addPharmaceuticalFormHotkey.tooltip,
        placement: 'topRight' as Placements,
      },
      dataTestId: 'create-pharmaceuticalForm',
    },
    editButton: {
      permission: {
        permission: PermissionEnum.Producao_FormaFarmaceutica_AlterarStatus,
        tooltip: 'noPermission.production.pharmaceuticalForm.statusEdit',
      },
      tooltip: {
        title: editPharmaceuticalFormHotkey.tooltip,
        placement: 'topRight' as Placements,
      },
      dataTestId: 'edit-pharmaceuticalForm',
      options: [
        {
          label: t('production.pharmaceuticalForm.list.editStatus'),
          isStatusOnly: true,
          key: 's',
        },
      ],
    },
    deleteButton: {
      permission: {
        permission: PermissionEnum.Producao_FormaFarmaceutica_Excluir,
        tooltip: 'noPermission.production.pharmaceuticalForm.delete',
      },
      tooltip: {
        title: deletePharmaceuticalFormHotkey.tooltip,
        placement: 'topRight' as Placements,
      },
      dataTestId: 'delete-pharmaceuticalForm',
    },
    searchPlaceholder: t(
      'production.pharmaceuticalForm.list.searchPlaceholder'
    ),
  };

  const rowOptions = {
    permission: {
      permission: PermissionEnum.Producao_FormaFarmaceutica_VerDetalhes,
      tooltip: 'noPermission.production.pharmaceuticalForm.details',
    },
    onRowDoubleClick: (x: any) =>
      history.push(`/production/dosage-form/${x.externalId}/details`),
  };

  const deleteModal = useMemo(
    (): IDeleteModalTable | undefined => ({
      title:
        selectedRowsOnTable &&
        `${t('common.delete')} ${
          selectedRowsOnTable.length > 1
            ? `${t('production.pharmaceuticalForm.details.manyDeleteTitle')}?`
            : `${t('production.pharmaceuticalForm.details.oneDeleteTitle')}  "${
                selectedRowsOnTable[0]?.descricao
              }"?`
        }`,
      customEndTitle: t('common.pharmaceuticalForms'),
      body:
        selectedRowsOnTable && selectedRowsOnTable?.length > 1 ? (
          DeleteModalOnTable(
            selectedRowsOnTable,
            `${t('modal.deleteModal.many.title')} ${t(
              'common.thePharmaceuticalForm'
            )}:`,
            'descricao',
            t('common.pharmaceuticalForms')
          )
        ) : (
          <DeleteModal selectedRowsOnTable={selectedRowsOnTable} />
        ),
      buttons: {
        okButtonColor: 'status-danger-base',
      },
       
    }),
    [selectedRowsOnTable, t]
  );

  const tableHeaderHotKeyTooltip = {
    title: selectAllTableHotKey.tooltip,
    placement: 'topLeft' as Placements,
    targetOffset: [11, 0],
  };


  return {
    headerOptions,
    rowOptions,
    deleteModal,
    tableHeaderHotKeyTooltip,
  };
};
