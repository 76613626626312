import { Button, Dropdown, Icon } from '@/Components';
import { Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { useListSupplyPlace } from '../hooks/useListSupplyPlace';
import { useListGroups } from '../hooks/useListGroups';
import { useListSubgroups } from '../hooks/useListSubgroups';
import { useFormikContext } from 'formik';

interface IRowElementsProps {
  index: number;
  addRow: (index: number) => void;
  supplyPlace: string;
  group: string;
  removeRow: (index: number) => void;
  edit: boolean;
}

export const RowElements: React.FC<IRowElementsProps> = ({
  index,
  addRow,
  edit,
  group,
  removeRow,
  supplyPlace,
}) => {
  const { t } = useTranslation();
  const form: any = useFormikContext();
  const { fetchNewSupplyPlacePage, supplyPlaceData } = useListSupplyPlace();
  const { refetchPage, groupsData, fetchNewGroupsPage } = useListGroups();
  const { fetchNewSubgroupsPage, refetchPageSubGroup, subgroupsData } =
    useListSubgroups();

  return (
    <>
      <Col span={14}>
        <Dropdown
          name={`inventarioEspecificacao.${index}.localEstoqueExternalId`}
          items={supplyPlaceData.supplyPlacesList}
          placeHolder={t('supply.inventory.create.supplyPlacesPlaceholder')}
          onScrollEnd={fetchNewSupplyPlacePage}
          onChange={(value: string) => {
            form.setFieldValue(
              `inventarioEspecificacao.${index}.grupoExternalId`,
              undefined
            );
            form.setFieldValue(
              `inventarioEspecificacao.${index}.subGrupoExternalId`,
              undefined
            );
            addRow(index);
            refetchPage(value);
          }}
        />
      </Col>
      <Col span={4}>
        <Dropdown
          name={`inventarioEspecificacao.${index}.grupoExternalId`}
          disabled={!supplyPlace}
          placeHolder={t('supply.inventory.create.groupsPlaceholder')}
          items={groupsData}
          onChange={(value: string) => {
            form.setFieldValue(
              `inventarioEspecificacao.${index}.subGrupoExternalId`,
              undefined
            );
            addRow(index);
            refetchPageSubGroup(supplyPlace, value);
          }}
          onScrollEnd={fetchNewGroupsPage}
        />
      </Col>
      <Col span={4}>
        <Dropdown
          name={`inventarioEspecificacao.${index}.subGrupoExternalId`}
          disabled={!group}
          items={subgroupsData}
          placeHolder={t('supply.inventory.create.subgroupsPlaceholder')}
          onChange={() => {
            addRow(index);
          }}
          onScrollEnd={fetchNewSubgroupsPage}
        />
      </Col>
      <Col span={2}>
        <Button
          disabled={index === form.values?.inventarioEspecificacao?.length}
          type="secondary"
          onClick={() => {
            removeRow(index);
          }}
        >
          <Icon
            style={{
              cursor:
                index === form.values?.inventarioEspecificacao?.length
                  ? 'not-allowed'
                  : 'default',
            }}
            icon="trash"
            size="M"
          />
        </Button>
      </Col>
    </>
  );
};
