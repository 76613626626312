import { FC, useEffect, useState } from 'react';
import { ConferenceButton } from './Components/Button';
import { IConferencesButtons } from './interfaces';

import styles from './styles.module.scss';
import { useFormikContext } from 'formik';

export const ConferenceButtons: FC<IConferencesButtons> = ({
  isStatic = false,
  index = 0,
  itemsChecked,
  onCheckItem,
  inventoryItemId,
  initialApproved,
}) => {
  const [correctActive, setCorrectActive] = useState(false);
  const [incorrectActive, setIncorrectActive] = useState(false);
  const form: any = useFormikContext();

  const onClick = (isCorrect: boolean) => {
    form.setFieldValue(
      `conferencia.${index}.inventarioItemId`,
      inventoryItemId
    );
    form.setFieldValue(`conferencia.${index}.aprovado`, isCorrect);

    if (isCorrect) {
      if (correctActive) {
        onCheckItem?.(null, index || 0);
      } else {
        onCheckItem?.(isCorrect, index);
      }

      setCorrectActive(!correctActive);
      setIncorrectActive(false);
    } else {
      if (incorrectActive) {
        onCheckItem?.(null, index);
      } else {
        onCheckItem?.(isCorrect, index);
      }

      setCorrectActive(false);
      setIncorrectActive(!incorrectActive);
    }
  };

  useEffect(() => {
    if (itemsChecked && itemsChecked[index]) {
      if (itemsChecked[index].aprovado === true) {
        setCorrectActive(true);
        setIncorrectActive(false);
      }

      if (itemsChecked[index].aprovado === false) {
        setCorrectActive(false);
        setIncorrectActive(true);
      }
    }
  }, [itemsChecked]);

  useEffect(() => {
    if (initialApproved === true) {
      form.setFieldValue(
        `conferencia.${index}.inventarioItemId`,
        inventoryItemId
      );
      form.setFieldValue(`conferencia.${index}.aprovado`, true);
    }

    if (initialApproved === false) {
      form.setFieldValue(
        `conferencia.${index}.inventarioItemId`,
        inventoryItemId
      );
      form.setFieldValue(`conferencia.${index}.aprovado`, false);
    }
  }, []);

  return (
    <div className={styles['container']}>
      <ConferenceButton
        type="correct"
        isActive={correctActive}
        isStatic={isStatic}
        onClick={() => onClick(true)}
      />
      <ConferenceButton
        type="incorrect"
        isActive={incorrectActive}
        isStatic={isStatic}
        onClick={() => onClick(false)}
      />
    </div>
  );
};
