import { TFunction } from 'react-i18next';
import { ICannotDeleteModalInfo } from '../ErrorHandlingUtils';

export const cannotDeleteLabelTemplateWithMovementsInTheSystemProps = (
  t: TFunction<'translation', undefined>
): ICannotDeleteModalInfo => ({
  formName: 'descricao',
  oneErrorModal: {
    titleModal: `${t('common.modalCannotDelete.messageTitle')} ${t(
      'production.labelTemplates.modalCannotDelete.messageTitle'
    )}`,
    subTitleModal: `${t('common.modalCannotDelete.messageSubTitle')} ${t(
      'production.labelTemplates.modalCannotDelete.messageSubtitle'
    )}`,
    reasonTranslation: `${t(
      'production.labelTemplates.modalCannotDelete.messageReasonCannotDelete'
    )}`,
    warningModal: t(
      'production.labelTemplates.modalCannotDelete.messageWarningCannotDelete'
    ),
  },
  manyErrorsModal: {
    entity: ` ${t('common.modalCannotDelete.messageTitle')}`,
    complement: `${t('common.laboratories')}`,
    subTitleModal: `${t(
      'production.labelTemplates.modalCannotDelete.messageManySubtitleFirst'
    )} ${t(
      'production.labelTemplates.modalCannotDelete.messageManySubtitleSecond'
    )}`,
    reasonTranslation: `${t(
      'production.labelTemplates.modalCannotDelete.messageManyReasonCannotDelete'
    )}`,
    warningModal: t(
      'production.labelTemplates.modalCannotDelete.messageManyWarningCannotDelete'
    ),
  },
});