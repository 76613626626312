import React, {
  CSSProperties,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Icon } from '../..';
import { IconName } from '../../Icon/IconClasses';
import { history } from '../../../Store';

import { Text } from '../../Text';

import styles from './MenuItem.module.scss';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { IPermissionWrapper, PermissionWrapper } from '../../PermissionWrapper';
import { motion } from 'framer-motion';
import { getKey } from '@/Utils/OnKeyDownKeys';

const clickOnSideMenu = (link: string) => {
  history.push(link);
};

export interface IMenuItem {
  id?: string;
  icon?: IconName;
  name: string;
  link: string;
  disabled?: boolean;
  isCollapsed?: boolean;
  shouldRedirect?: boolean;
  permission?: IPermissionWrapper;
  style?: CSSProperties;
  children?: IMenuItem[];
  tabIndex?: number;
}

export const MenuItem: FC<IMenuItem> = ({ name, permission, ...props }) => {
  return (
    <PermissionWrapper
      permission={permission?.permission}
      tooltip={permission?.tooltip || 'NoPermission'}
      key={name}
      style={{ marginRight: 0 }}
    >
      <InnerMenu {...props} name={name} permission={permission} />
    </PermissionWrapper>
  );
};

const InnerMenu: FC<IMenuItem> = ({
  icon,
  name,
  link,
  disabled,
  isCollapsed,
  style,
  shouldRedirect,
  children,
  tabIndex,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const { pathname } = useLocation();

  const iconFragment = icon ? (
    <Icon icon={icon} size="M" color="text-50" />
  ) : (
    <div className={styles['icon-holder']}></div>
  );

  const isActive = useCallback(
    (url: string) =>
      url !== '' && (pathname === url || pathname.startsWith(`${url}/`)),
    [pathname]
  );

  useEffect(() => {
    if (!children) {
      return;
    }

    const isInnerRouteActive = children.some((x) => isActive(x.link));
    const isItselfActive = isActive(link);
    setOpen(isInnerRouteActive || isItselfActive);
  }, [pathname, link, children, isActive]);

  const { t } = useTranslation();

  return (
    <div
      style={style}
      className={`${styles['container']} ${
        children ? styles['menuItem-with-children'] : ''
      } ${open ? styles['item-open'] : ''}
    `}
      onKeyDown={(e) => {
        if (e.key === getKey.enterKey) {
        e.stopPropagation();
        if (disabled) {
          return;
        }

        if (children) {
          setOpen(!open);
        }
        if (!children || (children && shouldRedirect)) {
          clickOnSideMenu(link);
        } 
        }
      }}
      onClick={(e) => {
        e.stopPropagation();
        if (disabled) {
          return;
        }

        if (children) {
          setOpen(!open);
        }
        if (!children || (children && shouldRedirect)) {
          clickOnSideMenu(link);
        }
      }}
    >
      <div
        tabIndex={tabIndex}
        className={`${styles['menuItem']} ${
          !isCollapsed
            ? styles['menuItem-not-collapsed']
            : styles['menuItem-collapsed']
        }  ${isActive(link) ? styles['active'] : ''} ${
          disabled ? styles['disabled'] : ''
        } ${open && !shouldRedirect ? styles['menuItem-open'] : ''} ${
          open ? styles['isOpen'] : ''
        } ${children && !shouldRedirect ? styles['collapse-only-item'] : ''}`}
      >
        <div className={styles['name-container']}>
          {iconFragment}
          <motion.div animate={{ width: isCollapsed ? '0px' : '100%' }}>
            <div style={{ marginLeft: 16, overflow: 'hidden' }}>
              <Text
                type="heading6"
                color="text-50"
                className={`${styles['show']} ${styles['text']}`}
              >
                {t(name)}
              </Text>
            </div>
          </motion.div>
        </div>
      </div>
      {children && !isCollapsed && (
        <Icon
          icon={open ? 'chevron-up' : 'chevron-down'}
          className={`${styles['show']} ${
            isCollapsed ? styles['hidden'] : ''
          } ${styles['icon']} ${
            children && !shouldRedirect
              ? styles['collapse-only-item-chevron']
              : ''
          }`}
          size="M"
          color="text-50"
        />
      )}
      {children ? (
        <div
          className={`${styles['expandable']} ${
            open ? styles['open'] : styles['close']
          }`}
          onFocus={() => setOpen(true)}
        >
          {children.map((x, index) => (
            <MenuItem
              {...x}
              key={`menuItem-${index}`}
              isCollapsed={isCollapsed}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};
