import { useState } from "react";
import { IItemChecked } from "./interfaces";
import { ITableItem } from "../interfaces";

export const useConferenceStates = () => {
  const [isConferenceStatus, setIsConferenceStatus] = useState<boolean>(false);

  const [itemsChecked, setItemsChecked] = useState<IItemChecked[]>([]);

  const [finishModalVisible, setFinishModalVisible] = useState<boolean>(false);

  const [printAll, setPrintAll] = useState<boolean>(false);

  const [printDivergent, setPrintDivergent] = useState<boolean>(false);

  const [printViewerVisible, setPrintViewerVisible] = useState<boolean>(false);

  const [supplyPlaceSelected, setSupplyPlaceSelected] = useState<string>('');

  const [tolerance, setTolerance] = useState<number>(0);

  const [isReleaseAgainModalVisible, setIsReleaseAgainModalVisible] =
  useState(false);

  const [releaseSpecifications, setReleaseSpecifications] = useState<ITableItem[]>([]);

  return {
    isConferenceStatus,
    setIsConferenceStatus,
    itemsChecked,
    setItemsChecked,
    finishModalVisible,
    setFinishModalVisible,
    printAll,
    setPrintAll,
    printDivergent,
    setPrintDivergent,
    printViewerVisible,
    setPrintViewerVisible,
    supplyPlaceSelected,
    setSupplyPlaceSelected,
    tolerance,
    setTolerance,
    isReleaseAgainModalVisible,
    setIsReleaseAgainModalVisible,
    releaseSpecifications,
    setReleaseSpecifications,
  };
};
