import React from 'react';
import { Table } from '@/Components';
import { useTranslation } from 'react-i18next';
import { PageLoading } from '@/Layouts/PageLoading';
import { purchaseOrderColumn } from './purchaseOrder.columns';
import StorageUtils from '@/Utils/StorageUtils';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListMemorizations } from './Hooks/useListMemorizations';
import { usePurchaseOrderListShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/Order/PurchaseOrder/list';

export const PurchaseList: React.FC = () => {
  const { t } = useTranslation();
  const preferences = StorageUtils.getPreference();

  const { purchaseOrderList, isLoadingList, refetchPurchaseOrderList } =
    useListCallAPI();

  const { getShortcut } = usePurchaseOrderListShortcuts(t);

  const { headerOptions, rowsOptions } = useListMemorizations({ getShortcut });

  return (
    <PageLoading loading={isLoadingList}>
      <Table
        columns={purchaseOrderColumn(t, preferences)}
        headerOptions={headerOptions}
        loading={isLoadingList}
        rowsOptions={rowsOptions}
        pagination={purchaseOrderList}
        onRefetch={refetchPurchaseOrderList}
      ></Table>
    </PageLoading>
  );
};

export default PurchaseList;
