import { IUseMemorizations } from './interfaces';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '@/Store';
import { IDeleteModalTable } from '@/Components/Table';
import { DeleteModalOnTable } from '@/Utils/DeleteModalUtils';
import { DeleteModal } from '../Modal/deleteModal';
import { Placements } from '@/Components/Tooltip';

export const useListMemorizations = ({
  selectedRowsOnTable,
  getShortcut,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const addSupplyPlaceHotkey = getShortcut('addSupplyPlace');
  const selectAllTableHotKey = getShortcut('selectAll');
  const deleteSupplyPlaceHotkey = getShortcut('deleteSelected');
  const editSupplyPlaceHotkey = getShortcut('editSelected');

  const headerOptions = {
    hasSearch: true,
    newButton: {
      onClick: () => history.push('/inventory/supply-places/create'),
      permission: {
        permission: 2009,
        tooltip: 'noPermission.supply.supplyPlaces.create',
      },
      tooltip: {
        title: addSupplyPlaceHotkey.tooltip,
        placement: 'topRight' as Placements,
      },
    },
    editButton: {
      permission: {
        permission: 2011,
        tooltip: 'noPermission.supply.supplyPlaces.statusEdit',
      },
      tooltip: {
        title: editSupplyPlaceHotkey.tooltip,
        placement: 'topRight' as Placements,
      },
      options: [
        {
          label: t('supply.supplyPlaces.edit.status'),
          isStatusOnly: true,
          key: 's',
        },
      ],
    },
    searchPlaceholder: t('supply.places.list.searchPlaceHolder'),
    deleteButton: {
      permission: {
        permission: 2012,
        tooltip: 'noPermission.supply.supplyPlaces.delete',
      },
      tooltip: {
        title: deleteSupplyPlaceHotkey.tooltip,
        placement: 'topRight' as Placements,
      },
    },
    nameKey: 'descricao',
  };

  const rowOptions = {
    permission: {
      permission: 2008,
      tooltip: 'noPermission.supply.supplyPlaces.detail',
    },
    onRowDoubleClick: (x: any) =>
      history.push(`/inventory/supply-places/${x.externalId}/details`),
  };

  const deleteModal = useMemo(
    (): IDeleteModalTable | undefined => ({
      title:
        selectedRowsOnTable &&
        `${t('common.delete')} ${
          selectedRowsOnTable.length > 1
            ? `${selectedRowsOnTable.length} ${t('common.stockLocations')}?`
            : `"${selectedRowsOnTable[0]?.descricao}"?`
        }`,
      customEndTitle: t('common.stockLocations'),
      body:
        selectedRowsOnTable && selectedRowsOnTable?.length > 1 ? (
          DeleteModalOnTable(
            selectedRowsOnTable,
            `${t('modal.deleteModal.many.title')} ${t(
              'common.thestockLocations'
            )}:`,
            'descricao',
            t('common.stockLocations')
          )
        ) : (
          <DeleteModal selectedRowsOnTable={selectedRowsOnTable} />
        ),
      buttons: {
        okButtonColor: 'status-danger-base',
      },
    }),
    [selectedRowsOnTable, t]
  );

  const editModal = useMemo(
    (): IDeleteModalTable | undefined => ({
      title:
        selectedRowsOnTable &&
        `${t('modal.editModal.many.title.before')} ${
          selectedRowsOnTable.length === 1
            ? `"${selectedRowsOnTable[0]?.descricao}"`
            : `${selectedRowsOnTable.length} ${t(
                'common.stockLocationsQuestionModalStatus'
              )}`
        }`,
    }),
    [selectedRowsOnTable, t]
  );

  const tableHeaderHotKeyTooltip = {
    title: selectAllTableHotKey.tooltip,
    placement: 'topLeft' as Placements,
    targetOffset: [11, 0],
  };

  return {
    headerOptions,
    rowOptions,
    deleteModal,
    editModal,
    tableHeaderHotKeyTooltip,
  };
};
