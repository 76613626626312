import { Icon, Modal, Text } from '@/Components';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useConferenceCallAPI } from '../../Hooks/useConferenceCallAPI';
import { useParams } from 'react-router';

interface IReleaseAgainModalProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
}

export const ReleaseAgainModal: React.FC<IReleaseAgainModalProps> = ({
  setVisible,
  visible,
}) => {
  const { externalId } = useParams<{ externalId: string }>();
  const formValues: any = useFormikContext().values;
  const { t } = useTranslation();
  const { relaunchInventory } = useConferenceCallAPI();

  const handleRelaunchInventory = async () => {
    const body = {
      externalId,
      conferencia: formValues.conferencia ?? [],
    };

    await relaunchInventory.mutateAsync(body);
    setVisible(false);
  };

  return (
    <Modal
      title={t('supply.inventory.releaseAgainModal.title')}
      widthModal={400}
      onClose={() => setVisible(false)}
      onCancelClick={() => setVisible(false)}
      visible={visible}
      loading={relaunchInventory.isLoading}
      onOkClick={async () => handleRelaunchInventory()}
      body={
        <div
          style={{
            padding: '20px',
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <div>
            {t('supply.inventory.releaseAgainModal.body')}
            <b>{t('supply.inventory.releaseAgainModal.release')}</b>.
          </div>
          <Divflex style={{ marginTop: 25 }}>
            <Icon
              icon={'exclamation-upside-down'}
              size={'SM'}
              color={'text-400'}
              style={{ padding: '18px 18px 0 8px' }}
            />
            <Text
              type="paragraph2"
              color={'text-400'}
              children={t('supply.inventory.releaseAgainModal.footerMessage')}
            />
          </Divflex>
        </div>
      }
    />
  );
};
