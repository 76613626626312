import React from 'react';
import { Table } from '../../../../../Components/Table';
import { useTranslation } from 'react-i18next';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { packinkgClassificationColumns } from './PackingClassification.column';
import { editTableStatus } from '../../../../../Utils/EditModalTableUtils';
import { useListStates } from './Hooks/useListStates';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListMemorizations } from './Hooks/useListMemorizations';
import { useListFunctions } from './Hooks/useListFunctions';
import { usePackingClassificationListShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/Production/PackingClassification/list';

export const PackingClassificationList: React.FC = () => {
  const { t } = useTranslation();

  const { setSelectedRowsOnTable, selectedRowsOnTable, selectedItems } = useListStates();

  const {
    packingClassificationFormList,
    isLoadingPackingListFormList,
    editPackingClassificationFormStatus,
    deletePackingClassificationForm,
    refetchPackingList,
  } = useListCallAPI();

  const { getShortcut } = usePackingClassificationListShortcuts(t, {});

  const { headerOptions, rowOptions, deleteModal, tableHeaderHotKeyTooltip  } = useListMemorizations({
    packingClassificationFormList,
    selectedRowsOnTable,
    getShortcut,
  });

  const { onEdit, onDelete } = useListFunctions({
    editPackingClassificationFormStatus,
    setSelectedRowsOnTable,
    deletePackingClassificationForm,
  });

  return (
    <PageLoading loading={isLoadingPackingListFormList}>
      <Table
        columns={packinkgClassificationColumns(t)}
        loading={isLoadingPackingListFormList}
        headerOptions={headerOptions}
        fieldStatus="ativo"
        editOptions={editTableStatus()}
        editStatusModalLoading={editPackingClassificationFormStatus.isLoading}
        onEdit={onEdit}
        onDelete={onDelete}
        selectedRowsOnTable={selectedRowsOnTable}
        setSelectedRowsOnTable={setSelectedRowsOnTable}
        tableHeaderTooltip={tableHeaderHotKeyTooltip}
        keyToSelect="externalId"
        initialRowsSelected={selectedItems}
        hasSelection
        rowsOptions={rowOptions}
        pagination={packingClassificationFormList}
        onRefetch={refetchPackingList}
        deleteModal={deleteModal}
        dataTestId="production-packaging-classification"
      ></Table>
    </PageLoading>
  );
};

export default PackingClassificationList;
