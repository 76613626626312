import { FC, useEffect } from 'react';
import { Button, Tooltip } from '@/Components';
import { AddProductModal } from './Modals/AddProductModal';
import { useTranslation } from 'react-i18next';
import { HistoricModal } from './Modals/Historic/HistoricModal';
import { AddManipulatedRecipeModal } from './Modals/ManipulatedRecipe/index';
import { PermissionWrapper } from '@/Components/PermissionWrapper';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { Divflex } from '@/Layouts/DivWhithFlex';
import { StatusSalesOrderEnum } from '../../SideRightMenu/Components/StatusHeader/Components/ActionButtons';
import { IGetSaleOrderResponse } from '@/Data/Interfaces/response/Sales/SalesOrder/ISalesOrderResponse';
import { useTopButtonsStates } from './Hooks/useTopButtonsStates';
import { useTicketsDetailsShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/Sales/Tickets/details';

import styles from './TopButtons.module.scss';
import _ from 'lodash';

interface ITopButtons {
  saleOrderExternalId?: string;
  customerDiscount: number;
  client?: {
    externalId: string;
    name: string;
  };
  saleOrder: IGetSaleOrderResponse;
}

export const TopButtons: FC<ITopButtons> = ({
  saleOrderExternalId,
  customerDiscount,
  client,
  saleOrder,
}) => {
  const {
    historicModalOpen,
    setHistoricModalOpen,

    manipulatedRecipeModalOpen,
    setManipulatedRecipModalOpen,

    productModalOpen,
    setProductModalOpen,

    stepCurrent,
    setStepCurrent,
  } = useTopButtonsStates();

  const { t } = useTranslation();
  const { getShortcut } = useTicketsDetailsShortcuts(t);

  const addFinishedProduct = getShortcut('addFinishedProduct');
  const viewHistory = getShortcut('viewHistory');
  const addManipulatedRecipe = getShortcut('addManipulatedRecipe');

  useEffect(
    () =>
      setStepCurrent(
        saleOrder?.pedidoVendaItens.filter((x) => _.isNil(x.produtoAcabado))
          .length
      ),
    [saleOrder.pedidoVendaItens, setStepCurrent]
  );

  return (
    <div className={styles['container']}>
      <Divflex>
        <PermissionWrapper
          permission={PermissionEnum.Vendas_PedidosVenda_EditarDetalhes}
          tooltip={t('noPermission.sale.order.editOrder')}
        >
          <Tooltip title={addFinishedProduct.tooltip} showMe>
            <Button
              className={styles['first-button']}
              type="secondary"
              children={t('saleOrder.editOrder.topButtons.finishedProduct')}
              leftIcon="box-add-alternative"
              onClick={() => setProductModalOpen(true)}
              dataTestId="finished-product-test-id"
              disabled={
                saleOrder?.statusPedido !== StatusSalesOrderEnum.Pendente &&
                saleOrder?.statusPedido !== StatusSalesOrderEnum.Orcado
              }
            />
          </Tooltip>
        </PermissionWrapper>
        <PermissionWrapper
          permission={PermissionEnum.Vendas_PedidosVenda_EditarDetalhes}
          tooltip={t('noPermission.sale.order.editOrder')}
        >
          <Tooltip title={addManipulatedRecipe.tooltip} showMe>
            <Button
              type="secondary"
              children={t('saleOrder.editOrder.topButtons.manipulatedRecipe')}
              leftIcon="gral-add"
              onClick={() => setManipulatedRecipModalOpen(true)}
              dataTestId="manipulated-recipe-test-id"
              disabled={
                saleOrder?.statusPedido !== StatusSalesOrderEnum.Pendente &&
                saleOrder?.statusPedido !== StatusSalesOrderEnum.Orcado
              }
            />
          </Tooltip>
        </PermissionWrapper>
      </Divflex>
      <Tooltip title={viewHistory.tooltip} showMe>
        <Button
          type="secondary"
          children={t('saleOrder.editOrder.topButtons.historic')}
          leftIcon="backup"
          onClick={() => setHistoricModalOpen(true)}
          dataTestId="history-data-test-id"
        />
      </Tooltip>
      <AddProductModal
        changeVisibleModal={(x) => setProductModalOpen(x)}
        visibleModal={productModalOpen}
        initialValues={{
          pedidoVendaExternalId: saleOrderExternalId,
          ...(customerDiscount > 0 && {
            desconto: customerDiscount,
            tipoDesconto: 1,
          }),
        }}
      />

      <AddManipulatedRecipeModal
        changeVisibleModal={(x: boolean) => setManipulatedRecipModalOpen(x)}
        visibleModal={manipulatedRecipeModalOpen}
        countRecipe={stepCurrent}
        client={client}
        saleOrderExternalId={saleOrder.externalId}
      />

      <HistoricModal
        changeVisibleModal={(x) => setHistoricModalOpen(x)}
        visibleModal={historicModalOpen}
        saleOrderExternalId={saleOrderExternalId}
      />
    </div>
  );
};
