import { HorizontalTabs } from '@/Components';
import { PageLoading } from '@/Layouts/PageLoading';
import { useState } from 'react';
import { useConfigTabs } from './tabs/Details/hooks/useConfigTabs';
import { useTabs } from './tabs/Details/hooks/useTabs';
import { DeleteModal } from './tabs/Details/components/DeleteModal';
import { useParams } from 'react-router';
import { CancelInventoryModal } from './tabs/Release/components/CancelInventoryModal';
import { useGetInventory } from './tabs/Release/hooks/useGetInventory';
import { useInventoryCallAPI } from './hooks/useInventoryCallAPI';
import { useInventoryFunctions } from './hooks/useInventoryFunctions';

export const DetailsInventory: React.FC = () => {
  const { tab } = useParams<{ tab: string }>();
  const { externalId } = useParams<{ externalId: string }>();
  const [edit, setEdit] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [visibleCancelModal, setVisibleCancelModal] = useState(false);
  const {
    rightButtonConfig,
    dropdownDetailsItems,
    dropdownReleaseItems,
    dropdownTitle,
  } = useConfigTabs(edit, setEdit);

  const { inventory } = useGetInventory(externalId);

  const { tabs } = useTabs(edit, setEdit, inventory);

  const { cancelInventory } = useInventoryCallAPI();

  const { onOkClickCancelInventoryModal } = useInventoryFunctions({
    externalId,
    setVisibleCancelModal,
  });

  return (
    <>
      <PageLoading>
        <HorizontalTabs
          type="secondary"
          items={tabs}
          dropdownDirection="bottomRight"
          dropdownTitle={dropdownTitle}
          leftIcon="menu-ellipsis-horizontal"
          rightButton={
            inventory?.statusInventarioId === 1 ? rightButtonConfig : undefined
          }
          dropdownItens={
            tab === 'details'
              ? dropdownDetailsItems(() => setVisibleDeleteModal(true))
              : dropdownReleaseItems(() => setVisibleCancelModal(true))
          }
        />
      </PageLoading>
      <DeleteModal
        visibleDeleteModal={visibleDeleteModal}
        setVisibleDeleteModal={setVisibleDeleteModal}
      />
      <CancelInventoryModal
        onCancel={() => setVisibleCancelModal(false)}
        visible={visibleCancelModal}
        onOk={() => onOkClickCancelInventoryModal(cancelInventory)}
        loading={cancelInventory.isLoading}
        inventoryCode={inventory?.codigo.toString()}
        supplyPlacesNames={
          Array.isArray(inventory?.inventarioEspecificacao)
            ? inventory.inventarioEspecificacao.map(
                (item: any) => item.localEstoqueDescricao
              )
            : []
        }
      />
    </>
  );
};
