import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '@/Store';
import { IPermissionWrapper } from '@/Components/PermissionWrapper';
import { BootysShortcut } from '@/Components/Shortcuts';
import { Placements } from '@/Components/Tooltip';

interface IUserListMemorizations {
  getShortcut: (id: string) => BootysShortcut;
}

export const useListMemorizations = ({
  getShortcut,
}: IUserListMemorizations) => {
  const { t } = useTranslation();

  const addBalanceAdjustmentHotkeyTooltip = getShortcut('addBalanceAdjustment');

  const headerOptions = useMemo(
    () => ({
      hasSearch: true,
      newButton: {
        onClick: () => history.push('/inventory/balance-adjustments/create'),
        label: 'supply.balanceAdjustment.list.newButton',
        permission: {
          permission: 2022,
          tooltip: 'noPermission.supply.balanceAdjustments.create',
        },
        dataTestId: 'inventory-balance-adjustment-new-button',
        tooltip: {
          title: addBalanceAdjustmentHotkeyTooltip.tooltip,
          placement: 'topRight' as Placements,
        },
      },
      searchPlaceholder: t('supply.balanceAdjustment.list.searchPlaceHolder'),
      nameKey: 'DataTransferencia',
    }),
    []
  );

  const rowsOptions = useMemo(
    (): {
      permission: IPermissionWrapper;
      onRowDoubleClick?: (record: any) => void;
    } => ({
      onRowDoubleClick: (x) =>
        history.push(`/inventory/balance-adjustments/${x.externalId}/details`),
      permission: {
        permission: 2021,
        tooltip: 'noPermission.supply.balanceAdjustments.detail',
      },
    }),
    []
  );

  return { headerOptions, rowsOptions };
};
