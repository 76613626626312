import { useMemo } from 'react';
import { IUseMemorizations } from './interfaces';
import { useTranslation } from 'react-i18next';
import { history } from '@/Store';
import { PermissionEnum } from '@/Data/Interfaces/model/permissionEnum';
import { DeleteModalBody } from '../Modal/DeleteModal';
import { IDeleteModalTable } from '@/Components/Table';
import { Placements } from '@/Components/Tooltip';

export const useListMemorizations = ({
  onRowDoubleClick,
  selectedRowsOnTable,
  getShortcut,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const addCustomerHotkey = getShortcut('addCustomer');
  const selectAllTableHotKey = getShortcut('selectAll');
  const deleteCustomerHotkey = getShortcut('deleteSelected');
  const editCustomerHotkey = getShortcut('editSelected');

  const headerOptions = useMemo(
    () => ({
      nameKey: 'nome',
      hasSearch: true,
      searchPlaceholder: t('client.listPage.searchPlaceholder'),
      newButton: {
        label: t('client.listPage.newButton'),
        onClick: () => history.push('/sales/customers/create'),
        permission: {
          permission: PermissionEnum.Vendas_Clientes_Cadastrar,
          tooltip: 'noPermission.sale.client.create',
        },
        tooltip: {
          title: addCustomerHotkey.tooltip,
          placement: 'topRight' as Placements,
        },
      },
      deleteButton: {
        permission: {
          permission: PermissionEnum.Vendas_Clientes_Excluir,
          tooltip: 'noPermission.sale.client.delete',
        },
        tooltip: {
          title: deleteCustomerHotkey.tooltip,
          placement: 'topRight' as Placements,
        },
      },
      editButton: {
        tooltip: {
          title: editCustomerHotkey.tooltip,
          placement: 'topRight' as Placements,
        },
        options: [
          {
            label: t('common.status'),
            isStatusOnly: true,
            permission: {
              permission: PermissionEnum.Vendas_Clientes_AlterarStatus,
              tooltip: 'noPermission.sale.client.editStatus',
            },
            key: 's',
          },
        ],
      },
    }),
    []
  );

  const rowsOptions = useMemo(
    () => ({
      onRowDoubleClick: onRowDoubleClick,
      permission: {
        permission: PermissionEnum.Vendas_Clientes_VerDetalhes,
        tooltip: 'noPermission.sale.client.detail',
      },
    }),
    [onRowDoubleClick]
  );

  const deleteModal = useMemo(
    (): IDeleteModalTable | undefined =>
      selectedRowsOnTable && {
        title:
          selectedRowsOnTable.length === 1
            ? `${t('common.delete')} "${selectedRowsOnTable[0].nome}"?`
            : `${t('common.delete')} ${selectedRowsOnTable.length} ${t(
                'client.clients'
              )}?`,
        body: <DeleteModalBody client={selectedRowsOnTable} />,
        buttons: {
          okButtonColor: 'status-danger-base',
        },
        widthModal: 400,
      },
    [selectedRowsOnTable, t]
  );

  const tableHeaderHotKeyTooltip = {
    title: selectAllTableHotKey.tooltip,
    placement: 'topLeft' as Placements,
    targetOffset: [11, 0],
  };

  return {
    headerOptions,
    rowsOptions,
    deleteModal,
    tableHeaderHotKeyTooltip,
  };
};
