import { IStandardFormulaListData } from "@/Data/Interfaces/response/StandardFormula/IStandardFormulaResponse";
import { useState } from "react";

export const useListStates = () => {
  const [
    selectedRowsOnTable, 
    setSelectedRowsOnTable
  ] = useState<IStandardFormulaListData[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[] | undefined>(
    undefined
  );

  return {
    selectedRowsOnTable,
    setSelectedRowsOnTable,
    selectedItems,
    setSelectedItems,
  };
};