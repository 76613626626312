import { useMemo } from 'react';
import { IUseMemorizations } from './interfaces';
import { ILaboratoryData } from '@/Data/Interfaces/response/Laboratory/ILaboratoryResponse';
import { useTranslation } from 'react-i18next';
import { history } from '@/Store';
import { PermissionEnum } from '../../../../../../Data/Interfaces/model/permissionEnum';
import { IPermissionWrapper } from '@/Components/PermissionWrapper';
import { IDeleteModalTable } from '@/Components/Table';
import { TitleHandler, BodyHandler } from '../Modal/laboratoryListUtils';
import { Placements } from '@/Components/Tooltip';

export const useListMemorizations = ({
  selectedRowsOnTable,
  getShortcut,
}: IUseMemorizations) => {
  const { t } = useTranslation();

  const addLaboratoryHotkey = getShortcut('addLaboratory');
  const selectAllTableHotKey = getShortcut('selectAll');
  const deleteProductHotkey = getShortcut('deleteSelected');
  const editProductHotkey = getShortcut('editSelected');

  const headerOptions = useMemo(
    () => ({
      hasSearch: true,
      newButton: {
        label: t('production.laboratory.list.newButton'),
        onClick: () => history.push('/production/labs/create'),
        permission: {
          permission: PermissionEnum.Producao_Laboratorio_Cadastrar,
          tooltip: 'noPermission.production.laboratory.create',
        },
        tooltip: {
          title: addLaboratoryHotkey.tooltip,
          placement: 'topRight' as Placements,
        },
        dataTestId: 'production-laboratory-new-button',
      },
      editButton: {
        permission: {
          permission: PermissionEnum.Producao_Laboratorio_AlterarStatus,
          tooltip: 'noPermission.production.laboratory.statusEdit',
        },
        tooltip: {
          title: editProductHotkey.tooltip,
          placement: 'topRight' as Placements,
        },
        dataTestId: 'production-laboratory-edit-button',
        options: [
          {
            label: t('production.laboratory.list.status'),
            isStatusOnly: true,
            key: 's'
          },
        ],
      },
      deleteButton: {
        permission: {
          permission: PermissionEnum.Producao_Laboratorio_Excluir,
          tooltip: 'noPermission.production.laboratory.delete',
        },
        tooltip: {
          title: deleteProductHotkey.tooltip,
          placement: 'topRight' as Placements,
        },
        dataTestId: 'production-laboratory-delete-button',
      },
      searchPlaceholder: t('production.laboratory.list.searchPlaceholder'),
      nameKey: 'laboratorio',
    }),
    []
  );

  const translationsEditModal = {
    editModal: {
      customEndTitle: t('production.laboratory.list.customEndTitleEditStatusModal')
    }
  };

  const rowsOptions = useMemo(
    (): {
      permission: IPermissionWrapper;
      onRowDoubleClick?: (record: any) => void;
      onRowClick?: (record: any) => void;
    } => ({
      permission: {
        permission: PermissionEnum.Producao_Laboratorio_VerDetalhes,
        tooltip: 'noPermission.production.laboratory.details',
        placement: 'topLeft',
      },
      onRowDoubleClick: (x: ILaboratoryData) =>
        history.push(`/production/labs/${x.externalId}/details`),
    }),
    []
  );

  const deleteModal = useMemo((): IDeleteModalTable | undefined => {
    return {
      title: TitleHandler(selectedRowsOnTable, t),
      body: BodyHandler(selectedRowsOnTable, t),
      buttons: {
        okButtonColor: 'status-danger-base',
      },
      widthModal: 400,
    };
  }, [selectedRowsOnTable, t]);

  const tableHeaderHotKeyTooltip = {
    title: selectAllTableHotKey.tooltip,
    placement: 'topLeft' as Placements,
    targetOffset: [11, 0],
  };

  return {
    headerOptions,
    rowsOptions,
    deleteModal,
    translationsEditModal,
    tableHeaderHotKeyTooltip
  };
};
