import { useControllerMutateApiHook } from '@/ControllerApiHook/Controller';
import { LabelTemplatesAPI } from '@/Data/API/Production/LabelTemplates';
import { CREATE_LABEL_TEMPLATES, FETCH_LIST_LABEL_TEMPLATES } from '@/ControllerApiHook/UniqIds/Production/LabelTemplatesKeys';
import { history } from '@/Store';
import { messageErrorToast } from '@/Utils/MessageErrorToast';
import { useQueryClient } from '@tanstack/react-query';

export const useCreateCallAPI = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: createLabelTemplate, isLoading: createLabelTemplateIsLoading }: any =
    useControllerMutateApiHook({
      uniqId: CREATE_LABEL_TEMPLATES,
      entityApi: LabelTemplatesAPI.createLabelTemplate,
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries([FETCH_LIST_LABEL_TEMPLATES]);
          history.push('/production/label-templates');
        },
        onError: (error) => messageErrorToast(error),
      },
    });

  return { createLabelTemplate, createLabelTemplateIsLoading };
};
