import {
  BootysShortcuts,
  defaultShortcuts,
} from '@/Components/Shortcuts/keymaps/default';
import { TFunction } from 'react-i18next';
import { useHotkeys } from 'reakeys';
import { getShortcut, getShortcuts } from '@/Components/Shortcuts';
import { isVisible } from '@/Utils/DOMUtils';

export const pageKey = 'header-menu';

const shortcuts = (
  t: TFunction<'translation', undefined>
): BootysShortcuts[] => [
  ...defaultShortcuts(t),
  {
    id: 'openUserMenu',
    name: t('shortcuts.headerMenu.openUserMenu'),
    keys: 'alt+u',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="user-menu-avatar-button"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'openCompanyMenu',
    name: t('shortcuts.headerMenu.changeCompany'),
    keys: 'alt+e',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="user-company-button"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'selectManageAccountOption',
    name: t('shortcuts.headerMenu.openUserMenu'),
    keys: 'g+a',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="manage-account-option"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'selectPreferenceOption',
    name: t('shortcuts.headerMenu.openUserMenu'),
    keys: 'g+p',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="manage-preference-option"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'selectHelpOption',
    name: t('shortcuts.headerMenu.openUserMenu'),
    keys: '?',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector('[data-testid="help-option"]');

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'selectExitOption',
    name: t('shortcuts.headerMenu.openUserMenu'),
    keys: 'alt+shift+q',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector('[data-testid="exit-option"]');

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
];

export const useHeaderMenuShortcuts = (
  t: TFunction<'translation', undefined>
) => {
  const pageShortcuts = shortcuts(t);

  const hotkeys = useHotkeys(pageShortcuts);

  return {
    ...hotkeys,
    getShortcut: getShortcut(pageShortcuts),
    getShortcuts: getShortcuts(pageShortcuts),
  };
};
