import { accountManagerEN as accountManager } from './partials/accountManager';
import { adminEN as admin } from './partials/admin';
import { adminBootisEN as adminBootis } from './partials/adminBootis';
import { breadCrumbEN as breadCrumb } from './partials/breadCrumb';
import { commonEN as common } from './partials/common';
import { dateEN as date } from './partials/date';
import { districtEN as district } from './partials/district';
import { dropDownEN as dropDown } from './partials/dropDown';
import { forgotPasswordEN as forgotPassword } from './partials/forgotPassword';
import { forgotPasswordSuccessEN as forgotPasswordSuccess } from './partials/forgotPasswordSuccess';
import { headerEN as header } from './partials/header';
import { layoutEN as layout } from './partials/layout';
import { loginEN as login } from './partials/login';
import { modalEN as modal } from './partials/modal';
import { notificationsEN as notifications } from './partials/notifications';
import { preferencesEN as preferences } from './partials/preferences';
import { recoverPasswordEN as recoverPassword } from './partials/recoverPassword';
import { systemConfigEN as systemConfig } from './partials/systemConfig';
import { tableEN as table } from './partials/table';
import { toastEN as toast } from './partials/toast';
import { sideMenuEN as sideMenu } from './partials/sideMenu';
import { productsEN as products } from './partials/products';
import { userGroupsEN as userGroups } from './partials/userGroups';
import { supplyEN as supply } from './partials/supply';
import { noPermissionEN as noPermission } from './partials/noPermission';
import { purchasingEN as purchasing } from './partials/purchasing';
import { prescribersEN as prescribers } from './partials/prescribers';
import { specialtiesEN as specialties } from './partials/specialties';
import { addressEN as address } from './partials/address';
import { contactEN as contact } from './partials/contact';
import { documentEN as document } from './partials/document';
import { productionEN as production } from './partials/production';
import { clientEN as client } from './partials/client';
import { callCenterEN as callCenter } from './partials/callCenter';
import { saleOrderEN as saleOrder } from './partials/saleOrder';
import { currencyEN as currency } from './partials/currency';
import { shortcutsEN as shortcuts } from './partials/shortcuts';

const translations = {
  translations: {
    breadCrumb,
    header,
    modal,
    common,
    layout,
    table,
    toast,
    dropDown,
    district,
    forgotPassword,
    forgotPasswordSuccess,
    recoverPassword,
    login,
    preferences,
    accountManager,
    systemConfig,
    notifications,
    adminBootis,
    admin,
    date,
    adminBootisLabel: 'Admin Bootis',
    adminLabel: 'Admin',
    sideMenu,
    products,
    userGroups,
    supply,
    noPermission,
    purchasing,
    prescribers,
    specialties,
    address,
    contact,
    document,
    production,
    client,
    callCenter,
    saleOrder,
    currency,
    shortcuts,
  },
};

export default translations;
