import { Button } from '@/Components';
import _ from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '../../../../../../../Store';
import styles from './LabelTemplate.module.scss';

interface IFooterButtons {
  isLoadingCreateLabelTemplate: boolean;
}

export const FooterButtons: FC<IFooterButtons> = ({
  isLoadingCreateLabelTemplate,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles['footer-button']}>
      <Button
        type="secondary"
        children={t('production.labelTemplates.create.footerButton.cancel')}
        onClick={() => {
          history.push('/production/label-templates');
        }}
      />
      <Button
        type="primary"
        children={t('production.labelTemplates.create.footerButton.okButton')}
        htmlType="submit"
        loading={isLoadingCreateLabelTemplate}
        dataTestId="submit-label-template"
      />
    </div>
  );
};
