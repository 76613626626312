import { useTranslation } from 'react-i18next';
import { PageLoading } from '../../../../../Layouts/PageLoading';
import { Table } from '../../../../../Components/Table';
import { editTableStatus } from '../../../../../Utils/EditModalTableUtils';
import { laboratoryColumns } from './Laboratory.column';
import { useListStates } from './Hooks/useListStates';
import { useListCallAPI } from './Hooks/useListCallAPI';
import { useListFunctions } from './Hooks/useListFunctions';
import { useListMemorizations } from './Hooks/useListMemorizations';
import { useLaboratoryListShortcuts } from '@/Components/Shortcuts/keymaps/pages/sectors/Production/Laboratory/list';

export const LaboratoryList: React.FC = () => {
  const { t } = useTranslation();

  const { selectedRowsOnTable, setSelectedRowsOnTable, selectedItems } =
  useListStates();

  const {
    deleteLaboratoryForm,
    laboratoryList,
    isLoadingLaboratoryList,
    editLaboratoryFormStatus,
    refetchLaboratoryList,
  } = useListCallAPI();

  const { getShortcut } = useLaboratoryListShortcuts(t, {});
  const { headerOptions, rowsOptions, deleteModal, translationsEditModal, tableHeaderHotKeyTooltip  } =
    useListMemorizations({
      selectedRowsOnTable,
      getShortcut,
    });

  const { onDelete, onEdit } = useListFunctions({
    deleteLaboratoryForm,
    setSelectedRowsOnTable,
    editLaboratoryFormStatus,
  });

  return (
    <PageLoading loading={isLoadingLaboratoryList}>
      <Table
        columns={laboratoryColumns(t)}
        loading={isLoadingLaboratoryList}
        headerOptions={headerOptions}
        onEdit={onEdit}
        fieldStatus="ativo"
        editOptions={editTableStatus()}
        editStatusModalLoading={editLaboratoryFormStatus.isLoading}
        hasSelection
        rowsOptions={rowsOptions}
        pagination={laboratoryList}
        onRefetch={refetchLaboratoryList}
        onDelete={onDelete}
        selectedRowsOnTable={selectedRowsOnTable}
        setSelectedRowsOnTable={setSelectedRowsOnTable}
        tableHeaderTooltip={tableHeaderHotKeyTooltip}
        keyToSelect="externalId"
        initialRowsSelected={selectedItems}
        deleteModal={deleteModal}
        dataTestId="production-laboratory"
        translations={translationsEditModal}
      />
    </PageLoading>
  );
};

export default LaboratoryList;
