import { Card } from '@components/Card';
import { Col, Row } from 'antd';
import { ProductRow } from '@/Pages/Sectors/Order/Invoice/RegisterLotsRefact/components/ProductRow';
import FormBreakLine from '@/Components/FormBreakLine';
import styles from './lotsStyles.module.scss';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { configColumnsSpacing } from '../../utils/functions';
import useMediaQuery from '@/Utils/CustomHooks/useMediaQuery';

interface ILotsSectionProps {
  products: any[];
  showTechnicalInformation: boolean;
  showTechInformation: () => void;
  toggleShowTechnicalInformation: () => void;
  selectProductIndex: (index: number) => void;
  lotsListController: any[];
  createNewLot: (itemId: number) => void;
  removeLot: (uuid: string) => void;
  selectLotIndex: (index: number) => void;
  setLotErrorNamesIndexes: (value: number[]) => void;
  lotErrorNamesIndexes: number[];
  savedLots: any[];
  setSubmitButtonState: (value: boolean) => void;
}

export const LotsSection: React.FC<ILotsSectionProps> = ({
  products,
  showTechnicalInformation,
  showTechInformation,
  toggleShowTechnicalInformation,
  selectProductIndex,
  lotsListController,
  createNewLot,
  removeLot,
  selectLotIndex,
  setLotErrorNamesIndexes,
  lotErrorNamesIndexes,
  savedLots,
  setSubmitButtonState,
}) => {
  const { t } = useTranslation();
  const [selectedRow, setSelectedRow] = useState<number | null>(null);

  const selectRow = (rowIndex: number) => {
    setSelectedRow(rowIndex);
  };

  const configButtonText = () => {
    if (showTechnicalInformation) {
      return t('purchasing.invoice.registerLots.hideDetails');
    } else {
      return t('purchasing.invoice.registerLots.showDetails');
    }
  };

  useEffect(() => {
    if (!savedLots) return;

    if (savedLots.length) {
      savedLots.forEach((e) => {
        createNewLot(e.notaFiscalEntradaItemId);
      });
    } else {
      products.forEach((e) => {
        createNewLot(e.notaFiscalEntradaItemId);
      });
    }
  }, [savedLots]);

  return (
    <Card
      withoutCollapse
      title={t('purchasing.invoice.registerLots.lotsCardTitle')}
      showButtonTitle
      isButtonTitleDisabled={selectedRow === null}
      onButtonTitleClick={toggleShowTechnicalInformation}
      buttonTittleText={configButtonText()}
    >
      <HeaderRow showTechnicalInformation={showTechnicalInformation} />
      <FormBreakLine />
      {products.map((e, i) => {
        return (
          <div
            key={e.notaFiscalEntradaItemId}
            onClick={() => selectProductIndex(i)}
          >
            <ProductRow
              product={e}
              index={i}
              selectedRow={selectedRow}
              selectRow={selectRow}
              showTechnicalInformation={showTechnicalInformation}
              showTechInformation={showTechInformation}
              lotsListController={lotsListController}
              createNewLot={createNewLot}
              removeLot={removeLot}
              selectLotIndex={selectLotIndex}
              setLotErrorNamesIndexes={setLotErrorNamesIndexes}
              lotErrorNamesIndexes={lotErrorNamesIndexes}
              savedLots={savedLots}
              setSubmitButtonState={setSubmitButtonState}
            />
            {i < products.length - 1 && <FormBreakLine marginBottom="0px" />}
          </div>
        );
      })}
    </Card>
  );
};

const HeaderRow: React.FC<{ showTechnicalInformation: boolean }> = ({
  showTechnicalInformation,
}) => {
  const { t } = useTranslation();
  const showDatesWidth = '2133px';
  const keepDatesOnScreen = useMediaQuery(`(min-width: ${showDatesWidth})`);
  const { firstColumn, restColumns } = configColumnsSpacing(
    keepDatesOnScreen,
    showTechnicalInformation
  );
  return (
    <Row wrap={false} gutter={[16, 0]} className={styles['headerRow']}>
      <Col span={firstColumn} className={styles['headerItem']}>
        {t('purchasing.invoice.registerLots.lotsCardHeader.product')}
        <span>*</span>
      </Col>
      <Col span={restColumns} className={styles['headerItem']}>
        {t('purchasing.invoice.registerLots.lotsCardHeader.quantity')}
        <span>*</span>
      </Col>
      <Col span={restColumns} className={styles['headerItem']}>
        {t('purchasing.invoice.registerLots.lotsCardHeader.lot')}
        <span>*</span>
      </Col>
      {(!showTechnicalInformation || keepDatesOnScreen) && (
        <>
          <Col span={restColumns} className={styles['headerItem']}>
            {t(
              'purchasing.invoice.registerLots.lotsCardHeader.manufacturingDate'
            )}
            <span>*</span>
          </Col>
          <Col span={restColumns} className={styles['headerItem']}>
            {t('purchasing.invoice.registerLots.lotsCardHeader.expiryDate')}
            <span>*</span>
          </Col>
        </>
      )}
    </Row>
  );
};
