import {
  BootysShortcuts,
  defaultShortcuts,
} from '@/Components/Shortcuts/keymaps/default';
import { TFunction } from 'react-i18next';
import { useHotkeys } from 'reakeys';
import { getShortcut, getShortcuts } from '@/Components/Shortcuts';
import { isVisible } from '@/Utils/DOMUtils';

export const pageKey = 'sector-menu';

const shortcuts = (
  t: TFunction<'translation', undefined>
): BootysShortcuts[] => [
  ...defaultShortcuts(t),
  {
    id: 'openSectorMenu',
    name: t('shortcuts.sectorMenu.openSectorMenu'),
    keys: 'alt+s',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="open-sector-menu-button"]'
      );

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'openSectorMenuItemAdministrative',
    name: t('shortcuts.sectorMenu.openSectorMenu'),
    keys: 'a',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector('[data-testid="sector-item-0"]');

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'openSectorMenuItemInventory',
    name: t('shortcuts.sectorMenu.openSectorMenu'),
    keys: 'e',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector('[data-testid="sector-item-1"]');

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'openSectorMenuItemProduction',
    name: t('shortcuts.sectorMenu.openSectorMenu'),
    keys: 'p',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector('[data-testid="sector-item-2"]');

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'openSectorMenuItemOrder',
    name: t('shortcuts.sectorMenu.openSectorMenu'),
    keys: 'c',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector('[data-testid="sector-item-3"]');

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'openSectorMenuItemSales',
    name: t('shortcuts.sectorMenu.openSectorMenu'),
    keys: 'v',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector('[data-testid="sector-item-4"]');

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
];

export const useSectorMenuShortcuts = (
  t: TFunction<'translation', undefined>
) => {
  const pageShortcuts = shortcuts(t);

  const hotkeys = useHotkeys(pageShortcuts);

  return {
    ...hotkeys,
    getShortcut: getShortcut(pageShortcuts),
    getShortcuts: getShortcuts(pageShortcuts),
  };
};
