import { Col, Row } from 'antd';
import { PlusInput } from './components/PlusInput';
import { useFormikContext } from 'formik';
import { useGetOpenPurchaseOrderList } from '@/Hooks/HooksServices/Purchase/PurchaseOrder/useGetOpenPurchaseOrderList';
import { useEffect, useState } from 'react';
import AddPurchaseOrderModal from './components/PurchaseOrderFoundModal';
import { PurchaseOrdersModal } from './components/PurchaseOrdersModal';

interface IRowSix {
  keepSupplier: boolean;
  setKeepSupplier: any;
}

export const RowSix: React.FC<IRowSix> = ({
  keepSupplier,
  setKeepSupplier,
}) => {
  const [visibleFoundPurchaseModal, setVisibleFoundPurchaseModal] =
    useState(false);
  const [visiblePurchaseOrderTable, setVisiblePurchaseOrderTable] =
    useState(false);

  const form: any = useFormikContext();
  const supplierId = form.values.fornecedorExternalId;

  const {
    data: allDataPurchaseOrder,
    refetch: refetchOpenPurchaseOrderList,
    isLoading,
  } = useGetOpenPurchaseOrderList({
    fieldName: 'pedidosCompra',
    enabled: !!supplierId,
  });

  useEffect(() => {
    if (supplierId) {
      refetchOpenPurchaseOrderList({
        filter: [{ filterName: 'fornecedorExternalId', value: supplierId }],
      });
    }
  }, [supplierId, keepSupplier]);

  useEffect(() => {
    if (
      allDataPurchaseOrder &&
      allDataPurchaseOrder.data.length &&
      !keepSupplier
    ) {
      setVisibleFoundPurchaseModal(true);
    }
  }, [allDataPurchaseOrder, keepSupplier]);

  return (
    <Row gutter={[16, 0]}>
      <Col span={6}>
        <PlusInput
          showEdit={false}
          setVisiblePurchaseOrderTable={setVisiblePurchaseOrderTable}
          allDataPurchaseOrder={
            allDataPurchaseOrder ? allDataPurchaseOrder.data : []
          }
        />

        <AddPurchaseOrderModal
          visible={visibleFoundPurchaseModal}
          changeVisibleModal={(value) => setVisibleFoundPurchaseModal(value)}
          purchaseOrderList={
            allDataPurchaseOrder ? allDataPurchaseOrder.data : []
          }
          totalPurchaseOrder={
            allDataPurchaseOrder ? allDataPurchaseOrder.count : 0
          }
          setVisiblePurchaseOrderTable={() => {
            setKeepSupplier(true);
            setVisiblePurchaseOrderTable(true);
          }}
        />

        <PurchaseOrdersModal
          changeVisibleModal={(value) => setVisiblePurchaseOrderTable(value)}
          visible={visiblePurchaseOrderTable}
          purchaseOrderList={
            allDataPurchaseOrder ? allDataPurchaseOrder : undefined
          }
          refetchPurchaseOrderList={refetchOpenPurchaseOrderList}
          setPurchaseOrderList={(value: any) => {
            form.setFieldValue('pedidosCompra', value);
          }}
          isLoading={isLoading}
        />
      </Col>
    </Row>
  );
};
