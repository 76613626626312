import {
  BootysShortcuts,
  defaultShortcuts,
} from '@/Components/Shortcuts/keymaps/default';
import { TFunction } from 'react-i18next';
import { history } from '../../../../../../../Store';
import { useHotkeys } from 'reakeys';
import { isVisible } from '@/Utils/DOMUtils';
import { getShortcut, getShortcuts } from '@/Components/Shortcuts';

export const pageKey = 'admin-company-list';

const shortcuts = (
  t: TFunction<'translation', undefined>
): BootysShortcuts[] => [
  ...defaultShortcuts(t),
  {
    id: 'addAdminCompany',
    name: t('adminBootis.shortcuts.addNewCompany'),
    keys: 'n',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      history.push('/admin-bootis/company/create');
    },
  },
  {
    id: 'selectAll',
    name: t('products.productList.shortcuts.selectAll'),
    keys: 'alt+x',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector(
        '[data-testid="table-select-all"]'
      );

      //@ts-ignore
      element?.click();
    },
  },
  {
    id: 'search',
    name: 'search',
    keys: 'p',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector('[data-testid="table-search"]');

      if (isVisible(element)) {
        //@ts-ignore
        element?.focus();
      }
    },
  },
  {
    id: 'editSelected',
    name: t('products.productList.shortcuts.editSelected'),
    keys: 'e',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector('#edit-selected-items');

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'editStatus',
    name: 'editStatus',
    keys: 's',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector('#edit-selected-items-0');

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
  {
    id: 'deleteSelected',
    name: t('products.productList.shortcuts.deleteSelected'),
    keys: 'backspace',
    action: 'keydown',
    callback: (event: any) => {
      event.preventDefault();
      const element = document.querySelector('#delete-select-items');

      if (isVisible(element)) {
        //@ts-ignore
        element?.click();
      }
    },
  },
];

export const useAdminCompanyListShortcuts = (
  t: TFunction<'translation', undefined>
) => {
  const pageShortcuts = shortcuts(t);

  const hotkeys = useHotkeys(pageShortcuts);

  return {
    ...hotkeys,
    getShortcut: getShortcut(pageShortcuts),
    getShortcuts: getShortcuts(pageShortcuts),
  };
};
