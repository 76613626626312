import { useTranslation } from 'react-i18next';
import { history } from '@/Store';
import { BootysShortcut } from '@/Components/Shortcuts';
import { Placements } from '@/Components/Tooltip';

interface IUserListMemorizations {
  getShortcut: (id: string) => BootysShortcut;
}

export const useListMemorizations = ({
  getShortcut,
}: IUserListMemorizations) => {
  const { t } = useTranslation();

  const addTransferHotkeyTooltip = getShortcut('addTransfer');

  const headerOptions = {
    nameKey: 'DataTransferencia',
    hasSearch: true,
    newButton: {
      onClick: () => history.push('/inventory/transfers/create'),
      label: 'supply.transfers.newButton',
      permission: {
        permission: 2015,
        tooltip: 'noPermission.supply.transfers.create',
      },
      dataTestId: 'inventory-transfer-new-button',
      tooltip: {
        title: addTransferHotkeyTooltip.tooltip,
        placement: 'topRight' as Placements,
      },
    },
    searchPlaceholder: t('supply.transfers.searchPlaceHolder'),
  };

  const rowOptions = {
    onRowDoubleClick: (x: any) =>
      history.push(`/inventory/transfers/${x?.externalId}/details`),
    permission: {
      permission: 2014,
      tooltip: 'noPermission.supply.transfers.detail',
    },
  };

  return {
    headerOptions,
    rowOptions,
  };
};
